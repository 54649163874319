import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Servicer } from 'src/app/interfaces/servicer';
import { Breadcrumb } from 'src/app/interfaces/breadcrumb';
import { ProjetosService } from 'src/app/services/projetos.service'
import { ServicerEditDialogComponent } from './servicer-edit-dialog/servicer-edit-dialog.component';

@Component({
  selector: 'app-servicer-list',
  templateUrl: './servicer-list.component.html',
  styleUrls: ['./servicer-list.component.css']
})
export class ServicerListComponent implements OnInit {

constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public projetoService: ProjetosService) {
  }
  private ngUnsubscriber = new Subject()
  
  public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Cadastros', href: 'gestao/cadastros' },
        { text: 'Servicers', href: 'gestao/list/servicers' },
  ]

  public loading: boolean = false
  public servicers: Servicer[] = []

  createServicerDialog(): void {
    const servicerDialog = this.dialog.open(ServicerEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            servicer: {id:-1, servicer:""},
        },
    })

    servicerDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading = true
          this.createServicer(params.servicer)
        }
        return
    })
  }

  createServicer(servicer:Servicer):void{
    let { id, ...servicerWithoutID} = servicer
    this.projetoService
        .createServicer(servicerWithoutID)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'OK') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar servicer', 'error-snackbar')
                }
                this.getServicers()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar servicer' + errorData.error.message, 'error-snackbar')
              this.getServicers()
            }
        )
  }

  updateServicerDialog(servicer: any): void {
    const servicerDialog = this.dialog.open(ServicerEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            servicer: servicer,
            create: false,
        },
    })

    servicerDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading = true
          this.updateServicer(params.servicer)
        }
        return
    })
  }

  updateServicer(servicer:Servicer):void{
    this.projetoService
        .updateServicer(servicer)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'OK') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar servicer', 'error-snackbar')
                }
                this.getServicers()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar servicer' + errorData.error.message, 'error-snackbar')
              this.getServicers()
            }
        )
  }

  ngOnInit(): void {
    this.loading = true
    this.getServicers()
  }

  getServicers():void{
    this.projetoService
        .getServicers()
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe((returnData: any) => {
            console.log(returnData)
            this.servicers = returnData.servicers
            this.loading = false
        })
  }

  openSnackbar(message: string, messageType: string): void {
    this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
  }

}
