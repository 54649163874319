import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core'
import { TriggerCard } from 'src/app/interfaces/trigger_card'
import { MatDialog } from '@angular/material/dialog'
import { Cri } from 'src/app/interfaces/cri'
import { ConfirmationdialogComponent } from 'src/app/modules/controladoria/calculos/confirmationdialog/confirmationdialog.component'

@Component({
    selector: 'app-triggercard',
    templateUrl: './triggercard.component.html',
    styleUrls: ['./triggercard.component.css'],
})
export class TriggercardComponent implements OnInit {
    @Input('triggerCard') declare triggerCard: TriggerCard
    @Input('cris') declare cris: Cri[]
    @Output('onTriggered') trigger = new EventEmitter()

    triggerEvent(): void {
        const confirmationDialog = this.dialog.open(ConfirmationdialogComponent, {
            width: '70%',
            height: 'auto',
            autoFocus: false,
            position: {
                top: '20px',
            },
            data: {
                title: 'Confirme a operação',
                subtitle: 'Para quais CRIs você quer calcular ' + this.triggerCard.title + '?',
                hasDate: this.triggerCard.hasDate,
                cris: this.cris,
            },
        })

        confirmationDialog.afterClosed().subscribe((result) => {
            var params = result
            if (!params) return
            params['event'] = this.triggerCard.title
            this.trigger.emit(params)
        })
    }
    constructor(public dialog: MatDialog) {}

    ngOnInit(): void {}
    ngOnChanges(): void {}
}
