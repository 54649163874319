<app-navbar></app-navbar>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="bottom-overlay">
    <div [ngClass]="loading ? 'disabled-div' : ''">
        <div *ngIf="regrasArredondamento.length">
            <div class="container" style="margin-top: 35px" *ngFor="let ra of regrasArredondamento">
                <div style="align-items: center" class="row">
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <app-h2title [text]="ra.Nome"></app-h2title>
                    </div>
                    <div class="col-lg-9 col-md-6 col-sm-12">
                        <dl>
                            <div *ngFor="let regra of ra.Descricao">
                                <div *ngIf="regra.includes('='); else dd">
                                    <dt>{{ removeBackSlash(regra) }}</dt>
                                </div>

                                <ng-template #dd>
                                    <dd>{{ removeBackSlash(regra) }}</dd>
                                </ng-template>
                            </div>
                        </dl>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    </div>
</div>
