import { Component, OnInit, Input, Output } from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.css'],
})
export class DatepickerComponent implements OnInit {
    @Input('text') text: string
    @Input('date') date: string = ''
    @Input('availableDates') availableDates: string[]

    @Output('onDateChange') onDateChange = new EventEmitter()
    constructor(private dateAdapter: DateAdapter<Date>) {
        this.text = ''
        this.dateAdapter.setLocale('pt-BR')
        this.availableDates = []
    }

    public declare formControlDate: FormControl

    filterDates = (date: Date | null): boolean => {
        if (date != null) {
            const stringDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
            if (this.availableDates.length) {
                return this.availableDates.includes(stringDate)
            } else return true
        } else {
            return false
        }
    }

    changeDate(event: any): void {
        this.onDateChange.emit(event.value)
    }

    ngOnInit(): void {
        if (this.date != '') {
            const dateAux: string[] = new Date(this.date).toISOString().split('T')[0].split('-')
            this.formControlDate = new FormControl(new Date(parseInt(dateAux[0]), parseInt(dateAux[1]) - 1, parseInt(dateAux[2])))
        } else this.formControlDate = new FormControl()

        this.formControlDate.disable()
    }

    ngOnChanges(): void {}
}
