import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { IgxSparklineComponent } from 'igniteui-angular-charts'

@Component({
    selector: 'app-parcelas-atraso-spark-line',
    templateUrl: './parcelas-atraso-spark-line.component.html',
    styleUrls: ['./parcelas-atraso-spark-line.component.css'],
})
export class ParcelasAtrasoSparkLineComponent implements OnInit {
    @Input('data') data: any[] = []

    public formated_data: any[] = []

    constructor() {}

    ngOnInit(): void {
        this.formated_data = []
        for (let index = 0; index < this.data.length; index++) {
            this.formated_data.push({ Label: this.data[index], Value: this.data[index] })
        }
    }

    ngOnChanges(): void {
        this.formated_data = []
        for (let index = 0; index < this.data.length; index++) {
            this.formated_data.push({ Label: this.data[index], Value: this.data[index] })
        }
    }
}
