import { Component, OnInit, Input } from '@angular/core'
import { ChartService } from 'src/app/services/chart.service'

@Component({
    selector: 'app-fluxofuturo-dashboard',
    templateUrl: './fluxofuturo-dashboard.component.html',
    styleUrls: ['./fluxofuturo-dashboard.component.css'],
})
export class FluxofuturoDashboardComponent implements OnInit {
    @Input('categorias') categorias: string[] = []
    @Input('curvasPmtsNames') curvasPmtsNames: string[] = []
    @Input('fluxoFuturoData') fluxoFuturoData: any = {}
    @Input('limiteRazaoFluxoMensal') limiteRazaoFluxoMensal: number = 0
    @Input('categoriasNaCarteira') categoriasNaCarteira: string[] = []
    @Input('obrasSiglas') obrasSiglas: string[] = []

    public chartOptions: any
    public curvaDatas: string[] = []

    selectedOption: string = 'Todas as Categorias'
    selectedObra: string = 'Total'

    constructor() {}

    public customColors: any[] = [
        { name: 'Total', value: '#e7a29d' },
        { name: 'Adimplente', value: '#c4170c' },
    ]

    ngOnInit(): void {
        this.curvaDatas = this.fluxoFuturoData['MesParcela']
        this.assembleChartData('Todas as Categorias', 'Total')
    }

    changeChart(event: any, tipo:string) {
        if (tipo === "obra"){
            this.selectedObra = event.value
        } else {
            this.selectedOption = event.value
        }
        this.assembleChartData(this.selectedOption, this.selectedObra)
    }

    assembleChartData(categoria: string, obra:string): void {
        if (categoria == 'Todas as Categorias') {
            this.assembleAllCategories(obra)
        } else if (categoria == 'Razão Fluxo Mensal') {
            this.assembleRazaoFluxoMensal()
        } else {
            this.assembleCategory(categoria, obra)
        }
    }

    assembleAllCategories(obra:string): void {
        let series:any[] = []
        if (obra === 'Total'){
            series = [
                { name: 'Adimplente', data: this.fluxoFuturoData['Adimplente'] },
                { name: 'Total', data: this.fluxoFuturoData['Total'] },
            ]
        } else {
            series = [
                { name: 'Adimplente', data: this.fluxoFuturoData[obra + ' ' + 'Adimplente'] },
                { name: 'Total', data: this.fluxoFuturoData[obra + ' ' + 'Total'] },
            ]
        }


        // Cores
        let colors = ['#c4170c', '#e7a29d']
        let PMTColorsCategoriasNaCarteira = {
            Senior: '#191B9C',
            'Mez. senior': '#191B9C',
            'Mez. sub': '#191B9C',
            Subordinada: '#191B9C',
        } // Se no futuro tiverem outras categorias é só adicionar cores aqui
        let PMTColorsOutrasCategorias = {
            Senior: '#878787',
            'Mez. senior': '#878787',
            'Mez. sub': '#878787',
            Subordinada: '#878787',
        }

        // Linhas pontilhadas (Queremos as liberações futuras como linhas pontilhadas e espessura 1)
        let widthArray = [2, 2]
        let dashArray = [0, 0]

        for (let i = 0; i < this.categorias.length; i++) {

            // PMT da categoria
            series.push({ name: 'PMT ' + this.categorias[i], data: this.fluxoFuturoData['PMT ' + this.categorias[i]] })
            widthArray.push(2)
            dashArray.push(0)
            if (this.categoriasNaCarteira.includes(this.categorias[i])){
                colors.push((PMTColorsCategoriasNaCarteira as any)[this.categorias[i]])
            } else{
                colors.push((PMTColorsOutrasCategorias as any)[this.categorias[i]])
            }

            // Se existir PMT com liberação da categoria
            if (this.fluxoFuturoData['PMTLibFutura ' + this.categorias[i]]) {
                series.push({ name: 'PMTLibFutura ' + this.categorias[i], data: this.fluxoFuturoData['PMTLibFutura ' + this.categorias[i]] })
                widthArray.push(1)
                dashArray.push(4)
                if (this.categoriasNaCarteira.includes(this.categorias[i])) {
                    colors.push((PMTColorsCategoriasNaCarteira as any)[this.categorias[i]])
                } else {
                    colors.push((PMTColorsOutrasCategorias as any)[this.categorias[i]])
                }
            }
        }

        let chart = {
            height: 400,
            type: 'line',
        }

        let stroke = {
            width: widthArray,
            dashArray: dashArray,
        }

        this.chartOptions = {
            series: series,
            chart: chart,
            stroke: stroke,
            dataLabels: { enabled: false },
            labels: this.curvaDatas,
            xaxis: {
                tickAmount: 20,
                labels: { hideOverlappingLabels: true },
            },
            yaxis: {},
            colors: colors,
            grid: { show: false },
            legend: {
                show: true,
                floating: true,
                onItemClick: { toggleDataSeries: true },
                position: 'right',
            },
        }
    }

    assembleCategory(option: string, obra:string): void {
        let adimplenteString = ''
        if (obra === "Total"){
            adimplenteString = "Adimplente"
        } else {
            adimplenteString = obra + " " + "Adimplente"
        }

        let series = [
            { name: 'Adimplente', data: this.fluxoFuturoData[adimplenteString], type: 'line' },
            { name: 'PMT', data: this.fluxoFuturoData['PMT ' + option], type: 'area' },
            { name: 'Amortização', data: this.fluxoFuturoData['Amortizacao ' + option], type: 'line' },
            { name: 'Juros', data: this.fluxoFuturoData['Juros ' + option], type: 'area' },
        ]

        // Cores
        let colors = ['#c4170c', '#B7B7B7', '#C3C2C2', '#575757']

        let widthArray = [2, 2, 2, 2]
        let dashArray = [0, 0, 0, 0]

        let chart = {
            height: 400,
            type: 'line',
        }

        let stroke = {
            width: widthArray,
            dashArray: dashArray,
        }

        let yaxis = {
            labels: {
                formatter: (value: number) => {
                    return Math.round(value).toLocaleString()
                },
            },
        }

        this.chartOptions = {
            series: series,
            chart: chart,
            stroke: stroke,
            dataLabels: { enabled: false },
            labels: this.curvaDatas,
            xaxis: {
                tickAmount: 20,
                labels: { hideOverlappingLabels: true },
            },
            yaxis: yaxis,
            colors: colors,
            grid: { show: false },
            legend: {
                show: true,
                floating: true,
                onItemClick: { toggleDataSeries: true },
                position: 'right',
            },
            fill: {
                opacity: [1, 1, 0, 1],
            },
        }
    }

    assembleRazaoFluxoMensal(): void {
        let series = [{name: 'Razão Fluxo Mensal', data: this.fluxoFuturoData['RazaoFluxoMensal']}]

        let widthArray = [2]
        let dashArray = [0]

        if (this.fluxoFuturoData['RazaoFluxoMensalComLibFutura']) {
            series.push({ name: 'Razão Fluxo Mensal Com Lib. Futura', data: this.fluxoFuturoData['RazaoFluxoMensalComLibFutura'] })
            widthArray.push(1)
            dashArray.push(4)
        }

        let colors = ['#c4170c']
        let chart = {
            height: 400,
            type: 'line',
        }
        let yaxis = {
            labels: {
                formatter: (value: number) => {
                    return value.toFixed(2)
                },
            },
        }
        let stroke = {
            width: widthArray,
            dashArray: dashArray,
        }

        let annotations = {
            yaxis: [
                {
                    y: this.limiteRazaoFluxoMensal,
                    opacity: 1,
                    strokeDashArray: 2,
                    borderColor: '#000000',
                    label: {
                        style: {
                            color: '#000000',
                            fontWeight: 'bold',
                        },
                        text: 'Limite RFM: ' + this.limiteRazaoFluxoMensal.toString(),
                    },
                },
            ],
        }

        this.chartOptions = {
            series: series,
            chart: chart,
            stroke: stroke,
            dataLabels: { enabled: false },
            labels: this.curvaDatas,
            xaxis: {
                tickAmount: 20,
                labels: { hideOverlappingLabels: true },
            },
            yaxis: yaxis,
            colors: colors,
            grid: { show: false },
            legend: {
                show: true,
                floating: true,
                onItemClick: { toggleDataSeries: true },
                position: 'right',
            },
            annotations: annotations,
            fill: {
                opacity: [1, 1, 0, 1],
            },
        }
    }
}
