<div class="container" style="margin-top: 5px; max-width: 900px">
    <div class="row">
        <table class="desempenho-table">
            <thead>
                <tr>
                    <app-obras-select (onObraChange)="onObraChange($event)" [obras]="desempenhoObras"> </app-obras-select>
                    <th *ngFor="let index of [5, 4, 3, 2, 1]">
                        {{ desempenhoTableDataAtual[index].MesBase }}
                    </th>
                    <th class="current-month-header">{{ desempenhoTableDataAtual[0].MesBase }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="column-header">% Vendido</td>
                    <td *ngFor="let index of [5, 4, 3, 2, 1]">
                        <app-percentage-value-cell [value]="desempenhoTableDataAtual[index].Vendido"></app-percentage-value-cell>
                    </td>
                    <td class="current-month-data-cell">
                        <app-percentage-value-cell [value]="desempenhoTableDataAtual[0].Vendido"></app-percentage-value-cell>
                    </td>
                </tr>
                <tr>
                    <td class="column-header">Vendas/Mês</td>
                    <td *ngFor="let index of [5, 4, 3, 2, 1]">
                        <app-data-cell [value]="desempenhoTableDataAtual[index].VendasMes"></app-data-cell>
                    </td>
                    <td class="current-month-data-cell"><app-data-cell [value]="desempenhoTableDataAtual[0].VendasMes"></app-data-cell></td>
                </tr>
                <tr>
                    <td class="column-header">Distratos/Mês</td>
                    <td *ngFor="let index of [5, 4, 3, 2, 1]">
                        <app-data-cell [value]="desempenhoTableDataAtual[index].Distratos"></app-data-cell>
                    </td>
                    <td class="current-month-data-cell"><app-data-cell [value]="desempenhoTableDataAtual[0].Distratos"></app-data-cell></td>
                </tr>
                <tr class="lotes-row">
                    <td class="column-header">{{ tipoProjeto }}</td>
                    <td *ngFor="let index of [5, 4, 3, 2, 1]">
                        <app-data-cell [value]="desempenhoTableDataAtual[index].Lotes"></app-data-cell>
                    </td>
                    <td class="current-month-data-cell"><app-data-cell [value]="desempenhoTableDataAtual[0].Lotes"></app-data-cell></td>
                </tr>
                <tr>
                    <td class="column-header">Elegíveis</td>
                    <td *ngFor="let index of [5, 4, 3, 2, 1]">
                        <app-data-cell [value]="desempenhoTableDataAtual[index].Elegiveis"></app-data-cell>
                    </td>
                    <td class="current-month-data-cell"><app-data-cell [value]="desempenhoTableDataAtual[0].Elegiveis"></app-data-cell></td>
                </tr>
                <tr>
                    <td class="column-header">Inelegíveis</td>
                    <td *ngFor="let index of [5, 4, 3, 2, 1]">
                        <app-data-cell [value]="desempenhoTableDataAtual[index].Inelegiveis"></app-data-cell>
                    </td>
                    <td class="current-month-data-cell"><app-data-cell [value]="desempenhoTableDataAtual[0].Inelegiveis"></app-data-cell></td>
                </tr>
                <tr>
                    <td class="column-header">Estoque</td>
                    <td *ngFor="let index of [5, 4, 3, 2, 1]">
                        <app-data-cell [value]="desempenhoTableDataAtual[index].Estoque"></app-data-cell>
                    </td>
                    <td class="current-month-data-cell"><app-data-cell [value]="desempenhoTableDataAtual[0].Estoque"></app-data-cell></td>
                </tr>
                <tr>
                    <td class="column-header">Quitados</td>
                    <td *ngFor="let index of [5, 4, 3, 2, 1]">
                        <app-data-cell [value]="desempenhoTableDataAtual[index].Quitados"></app-data-cell>
                    </td>
                    <td class="current-month-data-cell"><app-data-cell [value]="desempenhoTableDataAtual[0].Quitados"></app-data-cell></td>
                </tr>
                <tr class="inadimplencia-row">
                    <td class="column-header">Inadimplência</td>
                    <td *ngFor="let index of [5, 4, 3, 2, 1]">
                        <app-percentage-value-cell [value]="desempenhoTableDataAtual[index].Inadimplencia"></app-percentage-value-cell>
                    </td>
                    <td class="current-month-data-cell">
                        <app-percentage-value-cell [value]="desempenhoTableDataAtual[0].Inadimplencia"></app-percentage-value-cell>
                    </td>
                </tr>
                <tr *ngIf="desempenhoObras.length == 1 || obra_selected == 'Total'">
                    <td class="column-header">Obras</td>
                    <td *ngFor="let index of [5, 4, 3, 2, 1]">
                        <app-percentage-value-cell [value]="desempenhoTableDataAtual[index].Obras"></app-percentage-value-cell>
                    </td>
                    <td class="current-month-data-cell">
                        <app-percentage-value-cell [value]="desempenhoTableDataAtual[0].Obras"></app-percentage-value-cell>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="row">
            <table class="desempenho-table-aux">
                <thead>
                    <tr>
                        <th>
                            Vendas /<br />Estoque <br />
                            {{ mesBase }}
                        </th>
                        <th>Média<br />Vendas <br />6M</th>
                        <th>Média<br />Distratos <br />6M</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><app-percentage-value-cell [value]="razaoVendasEstoque"></app-percentage-value-cell></td>
                        <td><app-data-cell [value]="mediaVendas"></app-data-cell></td>
                        <td><app-data-cell [value]="mediaDistratos"></app-data-cell></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="container" style="margin-bottom: 40px">
    <div class="row" style="align-items: center; margin-bottom: 20px">
        <div class="col-lg-12">
            <div>
                <app-linechart
                    [height]="chartHeight"
                    [xAxisLabel]="'Mês Base'"
                    [yAxisLabel]="'Inadimplência (%)'"
                    [data]="inadimplenciaChartData"
                    [customColors]="customColorsChart"
                    [yScaleMin]="chartYmin"
                >
                    <!-- [showGridLines]="true" -->
                </app-linechart>
            </div>
        </div>
    </div>

    <div class="row" style="align-items: center; margin-bottom: 20px">
        <div class="col-lg-12">
            <div>
                <app-linechart
                    [height]="chartHeight"
                    [xAxisLabel]="'Mês Base'"
                    [yAxisLabel]="'Vendido (%)'"
                    [data]="vendasChartData"
                    [customColors]="customColorsChart"
                >
                </app-linechart>
            </div>
        </div>
    </div>
</div>
