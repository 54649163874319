import { Component, OnInit, Input } from '@angular/core'
import { SubmoduleCard } from 'src/app/interfaces/submodule_card'
@Component({
    selector: 'app-nestedsubmodulecard',
    templateUrl: './nestedsubmodulecard.component.html',
    styleUrls: ['./nestedsubmodulecard.component.css'],
})
export class NestedsubmodulecardComponent implements OnInit {
    @Input() declare cardInfo: SubmoduleCard
    constructor() {}

    ngOnInit(): void {}
}
