import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { ExpandedCri } from 'src/app/interfaces/expanded_cri'
import { CriService } from 'src/app/services/cri.service'
import { Emissor } from 'src/app/interfaces/emissor'
import { RegraArredondamento } from 'src/app/interfaces/regra_arredondamento'
import { Mtm } from 'src/app/interfaces/mtm'
import { IndexadorMensal } from 'src/app/interfaces/indexador_mensal'
import { ProjetosService } from 'src/app/services/projetos.service'
import { TipoAtivo } from 'src/app/interfaces/tipo_ativo'
import { TipoImovel } from 'src/app/interfaces/tipo_imovel'
import { GrupoEconomico } from 'src/app/interfaces/grupo_economico'
import { Estruturacao } from 'src/app/interfaces/estruturacao'
import { ProjetocategoriaExpanded } from 'src/app/interfaces/projeto_categoria_expanded'
import { Liberacao } from 'src/app/interfaces/liberacoes'
import { DataPagamentoCri } from 'src/app/interfaces/data_pagamento_cri'
import { TIRRCri } from 'src/app/interfaces/TIRR_cri'
import { CompraCri } from 'src/app/interfaces/compras'
import { Carteira } from 'src/app/interfaces/carteira'
import { Municipio } from 'src/app/interfaces/municipio'
import { UnidadeFederativa } from 'src/app/interfaces/unidade_federativa'
import { GeoService } from 'src/app/services/geo.service'
import { MunicipioRateio } from 'src/app/interfaces/municipio-rateio'

@Component({
    selector: 'app-cri-create',
    templateUrl: './cri-create.component.html',
    styleUrls: ['./cri-create.component.css'],
})
export class CriCreateComponent implements OnInit, OnDestroy {
    constructor(
        private activatedRoute: ActivatedRoute,
        private criService: CriService,
        private projetoService: ProjetosService,
        private geoService: GeoService
    ) {
        this.criId = this.activatedRoute.snapshot.queryParams['criid']
    }

    private ngUnsubscriber = new Subject()
    public success: boolean = false
    public error: boolean = false
    public errorMessage: string = ''

    public criId: number = -1
    public loading: boolean[] = []
    public emissores: Emissor[] = []
    public municipios: Municipio[] = []
    public unidadesFederativas: UnidadeFederativa[] = []
    public indexadores: IndexadorMensal[] = []
    public carteiras: Carteira[] = []
    public mtms: Mtm[] = []
    public regrasArredondamento: RegraArredondamento[] = []
    public gruposEconomicos: GrupoEconomico[] = []
    public projetosCategorias: ProjetocategoriaExpanded[] = []
    public liberacoes: Liberacao[] = []
    public datasPagamento: DataPagamentoCri[] = []
    public TIRRsCri: TIRRCri[] = []
    public compras: CompraCri[] = []

    public cri: ExpandedCri = {
        CRIID: -1,
        Nome: '',
        SerieNro: -1,
        CodigoIF: '',
        EmissaoNro: -1,
        IsDU: 1,
        MtmID: -1,
        TIRR: -1,
        DataEmissao: '',
        DataVencto: '',
        DataInicioPU: '',
        DataPrimeiroPmt: '',
        DiaDoMesPmt: -1,
        PUInicial: -1,
        ArredRegraID: -1,
        PodeTerCorrNeg: 1,
        AcumulaPrimeiroAno: 0,
        ProjetoCategoriaID: 0,
        PrimeiroDCT: -1,
        Liberacoes: [{ ID: 0, Data: '', Qtde: 0 }],
        ComprasCri: [{ ID: 0, Data: '', Qtde: 0, PU: 0, CarteiraID: 0, QtdeVenda: 0, DataVenda: '', PuVenda: 0, TaxaCompra: 0, TaxaVenda:0}],
        IndexadoresCri: [{ ID: 0, DataVigencia: '', IndexMensalID: 0, MesCorrecao: 1, IndexOffset: 2 }],
        TIRRs: [{ ID: 0, DataVigencia: '', TIRR: 0 }],
        DatasPagamento: [{ ID: 0, Data: '', TaxaAmortOrd: 0, TaxaAmortExtra: 0, IsAniversario: 0, HasAmort: 0, HasJuros: 0 }],
        Opcional: false,
        Lastro: "", 
        CorrecaoMonetariaRegra: 1,
        FatorJurosRegra: 1,
    }

    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Controladoria', href: 'controladoria' },
        { text: 'CRIs', href: 'controladoria/cri_list' },
        { text: 'Create Cri', href: 'controladoria/create/cri' },
    ]

    canDisplay(): boolean {


        if (this.breadcrumbList.length == 3) this.breadcrumbList.push({ text: this.cri.Nome, href: `controladoria/create/cri` })

        return true
    }

    submit(cri: ExpandedCri) {
        this.cri = cri
        this.error = this.success = false
        this.errorMessage = ''
        this.loading.push(true)
        this.criService
            .createCri(this.cri)
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.success = true
                    this.loading.pop()
                },
                (errorData: any) => {
                    this.error = true
                    this.errorMessage = errorData['error']['message']
                    this.loading.pop()
                }
            )
    }

    getIndexadores() {
        this.projetoService
            .getIndexadores()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.indexadores = returnData.indexadores
                    this.loading.pop()
                },
                (errorData) => this.loading.pop()
            )
    }

    getRegrasArredondamento() {
        this.criService
            .getRegrasArredondamento()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.regrasArredondamento = returnData.regras_arredondamento
                    this.loading.pop()
                },
                (error) => this.loading.pop()
            )
    }

    getGruposEconomicos() {
        this.criService
            .getGruposEconomicos()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.gruposEconomicos = returnData.grupos_economicos
                    this.loading.pop()
                },
                (error) => this.loading.pop()
            )
    }

    getProjetosCategorias() {
        this.projetoService
            .getProjetosCategorias()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.projetosCategorias = returnData.projetos_categorias
                    this.loading.pop()
                },
                (error) => this.loading.pop()
            )
    }

    getMtms(): void {
        this.criService
            .getMtms()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.mtms = returnData.mtms
                    this.loading.pop()
                },
                (errorData) => this.loading.pop()
            )
    }

    getMunicipios() {
        this.geoService
            .getMunicipios()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.municipios = returnData.municipios
                    this.loading.pop()
                },
                (error) => this.loading.pop()
            )
    }

    getUnidadesFederativas() {
        this.geoService
            .getUnidadesFederativas()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    console.log(returnData)
                    this.unidadesFederativas = returnData.unidades_federativas
                    this.loading.pop()
                },
                (error) => this.loading.pop()
            )
    }

    getCarteiras() {
        const params = { cri_id: this.criId }
        this.projetoService
            .getCarteiras()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.carteiras = returnData.carteiras
                    this.loading.pop()
                },
                (error) => this.loading.pop()
            )
    }

    ngOnInit(): void {
        for (let i = 0; i < 8; i++) this.loading.push(true)
        this.getMtms()
        this.getIndexadores()
        this.getRegrasArredondamento()
        this.getGruposEconomicos()
        this.getProjetosCategorias()
        this.getMunicipios()
        this.getUnidadesFederativas()
        this.getCarteiras()
    }

    ngOnDestroy(): void {
        this.ngUnsubscriber.next()
        this.ngUnsubscriber.complete()
    }
}
