import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-saldo-devedor-stacked-chart',
  templateUrl: './saldo-devedor-stacked-chart.component.html',
  styleUrls: ['./saldo-devedor-stacked-chart.component.css']
})
export class SaldoDevedorStackedChartComponent implements OnInit {

  @Input('historicoSaldoDevedor') historicoSaldoDevedor: any[] = []
  @Input('data') data: any[] = []

  constructor() { }

  // options
  gradient: boolean = false
  showLegend: boolean = false
  showXAxisLabel: boolean = true
  showYAxisLabel: boolean = true
  xAxisLabel: string = ''
  yAxisLabel: string = ''
  animations: boolean = true
  showGridLines: boolean = false
  barPadding: number = 45
  legend: boolean = false;
  showLabels: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  timeline: boolean = false;

  colorScheme = {
    domain: ['#FFC9C9', '#F86C6C', '#E03C3C', '#B71616'],
  }

  ngOnInit(): void {
    this.assembleSaldoDevedorData()
    console.log(this.data)
  }

  assembleSaldoDevedorData(): void {
    for (let i = 0; i < this.historicoSaldoDevedor.length; i++){
      let name = this.historicoSaldoDevedor[i][0].Sigla
      let series = []
      for (let j = this.historicoSaldoDevedor[i].length - 1; j > -1; j--){
        series.push({
          name: this.formatDate(j),
          value: this.historicoSaldoDevedor[i][j].SaldoDevedorCategoria
        })  
      }
      this.data.push({
        name: name,
        series: series
      })
    }
  }

  formatDate(index: number) {
    let str_month_year = new Date(this.historicoSaldoDevedor[0][index].DataBase).toLocaleDateString('pt-br')
    let month = str_month_year.split('/')[1]
    let year = str_month_year.split('/')[2]

    if (index == this.historicoSaldoDevedor[0].length - 1 || month == '01') {
        return month + '/' + year
    } else return month
  }

  formatDataLabel(value: number) {
      return (value / 1e6).toFixed(2) + ' MM'
  }

}
