<div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <a class="navbar-brand" href="/">
            <img src="assets/habitat_logo.png" width="40" height="40" class="d-inline-block align-top" alt="image" loading="lazy" />
        </a>
        <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a class="nav-link" href="gestao">Gestão</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="controladoria">Controladoria</a>
                </li>
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <button (click)="logOut($event)" type="button" class="btn btn-outline-danger">Sair</button>
                </li>
            </ul>
        </div>
    </nav>
</div>
