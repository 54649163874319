<app-navbar></app-navbar>

<div [ngClass]="loading ? 'disabled-div' : ''">
    <div class="container" style="margin-top: 30px">
        <div class="row">
            <div class="col-12">
                <app-h2title [text]="'Upload de Bases'"></app-h2title>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="standard">
                    <mat-label>Escolha a Categoria</mat-label>
                    <mat-select (selectionChange)="changeCategory($event.value)">
                        <mat-option *ngFor="let category of categories" [value]="category">
                            {{ category }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="standard" id="table-form-field">
                    <mat-label>Selecione a base que será atualizada</mat-label>
                    <mat-select (selectionChange)="changeTable($event.value)" [disabled]="selectedCategory == ''">
                        <mat-option *ngFor="let table of displayedTables" [value]="table.id">
                            {{ formatTableName(table.name) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row" style="float: right">
            <button mat-raised-button (click)="fileUpload.click()" [disabled]="selectedTable == null">Escolher Arquivos</button>
            <button mat-raised-button color="primary" (click)="uploadFile()" [disabled]="!canSubmit()">Enviar</button>
            <input type="file" #fileUpload hidden accept=".xlsx, .xls" (change)="updateFile($event)" />
        </div>
    </div>

    <div *ngIf="selectedTable != null" style="margin-top: 50px">
        <div class="container">
            <hr />
        </div>

        <div class="container">
            <div class="row">
                <app-h2title [text]="'Para atualizar a tabela, o arquivo Excel deve conter as colunas:'"> </app-h2title>
            </div>
        </div>

        <div class="container">
            <ul style="display: grid; grid-template-columns: repeat(4, 1fr)">
                <li style="padding: 6" *ngFor="let column of selectedTable.columns">{{ column }}</li>
            </ul>
        </div>

        <!-- <div>
            <h3> Para atualizar a tabela <strong> {{ formatString(selectedTable.name) }}</strong>, o
                arquivo CSV/XLSX deve
                conter as
                seguintes
                colunas:
            </h3>
            <div class="container">
                <div class="row">
                    <div>
                        <ul>
                            <li *ngFor="let column of selectedTable.columns">
                                {{column}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>
