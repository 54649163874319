import { Component, OnInit, Input, OnChanges } from '@angular/core'
import { AbstractChartComponent } from 'src/app/shared/abstract-chart/abstract-chart.component'

@Component({
    selector: 'app-piechart',
    templateUrl: './piechart.component.html',
    styleUrls: ['./piechart.component.css'],
})
export class PiechartComponent extends AbstractChartComponent implements OnInit {
    public gradient: boolean = true

    constructor() {
        super()
        this.data = []
    }

    public percentageFormatting(c: any) {
        return Math.round(c)
    }

    ngOnInit(): void {}

    ngOnChanges(): void {}
}
