<app-navbar></app-navbar>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="bottom-overlay">
    <div class="container">
        <div class="row" style="margin-bottom: 50px">
            <div *ngFor="let submodule of submodules" class="col-lg-4 col-md-6 col-sm-12">
                <app-submodulecard [cardInfo]="submodule"> </app-submodulecard>
            </div>
        </div>
    </div>
</div>
