import { Component, OnInit, ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { CriService } from 'src/app/services/cri.service';
import { MatPaginator } from '@angular/material/paginator';
import { takeUntil } from 'rxjs/operators';
import { LiberacaoFuturaDetailed } from 'src/app/interfaces/liberacao-futura-detailed';

@Component({
    selector: 'app-liberacoes-futuras',
    templateUrl: './liberacoes-futuras.component.html',
    styleUrls: ['./liberacoes-futuras.component.css'],
})
export class LiberacoesFuturasComponent implements OnInit {
    constructor(public criService: CriService) {}

    private ngUnsubscriber = new Subject()

    public displayedColumns: string[] = ['nome', 'data', 'descricao', 'qtdeLiberada', 'qtdeNaCarteira']
    public filteredDataSource = new MatTableDataSource<LiberacaoFuturaDetailed>()
    public dataSource: LiberacaoFuturaDetailed[] = []

    public filterValue: string = ''
    public filterDatasFuturas: boolean = true

    @ViewChild(MatPaginator) paginator: MatPaginator

    ngOnInit(): void {
        this.filteredDataSource.paginator = this.paginator
        this.getLiberacoesFuturas()
    }

    getLiberacoesFuturas(): void {
        this.criService
            .getLiberacoesFuturas()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    const libFuturas = returnData.liberacoes_futuras.map((obj: any) => {
                        return {
                            ID: obj.ID,
                            Comprado: obj.Comprado,
                            DataLiberacao: obj.DataLiberacao,
                            LiberacaoFutura: obj.LiberacaoFutura,
                            Nome: obj.Nome,
                            QtdeLiberacao: obj.QtdeLiberacao,
                            QtdeNaCarteira: obj.QtdeNaCarteira,
                        }
                    })
                    Object.assign(this.dataSource, libFuturas)

                    this.filtrarDatasFuturas(true)
                },
                (errorData: any) => {}
            )
    }

    filtrar(event: any) {
      
        const filterString = this.normalizar_string(event.target.value)
        const filteredData = this.dataSource.filter((obj) => {

            if (this.filterDatasFuturas){
              return this.normalizar_string(obj.Nome).includes(filterString) && (obj.LiberacaoFutura == 1)
            }
            else {
              return this.normalizar_string(obj.Nome).includes(filterString)
            }  
        })

        this.filteredDataSource = new MatTableDataSource<LiberacaoFuturaDetailed>(filteredData)
        this.filteredDataSource.paginator = this.paginator
    }

    normalizar_string(str: string) {
        return str
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
    }

    formatDate(date: string) {
        const [year, month, day] = date.split('-')
        const result = [day, month, year].join('/')
        return result
    }

    filtrarDatasFuturas(checked:boolean) {
      if (checked) {
        const filteredData = this.dataSource.filter((obj) => {
            return obj.LiberacaoFutura == 1
        })
        this.filteredDataSource = new MatTableDataSource<LiberacaoFuturaDetailed>(filteredData)
        this.filteredDataSource.paginator = this.paginator
      }
      else {
        this.filteredDataSource = new MatTableDataSource<LiberacaoFuturaDetailed>(this.dataSource)
        this.filteredDataSource.paginator = this.paginator
      }

      this.filterDatasFuturas = checked
    }

}
