import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Carteira } from 'src/app/interfaces/carteira';

@Component({
    selector: 'app-carteira-select',
    templateUrl: './carteira-select.component.html',
    styleUrls: ['./carteira-select.component.css'],
})
export class CarteiraSelectComponent implements OnInit {
    @Input('carteiras') carteiras: Carteira[] = []
    @Input('carteiraId') carteiraId: number = 0
    @Output() onCarteiraChange = new EventEmitter()

    constructor() {}

    emitCarteiraID(event: any) {
        this.onCarteiraChange.emit(event.value)
    }

    ngOnInit(): void {}
}
