import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'app-calculate-tabela-resumo-dialog',
    templateUrl: './calculate-tabela-resumo-dialog.component.html',
    styleUrls: ['./calculate-tabela-resumo-dialog.component.css'],
})
export class CalculateTabelaResumoDialogComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CalculateTabelaResumoDialogComponent>) {}

    public mesBase: string = ''

    ngOnInit(): void {}

    save(): void {
        const params = {
            mesBase: this.mesBase,
            save: true,
        }
        this.dialogRef.close(params)
    }

    cancel(): void {
        const params = {
            mesBase: this.mesBase,
            save: false,
        }
        this.dialogRef.close(params)
    }

    isButtonDisabled(): boolean {
        return this.mesBase.length != 6 || isNaN(Number(this.mesBase))
    }
}
