import { Injectable } from '@angular/core'
import { RequestService } from './request.service'

@Injectable({
    providedIn: 'root',
})
export class DashboardProjetoService {
    constructor(private http: RequestService) {}

    private dashboardProjetoBlueprintEndpoint: string = this.http.apiEndpoint + 'gestao/dashboard/'
    private gestaoBlueprintEndpoint: string = this.http.apiEndpoint + 'gestao/'

    public getFluxoFuturo(data: any) {
        return this.http.getRequest(this.dashboardProjetoBlueprintEndpoint + 'fluxo_futuro', data)
    }

    public getResumoEmissao(data: any) {
        return this.http.getRequest(this.dashboardProjetoBlueprintEndpoint + 'resumo_emissao', data)
    }

    public getTabelaDesempenho(data: any) {
        return this.http.getRequest(this.dashboardProjetoBlueprintEndpoint + 'tabela_desempenho', data)
    }

    public getTabelaParcelasEmAtraso(data: any) {
        return this.http.getRequest(this.dashboardProjetoBlueprintEndpoint + 'tabela_parcelas_em_atraso', data)
    }

    public getTabelaResumoDashboard(data: any) {
        return this.http.getRequest(this.dashboardProjetoBlueprintEndpoint + 'tabela_resumo', data)
    }

    public getEstoqueData(data: any) {
        return this.http.getRequest(this.dashboardProjetoBlueprintEndpoint + 'estoque', data)
    }

    public uploadUnidades(data: any) {
        return this.http.postRequest(this.dashboardProjetoBlueprintEndpoint + 'estoque/upload_unidades', data)
    }

    public uploadFundoReserva(data: any) {
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'fundo_reserva/upload_manuais', data)
    }

    public getFundosDeReservaManuais(data: any) {
        return this.http.getRequest(this.dashboardProjetoBlueprintEndpoint + 'fundos_de_reserva_manuais', data)
    }

    public getDadosMascara(data: any){
        return this.http.getRequest(this.dashboardProjetoBlueprintEndpoint + 'dados_mascara', data)
    }

    public getValuesPorObra(data: any){
        return this.http.getRequest(this.dashboardProjetoBlueprintEndpoint + 'values_por_obra', data)
    }
}
