<app-navbar></app-navbar>
<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<!-- Seletores -->
<div *ngIf="areCrisReady">
    <div class="container seletores-wrapper">
        <!-- Título -->
        <div class="row">
            <div class="col-12">
                <app-h2title [text]="'Duration'"></app-h2title>
            </div>
        </div>

        <div class="row">
            <!-- Input CRI -->
            <div class="col-md-4">
                <app-cri-selector style="position: relative; top: 15px" [cris]="cris" (onCriChange)="selectCri($event)"></app-cri-selector>
            </div>
            <!-- Input Mês Base -->
            <div class="col-md-2">
                <mat-form-field appearance="standard">
                    <mat-label> Data Base </mat-label>
                    <input type="date" matInput placeholder="Mês Base" [(ngModel)]="selectedBaseDate" />
                </mat-form-field>
            </div>

            <!-- Botão Calcular -->
            <div class="col-md-1">
                <button
                    class="calculate-duration-button"
                    (click)="calculateDuration()"
                    mat-raised-button
                    [disabled]="!areParametersReadyForCalculation()"
                    color="primary"
                >
                    Calcular Duration
                </button>
            </div>
        </div>
    </div>
</div>
<div class="container" *ngIf="!areCrisReady">
    <mat-spinner class="top-overlay"></mat-spinner>
</div>

<div class="container duration-table-container" *ngIf="isTableReady">
    <!-- Header -->
    <p><b>Duration:</b> {{ duration.toFixed(2) }}</p>
    <p><b>Saldo Devedor:</b> R${{ saldoDevedor.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) }}</p>

    <table class="duration-table">
        <tr>
            <th>Data</th>
            <th>VNe</th>
            <th>C</th>
            <th>VNa</th>
            <th>FJ</th>
            <th>Juros</th>
            <th>TaxaAmort</th>
            <th>PUCurvaCalc</th>
            <th>AmortOrd</th>
            <th>AmortExtra</th>
            <th>Pmt</th>
            <th>DU</th>
            <th>Duration</th>
        </tr>
        <tr *ngFor="let data of tableData">
            <td>{{ data.Data }}</td>
            <td>{{ data.VNe.toFixed(2) }}</td>
            <td>{{ data.C.toFixed(2) }}</td>
            <td>{{ data.VNa.toFixed(2) }}</td>
            <td>{{ data.FJ.toFixed(2) }}</td>
            <td>{{ data.Juros.toFixed(2) }}</td>
            <td>{{ data.TaxaAmortOrd.toFixed(2) }}</td>
            <td>{{ data.PUCalc.toFixed(2) }}</td>
            <td>{{ data.AmortOrd.toFixed(2) }}</td>
            <td>{{ data.AmortExtra.toFixed(2) }}</td>
            <td>{{ data.PMT.toFixed(2) }}</td>
            <td>{{ data.DU.toFixed(0) }}</td>
            <td>{{ data.Duration.toFixed(4) }}</td>
        </tr>
    </table>
    <!-- Tabela -->
</div>
