import { Component, OnInit } from '@angular/core'
import { NgxChartsModule } from '@swimlane/ngx-charts'
import { FormControl } from '@angular/forms'

@Component({
    selector: 'app-visualizations',
    templateUrl: './visualizations.component.html',
    styleUrls: ['./visualizations.component.css'],
})
export class VisualizationsComponent implements OnInit {
    displayData = [] as any
    paises = new FormControl()
    multi = [
        {
            name: 'Germany',
            series: [
                {
                    name: '1990',
                    value: 62000000,
                },
                {
                    name: '2010',
                    value: 73000000,
                },
                {
                    name: '2011',
                    value: 89400000,
                },
            ],
        },

        {
            name: 'USA',
            series: [
                {
                    name: '1990',
                    value: 250000000,
                },
                {
                    name: '2010',
                    value: 309000000,
                },
                {
                    name: '2011',
                    value: 311000000,
                },
            ],
        },

        {
            name: 'France',
            series: [
                {
                    name: '1990',
                    value: 58000000,
                },
                {
                    name: '2010',
                    value: 50000020,
                },
                {
                    name: '2011',
                    value: 58000000,
                },
            ],
        },
        {
            name: 'UK',
            series: [
                {
                    name: '1990',
                    value: 57000000,
                },
                {
                    name: '2010',
                    value: 62000000,
                },
            ],
        },
    ]
    // options
    legend: boolean = false
    showLabels: boolean = true
    animations: boolean = true
    xAxis: boolean = true
    yAxis: boolean = true
    showYAxisLabel: boolean = true
    showXAxisLabel: boolean = true
    xAxisLabel: string = 'Year'
    yAxisLabel: string = 'Population'
    timeline: boolean = true
    colorScheme = {
        domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
    }
    constructor() {}

    ngOnInit(): void {
        this.displayData = this.multi
    }

    updateFilter(data: any): void {
        if (data.value.length > 0) {
            this.displayData = this.multi.filter(function (el) {
                return data.value.includes(el.name)
            })
        } else this.displayData = this.multi
    }

    changeDate(data: any): void {
        console.log(data.value.getTime())
    }
}
