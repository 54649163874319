import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Servicer } from 'src/app/interfaces/servicer';
@Component({
  selector: 'app-servicer-edit-dialog',
  templateUrl: './servicer-edit-dialog.component.html',
  styleUrls: ['./servicer-edit-dialog.component.css']
})
export class ServicerEditDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<ServicerEditDialogComponent>) {}

    public servicer: Servicer = this.data.servicer
    public create: boolean = this.data.create

    ngOnInit(): void {
    }

    save(): void {
        const params = {
            servicer: this.servicer,
            save: true,
        }
        this.dialogRef.close(params)
    }

    cancel(): void {
        const params = {
            save: false,
        }
        this.dialogRef.close(params)
    }

    isButtonDisabled(): boolean {
        return (this.servicer.servicer === '')
    }

}
