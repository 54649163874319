import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Emissor } from 'src/app/interfaces/emissor'

@Component({
    selector: 'app-securitizadora-filter',
    templateUrl: './securitizadora-filter.component.html',
    styleUrls: ['./securitizadora-filter.component.css'],
})
export class SecuritizadoraFilterComponent implements OnInit {
    @Input('securitizadoras') securitizadoras: Emissor[] = []
    @Output() onSecuritizadoraChange = new EventEmitter()

    emitTipo(event: any) {
        this.onSecuritizadoraChange.emit(event.value)
    }

    ngOnInit(): void {}

    public formControl = new FormControl()
}
