<div style="padding: 10px 10px 10px 10px">
    <h4>Calcular Tabela Resumo</h4>

    <p>Entre com o mês base para o cálculo</p>

    <div class="row" style="align-items: center">
        <div class="col-3">
            <mat-form-field appearance="standard">
                <mat-label> Mês Base (p.e 202201)</mat-label>
                <input type="string" matInput placeholder="" [(ngModel)]="mesBase" />
            </mat-form-field>
        </div>
    </div>
    <div class="row" style="float: right">
        <button (click)="cancel()" style="margin-right: 20px" mat-raised-button>Cancelar</button>
        <button (click)="save()" mat-raised-button color="primary" [disabled]="isButtonDisabled()">Salvar</button>
    </div>
</div>
