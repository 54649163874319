import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { UnidadeFederativa } from 'src/app/interfaces/unidade_federativa'

@Component({
    selector: 'app-uf-auto-complete',
    templateUrl: './uf-auto-complete.component.html',
    styleUrls: ['./uf-auto-complete.component.css'],
})
export class UfAutoCompleteComponent implements OnInit {
    @Input('unidadesFederativas') unidadesFederativas: UnidadeFederativa[] = []
    @Input('ufId') ufId: number = -1
    @Output() onUFChange = new EventEmitter()
    @Output() clearForm = new EventEmitter()

    declare filteredUFs: Observable<string[]>

    public UFFormControl = new FormControl()
    public siglaUF: string = ''
    constructor() {}

    private _filterUF(value: string): string[] {
        if (value) {
            const filterValue = value.toLowerCase()
            return this.unidadesFederativas.map((uf) => uf.Sigla).filter((nome) => nome.toLowerCase().includes(filterValue))
        } else {
            return this.unidadesFederativas.map((uf) => uf.Sigla)
        }
    }

    clearUF(): void {
        this.UFFormControl.reset()
        this.onUFChange.emit(-1)
        this.clearForm.emit(true)
    }

    changeUF(event: string): void {
        const ufId = this.unidadesFederativas.filter((uf) => uf.Sigla == event)[0].ID
        this.onUFChange.emit(ufId)
    }

    ngOnInit(): void {
        if (this.ufId > 0) {
            const index = this.unidadesFederativas.findIndex( 
                (obj) => {return obj.ID == this.ufId;})
            this.siglaUF = this.unidadesFederativas[index].Sigla
        }

        this.filteredUFs = this.UFFormControl.valueChanges.pipe(
            startWith(this.siglaUF),
            map((value) => this._filterUF(value))
        )

        if (this.siglaUF) this.UFFormControl.setValue(this.siglaUF)
    }
}
