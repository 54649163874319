import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Breadcrumb } from 'src/app/interfaces/breadcrumb';
import { MunicipioDetailed } from 'src/app/interfaces/municipio_detailed';
import { GeoService } from 'src/app/services/geo.service';
import { takeUntil } from 'rxjs/operators'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { NONE_TYPE } from '@angular/compiler';

@Component({
    selector: 'app-municipios',
    templateUrl: './municipios.component.html',
    styleUrls: ['./municipios.component.css'],
})
export class MunicipiosComponent implements OnInit {
    constructor(public dialog: MatDialog, public geoService: GeoService) {}

    private ngUnsubscriber = new Subject()

    public displayedColumns: string[] = ['municipio', 'uf', 'regiao']
    public filteredDataSource = new MatTableDataSource<MunicipioDetailed>()
    public dataSource: MunicipioDetailed[] = []

    public filterValue: string = ''

    @ViewChild(MatPaginator) paginator: MatPaginator

    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Controladoria', href: 'controladoria' },
        { text: 'Municipios', href: 'controladoria/municipios' },
    ]

    ngOnInit(): void {
        this.filteredDataSource.paginator = this.paginator
        this.getMunicipios()
    }

    getMunicipios(): void {
        this.geoService
            .getMunicipiosDetailedList()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    const municipios = returnData.municipios.map((obj: any) => {
                        return { Nome: obj.Nome, UFID: obj.UFID, UFSigla: obj.UnidadeFederativa.Sigla, UFRegiao: obj.UnidadeFederativa.Regiao }
                    })
                    Object.assign(this.dataSource, municipios) 
                    this.filteredDataSource = new MatTableDataSource<MunicipioDetailed>(municipios)
                    this.filteredDataSource.paginator = this.paginator
                },
                (errorData: any) => {}
            )
    }

    filtrar(event: any) {
      const filterString = this.normalizar_string(event.target.value)

      const filteredData = this.dataSource.filter(
        (obj) =>{
          return this.normalizar_string(obj.Nome).includes(filterString)
        }
      )

      this.filteredDataSource = new MatTableDataSource<MunicipioDetailed>(filteredData)
      this.filteredDataSource.paginator = this.paginator

    }

    normalizar_string(str:string){
      return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
    }

    // openUpdateMunicipioDialog() {}
    // openDeleteMunicipioDialog() {}
}
