import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { IndexadorMensal } from 'src/app/interfaces/indexador_mensal'

@Component({
    selector: 'app-indexador-select',
    templateUrl: './indexador-select.component.html',
    styleUrls: ['./indexador-select.component.css'],
})
export class IndexadorSelectComponent implements OnInit {
    @Input('indexadores') indexadores: IndexadorMensal[] = []
    @Input('indexadorId') indexadorId: number = 0
    @Output() onIndexadorChange = new EventEmitter()

    constructor() {}

    emitIndexMensalID(event: any) {
        this.onIndexadorChange.emit(event.value)
    }

    ngOnInit(): void {}
}
