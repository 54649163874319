import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Emissor } from 'src/app/interfaces/emissor';
import { Breadcrumb } from 'src/app/interfaces/breadcrumb';
import { EmissorEditDialogComponent } from './emissor-edit-dialog/emissor-edit-dialog.component';
import { CriService } from 'src/app/services/cri.service';

@Component({
  selector: 'app-emissor-list',
  templateUrl: './emissor-list.component.html',
  styleUrls: ['./emissor-list.component.css']
})
export class EmissorListComponent implements OnInit {

constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public criService: CriService) {
  }
  private ngUnsubscriber = new Subject()
  
  public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Cadastros', href: 'gestao/cadastros' },
        { text: 'Emissores', href: 'gestao/list/emissores' },
  ]

  public loading: boolean = false
  public emissores: Emissor[] = []

  createEmissorDialog(): void {
    const emissorDialog = this.dialog.open(EmissorEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            emissor: {ID:-1, Nome:""},
        },
    })

    emissorDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading = true
          this.createEmissor(params.emissor)
        }
        return
    })
  }

  createEmissor(emissor:Emissor):void{
    let { EmissorID, ...emissorWithoutID} = emissor
    this.criService
        .createEmissor(emissorWithoutID)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'OK') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar emissor', 'error-snackbar')
                }
                this.getEmissores()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar emissor' + errorData.error.message, 'error-snackbar')
              this.getEmissores()
            }
        )
  }

  updateEmissorDialog(emissor: any): void {
    const emissorDialog = this.dialog.open(EmissorEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            emissor: emissor,
            create: false,
        },
    })

    emissorDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading = true
          this.updateEmissor(params.emissor)
        }
        return
    })
  }

  updateEmissor(emissor:Emissor):void{
    this.criService
        .updateEmissor(emissor)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'OK') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar emissor', 'error-snackbar')
                }
                this.getEmissores()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar emissor' + errorData.error.message, 'error-snackbar')
              this.getEmissores()
            }
        )
  }

  ngOnInit(): void {
    this.loading = true
    this.getEmissores()
  }

  getEmissores():void{
    this.criService
        .getEmissores()
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe((returnData: any) => {
            this.emissores = returnData.emissores
            this.loading = false
        })
  }

  openSnackbar(message: string, messageType: string): void {
    this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
  }

}
