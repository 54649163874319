import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Administrador } from 'src/app/interfaces/administrador';
import { Breadcrumb } from 'src/app/interfaces/breadcrumb';
import { Carteira } from 'src/app/interfaces/carteira';
import { ProjetosService } from 'src/app/services/projetos.service'
import { CarteiraEditDialogComponent } from './carteira-edit-dialog/carteira-edit-dialog.component';

@Component({
  selector: 'app-carteira-list',
  templateUrl: './carteira-list.component.html',
  styleUrls: ['./carteira-list.component.css']
})
export class CarteiraListComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public projetoService: ProjetosService) {
  }
  private ngUnsubscriber = new Subject()
  
  public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Carteiras', href: 'gestao/list/carteiras' },
  ]

  public loading: boolean[] = []
  public carteiras: Carteira[] = []
  public administradores: Administrador[] = []
  
  createCarteiraDialog(): void {
    const carteiraDialog = this.dialog.open(CarteiraEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            carteira: {ID:-1, Nome:"", Sigla:"", CNPJ:""},
            administradores: this.administradores
        },
    })

    carteiraDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading.push(true)
          this.createCarteira(params.carteira)
        }
        return
    })
  }

  createCarteira(carteira:Carteira):void{
    let { ID, ...carteiraWithoutID} = carteira
    this.projetoService
        .createCarteira(carteiraWithoutID)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'Carteira criada com sucesso') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar carteira', 'error-snackbar')
                }
                this.getCarteiras()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar carteira' + errorData.error.message, 'error-snackbar')
              this.getCarteiras()
            }
        )
  }

  updateCarteiraDialog(carteira: any): void {
    const carteiraDialog = this.dialog.open(CarteiraEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            carteira: carteira,
            administradores: this.administradores, 
            create: false,
        },
    })

    carteiraDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading.pop()
          this.updateCarteira(params.carteira)
        }
        return
    })
  }

  updateCarteira(carteira:Carteira):void{
    this.projetoService
        .updateCarteira(carteira)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'Carteira atualizada com sucesso') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar carteira', 'error-snackbar')
                }
                this.getCarteiras()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar carteira' + errorData.error.message, 'error-snackbar')
              this.getCarteiras()
            }
        )
  }

  ngOnInit(): void {
    this.loading = [true, true]
    this.getCarteiras()
    this.getAdministradores()
  }

  getCarteiras():void{
    this.projetoService
        .getCarteiras()
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe((returnData: any) => {
          this.carteiras = returnData.carteiras
          this.loading.pop()
        })
  }

  getAdministradores():void{
    this.projetoService
        .getAdministradores()
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe((returnData: any) => {
          this.administradores = returnData.administradores
          this.loading.pop()
        })
  }

  renderAdministradorNome(administradorID:number):string{
    if (administradorID < 1 || !administradorID){
      return ""
    }
    const nomeAdministrador = this.administradores.filter((obj:any) => obj.ID == administradorID)[0].Nome
    return nomeAdministrador
  }

  openSnackbar(message: string, messageType: string): void {
    this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
  }

  formatDate(dateString:any){
    if (!dateString){
      return ""
    }
    return new Date(dateString).toLocaleDateString('pt-BR')
  }

}
