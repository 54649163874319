<div class="container">
    <div class="row">
        <div class="col-12" style="margin-bottom: 20px">
            <h2 class="eventos-title">Eventos</h2>
        </div>
    </div>
    <div *ngIf="!eventos.length">
        <p> O CRI não tem eventos ativos. </p>
    </div>
    
    <cdk-virtual-scroll-viewport [itemSize]="200">
        <div *ngFor="let evento of eventos; let last = last">
            <div [ngClass]="oldDate(evento)? 'old-date':''" class="row evento-wrapper">
                <div class="col-3 data-wrapper" style="margin-bottom: 20px">
                    <p> {{ evento.Data.split('-').reverse().join('/') }}</p>
                </div>
                <div class="col-5 descricao-wrapper" style="margin-bottom: 20px">
                    <p> {{ evento.Descricao }}</p>
                </div>
                <div class="col-2 checkbox-wrapper" style="margin-bottom: 20px">
                    <mat-checkbox class="checkbox" [(ngModel)]="!evento.Ativo" (click)="changeAtivo(evento)">OK</mat-checkbox>
                </div>
                <div class="col-2 edit-delete-buttons-wrapper" style="margin-bottom: 20px">
                    <button mat-icon-button (click)="updateEventoDialog(evento)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="deleteEventoDialog(evento.ID)">
                        <mat-icon>delete_forever</mat-icon>
                    </button>
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>
    </cdk-virtual-scroll-viewport>
    
    <div class="row buttons-wrapper">
        <div class="col-2 new-evento-button">
            <button (click)="newEventoDialog()" mat-raised-button>Novo Evento</button>
        </div>
        <div class="col-8">
        </div>
        <div class="col-2 close-dialog-button">
            <button (click)="closeDialog()" mat-raised-button>Salvar</button>
        </div>
    </div>
</div>
