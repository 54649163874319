<div class="review-data">
    <div>
        <h1>Confira os dados antes de enviar</h1>
        <hr />
        <div>
            <div class="row"><strong>Servicer: </strong> {{ data['servicer'] }}</div>
            <div class="row"><strong>Projeto: </strong> {{ data['projectName'] }}</div>
            <div class="row"><strong>Base a ser atualizada: </strong> {{ data['fileType'] }}</div>
            <div class="row"><strong>Mes Base: </strong> {{ data['mesBase'] }}</div>
            <div class="row"><strong>Arquivo enviado: </strong> {{ data['file'].name }}</div>
        </div>
    </div>
    <div class="row button-row">
        <button mat-raised-button (click)="cancel()">Cancelar</button>
        <button mat-raised-button color="primary" (click)="confirm()">Enviar</button>
    </div>
</div>
