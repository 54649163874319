import { Component, OnInit } from '@angular/core'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { CaixaService } from 'src/app/services/caixa.service'

@Component({
    selector: 'app-demonstrativo-caixa',
    templateUrl: './demonstrativo-caixa.component.html',
    styleUrls: ['./demonstrativo-caixa.component.css'],
})
export class DemonstrativoCaixaComponent implements OnInit {
    constructor(private caixaService: CaixaService) {}

    public error: boolean = false
    public errorMessage: string = ''
    public success: boolean = false
    public successMessage: string = ''
    public loading: boolean = false

    public files: File[] = []
    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Controladoria', href: 'controladoria' },
        { text: 'Demonstrativo Caixa', href: 'controladoria/demonstrativo_caixa' },
    ]

    updateFiles(files: File[]) {
        this.files = files
    }

    async sendFiles() {
        this.error = this.success = false
        this.errorMessage = ''
        this.loading = true
        for (let i = 0; i < this.files.length; i++) {
            let formData = new FormData()
            formData.append('file', this.files[i])

            const result: any = await this.caixaService.uploadDemonstrativoCaixa(formData)

            if (result['message'] != 'Ok') {
                this.loading = this.success = false
                this.error = true
                this.errorMessage = result.error.message
                return
            }
        }
        this.loading = false
        this.success = true
    }

    ngOnInit(): void {}
}
