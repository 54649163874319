<app-navbar style="position: fixed; top: 0; z-index: 1030; width: 100%"></app-navbar>
<div style="margin-top: 88px"></div>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="container"> 
    <div class="row">
        <div class="col-12 notas-titulo">
            <app-h2title [text]="'Notas CRIs'"></app-h2title>
        </div>
    </div>
</div>
<div>
    <mat-tab-group dynamicHeight="true" mat-align-tabs="center">
        
        <!-- Resumo -->
        <mat-tab label="Resumo">
            <app-resumo-notas></app-resumo-notas>
        </mat-tab>

        <!-- Breakdown Notas -->
        <mat-tab label="Breakdown">
            <app-breakdown-notas></app-breakdown-notas>
        </mat-tab>

        <!-- Criterios -->
        <mat-tab label="Critérios">
            <app-criterios-notas></app-criterios-notas>
        </mat-tab>

    </mat-tab-group>
</div>