import { Component, OnInit, Input } from '@angular/core'

@Component({
    selector: 'app-abstract-chart',
    templateUrl: './abstract-chart.component.html',
    styleUrls: ['./abstract-chart.component.css'],
})
export class AbstractChartComponent implements OnInit {
    @Input('xAxisLabel') xAxisLabel: string
    @Input('yAxisLabel') yAxisLabel: string
    @Input('yAxis') yAxis: boolean = true
    @Input('yScaleMax') yScaleMax: number
    @Input('yScaleMin') yScaleMin: number
    @Input('legend') legend: boolean = false
    @Input('height') height: number
    @Input('data') data: any[]
    @Input('colorScheme') colorScheme?: Object
    @Input('showGridLines') showGridLines: boolean = false
    @Input('customColors') customColors: Object

    constructor() {
        this.xAxisLabel = ''
        this.yAxisLabel = ''
        this.data = []
        this.colorScheme = []
        this.yScaleMax = 0
        this.yScaleMin = 0
        this.height = 200
        this.customColors = {}
    }

    calculateYAxisRange() {
        if (this.yScaleMax || this.yScaleMin) return
        this.yScaleMax =
            1.1 *
            Math.max.apply(
                null,
                this.data.map((el) => el.value)
            )
        this.yScaleMin =
            0.8 *
            Math.min.apply(
                null,
                this.data.map((el) => el.value)
            )
    }
    ngOnInit(): void {
        this.calculateYAxisRange()
    }
}
