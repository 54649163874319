import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Categoria } from 'src/app/interfaces/categoria'

@Component({
    selector: 'app-categorias-select',
    templateUrl: './categorias-select.component.html',
    styleUrls: ['./categorias-select.component.css'],
})
export class CategoriasSelectComponent implements OnInit {
    @Input('categorias') categorias: Categoria[] = []
    @Input('categoriaId') categoriaId: number = 0
    @Output() onCategoriaSelect = new EventEmitter()
    constructor() {}

    emitCategoriaId(event: any) {
        this.onCategoriaSelect.emit(event.value)
    }
    ngOnInit(): void {}
}
