import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { ProjetosService } from 'src/app/services/projetos.service'
import { ProjetocategoriaExpanded } from 'src/app/interfaces/projeto_categoria_expanded'
import { takeUntil } from 'rxjs/operators'
import { Title } from '@angular/platform-browser'

@Component({
    selector: 'app-projeto-categoria-list',
    templateUrl: './projeto-categoria-list.component.html',
    styleUrls: ['./projeto-categoria-list.component.css'],
})
export class ProjetoCategoriaListComponent implements OnInit, OnDestroy {
    constructor(private projetoService: ProjetosService, private titleService: Title) {
        this.titleService.setTitle('CRIs-Categorias | Habitat App')
    }

    private ngUnsubscriber = new Subject()
    public loading: boolean = false
    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao/' },
        { text: 'CRIs-Categorias', href: 'gestao/list/cris_categorias' },
    ]
    public projetosCategorias: ProjetocategoriaExpanded[] = []

    ngOnInit(): void {
        this.loading = true
        this.projetoService
            .getProjetosCategorias()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    // console.log(returnData)
                    this.projetosCategorias = returnData.projetos_categorias
                    this.loading = false
                },
                (errorData) => (this.loading = false)
            )
    }

    formatDate(input: any) {
        const date: Date = new Date(input)
        const li: string[] = date.toISOString().split('T')[0].split('-')
        return `${li[2]}/${li[1]}/${li[0]}`
    }

    ngOnDestroy() {
        this.ngUnsubscriber.next()
        this.ngUnsubscriber.complete()
    }
}
