import { Component, OnInit, OnDestroy, HostListener } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ExcelService } from 'src/app/services/excel.service'
import { DictCard } from 'src/app/interfaces/dictcard'
import { Subject } from 'rxjs'
import { CriService } from 'src/app/services/cri.service'
import { takeUntil } from 'rxjs/operators'
import { IndexadorCriDetail } from 'src/app/interfaces/indexador_cri_detail'
import { dateToString } from 'src/app/utils/utils'

type LiberacoesFuturas = {
    Data: Date,
    Qtde: number,
}

type QuantidadesCarteiras = {
    Nome: string,
    Qtde: number,
}

@Component({
    selector: 'app-cridetail',
    templateUrl: './cridetail.component.html',
    styleUrls: ['./cridetail.component.css'],
})
export class CridetailComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject()
    constructor(public activatedRoute: ActivatedRoute, private criService: CriService, private excel: ExcelService) {
        this.criId = activatedRoute.snapshot.queryParams['criid']
        this.getScreenSize()
    }

    public loading: Boolean = false
    public declare criId: number

    // Datas Pagamento
    public datasPagamento: any[] = []

    // Indexadores
    public indexadores: IndexadorCriDetail[] = []

    // ChartData
    public chartData: Object[] = []
    public xAxisLabel: string = 'Data'
    public yAxisLabel: string = 'PU'

    // Pu data
    public puData: any[] = []
    public puHeader: string[] = []

    // Cri data
    public criData: DictCard[] = []
    public criHeader: any = {}

    // Liberações
    public declare quantidadeLiberada: number
    public liberacoesFuturas: LiberacoesFuturas[] = []
    public quantidadesCarteiras: QuantidadesCarteiras[] = []

    // Page Title
    public pageTitle: string = ''

    public screenHeight: number = -1

    // utils
    public dateToString = dateToString

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?: any) {
        this.screenHeight = window.innerHeight
    }

    dateTickFormatting(val: any): string {
        return val.toLocaleString('pt-BR', { month: '2-digit', year: '2-digit', day: '2-digit' })
    }

    clearDateFilter(event: any): void {
        this.updateChartData(this.puData, this.pageTitle)
    }

    applyDateFilter(event: any): void {
        const startDate = event.start
        const endDate = event.end
        const dateRangeData = this.puData.filter((pu) => {
            const date = new Date(pu.Data)
            return date >= startDate && date <= endDate
        })

        if (dateRangeData.length) this.updateChartData(dateRangeData, this.pageTitle)
    }

    updateChartData(data: any[], criName: string): void {
        var series: Object[] = []
        for (var i = 0; i < data.length; i++) {
            const dateAux = new Date(data[i].Data)
            series.push({ name: dateAux, value: data[i].PUCalc_OF, tooltipText: dateAux.toLocaleDateString() })
        }
        this.chartData = [{ name: criName, series: series }]
    }

    ngOnInit(): void {
        this.loading = true
        this.criService
            .getCriPu({
                cri_id: this.criId
            })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((returnData: any) => {
                this.pageTitle = returnData.cri_name
                this.criHeader = returnData.cri_header
                this.criData = returnData.cri_data
                this.puHeader = returnData.pu_header
                this.puData = returnData.pu_data
                this.indexadores = returnData.indexadores
                this.quantidadeLiberada = returnData.quantidade_liberada
                this.liberacoesFuturas = returnData.liberacoes_futuras
                this.quantidadesCarteiras = returnData.quantidades_carteiras
                this.updateChartData(this.puData, this.pageTitle)
                this.getDatasPagamentoCri()
                this.loading = false
            })
    }

    exportExcel(): void {
        var copy: any[] = this.puData.map((x) => Object.assign({}, x))
        this.excel.exportExcel(copy, this.getExcelFileName(), this.puHeader)
    }

    getDatasPagamentoCri() {
        const params = { cri_id: this.criId }
        this.criService
            .getDatasPagamentoCri(params)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((returnData: any) => {
                // console.log(returnData.datas_pagamentos)
                this.datasPagamento = returnData.datas_pagamentos
            })
    }

    getExcelFileName(): string {
        var aux: any = this.pageTitle
        return aux.replaceAll(' ', '_') + '.xlsx'
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.complete()
    }
}
