<app-navbar></app-navbar>
<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>
<div class="container" style="margin-top: 20px">
    <div class="row">
        <div class="col-12">
            <app-h2title [text]="'Municípios'"></app-h2title>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field class="filtro" appearance="fill">
                <mat-label>Filtrar</mat-label>
                <input matInput placeholder="Ex.: São Paulo" [value]="filterValue" (change)="filtrar($event)">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="mat-0elevation-z8 tableWrapper">
                <table mat-table [dataSource]="filteredDataSource">
                    
                    <ng-container matColumnDef="municipio">
                        <th mat-header-cell *matHeaderCellDef> Município</th>
                        <td mat-cell *matCellDef="let element"> {{element.Nome}} </td>
                    </ng-container>
                    <ng-container matColumnDef="uf">
                        <th mat-header-cell *matHeaderCellDef> UF </th>
                        <td mat-cell *matCellDef="let element"> {{element.UFSigla}} </td>
                    </ng-container>
                    <ng-container matColumnDef="regiao">
                        <th mat-header-cell *matHeaderCellDef> Região </th>
                        <td mat-cell *matCellDef="let element"> {{element.UFRegiao}} </td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="edit-buttons">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element"> 
                            <button (click)="openUpdateMunicipioDialog()" mat-icon-button>
                                <mat-icon>edit_outline</mat-icon>
                            </button> 
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="delete-buttons">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element"> 
                            <button (click)="openDeleteMunicipioDialog()" mat-icon-button>
                                <mat-icon>delete_outline</mat-icon>
                            </button> 
                        </td>
                    </ng-container> -->
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10, 15, 20, 40, 100]"
                    showFirstLastButtons 
                    aria-label="Select page">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>