import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Municipio } from 'src/app/interfaces/municipio';
import { MunicipioRateio } from 'src/app/interfaces/municipio-rateio';
import { UnidadeFederativa } from 'src/app/interfaces/unidade_federativa';
import { GeoService } from 'src/app/services/geo.service';
import { ProjetosService } from 'src/app/services/projetos.service';

@Component({
  selector: 'app-cidade-edit-dialog',
  templateUrl: './cidade-edit-dialog.component.html',
  styleUrls: ['./cidade-edit-dialog.component.css']
})
export class CidadeEditDialogComponent implements OnInit {

    public cidadeRateio: MunicipioRateio = this.data.municipioRateio
    public municipios: Municipio[] = this.data.municipios
    public unidadesFederativas: UnidadeFederativa[] = this.data.unidadesFederativas
    public create: boolean = this.data.create
    public selectedUFID: number = -1
    public loading: boolean = false

    private ngUnsubscriber = new Subject()

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
        public dialogRef: MatDialogRef<CidadeEditDialogComponent>, 
        private _snackBar: MatSnackBar,
        public geoService: GeoService) {
    }

    ngOnInit(): void {
        const aux = this.municipios.filter((obj:Municipio)=>{
            return obj.ID == this.data.municipioRateio.CidadeID
        })
        this.selectedUFID = aux[0].UFID 
        console.log(this.cidadeRateio.Rateio)
        this.cidadeRateio.Rateio = this.cidadeRateio.Rateio * 100
        this.cidadeRateio.Rateio = parseFloat(this.cidadeRateio.Rateio.toFixed(8))
    }

    save(): void {
        this.loading = true
        this.cidadeRateio.Rateio = this.cidadeRateio.Rateio / 100
        this.cidadeRateio.Rateio = parseFloat(this.cidadeRateio.Rateio.toFixed(8))
        if (this.data.create){
            this.createCidadeRateio()
        } else {
            this.updateCidadeRateio()
        }

        const params = {
            cidadeRateio: this.cidadeRateio,
            cancel: false,
        }
        this.dialogRef.close(params)
    }

    cancel(): void {
        const params = {
            cidadeRateio: this.cidadeRateio,
            cancel: true,
        }
        this.dialogRef.close(params)
    }

    isButtonDisabled(): boolean {
        return (this.cidadeRateio.CidadeID < 1 || 
                this.cidadeRateio.Rateio <= 0)
    }

    createCidadeRateio():void{
        
        let { ID, ...cidadeRateioWithoutID} = this.cidadeRateio
        this.geoService
            .createCidadeRateio(cidadeRateioWithoutID)
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    if (returnData.message == 'OK') {
                        this.cidadeRateio = returnData.cidade_rateio
                        this.openSnackbar(returnData.message, 'success-snackbar')
                    } else {
                        this.openSnackbar('Erro ao criar/editar Município-Rateio', 'error-snackbar')
                    }
                    this.loading = false
                },
                (errorData: any) => {
                this.openSnackbar('Erro ao criar/editar Município-Rateio' + errorData.error.message, 'error-snackbar')
                this.loading = false
                }
            )
    }

    updateCidadeRateio():void{
        let cidadeRateioCopy = {...this.cidadeRateio}
        this.geoService
            .updateCidadeRateio(cidadeRateioCopy)
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    if (returnData.message == 'OK') {
                        this.openSnackbar(returnData.message, 'success-snackbar')
                    } else {
                        this.openSnackbar('Erro ao criar/editar Município-Rateio', 'error-snackbar')
                    }
                    this.loading = false
                },
                (errorData: any) => {
                this.openSnackbar('Erro ao criar/editar Município-Rateio' + errorData.error.message, 'error-snackbar')
                this.loading = false
                }
            )
    }

    delete():void{
        if (this.cidadeRateio?.ID < 1){
            return 
        }
        
        this.loading = true
        this.deleteCidadeRateio()
        const params = {
            cidadeRateio: this.cidadeRateio,
            delete: true,
        }
        this.dialogRef.close(params)
    }

    deleteCidadeRateio():void{
        this.geoService
            .deleteCidadeRateio(this.cidadeRateio.ID)
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.loading = false
                },
                (errorData: any) => {
                this.openSnackbar('Erro ao criar/editar Município-Rateio' + errorData.error.message, 'error-snackbar')
                this.loading = false
                }
            )
    }

    openSnackbar(message: string, messageType: string): void {
        this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
    }

}
