import { Injectable } from '@angular/core'
import { Contas } from '../interfaces/contas'
import { RequestService } from './request.service'

@Injectable({
    providedIn: 'root',
})
export class VortxService {
    constructor(private http: RequestService) {}
    private vortxBlueprintEndpoint: string = this.http.apiEndpoint + 'vortx/'

    public uploadCarteiraVortx(data: any) {
        return this.http.promisePostRequest(this.vortxBlueprintEndpoint + 'upload_carteira', data)
    }

    public getLancamentosPorConta(data: any) {
        return this.http.getRequest(this.vortxBlueprintEndpoint + 'lancamentos_por_conta', data)
    }

    public getContas(data: any) {
        return this.http.getRequest(this.vortxBlueprintEndpoint + 'contas', data)
    }

    public updateConta(conta: Contas) {
        let formData = new FormData()
        formData.append('conta', JSON.stringify(conta))

        return this.http.putRequest(this.vortxBlueprintEndpoint + 'conta/update', formData)
    }

    public createConta(conta: Contas) {
        let formData = new FormData()
        formData.append('conta', JSON.stringify(conta))

        return this.http.postRequest(this.vortxBlueprintEndpoint + 'conta', formData)
    }

    public getSiglasVortx(data: any) {
        return this.http.getRequest(this.vortxBlueprintEndpoint + 'siglas', data)
    }

    public updateSiglas(data: any) {
        return this.http.putRequest(this.vortxBlueprintEndpoint + 'siglas/update', data)
    }

    public updateInsertLancamentoContabil(data: any) {
        return this.http.postRequest(this.vortxBlueprintEndpoint + 'lancamento_contabil/create', data)
    }
}
