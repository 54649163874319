import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Evento } from 'src/app/interfaces/eventos';
import { ProjetosService } from 'src/app/services/projetos.service';
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs';
import { CreateEventoDialogComponent } from './create-evento-dialog/create-evento-dialog.component';
import { UpdateEventoDialogComponent } from './update-evento-dialog/update-evento-dialog.component';
import { DeleteEventoDialogComponent } from './delete-evento-dialog/delete-evento-dialog.component';

@Component({
    selector: 'app-eventos-dialog',
    templateUrl: './eventos-dialog.component.html',
    styleUrls: ['./eventos-dialog.component.css'],
})
export class EventosDialogComponent implements OnInit {
    private ngUnsubscribe = new Subject()

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<EventosDialogComponent>,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        public projetoService: ProjetosService
    ) {}

    public eventos: Evento[] = this.data.eventos
    public eventosAtivosChanged: boolean = false

    ngOnInit(): void {
        console.log(this.eventos)
    }

    oldDate(evento: any): boolean {
        let date = new Date(evento.Date)
        let currentDate = new Date()

        if (date <= currentDate || evento.Ativo) {
            return true
        }
        return false
    }

    closeDialog(): void {
        if (this.eventosAtivosChanged) {
            this.projetoService
                .updateEventos(this.eventos)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(
                    (returnData: any) => {
                        if (returnData.message == 'Eventos editados com sucesso') {
                        } else {
                            this.openSnackbar('Erro ao editar eventos', 'error-snackbar')
                        }
                    },
                    (errorData: any) => {
                        this.openSnackbar('Erro ao editar eventos: ' + errorData.error.message, 'error-snackbar')
                    }
                )
        }

        const params = {
            eventos: this.eventos,
        }

        this.dialogRef.close(params)
    }

    newEventoDialog(): void {
        const eventosDialog = this.dialog.open(CreateEventoDialogComponent, {
            width: '50%',
            height: 'auto',
            autoFocus: false,
            position: {
                top: '100px',
            },
            data: {
                projetoID: this.data.projetoID,
            },
        })

        eventosDialog.afterClosed().subscribe((result:any) => {
            var params = result
            if (params.save) {
                this.createNewEvento(params.evento)
            }
            return
        })
    }

    createNewEvento(evento: any) {
        this.projetoService
            .createNewEvento(evento)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    if (returnData.message == 'Evento criado com sucesso') {
                        this.openSnackbar(returnData.message, 'success-snackbar')
                        evento.ID = returnData.evento_id
                        this.eventos.push(evento)
                    } else {
                        this.openSnackbar('Erro ao criar eventos', 'error-snackbar')
                    }
                },
                (errorData: any) => {
                    this.openSnackbar('Erro ao criar eventos: ' + errorData.error.message, 'error-snackbar')
                }
            )
    }

    updateEventoDialog(evento: any): void {
        const eventosDialog = this.dialog.open(UpdateEventoDialogComponent, {
            width: '70%',
            height: 'auto',
            autoFocus: false,
            position: {
                top: '100px',
            },
            data: {
                evento: evento,
            },
        })

        eventosDialog.afterClosed().subscribe((result:any) => {
            var params = result
            if (params.save) {
                this.updateEvento(params.evento)
            }
            return
        })
    }

    updateEvento(evento: any) {
        this.projetoService
            .updateEvento(evento)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    if (returnData.message == 'Evento atualizado com sucesso') {
                        this.openSnackbar(returnData.message, 'success-snackbar')
                    } else {
                        this.openSnackbar('Erro ao criar/editar eventos', 'error-snackbar')
                    }
                },
                (errorData: any) => {
                    this.openSnackbar('Erro ao criar/editar eventos' + errorData.error.message, 'error-snackbar')
                }
            )
    }

    deleteEventoDialog(eventoID: number): void {
        const eventosDialog = this.dialog.open(DeleteEventoDialogComponent, {
            width: '30%',
            height: 'auto',
            autoFocus: false,
            position: {
                top: '100px',
            },
            data: {
                eventoID: eventoID,
            },
        })

        eventosDialog.afterClosed().subscribe((result:any) => {
            var params = result
            if (params.save) {
                this.deleteEvento(params.eventoID)
            }
            return
        })
    }

    deleteEvento(eventoID: any) {
        this.projetoService
            .deleteEvento(eventoID)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    if (returnData.message.includes('deletado com sucesso')) {
                        this.openSnackbar(returnData.message, 'success-snackbar')
                        this.eventos = this.eventos.filter(function (obj) {
                            return obj.ID !== eventoID
                        })
                    } else {
                        this.openSnackbar('Erro ao deletar evento', 'error-snackbar')
                    }
                },
                (errorData: any) => {
                    this.openSnackbar('Erro ao deletar evento: ' + errorData.error.message, 'error-snackbar')
                }
            )
    }

    changeAtivo(evento: any) {
        if (evento.Ativo) {
            this.eventos = this.eventos.map((obj) => {
                if (obj.ID === evento.ID) {
                    return { ...obj, Ativo: 0 }
                }

                return obj
            })
        } else {
            this.eventos = this.eventos.map((obj) => {
                if (obj.ID === evento.ID) {
                    return { ...obj, Ativo: 1 }
                }

                return obj
            })
        }
        this.eventosAtivosChanged = true
    }

    openSnackbar(message: string, messageType: string): void {
        this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
    }
}
