<app-navbar></app-navbar>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="bottom-overlay">
    <div [ngClass]="loading ? 'disabled-div' : ''">
        <div class="container" *ngIf="ready && !loading" style="margin-top: 30px">
            <div class="row">
                <div class="col-lg-12">
                    <app-h2title [text]="'CRIs'"> </app-h2title>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <a href="controladoria/create/cri" style="margin-top: 20px" mat-raised-button color="primary">
                        <mat-icon style="margin-left: -10px">add</mat-icon>
                        Cadastrar Novo CRI
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3">
                    <app-cri-filter [cris]="allCris" [selectedCarteiras]="selectedCarteiras" (onCriChange)="updateDisplayedCris($event)">
                    </app-cri-filter>
                </div>
                <div class="col-lg-3">
                    <app-carteira-filter [carteiras]="carteiras" (onCarteiraChange)="updateSelectedCarteiras($event)"> </app-carteira-filter>
                </div>
                <div class="col-lg-3">
                    <app-datepicker (onDateChange)="changeDate($event)" [text]="'PU na Data'"></app-datepicker>
                </div>
            </div>
            <div class="row" style="margin-bottom: 100px">
                <div class="col-lg-12">
                    <app-cri-list-table [puDate]="puDate" [displayCris]="displayCris"></app-cri-list-table>
                </div>
            </div>
            <div class="row" style="margin-top: -50px; margin-bottom: 30px" *ngIf="allCris.length > 0">
                <div class="col-lg-12">
                    <app-exportexcelbutton (onClick)="exportExcel()"></app-exportexcelbutton>
                </div>
            </div>
        </div>
    </div>
    <div class="top-overlay" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>
