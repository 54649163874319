import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core'
import { DateAdapter } from '@angular/material/core'

@Component({
    selector: 'app-daterangepicker',
    templateUrl: './daterangepicker.component.html',
    styleUrls: ['./daterangepicker.component.css'],
})
export class DaterangepickerComponent implements OnInit {
    @Input('text') text: string
    @Output() onDateRangeChange = new EventEmitter()
    @Output() onDateRangeClear = new EventEmitter()

    constructor(private dateAdapter: DateAdapter<Date>) {
        this.text = ''
        this.dateAdapter.setLocale('pt-BR')
    }

    changeEndDate(event: any): void {
        if (event.value == null) return
        this.endDate = event.value
        this.onDateRangeChange.emit({ start: this.startDate, end: this.endDate })
    }

    clearDates(): void {
        this.startDate = null
        this.endDate = null
        this.onDateRangeClear.emit('')
    }

    public declare startDate: any
    public declare endDate: any
    changeDateRange(event: any): void {}

    ngOnChanges(): void {}
    ngOnInit(): void {}
}
