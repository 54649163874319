import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { CriService } from 'src/app/services/cri.service'
import { ExcelService } from 'src/app/services/excel.service'

@Component({
    selector: 'app-marcacao-mercado',
    templateUrl: './marcacao-mercado.component.html',
    styleUrls: ['./marcacao-mercado.component.css'],
})
export class MarcacaoMercadoComponent implements OnInit, OnDestroy {
    constructor(private criService: CriService, private excelService: ExcelService) {}
    private ngUnsubscribe = new Subject()
    public loading: boolean = false

    public dataBase: Date = new Date()
    public mtmCris: any[] = []
    public mtmCrisDisplay: any[] = []
    public carteiras: string[] = []
    public availableDates: string[] = []
    public selectedCarteiras: string[] = []

    // Excel export
    public calculationArray: any[] = []
    private header: string[] = []
    updateSelectedCarteiras(event: any) {
        this.selectedCarteiras = event
    }

    updateDisplayedCris(event: any) {
        this.mtmCrisDisplay = event
    }

    updateDataBase(date: Date) {
        this.dataBase = date
        const params = { data_base: this.dataBase.toISOString().split('T')[0] }
        this.loading = true
        this.criService
            .getMtmOnDate(params)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.mtmCris = this.mtmCrisDisplay = returnData.table_data
                    this.carteiras = [...new Set(this.mtmCris.map((mtmCri) => mtmCri.Carteira))]
                    this.calculationArray = returnData.calculations
                    this.header = returnData.header
                    this.loading = false
                },
                (errorData: any) => {
                    console.log(errorData)
                    this.loading = false
                }
            )
    }

    exportExcel(event: any) {
        console.log(event)
        const criId: number = event.criId
        const criName: string = event.Nome
        const mtmCalculation: any[] = this.calculationArray.filter((criMtm) => criMtm.CRIID == criId)

        const dateArray: string[] = this.dataBase.toLocaleDateString().split('/')

        this.excelService.exportExcel(mtmCalculation, `${criName}_${dateArray[2]}_${dateArray[1]}_${dateArray[0]}.xlsx`, this.header)
    }

    ngOnInit(): void {
        this.loading = true
        this.criService
            .getMtmDates()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((returnData: any) => {
                this.availableDates = returnData.pu_mtm_dates
                this.loading = false
            }),
            (errorData: any) => {
                this.loading = false
            }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.complete()
    }
}
