import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Evento } from 'src/app/interfaces/eventos';

@Component({
    selector: 'app-create-evento-dialog',
    templateUrl: './create-evento-dialog.component.html',
    styleUrls: ['./create-evento-dialog.component.css'],
})
export class CreateEventoDialogComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CreateEventoDialogComponent>) {}

    public evento: Evento = { ID: 0, Descricao: '', Data: '', Ativo: 1, ProjetoID: 0 }

    ngOnInit(): void {
        this.evento.ProjetoID = this.data.projetoID
    }

    save(): void {
        const params = {
            evento: this.evento,
            save: true,
        }
        this.dialogRef.close(params)
    }

    cancel(): void {
        const params = {
            evento: this.evento,
            save: false,
        }
        this.dialogRef.close(params)
    }

    isButtonDisabled(): boolean {
        return (this.evento.Descricao === '' || 
                this.evento.Data === '' || 
                this.evento.ID != 0 || 
                this.evento.ProjetoID == 0)
    }
}
