<div class="container filter-wrapper">
    <form class="example-form">
        <mat-form-field appearance="standard">
            <mat-label>Projeto</mat-label>
            <input type="text" placeholder="Selecione um projeto" aria-label="Projeto" matInput
                [formControl]="projetoControl" [matAutocomplete]="autoProjeto">
            <mat-autocomplete #autoProjeto="matAutocomplete" (optionSelected)="changeProjeto($event.option.value)">
                <mat-option *ngFor="let project of filteredProjetos | async" [value]="project">
                    {{project}}
                </mat-option>
            </mat-autocomplete>
            <button mat-icon-button matSuffix (click)="clearProjeto()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>Obra</mat-label>
            <input type="text" placeholder="Selecione uma obra" aria-label="Obra" matInput [formControl]="obraControl"
                [matAutocomplete]="autoObra">
            <mat-autocomplete #autoObra="matAutocomplete" (optionSelected)="changeObra($event.option.value)">
                <mat-option *ngFor="let obra of filteredObras | async" [value]="obra">
                    {{obra}}
                </mat-option>
            </mat-autocomplete>
            <button mat-icon-button matSuffix (click)="obraControl.reset()"
                aria-label="Example icon button with a vertical three dot icon">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </form>
</div>