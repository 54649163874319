import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
    selector: 'app-sharepoint-trigger',
    templateUrl: './sharepoint-trigger.component.html',
    styleUrls: ['./sharepoint-trigger.component.css'],
})
export class SharepointTriggerComponent implements OnInit {
    @Input('servicers') servicers: string[] = []
    @Output() onSubmit = new EventEmitter()

    constructor() {}

    public selectedServicers: string[] = []
    public mesBase: string = ''
    public formControl = new FormControl()

    updateMesbase(event: string) {
        this.mesBase = event
    }

    updateServicers(event: any) {
        this.selectedServicers = event.value
    }

    isDisabled(): boolean {
        if (!this.mesBase) return true
        if (!this.selectedServicers.length) return true

        return false
    }

    submit() {
        this.onSubmit.emit({
            mesBase: this.mesBase,
            selectedServicers: this.selectedServicers,
        })
    }

    ngOnInit(): void {}
}
