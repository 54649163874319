import { Component, OnInit, Input } from '@angular/core'

@Component({
    selector: 'app-h2title',
    templateUrl: './h2title.component.html',
    styleUrls: ['./h2title.component.css'],
})
export class H2titleComponent implements OnInit {
    @Input('text') text: string
    constructor() {
        this.text = ''
    }

    ngOnInit(): void {}
}
