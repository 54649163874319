import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { TipoAtivo } from 'src/app/interfaces/tipo_ativo'
@Component({
    selector: 'app-tipo-ativo-select',
    templateUrl: './tipo-ativo-select.component.html',
    styleUrls: ['./tipo-ativo-select.component.css'],
})
export class TipoAtivoSelectComponent implements OnInit {
    @Input('tiposAtivo') tiposAtivo: TipoAtivo[] = []
    @Input('tipoAtivoId') tipoAtivoId: number = 0
    @Output() onTipoAtivoChange = new EventEmitter()
    constructor() {}

    emitTipoAtivoId(event: any) {
        this.onTipoAtivoChange.emit(event.value)
    }

    ngOnInit(): void {}
}
