import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Breadcrumb } from 'src/app/interfaces/breadcrumb';
import { GrupoEconomico } from 'src/app/interfaces/grupo_economico';
import { CriService } from 'src/app/services/cri.service';
import { ProjetosService } from 'src/app/services/projetos.service'
import { GrupoEconomicoEditDialogComponent } from './grupo-economico-edit-dialog/grupo-economico-edit-dialog.component';

@Component({
  selector: 'app-grupo-economico-list',
  templateUrl: './grupo-economico-list.component.html',
  styleUrls: ['./grupo-economico-list.component.css']
})
export class GrupoEconomicoListComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public criService: CriService) {
  }
  private ngUnsubscriber = new Subject()
  
  public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Cadastros', href: 'gestao/cadastros' },
        { text: 'Grupos Econômicos', href: 'gestao/list/grupos_economicos' },
  ]

  public loading: boolean = false
  public gruposEconomicos: GrupoEconomico[] = []

  createGrupoEconomicoDialog(): void {
    const grupoEconomicoDialog = this.dialog.open(GrupoEconomicoEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            grupoEconomico: {ID:-1, Grupo:""},
        },
    })

    grupoEconomicoDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading = true
          this.createGrupoEconomico(params.grupoEconomico)
        }
        return
    })
  }

  createGrupoEconomico(grupoEconomico:any):void{
    let { ID, ...grupoEconomicoWithoutID} = grupoEconomico
    this.criService
        .createGrupoEconomico(grupoEconomicoWithoutID)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'OK') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar grupoEconomico', 'error-snackbar')
                }
                this.getGruposEconomicos()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar grupoEconomico' + errorData.error.message, 'error-snackbar')
              this.getGruposEconomicos()
            }
        )
  }

  updateGrupoEconomicoDialog(grupoEconomico: any): void {
    const grupoEconomicoDialog = this.dialog.open(GrupoEconomicoEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            grupoEconomico: grupoEconomico,
            create: false,
        },
    })

    grupoEconomicoDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading = true
          this.updateGrupoEconomico(params.grupoEconomico)
        }
        return
    })
  }

  updateGrupoEconomico(grupoEconomico:GrupoEconomico):void{
    this.criService
        .updateGrupoEconomico(grupoEconomico)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'OK') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar grupoEconomico', 'error-snackbar')
                }
                this.getGruposEconomicos()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar grupoEconomico' + errorData.error.message, 'error-snackbar')
              this.getGruposEconomicos()
            }
        )
  }

  ngOnInit(): void {
    this.loading = true
    this.getGruposEconomicos()
  }

  getGruposEconomicos():void{
    this.criService
        .getGruposEconomicos()
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe((returnData: any) => {
            console.log(returnData)
            this.gruposEconomicos = returnData.grupos_economicos
            this.loading = false
        })
  }

  openSnackbar(message: string, messageType: string): void {
    this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
  }

}

