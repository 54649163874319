import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core'
import { LTV } from 'src/app/interfaces/LTV'
import { intToString } from 'igniteui-angular-core'

@Component({
    selector: 'app-ltv-stacked-chart',
    templateUrl: './ltv-stacked-chart.component.html',
    styleUrls: ['./ltv-stacked-chart.component.css'],
})
export class LtvStackedChartComponent implements OnInit, AfterViewInit {
    @Input('ltvsFull') ltvsFull: LTV[] = []
    @Input('ltvsAtual') ltvsAtual: LTV[] = []
    @Input('data') data: any[] = []

    // Reference Line Element (Linha do elemento Limite LTV)
    public refline: any

    // options
    showXAxis: boolean = true
    showYAxis: boolean = true
    gradient: boolean = false
    showLegend: boolean = false
    showXAxisLabel: boolean = true
    showYAxisLabel: boolean = true
    xAxisLabel: string = ''
    yAxisLabel: string = ''
    animations: boolean = true
    showGridLines: boolean = false
    barPadding: number = 45
    yScaleMax: number = 65
    yAxisTicks: number[] = []

    colorScheme = {
        domain: ['#E87D7D', '#EF9A9A'],
    }

    constructor() {}

    ngAfterViewInit() {
        this.refline = new ElementRef(document.getElementById('ltv-limit-line'))
        this.refline.nativeElement.style.top = this.getRefLineTopCoordinate(this.yScaleMax)
    }

    getRefLineTopCoordinate(yScaleMax: number) {
        if (yScaleMax == 65) {
            return '4.3rem'
        }

        let valor_rem_top = 2.89
        let valor_rem_bot = 21.3
        if (yScaleMax > 300) {
            valor_rem_top = 2.46
        }

        let rems = (valor_rem_bot - valor_rem_top) * (1 - 60 / yScaleMax) + valor_rem_top
        let str_rems = intToString(rems).replace(',', '.') + 'rem'
        return str_rems
    }

    ngOnInit(): void {
        this.assembleLTVData()
    }

    assembleLTVData(): void {
        this.yAxisTicks = [10, 20, 30, 40, 50, 60]
        let max_value = Math.max.apply(
            Math,
            this.ltvsFull.map(function (o) {
                return o.Valor
            })
        )
        if (max_value > this.yScaleMax) {
            this.yScaleMax = max_value
            this.fillyAxisTicks(max_value)
        } else {
            this.fillyAxisTicks(69)
        }

        for (let i = this.ltvsAtual.length - 1; i > -1; i--) {
            this.data.push({
                name: this.formatDate(i),
                series: [
                    {
                        name: 'Atual',
                        value: this.ltvsAtual[i].Valor,
                    },
                    {
                        name: 'Full',
                        value: this.ltvsFull[i].Valor - this.ltvsAtual[i].Valor,
                    },
                ],
            })
        }

        console.log(this.data)
    }

    formatDataLabel(value: number) {
        return value.toFixed(2) + '%'
    }

    formatYAxis(value: number) {
        return value.toFixed(2) + '%'
    }

    formatDate(index: number) {
        let date_options = { year: 'numeric', month: '2-digit' }
        let str_month_year = new Date(this.ltvsAtual[index].Data).toLocaleDateString('pt-br')
        let month = str_month_year.split('/')[1]
        let year = str_month_year.split('/')[2]

        if (index == this.ltvsAtual.length - 1 || month == '01') {
            return month + '/' + year
        } else return month
    }

    fillyAxisTicks(max_value: number) {
        this.yAxisTicks = []
        let increment = 10
        let current_value = 0
        if (max_value > 200) {
            increment = 20
        }

        while (current_value < max_value) {
            this.yAxisTicks.push(current_value)
            current_value += increment
        }
    }
}
