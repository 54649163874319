<div class="container" style="margin-bottom: 40px">
    <div class="row" style="align-items: center; margin-bottom: 0px">
        <div class="col-lg-6">
            <div>
                <app-linechart
                    [height]="height"
                    [xAxisLabel]="''"
                    [yAxisLabel]="'Recebimento'"
                    [data]="recebidoChart"
                    [customColors]="customColorsRecebido"
                >
                </app-linechart>
            </div>
            <div style="border-top: 1px solid grey; margin-left: 50px" [ngStyle]="{ 'margin-top': height + 20 }"></div>
            <div style="margin-top: 25px">
                <app-linechart
                    [height]="height"
                    [xAxisLabel]="'Mês Base'"
                    [yAxisLabel]="'Pagamento'"
                    [data]="pagamentoChart"
                    [customColors]="customColorsPagamento"
                >
                </app-linechart>
            </div>
            <div *ngIf="this.recebimentosPrevistosChart.length > 0">
                <div style="border-top: 1px solid grey; margin-left: 75px; margin-right: 50px" [ngStyle]="{ 'margin-top': height + 40 }"></div>
                <div style="margin-top: 20px">
                    <app-linechart 
                        [height]="height" 
                        [xAxisLabel]="''" 
                        [yAxisLabel]="'Recebimento Real x Previsto'" 
                        [data]="recebimentosPrevistosChart" 
                        [customColors]="customColorsRecebimentosPrevistos"
                    >
                    </app-linechart>
                </div>
            </div>
        </div>
        <div style="border-left: 1px solid grey" [ngStyle]="{ height: 1.5 * height }"></div>
        <div class="col-lg-5">
            <div style="margin-left: 20px">
                <span *ngIf="noLimit == false" id="limit-rfm-value">{{ limiteRFM }}</span>
                <div *ngIf="noLimit == false" id="limit-line-rfm"></div>
                <app-verticalbarchart
                    [height]="1.5 * height"
                    [xAxisLabel]="''"
                    [yAxisLabel]="'Razão'"
                    [data]="razoesChart"
                    [customColors]="customColorsRazoes"
                    [showGridLines]="false"
                    [yScaleMax]="yScaleMax"
                >
                </app-verticalbarchart>
            </div>
        </div>
    </div>
</div>
