import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    selector: 'app-confirmationdialog',
    templateUrl: './confirmationdialog.component.html',
    styleUrls: ['./confirmationdialog.component.css'],
})
export class ConfirmationdialogComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ConfirmationdialogComponent>) {}

    public selectedCriIds: number[] = []
    public declare selectedDate: Date
    public cris = this.data.cris
    public options: string[] = ['Todos os CRIs', 'Escolher CRIs']
    public selectedOption: string = ''

    close(): void {
        this.dialogRef.close({ trigger: false })
    }

    confirm(): void {
        const params = {
            criIds: this.selectedCriIds,
            dataBase: this.selectedDate,
            trigger: true,
        }
        this.dialogRef.close(params)
    }

    displayDatepicker(): Boolean {
        return this.data.hasDate
    }

    isButtonDisabled(): boolean {
        if (this.selectedOption == '') return true
        if (this.selectedOption == this.options[0] && !this.data.hasDate) return false
        if (this.selectedOption == this.options[0] && this.data.hasDate && this.selectedDate != undefined) return false

        if (this.selectedOption == this.options[1] && this.selectedCriIds.length < 1) return true
        else {
            if (this.data.hasDate && this.selectedDate != undefined) return false
            if (!this.data.hasDate) return false
        }

        return true
    }

    updateDate(event: Date): void {
        this.selectedDate = event
    }

    updateSelectedCris(criIdList: number[]): void {
        this.selectedCriIds = criIdList
    }

    ngOnInit(): void {}
}
