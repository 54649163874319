<mat-form-field appearance="standard">
    <mat-select (selectionChange)="emitTipo($event)" placeholder="Servicer" [formControl]="formControl" multiple>
        <mat-select-trigger>
            {{ formControl.value ? formControl.value[0] : '' }}
            <span *ngIf="formControl.value?.length > 1">
                (+{{ formControl.value.length - 1 }} {{ formControl.value?.length === 2 ? 'other' : 'others' }})
            </span>
        </mat-select-trigger>
        <mat-option *ngFor="let servicer of servicers" [value]="servicer.id">
            {{ servicer.servicer }}
        </mat-option>
    </mat-select>
</mat-form-field>
