<app-navbar></app-navbar>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="bottom-overlay">
    <div [ngClass]="loading ? 'disabled-div' : ''">
        <div class="container" style="margin-top: 20px">
            <div class="row">
                <div class="col-12">
                    <app-h2title [text]="'Administradores'"></app-h2title>
                </div>
            </div>
        </div>

        <div *ngIf="!loading" class="container" style="margin-bottom: 20px; margin-top: 15px">
            <div class="row">
                <div class="col-lg-12">
                    <table style="width: 100%" id="customTable">
                        <tr>
                            <th></th>
                            <th>Nome</th>
                        </tr>
                        <tr *ngFor="let administrador of administradores">
                            <td>
                                <button mat-icon-button (click)="updateAdministradorDialog(administrador)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </td>
                            <td>{{ administrador.Nome }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <button class="cadastro-button" (click)="createAdministradorDialog()" 
                        mat-raised-button color="primary">
                        Cadastrar novo administrador
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="top-overlay">
        <div *ngIf="loading">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>
