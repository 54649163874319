<div class="container">
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label>Selecione a servicer</mat-label>
                <mat-select (selectionChange)="updateServicer($event)">
                    <mat-option *ngFor="let servicer of servicers" [value]="servicer">
                        {{ servicer }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label>Selecione o projeto</mat-label>
                <mat-select (selectionChange)="updateProject($event)" [disabled]="selectedServicer == ''">
                    <mat-option *ngFor="let projeto of displayProjetos" [value]="projeto.ProjetoID">
                        {{ projeto.Projeto }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label>Selecione o arquivo a ser enviado</mat-label>
                <mat-select [disabled]="selectedServicer == ''" (selectionChange)="updateUploadFile($event)">
                    <mat-option *ngFor="let displayFileName of displayFileNames" [value]="displayFileName">
                        {{ displayFileName.FileName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-12">
            <app-mesbase-picker (onMesBaseChange)="updateMesBase($event)"></app-mesbase-picker>
        </div>
    </div>
    <div class="row" style="float: right">
        <button mat-raised-button (click)="fileUpload.click()" [disabled]="!canChooseFile()">Escolher Arquivo</button>
        <button mat-raised-button color="primary" [disabled]="!canSubmit()" (click)="openDialog()">Enviar</button>
        <input type="file" #fileUpload hidden accept=".xlsx, .xlsb" (change)="updateFile($event)" />
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="file-display" *ngIf="file != null"><strong>Arquivo Escolhido:</strong> {{ file.name }}</div>
        </div>
    </div>
</div>
