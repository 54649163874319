<mat-form-field class="example-chip-list">
    <mat-label>Ativos Selecionados</mat-label>
    <mat-chip-list #chipList aria-label="Fruit selection">
        <mat-chip *ngFor="let cri of selectedCris" [selectable]="selectable" [removable]="removable" (removed)="remove(cri)">
            {{ cri.Nome }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
            placeholder="Adicionar Ativo..."
            #criInput
            [formControl]="criControl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        />
    </mat-chip-list>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let cri of filteredCris | async" [value]="cri">
            {{ cri }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
