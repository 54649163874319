<mat-form-field appearance="standard">
    <mat-select (selectionChange)="emitTipo($event)" placeholder="Tipo do Cri" [formControl]="formControlTipo" multiple>
        <mat-select-trigger>
            {{ formControlTipo.value ? formControlTipo.value[0] : '' }}
            <span *ngIf="formControlTipo.value?.length > 1">
                (+{{ formControlTipo.value.length - 1 }} {{ formControlTipo.value?.length === 2 ? 'other' : 'others' }})
            </span>
        </mat-select-trigger>
        <mat-option *ngFor="let tipo of tipos" [value]="tipo.TipoID">{{ tipo.Sigla }}</mat-option>
    </mat-select>
</mat-form-field>
