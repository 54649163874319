import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'app-percentage-value-cell',
    templateUrl: './percentage-value-cell.component.html',
    styleUrls: ['./percentage-value-cell.component.css'],
})
export class PercentageValueCellComponent implements OnInit {
    @Input('value') value: number

    constructor() {
        this.value = 0
    }

    ngOnInit(): void {}
}
