import { Component, OnInit, OnDestroy } from '@angular/core'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { Projeto } from 'src/app/interfaces/projeto'
import { ProjetosService } from 'src/app/services/projetos.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { GeoService } from 'src/app/services/geo.service'
import { Municipio } from 'src/app/interfaces/municipio'
import { UnidadeFederativa } from 'src/app/interfaces/unidade_federativa'
import { Empreendimento } from 'src/app/interfaces/empreendimento'
@Component({
    selector: 'app-novo-empreendimento',
    templateUrl: './novo-empreendimento.component.html',
    styleUrls: ['./novo-empreendimento.component.css'],
})
export class NovoEmpreendimentoComponent implements OnInit, OnDestroy {
    constructor(private projetoService: ProjetosService, private geoService: GeoService) {}
    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Empreendimentos', href: 'gestao/list/empreendimentos' },
        { text: 'Cadastro Empreendimento', href: 'gestao/create/empreendimento' },
    ]
    private ngUnsubscriber = new Subject()

    public loading: boolean[] = []
    public error: boolean = false
    public success: boolean = false
    public errorMessage: string = ''
    public projetos: Projeto[] = []
    public municipios: Municipio[] = []
    public unidadesFederativas: UnidadeFederativa[] = []

    canDisplay(): boolean {
        if (!this.projetos.length) return false
        if (!this.municipios.length) return false
        if (!this.unidadesFederativas.length) return false

        return true
    }

    createEmpreendimento(empreendimento: Empreendimento) {
        this.loading.push(true)
        this.error = this.success = false
        this.errorMessage = ''

        this.projetoService
            .createEmpreendimento(empreendimento)
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.success = true
                    this.loading.pop()
                },
                (errorData: any) => {
                    this.error = true
                    this.errorMessage = errorData['error']['message']
                    this.loading.pop()
                }
            )
    }

    updateEmpreendimento(empreendimento: Empreendimento) {
        this.loading.push(true)
        this.error = this.success = false
        this.errorMessage = ''

        this.projetoService
            .updateEmpreendimento(empreendimento)
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.success = true
                    this.loading.pop()
                },
                (errorData: any) => {
                    this.error = true
                    this.errorMessage = errorData['error']['message']
                    this.loading.pop()
                }
            )
    }

    getProjetos() {
        this.projetoService
            .getProjetos()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.projetos = returnData.projetos
                    this.loading.pop()
                },
                (errorData) => this.loading.pop()
            )
    }

    getMunicipios() {
        this.geoService
            .getMunicipios()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.municipios = returnData.municipios
                    this.loading.pop()
                },
                (error) => this.loading.pop()
            )
    }

    getUnidadesFederativas() {
        this.geoService
            .getUnidadesFederativas()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.unidadesFederativas = returnData.unidades_federativas
                    this.loading.pop()
                },
                (error) => this.loading.pop()
            )
    }

    ngOnInit(): void {
        this.loading.push(true, true, true)
        this.getProjetos()
        this.getMunicipios()
        this.getUnidadesFederativas()
    }

    ngOnDestroy(): void {
        this.ngUnsubscriber.next()
        this.ngUnsubscriber.complete()
    }
}
