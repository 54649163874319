<div class="chart-wrapper" #containerRef>
    <ngx-charts-line-chart
        [view]="[containerRef.offsetWidth, height]"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [timeline]="timeline"
        [results]="data"
        [autoScale]="autoScale"
        [xAxisTickFormatting]="xAxisTickFormatting"
        [yScaleMin]="yScaleMin"
        [yScaleMax]="yScaleMax"
        [showGridLines]="showGridLines"
        [customColors]="customColors"
    >
        <ng-template #seriesTooltipTemplate let-model="model" style="padding: 15px">
            <div *ngFor="let series of model">
                <div class="row" style="align-items: center; padding: 5px 15px 5px 5px">
                    <div class="label-box" [ngStyle]="{ 'background-color': series.color }"></div>
                    <div style="font-size: 0.8rem">{{ series.series }}: {{ series.value.toLocaleString('pt-BR') }}</div>
                </div>
            </div>
            <hr style="border: 0.8px solid white; margin-top: 3px; margin-bottom: 3px" />
            <div style="margin-bottom: 10px">
                {{ formatXLabelTooltip(model[0].name) }}
            </div>
        </ng-template>

        <ng-template #tooltipTemplate let-model="model" style="padding: 15px">
            <div class="row" style="align-items: center; padding: 5px 15px 5px 5px">
                <div class="borderless-label-box"></div>
                <div style="font-size: 0.8rem">{{ model.series }}: {{ model.value.toLocaleString('pt-BR') }}</div>
            </div>
            <hr style="border: 0.8px solid white; margin-top: 3px; margin-bottom: 3px" />
            <div style="margin-bottom: 10px">
                {{ formatXLabelTooltip(model.name) }}
            </div>
        </ng-template>
    </ngx-charts-line-chart>
</div>
