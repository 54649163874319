<app-navbar></app-navbar>

<div *ngIf="error">
    <app-error-alert [text]="errorMessage"></app-error-alert>
</div>
<div *ngIf="success">
    <app-success-alert [text]="'Projeto Atualizado com Sucesso'"></app-success-alert>
</div>

<div class="bottom-overlay" style="margin-top: 15px">
    <div [ngClass]="loading.length ? 'disabled-div' : ''">
        <div>
            <div *ngIf="ready">
                <div *ngIf="breadcrumbList.length == 4">
                    <app-breadcrumbs [breadcrumbList]="breadcrumbList"> </app-breadcrumbs>

                    <div style="margin-top: 15px">
                        <app-projeto-form
                            (onSubmit)="updateProjeto($event)"
                            [update]="true"
                            [servicers]="servicers"
                            [metodologiasFundoReserva]="metodologiasFundoReserva"
                            [metodologiasEstoque]="metodologiasEstoque"
                            [tiposProjeto]="tiposProjeto"
                            [emissores]="emissores"
                            [projeto]="projeto"
                            [metodologiasLTV]="metodologiasLTV"
                            [metodologiasRFM]="metodologiasRFM"
                            [metodologiasInadimplencia]="metodologiasInadimplencia"
                            [agentesFiduciarios]="agentesFiduciarios"
                            [ativos]="ativos"
                            [setores]="setores"
                            [gruposEconomicos]="gruposEconomicos"
                        >
                        </app-projeto-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="top-overlay" *ngIf="loading.length > 0">
        <mat-spinner></mat-spinner>
    </div>
</div>
