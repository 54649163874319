<app-navbar></app-navbar>

<div class="bottom-overlay" style="background-color: #fafafa">
    <div [ngClass]="loading ? 'disabled-div' : ''">
        <div *ngIf="!(loading || loading2)">
            <!-- Título -->
            <div class="container" style="margin: left 10px; margin-bottom: 6px; padding-top: 30px">
                <div class="row" style="align-items: center">
                    <div class="col-lg-9">
                        <app-h2title [text]="'Movimentação Caixa'"> </app-h2title>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <!-- Date Picker -->
                    <div class="col-5">
                        <app-daterangepicker
                            (onDateRangeChange)="applyDateFilter($event)"
                            (onDateRangeClear)="clearDateFilter($event)"
                            [text]="'Intervalo de datas'"
                        >
                        </app-daterangepicker>
                    </div>

                    <!-- Botão Baixar excel -->
                    <div class="col-lg-2">
                        <mat-form-field appearance="standard">
                            <mat-label>Baixar Excel</mat-label>
                            <mat-select appearance="standard" (selectionChange)="exportExcel($event)" placeholder="Baixar Excel">
                                <mat-option [value]="1">Completo</mat-option>
                                <mat-option [value]="2">Filtrado</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <!-- Movimentação Caixa Table -->
            <div class="container" style="padding-bottom: 70px">
                <div class="row" style="margin-bottom: 20px">
                    <div class="col">
                        <table id="movimentacao-caixa-table">
                            <tr>
                                <th id="data-column">Data</th>
                                <th id="valor-column">Valor</th>
                                <th id="historico-column">Histórico</th>
                                <th>Classificação</th>
                                <th id="is-manual-column">Manual</th>
                            </tr>
                            <tr *ngFor="let data of tableData" onclick="'selected=data.ID'">
                                <td class="text-center"><input type="date" [(ngModel)]="data.Data" /></td>
                                <td class="text-right">
                                    <app-formated-number-input [valor]="data.Valor" (output)="data.Valor = $event"> </app-formated-number-input>
                                </td>
                                <td id="historico-column"><input type="string" [(ngModel)]="data.Historico" /></td>
                                <td>
                                    <app-classificacao-autocomplete
                                        (onClassificacaoChange)="data.ClassificacaoID = $event"
                                        [classificacoes]="classificacoes"
                                        [classificacaoID]="data.ClassificacaoID"
                                    >
                                    </app-classificacao-autocomplete>
                                </td>
                                <td><input type="number" [(ngModel)]="data.IsManual" /></td>
                                <!-- <td class="delete-button"> <button (click)="deleteMovimentacao(data.ID)" mat-raised-button>X</button> </td> -->
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button (click)="saveEditions()" mat-raised-button color="primary" style="float: right">Salvar Atualizações</button>
                        <button (click)="createMovimentacao()" mat-raised-button color="primary" style="float: left">+</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="top-overlay" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>
