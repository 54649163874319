<mat-form-field appearance="outline">
    <mat-label>{{text}}</mat-label>
    <mat-date-range-input [rangePicker]="picker">
        <input disabled [(ngModel)]="startDate" matStartDate placeholder="Começo">
        <input disabled [(ngModel)]="endDate" matEndDate placeholder="Fim" (dateChange)="changeEndDate($event)">
    </mat-date-range-input>
    <div matSuffix style="display:flex">
        <mat-datepicker-toggle [for]="picker">
        </mat-datepicker-toggle>
        <button mat-button mat-icon-button aria-label="Clear" (click)="clearDates()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
</mat-form-field>