import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { Cri } from 'src/app/interfaces/cri'

@Component({
    selector: 'app-cri-selector',
    templateUrl: './cri-selector.component.html',
    styleUrls: ['./cri-selector.component.css'],
})
export class CriSelectorComponent implements OnInit {
    criControl = new FormControl()
    @Input('cris') cris: Cri[] = []
    @Output('onCriChange') onCriChange = new EventEmitter()

    declare filteredCris: Observable<string[]>
    declare selectedCri: Cri

    constructor() {}

    ngOnInit(): void {
        console.log(this.cris)

        this.filteredCris = this.criControl.valueChanges.pipe(
            startWith(''),
            map((value: string | null) =>
                value
                    ? this._filter(value)
                    : this.cris
                          .map((cri) => cri.Nome)
                          .sort()
                          .slice()
            )
        )
    }

    emitCri(event: any): void {
        console.log(event.option.viewValue)

        var selectedCriNome = event.option.viewValue
        var criToBeEmited = this.cris.filter(function (obj) {
            return obj.Nome == selectedCriNome
        })

        this.onCriChange.emit(criToBeEmited[0].CRIID)
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase()

        return this.cris
            .map((cri) => cri.Nome)
            .sort()
            .filter((cri) => cri.toLowerCase().includes(filterValue))
    }
}
