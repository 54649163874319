import { Component, OnInit, Input, OnChanges, ViewEncapsulation } from '@angular/core'
import { AppSettings } from 'src/app/constants/AppSettings'
import { DictCard } from 'src/app/interfaces/dictcard'
@Component({
    selector: 'app-dictcard',
    templateUrl: './dictcard.component.html',
    styleUrls: ['./dictcard.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class DictcardComponent implements OnInit {
    @Input('dictCard') declare dictCard: DictCard
    constructor() {}

    formatString(input: any): string {
        const regExp = /[a-zA-Z]/g
        const stringInput = input.toString()
        const appSettings = new AppSettings()

        if (stringInput.includes('-')) {
            // Data
            const aux = stringInput.split('-')
            return aux[2] + '/' + aux[1] + '/' + aux[0]
        } else if (stringInput.includes('/')) {
            return stringInput
        } else if (regExp.test(stringInput)) {
            // Texto com letra
            return stringInput
        } else if (stringInput.includes('%')) {
            // Porcentagem
            return stringInput
        } else if (parseFloat(stringInput)) {
            // Numero para formatar
            return appSettings.formatStringAsCurrency(stringInput)
        } else return stringInput // Safety
    }
    ngOnInit(): void {}

    ngOnChanges(): void {}
}
