<div style="padding: 10px 10px 10px 10px">
    <h4>Consolidar Tabela Resumo</h4>

    <p class="texto">Deseja consolidar a tabela do mês base? Os dados ficarão bloqueados para modificações e recálculos.</p>

    <div class="row" style="float: right">
        <button (click)="cancel()" style="margin-right: 20px" mat-raised-button>Cancelar</button>
        <button (click)="save()" mat-raised-button color="primary">Consolidar Mês</button>
    </div>
</div>
