import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Carteira } from 'src/app/interfaces/carteira';
import { Distribuicao } from 'src/app/interfaces/distribuicao';
import { CriService } from 'src/app/services/cri.service';
import { ProjetosService } from 'src/app/services/projetos.service';
import { takeUntil } from 'rxjs/operators'
import { DateAdapter } from '@angular/material/core';
import { Sort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-distribuicao',
  templateUrl: './distribuicao.component.html',
  styleUrls: ['./distribuicao.component.css']
})
export class DistribuicaoComponent implements OnInit {
  private ngUnsubscribe = new Subject()
  
  constructor(
     private criService: CriService,
     private projetoService: ProjetosService,
     private adapter: DateAdapter<any>,
     private _snackBar: MatSnackBar,
     private excel: ExcelService
  ) { 
    this.adapter.setLocale('pt-BR');
  }

  public loadingFilters: boolean[] = [];
  public loading: boolean = false;
  public dataReady: boolean = false;
  public carteirasOptions: Carteira[] = []
  public crisOptions: any[] = [];
    
  public distribuicoes: Distribuicao[] = []

  public filters = {
    carteiras: [] as number[],
    cris: [] as number[],
    dataInicio: null as Date | null,
    dataFim: null as Date | null
  };
  
  ngOnInit(): void {

    const hoje = new Date();
    this.filters.dataInicio = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
    this.filters.dataFim = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0);
    for (let i = 0; i < 2; i++) {
        this.loadingFilters.push(true)
    }
    this.getCriOptions()
    this.getCarteiras()
    
  }

  getCarteiras(){
    this.projetoService
        .getCarteiras()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (returnData: any) => {
                this.carteirasOptions = returnData.carteiras
                this.loadingFilters.pop()
            },
            (errorData: any) => this.loadingFilters.pop()
        )
  }

  getCriOptions(){
    const params = { carteiras_ids: `[${this.filters.carteiras.join(',')}]` };
    this.criService
            .getCris(params)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((returnData: any) => {
                this.crisOptions = returnData.cri_data
                this.loadingFilters.pop()
            })
  }

  selectCris(cris: any){
    this.filters.cris = cris;
  }

  filtrarCarteira(carteiras: any) {
    this.filters.carteiras = carteiras;
    this.filters.cris = []
    this.loadingFilters.push(true)
    this.getCriOptions()
  }

  onClickBuscar(){
    this.loading = true
    this.getDistribuicoes()
  }

  getDistribuicoes(){
    const params = { 
      carteiras_ids: `[${this.filters.carteiras.join(',')}]` ,
      cri_ids: `[${this.filters.cris.join(',')}]`,
      data_inicio: formatDate(this.filters.dataInicio),
      data_fim: formatDate(this.filters.dataFim),
    };

    this.criService
      .getDistribuicoes(params)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((returnData: any) => {
          this.distribuicoes = returnData.distribuicoes
          this.distribuicoes = this.distribuicoes.map((obj:Distribuicao) =>{
            return { ...obj, Modificado: false }
          })
          this.loading = false
          this.dataReady = true
      })
  }
  
  sortData(sort: Sort) {
    const data = this.distribuicoes.slice()
    if (!sort.active || sort.direction === '') {
        this.distribuicoes = data
        return
    }
    this.distribuicoes = data.sort((a, b) => {
    const isAsc = sort.direction === 'asc'
    
    switch (sort.active) {
      case 'Carteira':
          return compare(a.Carteira, b.Carteira, isAsc)
        case 'CRI':
            return compare(a.NomeCri, b.NomeCri, isAsc)
        case 'Data':
          const dateA = a.Data instanceof Date ? a.Data : new Date(a.Data);
          const dateB = b.Data instanceof Date ? b.Data : new Date(b.Data);
          return compare(dateA, dateB, isAsc);
        case 'Saldo':
            return compare(a.Saldo, b.Saldo, isAsc)
        case 'Juros':
            return compare(a.Juros, b.Juros, isAsc)
        case 'CM':
            return compare(a.CM, b.CM, isAsc)
        case 'AmortTotal':
            return compare(a.AmortTotal, b.AmortTotal, isAsc)
        case 'CMAcumulada':
            return compare(a.CMAcumulada, b.CMAcumulada, isAsc)
        case 'JurosDistribuido':
            return compare(a.JurosDistribuido, b.JurosDistribuido, isAsc)
        case 'CMDistribuida':
            return compare(a.CMDistribuida, b.CMDistribuida, isAsc)
        case 'PercJuros':
            return compare(a.PercJuros, b.PercJuros, isAsc)
        case 'PercCM':
            return compare(a.PercCM, b.PercCM, isAsc)
        case 'AmortClean':
            return compare(a.AmortClean, b.AmortClean, isAsc)
        case 'TotalDistribuido':
            return compare(a.TotalDistribuido, b.TotalDistribuido, isAsc)
        default:
            return 0
        }
    })
  }

  setPercCM(id: number, value: any){
    this.distribuicoes = this.distribuicoes.map((obj) => {
        if (obj.ID === id) {
            return { ...obj, PercCM: value, Modificado: true }
        }

        return obj
    })
  }

  setPercJuros(id: number, value: any){
    this.distribuicoes = this.distribuicoes.map((obj) => {
        if (obj.ID === id) {
            return { ...obj, PercJuros: value, Modificado: true }
        }

        return obj
    })
  }

  salvarModificacoes(){
    
    this.loading = true
    const inputsDistribuicao = this.distribuicoes
      .filter(obj => obj.Modificado)
      .map((obj:Distribuicao) => {
        return { CRIID:obj.CRIID, ID: obj.ID, PercCM: obj.PercCM/100, PercJuros: obj.PercJuros/100 }
    })

    this.criService
      .editInputDistribuicoes({distribuicao_inputs: inputsDistribuicao})
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
          (returnData: any) => {
              this.getDistribuicoes()
          },
          (errorData: any) => {
            this.loading = false
            this.dataReady = true
            alert("Erro ao salvar inputs")
          }
      )
  }

  recalcularDistribuicoes(){
    
    this.criService.calculateDistribuicoes({cri_ids: this.filters.cris}).pipe(takeUntil(this.ngUnsubscribe)).subscribe()
    this._snackBar.open(
        `Cálculo de distribuicoes para as carteiras selecionadas. Quando concluído, uma notificação será enviada no canal do Slack`,
        'Fechar',
        {
            duration: 10000,
        }
    )
  }

  exportExcel(event: any): void {
    var copy: any[] = this.distribuicoes.map((x) => Object.assign({}, x))

    var copy = copy.map((row) => {
        row.Data = new Date(row.Data)
        row.Data = formatDatePTBR(row.Data)
        return row
    })

    this.excel.exportExcel(copy, 'Distribuicoes' + '.xlsx', ['ID','CRIID','NomeCri','CarteiraID','Carteira','Data','Juros','CM','AmortOrd','AmortExtra','PercJuros','PercCM','AmortTotal','CMAcumulada','JurosDistribuido','CMDistribuida','AmortClean','TotalDistribuido','Saldo','Modificado'])
  }
}

function formatDate (date: Date | null): string | null {
  if (date == null){
    return null
  }
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0')
}

function formatDatePTBR(date:Date){
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/')
};


function compare(a: number | string | Date | null, b: number | string | Date | null, isAsc: boolean) {
    if (a === b) {
        return 0;
    }
    const aIsNull = a === null || a === 'null';
    const bIsNull = b === null || b === 'null';
    if (aIsNull && bIsNull) {
        return 0;
    } else if (aIsNull) {
        return isAsc ? -1 : 1;
    } else if (bIsNull) {
        return isAsc ? 1 : -1;
    }

    let aValue = a instanceof Date ? a.getTime() : a;
    let bValue = b instanceof Date ? b.getTime() : b;

    if (typeof aValue === 'string' || typeof bValue === 'string') {
        aValue = aValue.toString();
        bValue = bValue.toString();
    }

    return (aValue < bValue ? -1 : 1) * (isAsc ? 1 : -1);
}


