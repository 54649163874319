<app-navbar></app-navbar>
<div *ngIf="success" class="alert alert-success" role="alert">Projeto criado com sucesso!</div>
<div *ngIf="error" class="alert alert-danger" role="alert">
    {{ errorMessage }}
</div>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<!-- Projeto -->
<div class="bottom-overlay" style="margin-top: 15px">
    <div [ngClass]="loading.length > 0 ? 'disabled-div' : ''">
        <app-projeto-form
            [metodologiasFundoReserva]="metodologiasFundoReserva"
            [tiposProjeto]="tiposProjeto"
            [metodologiasLTV]="metodologiasLTV"
            [metodologiasEstoque]="metodologiasEstoque"
            [servicers]="servicers"
            [agentesFiduciarios]="agentesFiduciarios"
            [metodologiasRFM]="metodologiasRFM"
            [metodologiasInadimplencia]="metodologiasInadimplencia"
            [emissores]="emissores"
            [ativos]="ativos"
            [setores]="setores"
            [gruposEconomicos]="gruposEconomicos"
            (onSubmit)="createProject($event)"
        >
        </app-projeto-form>
    </div>

    <div class="top-overlay" *ngIf="loading.length > 0">
        <mat-spinner></mat-spinner>
    </div>
</div>
