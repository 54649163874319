<div style="padding: 10px 10px 10px 10px">
    <h4>Editar Conta</h4>

    <p>Entre com os dados para a nova conta</p>

    <div class="row" style="align-items: center">
        <div class="col-3">
            <mat-form-field appearance="standard">
                <mat-label> Sigla </mat-label>
                <input type="string" matInput placeholder="" [(ngModel)]="conta.Nome" />
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field appearance="standard" style="min-width: 400px">
                <mat-label> Descrição </mat-label>
                <input type="string" matInput placeholder="" [(ngModel)]="conta.Descricao" />
            </mat-form-field>
        </div>
    </div>
    <div class="row" style="float: right">
        <button (click)="cancel()" style="margin-right: 20px" mat-raised-button>Cancelar</button>
        <button (click)="save()" mat-raised-button color="primary" [disabled]="isButtonDisabled()">Salvar</button>
    </div>
</div>
