import { Directive, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input'

@Directive({
    selector: 'input[appMatInputFormated]',
    providers: [
        { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: MatInputFormatedDirective },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MatInputFormatedDirective),
            multi: true,
        },
    ],
})
export class MatInputFormatedDirective {
    private _value: string | null = ''

    constructor(private elementRef: ElementRef<HTMLInputElement>) {}

    get value(): string | null {
        return this._value
    }

    @Input('value')
    set value(value: string | null) {
        this._value = value
        this.formatValue(value)
    }

    @Output('output') output = new EventEmitter()

    private formatValue(value: string | null) {
        if (value !== null) {
            this.elementRef.nativeElement.value = this.formatNumberWithPoints(value)
        } else {
            this.elementRef.nativeElement.value = ''
        }
    }

    private formatNumberWithPoints(value: string): string {
        value = value.replace(',', '.')
        let num: number = parseFloat(value)
        return num.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    }

    private unFormatValue() {
        const value = this.elementRef.nativeElement.value
        this._value = value.replace(/[^\d,-]/g, '')
        if (value) {
            this.elementRef.nativeElement.value = this._value
        } else {
            this.elementRef.nativeElement.value = ''
        }
    }

    @HostListener('input', ['$event.target.value'])
    onInput(value: any) {
        // here we cut any non numerical symbols
        this._value = value.replace(/[^\d.,-]/g, '')
        this._onChange(this._value)
    }

    @HostListener('blur') // Quando Desseleciona
    _onBlur() {
        if (this._value == '') {
            this.output.emit(0)
        } else if (this._value != null) {
            this.output.emit(this.convertStringToNumber(this._value))
        } else {
            this.output.emit(0)
        }
        this.formatValue(this._value) // add points
    }

    @HostListener('focus')
    onFocus() {
        this.unFormatValue() // Quando Seleciona pra editar
    }

    _onChange(value: any): void {}

    convertStringToNumber(strValue: string): number {
        let numValue: number = parseFloat(strValue.replace(',', '.'))
        return numValue
    }

    writeValue(value: any) {
        this._value = value
        this.formatValue(this._value) // format Value
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn
    }

    registerOnTouched() {}

    @HostListener('keydown', ['$event'])
    onEnter(event: any) {
        if (event.key == 'Enter') {
            this.elementRef.nativeElement.blur()
        }
    }
}
