<div class="sidenav-wrapper">
    <div class="col-12 eventos-link-wrapper">
        <button class="eventos-link" mat-raised-button (click)="openEventosDialog()">
            Eventos ({{ eventos.length }})
        </button>
    </div>

    <div class="col-12" style="margin-bottom: 20px">
        <h2 class="sidenav-title">Projeto 
            <button mat-icon-button>
                <a href="gestao/edit/cri?projeto_id={{ projetoID }}">
                    <mat-icon>mode_edit_outline</mat-icon>
                </a>
            </button>
        </h2>
    </div>
    <div class="col-12">
        <div class="sidenav-element">
            <b class="element-bold-title">Data Base </b><br />
            {{ formatString(dataBase) }}
        </div>
    </div>
    <div class="col-12" *ngFor="let data of projetoDictCardList">
        <div class="sidenav-element">
            <b class="element-bold-title">{{ data.name }} </b><br />
            {{ formatString(data.value) }}
        </div>
    </div>

    <!-- Emissão -->
    <div class="col-12" style="margin-top: 40px; margin-bottom: 20px">
        <h2 class="sidenav-title">Emissão</h2>
    </div>
    <div class="col-12 sidenav-element" *ngFor="let ed of emissaoDictCardList">
        <b class="element-bold-title">{{ ed.name }}</b> <br />
        {{ formatString(ed.value) }}
    </div>

    <!-- Empreendimentos (Obras) -->
    <div class="col-12" style="margin-top: 40px; margin-bottom: 20px">
        <h2 class="sidenav-title">Empreendimentos</h2>
    </div>
    <div class="col-12 sidenav-element" *ngFor="let obra of obras">
        <div class="mb">
            <b class="element-bold-title">{{ obra.Sigla }}</b> <br />
            {{ formatString(obra.percCedido) }}<br />
        </div>
        <div class="mb">
            {{ obra.Municipio }}<br />
        </div>
        <div class="mb">
            <b >VGV</b><br />
            {{ formatString(obra.VGV) }}<br />
        </div>
        <div class="mb">
            <b >Padrão de Construção</b><br />
            {{ obra.padraoDeConstrucao }}
        </div>
    </div>

    <!-- Séries -->
    <div class="col-12" style="margin-top: 20px; margin-bottom: 20px">
        <h2 *ngIf="cris.length > 1" class="sidenav-title">Séries</h2>
        <h2 *ngIf="cris.length == 1" class="sidenav-title">Série</h2>
    </div>
    <div *ngFor="let cri of cris" class="cri-element">
        <b class="element-bold-title">{{ cri.Nome }}</b>
        <button mat-icon-button [matMenuTriggerFor]="menu" class="cri-menu-button">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item hidden="true">
                <span>Dummyautofocus</span>
            </button>
            <a href="controladoria/pu?criid={{ cri.CRIID }}" mat-menu-item>
                <mat-icon>timeline</mat-icon>
                <span>Preços Unitários</span>
            </a>
            <a href="controladoria/edit/cri?criid={{ cri.CRIID }}" mat-menu-item>
                <mat-icon>edit</mat-icon>
                <span>Editar</span>
            </a>
        </mat-menu>
    </div>

    <!-- Liberações Futuras -->
    <div class="lib-futuras-wrapper">
        <div class="col-12" style="margin-top: 20px; margin-bottom: 20px" *ngIf="hasLiberacoesFuturas">
            <h2 class="sidenav-title">Lib. Futuras</h2>
        </div>
        <div *ngFor="let cri of cris; let i = index">
            <div *ngIf="cri.LiberacoesFuturas.length > 0" class="liberacao-futura-element">
                <b class="element-bold-title">{{ cri.Nome }}</b><br/>
                <div *ngFor="let libfutura of cri.LiberacoesFuturas">
                    SD: {{ libfutura.SD.toLocaleString() }} <br>
                    Data Prevista: {{ libfutura.Data }} <br>
                    <div *ngIf='libfutura.Opcional'>
                        Opcional
                    </div>
                    <div *ngIf='!libfutura.Opcional'>
                        Obrigatória
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
