import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgenteFiduciario } from 'src/app/interfaces/agende-fiduciario';
import { Breadcrumb } from 'src/app/interfaces/breadcrumb';
import { ProjetosService } from 'src/app/services/projetos.service'
import { AgenteFiduciarioEditDialogComponent } from './agente-fiduciario-edit-dialog/agente-fiduciario-edit-dialog.component';

@Component({
  selector: 'app-agente-fiduciario-list',
  templateUrl: './agente-fiduciario-list.component.html',
  styleUrls: ['./agente-fiduciario-list.component.css']
})
export class AgenteFiduciarioListComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public projetoService: ProjetosService) {
  }
  private ngUnsubscriber = new Subject()
  
  public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Agentes Fiduciários', href: 'gestao/list/agentes_fiduciarios' },
  ]

  public loading: boolean = false
  public agentesFiduciarios: AgenteFiduciario[] = []

  createAgenteFiduciarioDialog(): void {
    const agenteFiduciarioDialog = this.dialog.open(AgenteFiduciarioEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            agenteFiduciario: {ID:-1, Nome:""},
        },
    })

    agenteFiduciarioDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading = true
          this.createAgenteFiduciario(params.agenteFiduciario)
        }
        return
    })
  }

  createAgenteFiduciario(agenteFiduciario:AgenteFiduciario):void{
    let { ID, ...agenteFiduciarioWithoutID} = agenteFiduciario
    this.projetoService
        .createAgenteFiduciario(agenteFiduciarioWithoutID)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'Agente Fiduciario criado com sucesso') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar agente fiduciario', 'error-snackbar')
                }
                this.getAgentesFiduciarios()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar agente fiduciário' + errorData.error.message, 'error-snackbar')
              this.getAgentesFiduciarios()
            }
        )
  }

  updateAgenteFiduciarioDialog(agenteFiduciario: any): void {
    const agenteFiduciarioDialog = this.dialog.open(AgenteFiduciarioEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            agenteFiduciario: agenteFiduciario,
            create: false,
        },
    })

    agenteFiduciarioDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading = true
          this.updateAgenteFiduciario(params.agenteFiduciario)
        }
        return
    })
  }

  updateAgenteFiduciario(agenteFiduciario:AgenteFiduciario):void{
    this.projetoService
        .updateAgenteFiduciario(agenteFiduciario)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'Agente Fiduciario atualizado com sucesso') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar agente fiduciario', 'error-snackbar')
                }
                this.getAgentesFiduciarios()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar agente fiduciario' + errorData.error.message, 'error-snackbar')
              this.getAgentesFiduciarios()
            }
        )
  }

  ngOnInit(): void {
    this.loading = true
    this.getAgentesFiduciarios()
  }

  getAgentesFiduciarios():void{
    this.projetoService
        .getAgentesFiduciarios()
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe((returnData: any) => {
            console.log(returnData)
            this.agentesFiduciarios = returnData.agentes_fiduciarios
            this.loading = false
        })
  }

  openSnackbar(message: string, messageType: string): void {
    this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
  }

}
