<form>
    <mat-form-field appearance="standard">
        <mat-label>Município</mat-label>
        <input type="text" placeholder="Selecione o Município" matInput [formControl]="municipioFormControl" [matAutocomplete]="autoMunicipio" />
        <mat-autocomplete autoActiveFirstOption #autoMunicipio="matAutocomplete" (optionSelected)="changeMunicipio($event.option.value)">
            <mat-option *ngFor="let municipio of filteredMunicipios | async" [value]="municipio">
                {{ municipio }}
            </mat-option>
        </mat-autocomplete>
        <button mat-icon-button matSuffix (click)="clearMunicipio()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
</form>
