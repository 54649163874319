<div *ngIf="messageType == 'success'">
    <app-success-alert [text]="message"></app-success-alert>
</div>
<div *ngIf="messageType == 'error'">
    <app-error-alert [text]="message"></app-error-alert>
</div>

<div class="row" style="float: right">
    <button (click)="close()" style="margin-right: 20px" mat-raised-button>OK</button>
</div>
