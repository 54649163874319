import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { ProjetocategoriaExpanded } from 'src/app/interfaces/projeto_categoria_expanded'

@Component({
    selector: 'app-projeto-categoria-autocomplete',
    templateUrl: './projeto-categoria-autocomplete.component.html',
    styleUrls: ['./projeto-categoria-autocomplete.component.css'],
})
export class ProjetoCategoriaAutocompleteComponent implements OnInit {
    @Input('projetosCategorias') projetosCategorias: ProjetocategoriaExpanded[] = []
    @Input('projetoCategoriaId') projetoCategoriaId: number = 1
    @Output() onProjCatChange = new EventEmitter()

    public projetosCategoriasModified: any[] = []
    public projCatControl = new FormControl()
    declare filteredProjCat: Observable<string[]>
    public nomeProjetoCategoria: string = ''

    constructor() {}

    ngOnInit(): void {
        // Hard copy
        this.projetosCategoriasModified = this.projetosCategorias.map((pc) => ({ ...pc }))

        // Novo campo Projeto Categoria
        this.projetosCategoriasModified = this.projetosCategoriasModified.map((pc) => {
            pc.ProjetoCategoria = `${pc.CRI} (${pc.Categoria})`
            return pc
        })
        if (this.projetoCategoriaId != 0) {
            const index = this.projetosCategoriasModified.map((p) => p.ID).indexOf(this.projetoCategoriaId)
            if (this.projetoCategoriaId == null) {
                this.nomeProjetoCategoria = ''
            } else {
                this.nomeProjetoCategoria = this.projetosCategoriasModified[index]?.ProjetoCategoria
            }
        }

        this.filteredProjCat = this.projCatControl.valueChanges.pipe(
            startWith(this.nomeProjetoCategoria),
            map((value) => this._filterProjects(value))
        )

        if (this.nomeProjetoCategoria) this.projCatControl.setValue(this.nomeProjetoCategoria)
    }

    private _filterProjects(value: string): string[] {
        if (value) {
            const filterValue = value.toLowerCase()
            return this.projetosCategoriasModified.map((pc) => pc.ProjetoCategoria).filter((project) => project.toLowerCase().includes(filterValue))
        } else {
            return this.projetosCategoriasModified.map((projeto) => projeto.ProjetoCategoria)
        }
    }

    changeProjetoCategoria(event: string): void {
        const projetoCategoriaId: number = this.projetosCategoriasModified.filter((pc) => pc.ProjetoCategoria == event)[0].ID
        this.onProjCatChange.emit(projetoCategoriaId)
    }

    clearProjeto(): void {
        this.projCatControl.reset()
    }

    emitProjCatId(event: any) {
        this.onProjCatChange.emit(event.value)
    }

    ngOnChanges() {
        this.ngOnInit()
    }
}
