import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-novo-projeto-categoria-carteira',
    templateUrl: './novo-projeto-categoria-carteira.component.html',
    styleUrls: ['./novo-projeto-categoria-carteira.component.css'],
})
export class NovoProjetoCategoriaCarteiraComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
