<div style="padding: 10px 10px 10px 10px;">
    <h4>Editar Servicer</h4>

    <p>Selecione os campos a serem criador/editados</p>

    <div class="row" style="align-items: center">
        <div class="col-lg-3 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Nome </mat-label>
                <input type="string" matInput placeholder="Nome" [(ngModel)]="servicer.servicer" />
            </mat-form-field>
        </div>
    </div>
    <div class="row" style="float: right">
        <button (click)="cancel()" style="margin-right: 20px" mat-raised-button>Cancelar</button>
        <button (click)="save()" mat-raised-button color="primary" [disabled]="isButtonDisabled()">Salvar</button>
    </div>
</div>