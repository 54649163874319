<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <app-projetos [projetoId]="projetoCategoria.ProjetoID" (onProjetoChange)="projetoCategoria.ProjetoID = $event" [projetos]="projetos">
            </app-projetos>
        </div>

        <div class="col-lg-3 col-md-12">
            <app-categorias-select
                [categoriaId]="projetoCategoria.CategoriaID"
                (onCategoriaSelect)="projetoCategoria.CategoriaID = $event"
                [categorias]="categorias"
            >
            </app-categorias-select>
        </div>
        <div class="col-lg-2 col-md-12">
            <app-indexador-select
                [indexadorId]="projetoCategoria.IndexMensalID"
                (onIndexadorChange)="projetoCategoria.IndexMensalID = $event"
                [indexadores]="indexadores"
            >
            </app-indexador-select>
        </div>
        <div class="col-lg-3 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label>Carteira</mat-label>
                <mat-select [(value)]="projetoCategoria.CarteiraID" (selectionChange)="projetoCategoria.CarteiraID = $event.value">
                    <mat-option *ngFor="let carteira of carteiras" [value]="carteira.ID">
                        {{ carteira.Nome }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
<div class="container">
    <div class="row" style="align-items: center">
        <div class="col-lg-3">
            <mat-form-field class="example-full-width">
                <mat-label>TIRR da Emissão (%)</mat-label>
                <input type="number" matInput [(ngModel)]="projetoCategoria.TIRR" />
                <mat-hint align="start"
                    ><strong>{{ projetoCategoria.TIRR ? projetoCategoria.TIRR.toLocaleString() : '' }}%</strong>
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="col-lg-3">
            <mat-form-field class="example-full-width">
                <mat-label>Valor da Emissão</mat-label>
                <input type="number" matInput [(ngModel)]="projetoCategoria.ValorEmissao" />
                <mat-hint align="start"
                    ><strong>{{ projetoCategoria.ValorEmissao ? projetoCategoria.ValorEmissao.toLocaleString() : '' }}</strong>
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="col-lg-3">
            <app-datepicker
                [date]="projetoCategoria.DataEmissao"
                (onDateChange)="updateDataEmissao($event)"
                [text]="'Data de Emissão'"
            ></app-datepicker>
        </div>
        <div class="col-lg-3">
            <app-datepicker [date]="projetoCategoria.DataVencto" (onDateChange)="updateDataVencimento($event)" [text]="'Data de Vencimento'">
            </app-datepicker>
        </div>
    </div>
</div>
<div class="container">
    <div class="row" style="float: right">
        <div class="col-3">
            <button (click)="submit()" mat-raised-button color="primary">{{ buttonText }}</button>
        </div>
    </div>
</div>
