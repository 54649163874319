import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Carteira } from 'src/app/interfaces/carteira'

@Component({
    selector: 'app-carteira-filter-resumo',
    templateUrl: './carteira-filter-resumo.component.html',
    styleUrls: ['./carteira-filter-resumo.component.css'],
})
export class CarteiraFilterResumoComponent implements OnInit {
    @Input('carteiras') carteiras: Carteira[] = []
    @Output() onCarteiraChange = new EventEmitter()

    emitCarteira(event: any) {
        this.onCarteiraChange.emit(event.value)
    }

    ngOnInit(): void {
    }

    public formControl = new FormControl(this.carteiras)
}
