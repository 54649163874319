import { Component, OnInit } from '@angular/core'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { ProjetosService } from 'src/app/services/projetos.service'
import { ProjetoExpanded } from 'src/app/interfaces/projeto_expanded'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { AppSettings } from 'src/app/constants/AppSettings'
import { Title } from '@angular/platform-browser'

@Component({
    selector: 'app-projeto-list',
    templateUrl: './projeto-list.component.html',
    styleUrls: ['./projeto-list.component.css'],
})
export class ProjetoListComponent implements OnInit {
    constructor(private projetoService: ProjetosService, private titleService: Title) {
        this.titleService.setTitle('CRIs Gestão | Habitat App')
    }

    public loading: boolean = false
    private ngUnsubscriber = new Subject()

    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'CRIs', href: 'gestao/list/cris' },
    ]

    public projetos: ProjetoExpanded[] = []

    ngOnInit(): void {
        this.loading = true
        this.projetoService
            .getProjetos()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    console.log(returnData)
                    this.projetos = returnData.projetos
                    this.loading = false
                },
                (errorData: any) => {
                    this.loading = false
                }
            )
    }

    formatString(input: any) {
        const appSettings = new AppSettings()
        return appSettings.formatStringAsCurrency(input)
    }
}
