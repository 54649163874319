import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core'
import { ProjetosService } from 'src/app/services/projetos.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ServicersService } from 'src/app/services/servicers.service'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Title } from '@angular/platform-browser'

@Component({
    selector: 'app-upload-servicers',
    templateUrl: './uploadservicers.component.html',
    styleUrls: ['./uploadservicers.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class UploadServicersComponent implements OnInit, OnDestroy {
    constructor(
        private projetosService: ProjetosService,
        private servicersService: ServicersService,
        private _snackBar: MatSnackBar,
        private titleService: Title
    ) {
        this.titleService.setTitle('Upload Bases Gestão | Habitat App')
    }

    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Upload Bases Servicers', href: 'gestao/upload_servicers' },
    ]

    private ngUnsubscribe = new Subject()
    public loading: Boolean = false

    public projetos: any[] = []
    public fileNames: any[] = []
    public servicers: string[] = []

    getTooltipText(includeFile: boolean): string {
        if (includeFile)
            return `
    Os arquivos devem estar em \n
    Habitatcp/4.Fundos - Documentos/Bases CRI/Ano/Ano.Mes/SERVICER/Arquivo/ \n
    Por exemplo: \n
    Habitatcp/4.Fundos - Documentos/Bases CRI/2021/2021.5/ARKE/2021.05 HANEI.xlsx
    `
        else
            return `
    Os arquivos devem estar em \n
    Habitatcp/4.Fundos - Documentos/Bases CRI/Ano/Ano.Mes/SERVICER/ \n
    Por exemplo: \n
    Habitatcp/4.Fundos - Documentos/Bases CRI/2021/2021.5/ARKE/
    `
    }

    canDisplay(): boolean {
        if (!this.projetos.length) return false
        if (!this.fileNames.length) return false
        if (!this.servicers.length) return false

        return true
    }

    triggerUpload(event: any) {
        const mesBase: string = event.mesBase
        console.log(event.mesBase)
        const servicers: string[] = event.selectedServicers

        this.loading = true
        this.servicersService
            .upload(mesBase, servicers)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                () => {
                    this.loading = false
                    this.showSnackbar()
                },
                (errorData: any) => {
                    this.loading = false
                    this.showSnackbar(errorData['error']['message'])
                }
            )
    }

    showSnackbar(message: string = '') {
        if (!message) {
            message =
                'Envio de bases disparado. Fique atento ao canal #servicers-upload-alert no Slack. Quando terminar, uma notificação será enviada nele.'
        }

        this._snackBar.open(message, 'Fechar', { duration: 5000 })
    }

    submitFile(event: any): void {
        const servicer: string = event.servicer
        const fileName: string = event.file.name
        const mesBase: string = event.mesBase

        this.loading = true
        this.servicersService
            .upload(mesBase, [servicer], fileName)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                () => {
                    this.showSnackbar()
                    this.loading = false
                },
                (errorData: any) => {
                    this.showSnackbar(errorData['error']['message'])
                    this.loading = false
                }
            )
    }

    ngOnInit() {
        this.loading = true
        this.projetosService
            .getProjetosServicers({})
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                // Successful return
                (returnData: any) => {
                    this.projetos = returnData['projects']
                    this.fileNames = returnData['upload_files']

                    this.servicers = [...new Set(this.projetos.map((p) => p.Servicer))].sort()
                    this.loading = false
                },
                // Failed return
                () => {
                    this.loading = false
                }
            )
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.complete()
    }
}
