import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Mtm } from 'src/app/interfaces/mtm'

@Component({
    selector: 'app-mtm-select',
    templateUrl: './mtm-select.component.html',
    styleUrls: ['./mtm-select.component.css'],
})
export class MtmSelectComponent implements OnInit {
    @Input('mtms') mtms: Mtm[] = []
    @Input('mtmId') mtmId: number = 0
    @Output() onMtmChange = new EventEmitter()

    constructor() {}

    emitMtmId(event: any) {
        this.onMtmChange.emit(event.value)
    }

    ngOnInit(): void {}
}
