import { Component, OnInit, Input, ElementRef, AfterViewInit, AfterContentInit, AfterViewChecked, DoCheck } from '@angular/core'
import { intToString } from 'igniteui-angular-core'
import { RazaoAbstractComponent } from 'src/app/modules/gestao/dashboardprojeto/tab-razoes/razao-abstract/razao-abstract.component'

@Component({
    selector: 'app-razao-fluxo-mensal',
    templateUrl: './razao-fluxo-mensal.component.html',
    styleUrls: ['./razao-fluxo-mensal.component.css'],
})
export class RazaoFluxoMensalComponent extends RazaoAbstractComponent implements OnInit, DoCheck {
    @Input('recebido') recebido: any[]
    @Input('pagamento') pagamento: any[]
    @Input('razoes') razoes: any[]
    @Input('limiteRFM') limiteRFM: any
    @Input('recebimentosPrevistos') recebimentosPrevistos: any[]

    constructor() {
        super()
        this.recebido = this.pagamento = this.razoes = this.recebimentosPrevistos = []
        this.customColorsRecebido = [
            { name: 'Adimplente', value: '#cccccc' },
            { name: 'Adiantamento', value: '#bebfbf' },
            { name: 'Atraso', value: '#999999' },
            { name: 'Total', value: '#c4170c' },
        ]

        this.customColorsPagamento = [
            { name: 'Senior', value: '#e5e5e5' },
            { name: 'Mez. Senior', value: '#b2b2b2' },
            { name: 'Mez. Sub', value: '#999999' },
            { name: 'Subordinada', value: '#7f7f7f' },
            { name: 'Total', value: '#c4170c' },
        ]
        this.customColorsRecebimentosPrevistos = [
            { name: 'Recebimento Real', value: '#c4170c' },
            { name: 'Recebimento Previsto', value: '#999999' },
        ]
    }

    public recebidoChart: any[] = []
    public pagamentoChart: any[] = []
    public razoesChart: any[] = []
    public recebimentosPrevistosChart: any[] = []

    public height: number = 160

    public yScaleMin: number = 0
    public yScaleMax: number = 1
    public customColorsRecebido: any
    public customColorsPagamento: any
    public customColorsRazoes: any[] = []
    public customColorsRecebimentosPrevistos: any[] = []

    public opened_tab = false
    public noLimit = false

    //reference line (limite da razao fluxo mensal)
    public refline: any
    public refvalue: any

    ngDoCheck() {
        this.refvalue = new ElementRef(document.getElementById('limit-rfm-value'))
        this.refline = new ElementRef(document.getElementById('limit-line-rfm'))
        if (this.refline.nativeElement != null && this.refvalue.nativeElement != null && this.opened_tab == false) {
            this.refline.nativeElement.style.top = this.getRefTopCoordinate(this.yScaleMax, this.limiteRFM, 0.71, 11.37)
            this.refvalue.nativeElement.style.top = this.getRefTopCoordinate(this.yScaleMax, this.limiteRFM, 0.6, 10.74)
            this.opened_tab = true
        }
    }

    getRefTopCoordinate(yScaleMax: number, limiteRSD: number, rem_top: number, rem_bot: number) {
        let rems = (rem_bot - rem_top) * (1 - limiteRSD / yScaleMax) + rem_top
        let str_rems = intToString(rems).replace(',', '.') + 'rem'
        return str_rems
    }

    ngOnInit(): void {
        this.opened_tab = false
        if (this.limiteRFM == 0) {
            this.noLimit = true
        }

        const recebidoChartData = this.getChartData(this.recebido, true, this.yScaleMax, this.yScaleMin, true)
        this.recebidoChart = recebidoChartData.chartData

        const pagamentoChartData = this.getChartData(this.pagamento, true, this.yScaleMax, this.yScaleMin)
        this.pagamentoChart = pagamentoChartData.chartData

        const razaoChartData = this.getRazaoChartData(this.razoes)
        this.razoesChart = razaoChartData.razaoChart
        this.customColorsRazoes = razaoChartData.customColors

        this.mountRecebimentosChartData()

        this.yScaleMax = Math.max.apply(
            Math,
            this.razoesChart.map(function (o) {
                return o.value
            })
        )

        if (this.yScaleMax < this.limiteRFM) {
            this.yScaleMax = this.limiteRFM
        }
    }

    mountRecebimentosChartData() {
        const recebimentosPrevistosChart = []
        
        const recebimentoRealSemAtrasos = this.recebidoChart[3].series.map((item:any, index:any) => {
            return {value: item.value - this.recebidoChart[2].series[index].value, name:item.name}
            // Aqui estou subtraindo o recebimento real dos atrasos para fazer a comparação com o previsto
        })

        recebimentosPrevistosChart.push({ name: 'Recebimento Real', series: recebimentoRealSemAtrasos })
        const recebimentosPrevistosSeries = this.recebimentosPrevistos.map((obj) => {
            return { name: obj.MesBase, value: obj.Valor }
        })
        recebimentosPrevistosChart.push({ name: 'Recebimento Previsto', series: recebimentosPrevistosSeries })
        this.recebimentosPrevistosChart = recebimentosPrevistosChart
    }
}
