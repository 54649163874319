<div #pieChartRef>
    <ngx-charts-advanced-pie-chart
        [view]="[pieChartRef.offsetWidth, height]"
        [percentageFormatting]="percentageFormatting"
        [results]="data"
        [gradient]="gradient"
        [customColors]="customColors"
    >
    </ngx-charts-advanced-pie-chart>
</div>
