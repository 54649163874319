<app-navbar style="position: fixed; top: 0; z-index: 1030; width: 100%"></app-navbar>

<div [ngStyle]="{ height: screenHeight - 70 }">
    <mat-drawer-container fixedInViewport="true" fixedTopGap="70" style="top: 70px">
        <mat-drawer mode="side" opened *ngIf="isResumoReady()">
            <div>
                <div>
                    <app-sidenav-dashboard 
                        [resumoEmissaoApiData]="resumoEmissaoApiData" 
                        [projetoID]="projetoId"> 
                    </app-sidenav-dashboard>
                </div>
            </div>
        </mat-drawer>

        <mat-drawer-content>
            <div class="bottom-overlay" style="margin-left: 15px; margin-right: 15px">
                <div>
                    <div [ngClass]="loading.length > 0 ? 'disabled-div' : ''">
                        <div *ngIf="projetos.length > 0">
                            <app-projetoscategorias
                                [projetos]="projetos"
                                [projetosCategorias]="projetosCategorias"
                                [mesesBase]="mesesBase"
                                (onProjetoChange)="updateProjetoId($event)"
                                (onCategoriaChange)="updateProjetoCategoriaId($event)"
                                (onMesBaseChange)="updateMesBase($event)"
                            >
                            </app-projetoscategorias>
                        </div>
                        <div class="tab-group-wrapper" *ngIf="projetoCategoriaId != -1">
                            <div *ngIf="isResumoReady()">
                                <mat-tab-group dynamicHeight="true" mat-align-tabs="center">
                                    <mat-tab label="Resumo">
                                        <div class="container">
                                            <div style="margin-top: 30px">
                                                <div class="row">
                                                    <!-- Pie chart VP-->
                                                    <div class="col-lg-6">
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <h5>Value</h5>
                                                            </div>
                                                        </div>
                                                        <div class="value-table">
                                                            <mat-tab-group dynamicHeight="true" mat-align-tabs="left">
                                                                <mat-tab label="Critério Habitat">
                                                                    <div class="container" style="padding: 0px">
                                                                        <div class="col-lg-12" style="padding: 0px">
                                                                            <app-value-chart
                                                                                [valorParcelaAdimplente]="valorParcelaAdimplente"
                                                                                [valorParcelaInadimplente]="valorParcelaInadimplenteCriterioHabitat"
                                                                                [valorEstoque]="valorEstoqueCriterioHabitat"
                                                                                [criterioHabitat]="true"
                                                                            >
                                                                            </app-value-chart>
                                                                        </div>
                                                                    </div>
                                                                </mat-tab>
                                                                <mat-tab label="Original">
                                                                    <div class="container" style="padding: 0px">
                                                                        <div class="col-lg-12" style="padding: 0px">
                                                                            <app-value-chart
                                                                                [valorParcelaAdimplente]="valorParcelaAdimplente"
                                                                                [valorParcelaInadimplente]="valorParcelaInadimplente"
                                                                                [valorEstoque]="valorEstoque"
                                                                                [criterioHabitat]="false"
                                                                            >
                                                                            </app-value-chart>
                                                                        </div>
                                                                    </div>
                                                                </mat-tab>
                                                            </mat-tab-group>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <!-- Saldo devedor -->
                                                        <div class="row">
                                                            <div class="col-lg-4 saldo-devedor-title">
                                                                <h5>Saldo Devedor</h5>
                                                            </div>
                                                            <div class="col-md-3 col-3">
                                                                <app-carteira-select
                                                                    class="app-carteira"
                                                                    (onCarteiraChange)="changeCarteira($event)"
                                                                    [carteiraId]="selectedCarteiraID"
                                                                    [carteiras]="carteiras">
                                                                </app-carteira-select>
                                                            </div>
                                                        </div>
                                                        <div class="saldo-devedor-data">
                                                            <div class="row">
                                                                <div class="col-md-4 col-4" *ngFor="let data of saldoDevedorDictCardList">
                                                                    <app-dictcard [dictCard]="data"></app-dictcard>
                                                                </div>
                                                            </div>
                                                            <div class="row" style="margin-top: 20px">
                                                                <div class="col-lg-12">
                                                                    <h5>Estoque</h5>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-4 col-4" *ngFor="let data of estoqueDictCardList; let lastItem = last">
                                                                    <app-dictcard *ngIf="!lastItem" [dictCard]="data"></app-dictcard>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container">
                                            <hr />
                                        </div>
                                        <!-- bar charts -->
                                        <div class="container">
                                            <div class="row">
                                                <div *ngIf="carrouselControler" class="col-lg-6" >
                                                    <app-h2title [text]="'Evolução da carteira'"></app-h2title>
                                                    <br><br><br><br>
                                                    <app-values-stacked-chart
                                                        [obras]="obras"
                                                        [valuesPorObra]="valuesObras"
                                                        [valueCA]="valuesCA"
                                                        [valueCI]="valuesCI"
                                                        [valueE]="valuesE"
                                                        style="width: 100%"
                                                    >
                                                    </app-values-stacked-chart>
                                                </div>
                                                <div class="col-lg-6">
                                                    <button *ngIf="carrouselControler" (click)="toggleSlides()" class="botao-slider-dir" mat-icon-button>
                                                        <mat-icon style="
                                                            height:48px !important;
                                                            width:48px !important;
                                                            font-size:48px !important;
                                                            color: #9B9B9B">
                                                            keyboard_arrow_right
                                                        </mat-icon>
                                                    </button>
                                                    <button *ngIf="!carrouselControler" (click)="toggleSlides()" class="botao-slider-esq" mat-icon-button>
                                                        <mat-icon style="
                                                            height:48px !important;
                                                            width:48px !important;
                                                            font-size:48px !important;
                                                            color: #9B9B9B">
                                                            keyboard_arrow_left
                                                        </mat-icon>
                                                    </button>
                                                    <app-h2title [text]="'LTV'"></app-h2title>
                                                    <br><br><br><br>
                                                    <app-ltv-stacked-chart 
                                                        [ltvsFull]="ltvsFull" 
                                                        [ltvsAtual]="ltvsAtual" 
                                                        style="width: 100%">
                                                </app-ltv-stacked-chart>
                                            </div>
                                            <div *ngIf="!carrouselControler" class="col-lg-6">
                                                    <app-h2title [text]="'Evolução Saldo Devedor'"></app-h2title>
                                                    <br><br><br><br>
                                                    <app-saldo-devedor-stacked-chart
                                                        [historicoSaldoDevedor]="historicoSaldoDevedor"
                                                        style="width: 100%"
                                                    >
                                                    </app-saldo-devedor-stacked-chart>
                                                </div>
                                                <div class="col-lg-6"></div>
                                            </div>
                                        </div>
                                        <div class="container">
                                            <hr />
                                        </div>
                                        <!-- Fluxo Futuro -->
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <app-h2title [text]="'Fluxo Futuro do Projeto'"></app-h2title>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="isFluxoReady()">
                                            <div class="container" style="margin-bottom: 50px">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <app-fluxofuturo-dashboard 
                                                            [categorias]= "fluxoFuturoCategorias"
                                                            [curvasPmtsNames]= "fluxoFuturoCurvasPmtsNames"
                                                            [fluxoFuturoData]= "fluxoFuturoFluxoFuturoData"
                                                            [categoriasNaCarteira]="fluxoFuturoCategoriasNaCarteira"
                                                            [limiteRazaoFluxoMensal]="limiteRFM"
                                                            [obrasSiglas]="obrasSiglas"
                                                            >
                                                        </app-fluxofuturo-dashboard>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="loading_fluxo_futuro.length > 0">
                                            <mat-spinner style="position: relative; left: 45%; top: 100px; margin-bottom: 200px"></mat-spinner>
                                        </div>
                                    </mat-tab>
                                    
                                    <!-- Tab Razões -->
                                    <mat-tab label="Razões">
                                        <app-tab-razoes
                                            [recebido]="rfmRecebido"
                                            [pagamento]="rfmPagamento"
                                            [razoesFm]="rfmRazoes"
                                            [razoesSd]="rsdRazoes"
                                            [direitosCred]="rsdVpdc"
                                            [saldoDevedor]="rsdSaldosDevedores"
                                            [fundosDeReservaManuais]="fundosDeReservaManuais"
                                            [limiteRFM]="limiteRFM"
                                            [limiteRSD]="limiteRSD"
                                            [recebimentosPrevistos]="recebimentosPrevistos"
                                        >
                                        </app-tab-razoes>
                                    </mat-tab>

                                    <!-- Tab Desempenho -->
                                    <mat-tab label="Desempenho">
                                        <app-tab-desempenho
                                            [desempenhoTableDataProjeto]="desempenhoTableDataProjeto"
                                            [desempenhoTableDataObras]="desempenhoTableDataObras"
                                            [desempenhoObras]="desempenhoObras"
                                            [desempenhoTipoProjeto]="desempenhoTipoProjeto"
                                        >
                                        </app-tab-desempenho>
                                    </mat-tab>
                                    
                                    <!-- Tab Parcelas em Atraso -->
                                    <mat-tab label="Parcelas Atraso">
                                        <app-tab-parcelas-atraso
                                            [parcelasAtrasoTableDataProjeto]="parcelasEmAtrasoTableDataProjeto"
                                            [parcelasAtrasoTableDataObras]="parcelasEmAtrasoTableDataObras"
                                            [obras]="parcelasEmAtrasoObras"
                                            [tipoProjeto]="parcelasEmAtrasoTipoProjeto"
                                        >
                                        </app-tab-parcelas-atraso>
                                    </mat-tab>

                                    <!-- Tab Contratos -->
                                    <mat-tab label="Contratos">
                                        <app-fluxofuturo
                                            [projetoID]="projetoId">
                                        </app-fluxofuturo>
                                    </mat-tab>

                                    <!-- Tab Estoque -->
                                    <mat-tab *ngIf="estoqueMetodologiaID != 1" label="Estoque">
                                        <app-tab-estoque
                                            [estoqueData]="estoqueData"
                                            [estoqueMetodologiaID]="estoqueMetodologiaID"
                                            [projetoID]="projetoId"
                                            [mesBase]="selectedMesBase">
                                            
                                        </app-tab-estoque>
                                    </mat-tab>

                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="top-overlay" *ngIf="loading.length > 0">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
