import { Component, OnInit, Input, OnChanges } from '@angular/core'

@Component({
    selector: 'app-numbercard',
    templateUrl: './numbercard.component.html',
    styleUrls: ['./numbercard.component.css'],
})
export class NumbercardComponent implements OnInit {
    @Input('data') data: Object[]

    public cardColor: string = '#232837'
    constructor() {
        this.data = []
    }

    ngOnInit(): void {}

    ngOnChanges(): void {}
}
