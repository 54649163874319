import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { GrupoEconomico } from 'src/app/interfaces/grupo_economico'

@Component({
    selector: 'app-grupo-economico-select',
    templateUrl: './grupo-economico-select.component.html',
    styleUrls: ['./grupo-economico-select.component.css'],
})
export class GrupoEconomicoSelectComponent implements OnInit {
    @Input('gruposEconomicos') gruposEconomicos: GrupoEconomico[] = []
    @Input('grupoEconomicoId') grupoEconomicoId: number = 0
    @Output() onGrupoChange = new EventEmitter()
    constructor() {}

    emitGrupoEconomicoId(event: any) {
        this.onGrupoChange.emit(event.value)
    }

    ngOnInit(): void {}
}
