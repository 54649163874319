import { Y } from '@angular/cdk/keycodes'
import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-razao-abstract',
    templateUrl: './razao-abstract.component.html',
    styleUrls: ['./razao-abstract.component.css'],
})
export class RazaoAbstractComponent implements OnInit {
    constructor() {}

    insertHeader(firstEl: any, chartData: any, insertTotal: boolean): void {
        Object.keys(firstEl).forEach((key) => {
            if (!key.toLowerCase().includes('mes')) {
                chartData.push({ name: key, series: [] })
            }
        })
        if (insertTotal) if (chartData) chartData.push({ name: 'Total', series: [] })
    }

    getChartData(apiData: any, insertTotal: boolean, yMax: number = -Infinity, yMin: number = Infinity, recebidoChart: boolean = false) {
        var chartData: any = []
        if (!apiData.length) return chartData

        this.insertHeader(apiData[0], chartData, insertTotal)

        for (var i = 0; i < apiData.length; i++) {
            const aux = apiData[i]
            const mesBase = aux.MesBase.toString()
            delete aux.MesBase

            if (insertTotal) {
                let total = Object.values(aux).reduce((a: any, b: any) => a + b)
                if (recebidoChart) {
                    total = aux.Adimplente + aux.Atraso
                }
                yMax = this.updateYScale(total, yMax, true)
                yMin = this.updateYScale(total, yMin, false)
                chartData[chartData.length - 1].series.push({ name: mesBase, value: total })
            }

            Object.keys(aux).forEach((key) => {
                if (!key.toLowerCase().includes('mes')) {
                    const valor = aux[key]
                    yMax = this.updateYScale(valor, yMax, true)
                    yMin = this.updateYScale(valor, yMin, false)

                    const index = chartData.map((el: any) => el.name).indexOf(key)
                    chartData[index].series.push({ name: mesBase, value: valor })
                }
            })
        }
        return { chartData: chartData, yMin: yMin, yMax: yMax }
    }

    updateYScale(num: any, y: number, max: boolean = true): number {
        const numFloat: number = parseFloat(num)
        if (max) {
            if (numFloat > y) y = num
        } else if (numFloat < y) y = num

        return y
    }

    getRazaoChartData(apiData: any[]) {
        var razaoChart: any[] = []
        var customColors: any[] = []
        for (var i = 0; i < apiData.length; i++) {
            const aux = apiData[i]
            const mesBase = aux.MesBase.toString()
            const razao = aux.Razao

            razaoChart.push({ name: mesBase, value: razao })
            customColors.push({ name: mesBase, value: '#c4170c' })
        }

        return {
            razaoChart: razaoChart,
            customColors: customColors,
        }
    }

    ngOnInit(): void {}
}
