import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { ProjetoCategoria } from 'src/app/interfaces/projeto_categoria'
import { ProjetosService } from 'src/app/services/projetos.service'
import { Projeto } from 'src/app/interfaces/projeto'
import { IndexadorMensal } from 'src/app/interfaces/indexador_mensal'
import { Categoria } from 'src/app/interfaces/categoria'
import { Carteira } from 'src/app/interfaces/carteira'

@Component({
    selector: 'app-projeto-categoria-update',
    templateUrl: './projeto-categoria-update.component.html',
    styleUrls: ['./projeto-categoria-update.component.css'],
})
export class ProjetoCategoriaUpdateComponent implements OnInit, OnDestroy {
    constructor(private activatedRoute: ActivatedRoute, private projetoService: ProjetosService) {
        this.projetoCategoriaId = this.activatedRoute.snapshot.queryParams['projeto_categoria_id']
    }

    public loading: boolean[] = []
    public success: boolean = false
    public error: boolean = false
    public errorMessage: string = ''

    private ngUnsubscriber = new Subject()
    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'CRIs-Categorias', href: 'gestao/list/cris_categorias' },
    ]

    public projetoCategoriaId: number = -1
    public projetoCategoria: ProjetoCategoria = {
        ID: 0,
        ProjetoID: 0,
        CategoriaID: 0,
        ValorEmissao: 0,
        TIRR: 0,
        DataEmissao: '',
        DataVencto: '',
        IndexMensalID: 0,
        CarteiraID: 0,
    }
    public projetos: Projeto[] = []
    public categorias: Categoria[] = []
    public carteiras: Carteira[] = []
    public indexadores: IndexadorMensal[] = []

    canDisplay(): boolean {
        if (this.projetos.length == 0) return false
        if (this.categorias.length == 0) return false
        if (this.indexadores.length == 0) return false
        if (this.projetoCategoria.ID == 0) return false

        if (this.breadcrumbList.length == 3) {
            const projetoIndex: number = this.projetos.map((p) => p.ID).indexOf(this.projetoCategoria.ProjetoID)
            const projetoNome: string = this.projetos[projetoIndex].Sigla

            const categoriaIndex: number = this.categorias.map((c) => c.CategoriaID).indexOf(this.projetoCategoria.CategoriaID)
            const categoriaNome: string = this.categorias[categoriaIndex].Categoria

            const href: string = `gestao/edit/cri_categoria?projeto_categoria_id=${this.projetoCategoriaId}`
            this.breadcrumbList.push({ text: `${projetoNome}-${categoriaNome}`, href: href })
        }

        return true
    }

    getProjetoCategoria() {
        const params = { projeto_categoria_id: this.projetoCategoriaId }
        this.projetoService
            .getProjetoCategoria(params)
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    console.log(returnData)
                    this.projetoCategoria = returnData.projeto_categoria[0]
                    console.log(this.projetoCategoria)
                    this.loading.pop()
                },
                (errorData) => this.loading.pop()
            )
    }

    getProjetos() {
        this.projetoService
            .getProjetos()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.projetos = returnData.projetos
                    this.loading.pop()
                },
                (errordata) => this.loading.pop()
            )
    }

    getCategorias() {
        this.projetoService
            .getCategorias()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.categorias = returnData.categorias
                    this.loading.pop()
                },
                (errordata) => this.loading.pop()
            )
    }

    getCarteiras() {
        this.projetoService
            .getCarteiras()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.carteiras = returnData.carteiras
                    this.loading.pop()
                },
                (errordata) => this.loading.pop()
            )
    }

    getIndexadores() {
        this.projetoService
            .getIndexadores()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.indexadores = returnData.indexadores
                    this.loading.pop()
                },
                (errorData) => this.loading.pop()
            )
    }

    ngOnInit(): void {
        this.loading.push(true, true, true, true, true)
        this.getIndexadores()
        this.getProjetos()
        this.getCategorias()
        this.getProjetoCategoria()
        this.getCarteiras()
    }

    ngOnDestroy() {
        this.ngUnsubscriber.next()
        this.ngUnsubscriber.complete()
    }

    submit(projetoCategoria: ProjetoCategoria) {
        this.projetoCategoria = projetoCategoria
        this.projetoCategoria.DataEmissao = new Date(this.projetoCategoria.DataEmissao).toISOString().split('T')[0]
        this.projetoCategoria.DataVencto = new Date(this.projetoCategoria.DataVencto).toISOString().split('T')[0]

        this.error = this.success = false
        this.loading.push(true)

        this.projetoService
            .updateProjetoCategoria(this.projetoCategoria)
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.success = true
                    this.loading.pop()
                },
                (errorData: any) => {
                    this.error = true
                    this.errorMessage = errorData['message']['error']
                    this.loading.pop()
                }
            )
    }
}
