import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
@Injectable({
    providedIn: 'root',
})
export class ServicersService {
    constructor(private http: RequestService) {}
    private gestaoBlueprintEndpoint: string = this.http.apiEndpoint + 'gestao/'

    public getEndpoint(servicer: string, fileName: string) {
        if (servicer.toLowerCase().includes('conveste')) {
            const prefix = 'conveste/'
            if (fileName.includes('3_')) return prefix + 'base_dados'
            if (fileName.includes('1_')) return prefix + 'mascara'
            if (fileName.includes('6_')) return prefix + 'resumo_obras'
        }

        if (servicer.toLowerCase().includes('cci')) return 'cci'
        else return 'axis'
    }

    public uploadSingleFile(endpoint: string, data: any) {
        return this.http.postRequest(this.gestaoBlueprintEndpoint + endpoint, data)
    }

    public upload(mesBase: string, servicers: string[], fileName: string = '') {
        let formData = new FormData()
        formData.append('mes_base', mesBase)
        formData.append('servicers', JSON.stringify(servicers))

        if (fileName) formData.append('file_name', fileName)

        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'upload_servicers', formData)
    }
}
