import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core'
import { SubmoduleCard } from 'src/app/interfaces/submodule_card'
@Component({
    selector: 'app-submoduleexpansion',
    templateUrl: './submoduleexpansion.component.html',
    styleUrls: ['./submoduleexpansion.component.css'],
})
export class SubmoduleexpansionComponent implements OnInit {
    @Input('submoduleCards') submoduleCards: SubmoduleCard[] = []
    @Input('title') title: string
    @Input('description') description: string
    @Output('onCardTrigger') cardTriggered = new EventEmitter()
    constructor() {
        this.title = ''
        this.description = ''
    }

    ngOnInit(): void {}
    ngOnChanges(): void {}
}
