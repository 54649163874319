import { Component, OnInit, Input, OnChanges, AfterViewInit } from '@angular/core'
import { AbstractChartComponent } from 'src/app/shared/abstract-chart/abstract-chart.component'
@Component({
    selector: 'app-verticalbarchart',
    templateUrl: './verticalbarchart.component.html',
    styleUrls: ['./verticalbarchart.component.css'],
})
export class VerticalbarchartComponent extends AbstractChartComponent implements OnInit, OnChanges, AfterViewInit {
    public timeline: boolean = false
    public legend: boolean = false
    public showLabels: boolean = true
    public animations: boolean = true
    public xAxis: boolean = true
    public showYAxis: boolean = true
    public showXAxisLabel: boolean = true
    public showYAxisLabel: boolean = true
    public gradient: boolean = false
    public showGridLines: boolean = false
    public barPadding: number = 8
    public showDataLabel: boolean = true

    constructor() {
        super()
    }

    getBarPadding(): number {
        if (this.data.length == 0) return 8

        if (this.data.length < 4) return 75
        else if (this.data.length < 8) return 35
        else if (this.data.length < 12) return 8

        return 8
    }

    getCustomColors(): any[] {
        var ret: any[] = []

        if (this.data.length == 0) return ret
        for (var i = 0; i < this.data.length; i++) {
            const name = this.data[i].name
            if (name == 'CA') ret.push({ name: name, value: '#edb9b6' })
            else if (name == 'CA+CI') ret.push({ name: name, value: '#e7a29d' })
            else if (name == 'CA+CI+E') ret.push({ name: name, value: '#c4170c' })
        }
        return ret
    }

    ngOnChanges(changes: any): void {}

    ngOnInit(): void {
        super.ngOnInit()
        if (!this.customColors) this.customColors = this.getCustomColors()
        this.barPadding = this.getBarPadding()
    }

    ngAfterViewInit(): void {
        setTimeout(() => {})
    }
}
