import { Component, OnInit, Input, OnChanges } from '@angular/core'
import { AbstractChartComponent } from 'src/app/shared/abstract-chart/abstract-chart.component'
@Component({
    selector: 'app-linechart',
    templateUrl: './linechart.component.html',
    styleUrls: ['./linechart.component.css'],
})
export class LinechartComponent extends AbstractChartComponent implements OnInit {
    @Input('xAxisTickFormatting') xAxisTickFormatting?: Function
    @Input('xLabelTooltipOptions') xLabelTooltipOptions?: Object
    @Input('height') height: number = 300
    @Input('xAxis') xAxis: boolean = true

    formatXLabelTooltip(val: any): string {
        if (val instanceof Date) return val.toLocaleDateString('pt-BR', this.xLabelTooltipOptions)
        return val
    }

    constructor() {
        super()
        this.xAxisLabel = ''
        this.yAxisLabel = ''
        this.data = []
        this.xLabelTooltipOptions = { day: '2-digit', month: '2-digit', year: 'numeric' }
    }

    public timeline: boolean = false
    public legend: boolean = false
    public showLabels: boolean = true
    public animations: boolean = true
    public yAxis: boolean = true
    public showYAxisLabel: boolean = true
    public showXAxisLabel: boolean = false
    public autoScale: boolean = false
    public showGridLines: boolean = false

    ngOnChanges(): void {}

    ngOnInit(): void {
        if (!this.yScaleMax || !this.yScaleMin) this.autoScale = true
    }
}
