<mat-card>
    <mat-card-header>
        <a href="{{ cardInfo.href }}">
            <mat-card-title> {{ cardInfo.title }} </mat-card-title>
        </a>
    </mat-card-header>
    <mat-card-content>
        <p>
            {{ cardInfo.description }}
        </p>
    </mat-card-content>
</mat-card>
