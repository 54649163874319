<app-navbar></app-navbar>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="container">
    <div [ngClass]="loadingFileUpload ? 'disabled-div' : ''">
        
        <!-- Unidades -->
        <div class="upload-container">
            <div>
                <div class="row">
                    <div class="col-lg-12 titulo">
                        <app-h2title [text]="'Upload do Mapa de Unidades'"></app-h2title>
                    </div>
                </div>
            </div>
            <div style="margin-top: 20px">
                <div class="row">
                    <div class="col-lg-12">
                        <p>Faça o upload do arquivo Excel contendo o mapa de unidades de um cri</p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <app-filepicker [extensions]="'.xlsx, .xls'" (onFilePick)="updateUnidadesFile($event)"></app-filepicker>
                    <button [disabled]="filesUnidades.length == 0" style="margin-left: 20px" mat-raised-button color="primary" (click)="sendUnidadesFiles()">
                        Enviar
                    </button>
                </div>
            </div>
            <div *ngIf="filesUnidades.length > 0" style="margin-top: 20px">
                <div class="row">
                    <div class="col-lg-12">
                        <p>Arquivo selecionado:</p>
                        <ul *ngFor="let file of filesUnidades">
                            <li>{{ file.name }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>
        <!-- Fundo de Reserva -->
        <div class="upload-container">
            <div>
                <div class="row">
                    <div class="col-lg-12 titulo-upload-unidades">
                        <app-h2title [text]="'Upload Fundo de Reserva'"></app-h2title>
                    </div>
                </div>
            </div>
            <div style="margin-top: 20px">
                <div class="row">
                    <div class="col-lg-12">
                        <p>Faça o upload do arquivo Excel contendo os valores de fundo de reserva manuais dos cris</p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <app-filepicker [extensions]="'.xlsx, .xls'" (onFilePick)="updateFundoReservaFile($event)"></app-filepicker>
                    <button [disabled]="filesFundoReserva.length == 0" style="margin-left: 20px" mat-raised-button color="primary" (click)="sendFundoReservaFiles()">
                        Enviar
                    </button>
                </div>
            </div>
            <div *ngIf="filesFundoReserva.length > 0" style="margin-top: 20px">
                <div class="row">
                    <div class="col-lg-12">
                        <p>Arquivo selecionado:</p>
                        <ul *ngFor="let file of filesFundoReserva">
                            <li>{{ file.name }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="top-overlay" *ngIf="loadingFileUpload">
        <mat-spinner></mat-spinner>
    </div>
</div>