function getEndpoint() {
    const localAngularHost = 'localhost:4200'
    const localFlaskHost = 'http://localhost:5002/'
    const remoteFlaskHost = 'https://habitatapi.com/'
    const host = window.location.host
    if (host == localAngularHost) return localFlaskHost
    else return remoteFlaskHost
}

export class AppSettings {
    public static API_ENDPOINT = getEndpoint()
    public static SNACKBAR_DURATION = 10000

    public formatStringAsCurrency(input: string): string {
        return Number(input).toLocaleString('pt-br')
    }

    formatString(input: any): string {
        const regExp = /[a-zA-Z]/g
        const stringInput = input.toString()
        const appSettings = new AppSettings()

        if (stringInput.includes('-')) {
            // Data
            const aux = stringInput.split('-')
            return aux[2] + '/' + aux[1] + '/' + aux[0]
        } else if (stringInput.includes('/')) {
            return stringInput
        } else if (regExp.test(stringInput)) {
            // Texto com letra
            return stringInput
        } else if (stringInput.includes('%')) {
            // Porcentagem
            return stringInput
        } else if (parseFloat(stringInput)) {
            // Numero para formatar
            return appSettings.formatStringAsCurrency(stringInput)
        } else return stringInput // Safety
    }
}
