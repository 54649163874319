import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgenteFiduciario } from 'src/app/interfaces/agende-fiduciario';

@Component({
  selector: 'app-agente-fiduciario-edit-dialog',
  templateUrl: './agente-fiduciario-edit-dialog.component.html',
  styleUrls: ['./agente-fiduciario-edit-dialog.component.css']
})
export class AgenteFiduciarioEditDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<AgenteFiduciarioEditDialogComponent>) {}

    public agenteFiduciario: AgenteFiduciario = this.data.agenteFiduciario
    public create: boolean = this.data.create

    ngOnInit(): void {
    }

    save(): void {
        const params = {
            agenteFiduciario: this.agenteFiduciario,
            save: true,
        }
        this.dialogRef.close(params)
    }

    cancel(): void {
        const params = {
            save: false,
        }
        this.dialogRef.close(params)
    }

    isButtonDisabled(): boolean {
        return (this.agenteFiduciario.Nome === '')
    }

}
