import { Component, OnInit, OnDestroy } from '@angular/core'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { ProjetosService } from 'src/app/services/projetos.service'
import { Projeto } from 'src/app/interfaces/projeto'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Categoria } from 'src/app/interfaces/categoria'
import { IndexadorMensal } from 'src/app/interfaces/indexador_mensal'
import { ProjetoCategoria } from 'src/app/interfaces/projeto_categoria'
import { Carteira } from 'src/app/interfaces/carteira'

@Component({
    selector: 'app-novo-projeto-categoria',
    templateUrl: './novo-projeto-categoria.component.html',
    styleUrls: ['./novo-projeto-categoria.component.css'],
})
export class NovoProjetoCategoriaComponent implements OnInit, OnDestroy {
    constructor(private projetoService: ProjetosService) {}
    private ngUnsubscriber = new Subject()

    public loading: boolean[] = []
    public success: boolean = false
    public error: boolean = false
    public errorMessage: string = ''
    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'CRI-Categorias', href: 'gestao/list/cris_categorias' },
        { text: 'CRI-Categoria', href: 'gestao/create/cri_categoria' },
    ]

    public projetos: Projeto[] = []
    public categorias: Categoria[] = []
    public carteiras: Carteira[] = []
    public indexadores: IndexadorMensal[] = []
    public projetoCategoria: ProjetoCategoria = {
        ProjetoID: 0,
        CategoriaID: 0,
        ValorEmissao: 0,
        DataEmissao: '',
        DataVencto: '',
        TIRR: 0,
        IndexMensalID: 0,
        CarteiraID: 0,
    }

    getProjetos() {
        this.projetoService
            .getProjetos()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.projetos = returnData.projetos
                    this.loading.pop()
                },
                (errorData) => this.loading.pop()
            )
    }

    getCategorias() {
        this.projetoService
            .getCategorias()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.categorias = returnData.categorias
                    this.loading.pop()
                },
                (errorData) => this.loading.pop()
            )
    }

    getCarteiras() {
        this.projetoService
            .getCarteiras()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.carteiras = returnData.carteiras
                    this.loading.pop()
                },
                (errordata) => this.loading.pop()
            )
    }

    getIndexadores() {
        this.projetoService
            .getIndexadores()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.indexadores = returnData.indexadores
                    this.loading.pop()
                },
                (errorData) => this.loading.pop()
            )
    }

    ngOnInit(): void {
        this.loading.push(true, true, true, true)
        this.getCategorias()
        this.getProjetos()
        this.getIndexadores()
        this.getCarteiras()
    }

    submit(projetoCategoria: ProjetoCategoria) {
        this.projetoCategoria = projetoCategoria

        this.success = this.error = false
        this.loading.push(true)
        this.projetoService
            .createProjetoCategoria(this.projetoCategoria)
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.success = true
                    this.loading.pop()
                },
                (errorData: any) => {
                    this.error = true
                    this.errorMessage = errorData['error']['message']
                    this.loading.pop()
                }
            )
    }
    ngOnDestroy() {
        this.ngUnsubscriber.next()
        this.ngUnsubscriber.complete()
    }
}
