<mat-card>
    <mat-card-header>
        <mat-card-title>{{ triggerCard.title }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>
            {{ triggerCard.description }}
        </p>
    </mat-card-content>
    <div style="margin-left: 40px">
        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="triggerEvent()" mat-button>Calcular</button>
        </mat-card-actions>
    </div>
</mat-card>
