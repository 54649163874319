import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Subject } from 'rxjs'
import { MovimentacaoCaixa } from 'src/app/interfaces/movimentacao_caixa'
import { MovCaixaClassificacao } from 'src/app/interfaces/mov_caixa_classificacao'
import { CaixaService } from 'src/app/services/caixa.service'
import { ExcelService } from 'src/app/services/excel.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { takeUntil } from 'rxjs/operators'
import { AppSettings } from 'src/app/constants/AppSettings'

@Component({
    selector: 'app-movimentacao-caixa',
    templateUrl: './movimentacao-caixa.component.html',
    styleUrls: ['./movimentacao-caixa.component.css'],
})
export class MovimentacaoCaixaComponent implements OnInit {
    private ngUnsubscribe = new Subject()

    // Constants
    public loading: boolean = false
    public loading2: boolean = false
    private userToken = localStorage.getItem('token')

    // Dados mostrados na tabela
    public tableData: MovimentacaoCaixa[] = []
    public classificacoes: MovCaixaClassificacao[] = []

    // Filtros de datas selecionado
    public dataInicio: string = ''
    public dataFim: string = ''

    constructor(private caixaService: CaixaService, public dialog: MatDialog, private excel: ExcelService, private snackBar: MatSnackBar) {}

    ngOnInit(): void {
        this.loading = true
        this.loading2 = true

        this.getApiMovimentacaoCaixaDefault()
        this.getApiClassificacoes()
    }

    openSnackbar(message: string, messageType: string): void {
        this.snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
    }

    clearDateFilter(event: any): void {
        this.getApiMovimentacaoCaixaDefault()
        this.dataInicio = ''
        this.dataFim = ''
    }

    applyDateFilter(event: any): void {
        const startDate = event.start.toISOString().split('T')[0]
        const endDate = event.end.toISOString().split('T')[0]

        this.dataInicio = startDate
        this.dataFim = endDate

        this.getApiMovimentacaoCaixaWithDateRange(startDate, endDate)
    }

    getApiMovimentacaoCaixaWithDateRange(data_inicio: any, data_fim: any) {
        this.caixaService
            .getMovimentacaoCaixa({
                data_inicio: data_inicio,
                data_fim: data_fim,
            })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((returnData: any) => {
                this.tableData = [...returnData.movimentacao_caixa]
                this.loading = false
            })
    }

    getApiMovimentacaoCaixaDefault() {
        this.caixaService
            .getMovimentacaoCaixa({})
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((returnData: any) => {
                this.tableData = [...returnData.movimentacao_caixa]
                this.loading = false
            })
    }

    getApiClassificacoes() {
        this.caixaService
            .getClassificacoes({})
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((returnData: any) => {
                this.classificacoes = [...returnData.classificacoes]
                this.loading2 = false
            })
    }

    saveEditions() {
        if (!this.isTableDataValid()) {
            this.openSnackbar('Dados inválidos', 'error-snackbar')
        } else {
            this.caixaService
                .insertUpdateMovimentacao({ movimentacoes_caixa: this.tableData })
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(
                    (returnData: any) => {
                        if (returnData.message == 'sucesso') {
                            this.openSnackbar('Alterações salvas com sucesso', 'success-snackbar')
                        } else {
                            this.openSnackbar('Erro ao salvar dados', 'error-snackbar')
                        }
                    },
                    (errorData: any) => {
                        this.openSnackbar('Erro ao salvar dados na API: ' + errorData.error.message, 'error-snackbar')
                    }
                )
        }
    }

    isTableDataValid(): boolean {
        for (let i = 0; i < this.tableData.length; i++) {
            if (
                this.tableData[i].ClassificacaoID <= 0 ||
                this.tableData[i].Data == '' ||
                this.tableData[i].ID < 0 ||
                ![0, 1].includes(this.tableData[i].IsManual) ||
                this.tableData[i].ClassificacaoID <= 0
            ) {
                return false
            }
        }
        return true
    }

    createMovimentacao() {
        let movimentacao = { ID: 0, Data: '', Historico: '', Valor: -1, ClassificacaoID: 1, IsManual: 1 }
        this.tableData.push(movimentacao)
    }

    exportExcel(event: any): void {
        if (event.value == 1) {
            const endpoint = AppSettings.API_ENDPOINT + 'demonstrativo_caixa/movimentacao/excel?user_token=' + this.userToken
            window.location.href = endpoint
        } else {
            var copy: any[] = this.tableData.map((x) => Object.assign({}, x))
            for (let i = 0; i < copy.length; i++) {
                Object.defineProperty(copy[i], 'Classificacao', {
                    value: this.getClassificacaoFromClassificacaoId(copy[i].ClassificacaoID),
                    enumerable: true,
                })
            }
            this.excel.exportExcel(copy, 'Movimentacao_caixa' + '.xlsx', ['Data', 'Valor', 'Historico', 'Classificacao', 'IsManual'])
        }
    }

    getClassificacaoFromClassificacaoId(classificaoID: number): string {
        for (let classificacao of this.classificacoes) {
            if (classificacao.ID == classificaoID) {
                return classificacao.Nome
            }
        }
        return 'N/A'
    }

    deleteMovimentacao(movimentacao_id: number) {}
}
