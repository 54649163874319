<div class="container">
    <div class="row center-based-row">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <app-h2title [text]="'CRI'"></app-h2title>
        </div>
        <div class="col-lg-9 col-md-6 col-sm-12">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Nome do CRI</mat-label>
                        <input type="text" matInput [ngModel]="projeto.Sigla" (ngModelChange)="projeto.Sigla = $event" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Ativo</mat-label>
                        <mat-select [(value)]="projeto.AtivoID" (selectionChange)="projeto.AtivoID = $event.value">
                            <mat-option *ngFor="let ativo of ativos" [value]="ativo.ID">
                                {{ ativo.Nome }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Tipo</mat-label>
                        <mat-select [(value)]="projeto.TipoID" (selectionChange)="projeto.TipoID = $event.value">
                            <mat-option *ngFor="let tipo of tiposProjeto" [value]="tipo.TipoID">
                                {{ tipo.Sigla }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Setor</mat-label>
                        <mat-select [(value)]="projeto.SetorID" (selectionChange)="projeto.SetorID = $event.value">
                            <mat-option *ngFor="let setor of setores" [value]="setor.ID">
                                {{ setor.Nome }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Emissor</mat-label>
                        <mat-select [(value)]="projeto.EmissorID" (selectionChange)="projeto.EmissorID = $event.value">
                            <mat-option *ngFor="let emissor of emissores" [value]="emissor.EmissorID">
                                {{ emissor.Nome }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Servicer</mat-label>
                        <mat-select [value]="projeto.ServicerID" (selectionChange)="projeto.ServicerID = $event.value">
                            <mat-option *ngFor="let servicer of servicers" [value]="servicer.id">
                                {{ servicer.servicer }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Agente Fiduciário</mat-label>
                        <mat-select [(value)]="projeto.AgenteFiduciarioID" (selectionChange)="projeto.AgenteFiduciarioID = $event.value">
                            <mat-option *ngFor="let agenteFiduciario of agentesFiduciarios" [value]="agenteFiduciario.ID">
                                {{ agenteFiduciario.Nome }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-6 col-md-12">
                    <app-grupo-economico-select
                        (onGrupoChange)="projeto.GrupoEconomicoID = $event"
                        [gruposEconomicos]="gruposEconomicos"
                        [grupoEconomicoId]="projeto.GrupoEconomicoID"
                    >
                    </app-grupo-economico-select>
                </div>
                <div class="col-lg-6 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Metodologia LTV</mat-label>
                        <mat-select [value]="projeto.MetodologiaLTV_ID" (selectionChange)="projeto.MetodologiaLTV_ID = $event.value">
                            <mat-option *ngFor="let metodologiaLTV of metodologiasLTV" [value]="metodologiaLTV.ID">
                                {{ metodologiaLTV.Nome }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-6 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Metodologia RFM</mat-label>
                        <mat-select [value]="projeto.MetodologiaRFM_ID" (selectionChange)="projeto.MetodologiaRFM_ID = $event.value">
                            <mat-option *ngFor="let metodologiaRFM of metodologiasRFM" [value]="metodologiaRFM.ID">
                                {{ metodologiaRFM.Sigla }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-6 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Critério Inadimplência</mat-label>
                        <mat-select [value]="projeto.MetodologiaInadimplenciaID" (selectionChange)="projeto.MetodologiaInadimplenciaID = $event.value">
                            <mat-option *ngFor="let metodologiaInadimplencia of metodologiasInadimplencia" [value]="metodologiaInadimplencia.ID">
                                {{ metodologiaInadimplencia.Sigla }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-6 col-md-12" >
                    <mat-form-field appearance="standard">
                        <mat-label>Atraso Inadimplência</mat-label>
                        <mat-select [value]="projeto.ParcelasInadimplencia" (selectionChange)="projeto.ParcelasInadimplencia = $event.value">
                            <mat-option *ngFor="let option of [1, 2, 3, 4]" [value]="option"> {{ option }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-6 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Metodologia Estoque</mat-label>
                        <mat-select [value]="projeto.MetodologiaEstoqueID" (selectionChange)="projeto.MetodologiaEstoqueID = $event.value">
                            <mat-option *ngFor="let metodologiaEstoque of metodologiasEstoque" [value]="metodologiaEstoque.ID">
                                {{ metodologiaEstoque.Nome }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            
            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Despesas Mensais</mat-label>
                        <input type="number" matInput [ngModel]="projeto.DespesasMensal" (ngModelChange)="projeto.DespesasMensal = $event" />
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Despesas Anuais</mat-label>
                        <input type="number" matInput [ngModel]="projeto.DespesasAnual" (ngModelChange)="projeto.DespesasAnual = $event" />
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Possui Obra</mat-label>
                        <mat-select [value]="projeto.TemObra" (selectionChange)="projeto.TemObra = $event.value">
                            <mat-option [value]="1"> Sim </mat-option>
                            <mat-option [value]="0"> Não </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>VP Normalizado</mat-label>
                        <mat-select [value]="projeto.VPNormalizado" (selectionChange)="projeto.VPNormalizado = $event.value">
                            <mat-option [value]="1"> Sim </mat-option>
                            <mat-option [value]="0"> Não </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <hr />
</div>

<div class="container">
    <div class="row center-based-row">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <app-h2title [text]="'Razões'"></app-h2title>
        </div>
        <div class="col-lg-9 col-md-6 col-sm-12">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <mat-form-field appearance="standard">
                        <mat-label>Limite Razão Fluxo Mensal (%)</mat-label>
                        <input type="number" matInput [ngModel]="projeto.LimRazaoFluxoMensal" (ngModelChange)="projeto.LimRazaoFluxoMensal = $event" />
                        <span matSuffix>% </span>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-6">
                    <mat-form-field appearance="standard">
                        <mat-label>Limite Razão Saldo Devedor (%)</mat-label>
                        <input
                            type="number"
                            matInput
                            [ngModel]="projeto.LimRazaoSaldoDevedor"
                            (ngModelChange)="projeto.LimRazaoSaldoDevedor = $event"
                        />
                        <span matSuffix>% </span>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <hr />
</div>

<!-- Fundo de Reserva -->
<div class="container">
    <div class="row center-based-row">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <app-h2title [text]="'Fundo De Reserva'"></app-h2title>
        </div>
        <div class="col-lg-9 col-md-6 col-sm-12">
            <div class="row">
                <div class="col-lg-7 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Metodologia de Cálculo</mat-label>
                        <mat-select [value]="projeto.MetodologiaFundoReservaID" (selectionChange)="projeto.MetodologiaFundoReservaID = $event.value">
                            <mat-option *ngFor="let metodologia of metodologiasFundoReserva" [value]="metodologia.MetodologiaID">
                                {{ metodologia.Sigla }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-5 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Valor</mat-label>
                        <input type="number" matInput [ngModel]="projeto.ValorFundoReserva" (ngModelChange)="projeto.ValorFundoReserva = $event" />
                        <mat-hint align="start">{{ projeto.ValorFundoReserva.toLocaleString() }}</mat-hint>
                        <span *ngIf="projeto.MetodologiaFundoReservaID == 3" matSuffix>% </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="row" *ngIf="projeto.MetodologiaFundoReservaID == 4">
                <div class="col-lg-4 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Valor Mín.</mat-label>
                        <input type="number" matInput [ngModel]="projeto.MaxFundoReserva" (ngModelChange)="projeto.MaxFundoReserva = $event" />
                        <mat-hint align="start">{{ projeto.MaxFundoReserva.toLocaleString() }}</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Valor Máx.</mat-label>
                        <input type="number" matInput [ngModel]="projeto.MinFundoReserva" (ngModelChange)="projeto.MinFundoReserva = $event" />
                        <mat-hint align="start">{{ projeto.MaxFundoReserva.toLocaleString() }}</mat-hint>
                    </mat-form-field>
                </div>
                </div>
        </div>   
    </div>

    <!-- Fundo de Reserva Manual -->
    <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-12"></div>
        <div class="col-lg-4 col-md-12 manuais-title">
            <h5>Manuais</h5>
        </div>
    </div>
    <div class="row" style="align-items: center" *ngFor="let fundoReservaManual of projeto.FundosReservasManuais; let i = index">
        <div class="col-lg-3 col-md-6 col-sm-12"></div>
        <div class="col-lg-4 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Data </mat-label>
                <input type="number" matInput placeholder="Data" [(ngModel)]="fundoReservaManual.MesBase" />
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Valor </mat-label>
                <input type="number" matInput placeholder="Valor" [(ngModel)]="fundoReservaManual.Valor" />
            </mat-form-field>
        </div>

        <div class="col-lg-1 col-md-12">
            <button (click)="deleteFundoReservaManual(i)" mat-icon-button>
                <mat-icon>delete_outline</mat-icon>
            </button>
        </div>
    </div>
    <div class="row" style="align-items: center">
        <div class="col-lg-3 col-md-6 col-sm-12"></div>
        <div class="col-lg-4 col-md-12">
            <button (click)="createFundoReservaManual()" mat-icon-button>
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
</div>

<div class="container">
    <div class="push-right row button-wrapper">
        <button (click)="submit()" mat-raised-button color="primary">{{ buttonText }}</button>
    </div>
</div>
