import { Component, OnInit, Input } from '@angular/core'
import { AppSettings } from 'src/app/constants/AppSettings'

@Component({
    selector: 'app-cri-list-table',
    templateUrl: './cri-list-table.component.html',
    styleUrls: ['./cri-list-table.component.css'],
})
export class CriListTableComponent implements OnInit {
    @Input('displayCris') displayCris: any[] = []
    @Input('puDate') puDate: Date = new Date()

    constructor() {}

    getDisplayDate(): string {
        const aux: string[] = this.puDate.toISOString().split('T')[0].split('-')
        return `${aux[2]}/${aux[1]}/${aux[0]}`
    }

    formatNumber(input: string): string {
        const appSettings = new AppSettings()
        return appSettings.formatStringAsCurrency(input)
    }

    ngOnInit(): void {}
}
