<app-navbar></app-navbar>

<div *ngIf="success">
    <app-success-alert [text]="'Arquivo enviado com sucesso'"></app-success-alert>
</div>
<div *ngIf="error">
    <app-error-alert [text]="errorMessage"></app-error-alert>
</div>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="bottom-overlay">
    <div [ngClass]="loading ? 'disabled-div' : ''">
        <div class="container" style="margin-top: 20px">
            <div class="row">
                <div class="col-12">
                    <app-h2title [text]="'Envio do Demonstrativo de Caixa'"></app-h2title>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p>Faça o envio do arquivo com o Demonstrativo de Caixa</p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <app-filepicker [extensions]="'.xlsx, .xls'" (onFilePick)="updateFiles($event)"></app-filepicker>
                    <button [disabled]="files.length == 0" style="margin-left: 20px" mat-raised-button color="primary" (click)="sendFiles()">
                        Enviar
                    </button>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="files.length > 0" style="margin-top: 20px">
            <div class="row">
                <div class="col-12">
                    <span>Arquivos Selecionados: </span>
                    <ul>
                        <li style="margin-top: 10px" *ngFor="let file of files">{{ file.name }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="loading" class="top-overlay">
        <mat-spinner></mat-spinner>
    </div>
</div>
