<app-navbar></app-navbar>
<div *ngIf="success">
    <app-success-alert [text]="'CRI criado com sucesso'"></app-success-alert>
</div>
<div *ngIf="error">
    <app-error-alert [text]="errorMessage"></app-error-alert>
</div>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="bottom-overlay">
    <div [ngClass]="loading.length ? 'disabled-div' : ''">
        <div style="margin-top: 20px" *ngIf="canDisplay()">
            <app-cri-form
                (onSubmit)="submit($event)"
                [gruposEconomicos]="gruposEconomicos"
                [regrasArredondamento]="regrasArredondamento"
                [indexadores]="indexadores"
                [carteiras]="carteiras"
                [cri]="cri"
                [municipios]="municipios"
                [unidadesFederativas]="unidadesFederativas"
                [mtms]="mtms"
                [projetosCategorias]="projetosCategorias"
                [create]="true"
            >
            </app-cri-form>
        </div>
    </div>
    <div class="top-overlay" *ngIf="loading.length > 0">
        <mat-spinner></mat-spinner>
    </div>
</div>
