import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'

@Component({
    selector: 'app-notas',
    templateUrl: './notas.component.html',
    styleUrls: ['./notas.component.css'],
})
export class NotasComponent implements OnInit {
    constructor() {}

    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Notas', href: 'gestao/notas' },
    ]

    ngOnInit(): void {
      
    }

}
