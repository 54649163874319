<div class="container" style="margin-top: 20px">
    <div class="row">
        <div class="col-12">
            <mat-form-field class="filtro" appearance="fill">
                <mat-label>Filtrar</mat-label>
                <input matInput placeholder="" [value]="filterValue" (change)="filtrar($event)">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="mat-0elevation-z8 tableWrapper">
                <table mat-table [dataSource]="filteredDataSource">
                    
                    <ng-container matColumnDef="cri">
                        <th mat-header-cell *matHeaderCellDef> CRI</th>
                        <td mat-cell *matCellDef="let element"> {{ element.SiglaCRI }} </td>
                    </ng-container>
                    <ng-container matColumnDef="data">
                        <th mat-header-cell *matHeaderCellDef> Data </th>
                        <td mat-cell *matCellDef="let element"> {{ formatDate(element.Data) }} </td>
                    </ng-container>
                    <ng-container matColumnDef="descricao">
                        <th mat-header-cell *matHeaderCellDef> Descrição </th>
                        <td mat-cell *matCellDef="let element"> {{ element.Descricao }} </td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10, 15, 20]"
                    showFirstLastButtons 
                    aria-label="Select page">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>