import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { ExpandedEmpreendimento } from 'src/app/interfaces/expanded_empreendimento'
import { ProjetosService } from 'src/app/services/projetos.service'

@Component({
    selector: 'app-empreendimento-list',
    templateUrl: './empreendimento-list.component.html',
    styleUrls: ['./empreendimento-list.component.css'],
})
export class EmpreendimentoListComponent implements OnInit {
    constructor(private titleService: Title, private projetoService: ProjetosService) {
        this.titleService.setTitle('Empreendimentos | Gestão')
    }

    private ngUnsubscriber = new Subject()
    public loading: boolean = false
    public empreendimentos: ExpandedEmpreendimento[] = []
    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Empreendimentos', href: 'gestao/list/empreendimentos' },
    ]

    ngOnInit(): void {
        this.loading = true
        this.projetoService
            .getEmpreendimentos()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe((returnData: any) => {
                console.log(returnData)
                this.empreendimentos = returnData.obras
                this.loading = false
            })
    }
}
