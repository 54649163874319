<div class="container" style="margin-bottom: 30px">
    <div class="row" style="align-items: center">
        <div class="col-lg-5">
            <span *ngIf="noLimit == false" id="limit-rsd-value">{{ limiteRSD }}</span>
            <div *ngIf="noLimit == false" id="limit-line-sd"></div>
            <app-verticalbarchart
                [height]="1.5 * height"
                [xAxisLabel]="''"
                [yAxisLabel]="'Razão'"
                [data]="razoesChart"
                [customColors]="customColorsRazoes"
                [yScaleMax]="yScaleMax"
            >
            </app-verticalbarchart>
        </div>
        <div class="col-lg-7">
            <div>
                <app-linechart [height]="height" [xAxisLabel]="''" [yAxisLabel]="'Direitos Cred.'" [data]="dcChart" [customColors]="customColorsDc">
                </app-linechart>
            </div>
            
            <div style="border-top: 1px solid grey; margin-left: 75px; margin-right: 50px" [ngStyle]="{ 'margin-top': height + 20 }"></div>

            <div style="margin-top: 20px">
                <app-linechart [height]="height" [xAxisLabel]="''" [yAxisLabel]="'Saldo Devedor'" [data]="sdChart" [customColors]="customColorsSd">
                </app-linechart>
            </div>
            <div *ngIf="this.fundoDeReservaManualChart.length > 0">
                <div style="border-top: 1px solid grey; margin-left: 75px; margin-right: 50px" [ngStyle]="{ 'margin-top': height + 40 }"></div>
    
                <div style="margin-top: 20px">
                    <app-linechart [height]="height" [xAxisLabel]="''" [yAxisLabel]="'Fundo de Reserva'" [data]="fundoDeReservaManualChart" [customColors]="customColorsFundoDeReserva">
                    </app-linechart>
                </div>
            </div>
        </div>
    </div>
</div>
