import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    selector: 'app-confirmationdialog',
    templateUrl: './confirmationdialog.component.html',
    styleUrls: ['./confirmationdialog.component.css'],
})
export class GestaoConfirmationdialogComponent {
    public loading: Boolean = true

    public confirm(): void {
        this.matDialogRef.close(true)
    }

    public cancel(): void {
        this.matDialogRef.close(false)
    }
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public matDialogRef: MatDialogRef<GestaoConfirmationdialogComponent>) {}
}
