<div class="container">
    <form class="example-form">
        <div class="row">
            <div class="col-4">
                <mat-form-field appearance="standard">
                    <mat-label>Projeto</mat-label>
                    <input
                        type="text"
                        placeholder="Selecione um projeto"
                        aria-label="Projeto"
                        matInput
                        [formControl]="projetoControl"
                        [matAutocomplete]="autoProjeto"
                    />
                    <mat-autocomplete autoActiveFirstOption #autoProjeto="matAutocomplete" (optionSelected)="changeProjeto($event.option.value)">
                        <mat-option *ngFor="let project of filteredProjetos | async" [value]="project">
                            {{ project }}
                        </mat-option>
                    </mat-autocomplete>
                    <button mat-icon-button matSuffix (click)="clearProjeto()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div class="col-4">
                <mat-form-field appearance="standard">
                    <mat-label>Categoria</mat-label>
                    <input
                        type="text"
                        placeholder="Selecione uma categoria"
                        aria-label="Categoria"
                        matInput
                        [formControl]="categoriaControl"
                        [matAutocomplete]="autoCategoria"
                    />
                    <mat-autocomplete autoActiveFirstOption #autoCategoria="matAutocomplete" (optionSelected)="changeCategoria($event.option.value)">
                        <mat-option *ngFor="let categoria of filteredCategorias | async" [value]="categoria">
                            {{ categoria }}
                        </mat-option>
                    </mat-autocomplete>
                    <button
                        mat-icon-button
                        matSuffix
                        (click)="categoriaControl.reset()"
                        aria-label="Example icon button with a vertical three dot icon"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div class="col-2">
                <mat-form-field appearance="standard">
                    <mat-label>Mês Base</mat-label>
                    <mat-select appearance="standard" [(value)]="mesBaseSelected" (selectionChange)="changeMesBase()">
                        <mat-option *ngFor="let mesBase of mesesBase" [value]="mesBase">
                            {{ mesBase }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
