import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Carteira } from 'src/app/interfaces/carteira';
import { Trade } from 'src/app/interfaces/trade';
import { CriService } from 'src/app/services/cri.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ProjetosService } from 'src/app/services/projetos.service';

@Component({
  selector: 'app-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.css']
})
export class TradesComponent implements OnInit {
  
  private ngUnsubscribe = new Subject()
  
  constructor(
    private criService: CriService,
    private _snackBar: MatSnackBar,
    private projetoService: ProjetosService,
    private excel: ExcelService
  ) { 

  }

  public loadingFilters: boolean[] = [];
  public loading: boolean = false;
  public dataReady: boolean = false;

  public carteirasOptions: Carteira[] = []
  public crisOptions: any[] = [];

  public trades: Trade[] = []

  public filters = {
    dataInicio: null as Date | null,
    dataFim: null as Date | null,
    carteiras: [] as number[],
    cris: [] as number[]
  };

  ngOnInit(): void {
    for (let i = 0; i < 2; i++) {
        this.loadingFilters.push(true)
    }
    this.getCriOptions()
    this.getCarteiras()
  }

  getCarteiras(){
    this.projetoService
        .getCarteiras()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (returnData: any) => {
                this.carteirasOptions = returnData.carteiras
                this.loadingFilters.pop()
            },
            (errorData: any) => this.loadingFilters.pop()
        )
  }

  getCriOptions(){
    const params = { carteiras_ids: `[${this.filters.carteiras.join(',')}]` };
    this.criService
          .getCris(params)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((returnData: any) => {
              this.crisOptions = returnData.cri_data
              this.loadingFilters.pop()
          })
  }

  selectCris(cris: any){
    this.filters.cris = cris;
  }

  filtrarCarteira(carteiras: any) {
    this.filters.carteiras = carteiras;
    this.filters.cris = []
    this.loadingFilters.push(true)
    this.getCriOptions()
  }

  onClickBuscar(){
    this.loading = true
    this.getTrades()
  }

  getTrades(){
    const params = { 
      carteiras_ids: `[${this.filters.carteiras.join(',')}]` ,
      cri_ids: `[${this.filters.cris.join(',')}]`,
      data_inicio: formatDate(this.filters.dataInicio),
      data_fim: formatDate(this.filters.dataFim)
    };
    
    this.criService
      .getTrades(params)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((returnData: any) => {
          this.trades = returnData.trades
          this.loading = false
          this.dataReady = true
      },
      (errorData: any) => {
        this.loading = false
        this.dataReady = true
        alert("Erro ao buscar dados na API")
      })
  }

  sortData(sort: Sort) {
    const data = this.trades.slice()
    if (!sort.active || sort.direction === '') {
        this.trades = data
        return
    }
    this.trades = data.sort((a, b) => {
    const isAsc = sort.direction === 'asc'
    
    switch (sort.active) {
      case 'Carteira':
          return compare(a.Carteira, b.Carteira, isAsc)
        case 'CRI':
            return compare(a.NomeCri, b.NomeCri, isAsc)
        case 'Data':
          const dateA = a.Data instanceof Date ? a.Data : new Date(a.Data);
          const dateB = b.Data instanceof Date ? b.Data : new Date(b.Data);
          return compare(dateA, dateB, isAsc);
        case 'Saldo':
            return compare(a.Saldo, b.Saldo, isAsc)
        case 'CV':
            return compare(a.CV, b.CV, isAsc)
        case 'Compra':
            return compare(a.Compra, b.Compra, isAsc)
        case 'Venda':
            return compare(a.Venda, b.Venda, isAsc)
        case 'TaxaCompra':
            return compare(a.TaxaCompra, b.TaxaCompra, isAsc)
        case 'TaxaVenda':
            return compare(a.TaxaVenda, b.TaxaVenda, isAsc)
        case 'PUCompra':
            return compare(a.PUCompra, b.PUCompra, isAsc)
        case 'PUVenda':
            return compare(a.PUVenda, b.PUVenda, isAsc)
        case 'PUCompraMedio':
            return compare(a.PUCompraMedio, b.PUCompraMedio, isAsc)
        case 'PUAquisicaoVenda':
            return compare(a.PUAquisicaoVenda, b.PUAquisicaoVenda, isAsc)
        case 'GanhoK':
            return compare(a.GanhoK, b.GanhoK, isAsc)
        default:
            return 0
        }
    })
  }

  exportExcel(event: any): void {
    var copy: any[] = this.trades.map((x) => Object.assign({}, x))

    var copy = copy.map((row) => {
        row.Data = new Date(row.Data)
        row.Data = formatDatePTBR(row.Data)
        return row
    })

    this.excel.exportExcel(copy, 'Trades' + '.xlsx', ['ID','CRIID','NomeCri','CarteiraID','Carteira','Data', 'Saldo','CV', 'Compra', 'Venda', 'TaxaCompra', 'TaxaVenda', 'PUCompra', 'PUVenda', 'PUCompraMedio', 'AmortClean', 'PUAjustado', 'PUAquisicaoMedio', 'GanhoK'])
  }
}

function formatDatePTBR(date:Date){
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/')
};


function compare(a: number | string | Date | null, b: number | string | Date | null, isAsc: boolean) {
    if (a === b) {
        return 0;
    }
    const aIsNull = a === null || a === 'null';
    const bIsNull = b === null || b === 'null';
    if (aIsNull && bIsNull) {
        return 0;
    } else if (aIsNull) {
        return isAsc ? -1 : 1;
    } else if (bIsNull) {
        return isAsc ? 1 : -1;
    }

    let aValue = a instanceof Date ? a.getTime() : a;
    let bValue = b instanceof Date ? b.getTime() : b;

    if (typeof aValue === 'string' || typeof bValue === 'string') {
        aValue = aValue.toString();
        bValue = bValue.toString();
    }

    return (aValue < bValue ? -1 : 1) * (isAsc ? 1 : -1);
}

function formatDate (date: Date | null): string | null {
  if (date == null){
    return null
  }
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0')
}