
<div class="bottom-overlay">
    <div>
        <div [ngClass]="loading ? 'disabled-div' : ''">
            <div class="container" style="margin-top: 20px">
                <div class="alert alert-danger" role="alert" *ngIf="error">Ops! Não foi possível exportar o arquivo Excel</div>
            </div>
            <div class="container filter-wrapper">
                <div class="row">
                    <div *ngIf="obras.length > 0">
                        <!--Filtros-->
                        <app-obras-select 
                            (onObraChange)="onObraChange($event)" 
                            [obras]="obrasNames"> 
                         </app-obras-select>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedIndex != -1">
                <div class="container">
                    <div class="row chart-row">
                        <!--Resumo-->
                        <div class="col-lg-4">
                            <div class="project-div" *ngFor="let column of header">
                                <strong>{{ column }}:</strong> {{ formatNumber(tableData[selectedIndex][column]) }}
                            </div>
                        </div>
                        <!--Grafico-->
                        <div class="col-lg-8">
                            <div class="container chart-wrapper" #containerRef>
                                <ngx-charts-line-chart
                                    id="chart"
                                    [view]="[containerRef.offsetWidth, 300]"
                                    [legend]="legend"
                                    [showXAxisLabel]="showXAxisLabel"
                                    [showYAxisLabel]="showYAxisLabel"
                                    [xAxis]="xAxis"
                                    [yAxis]="yAxis"
                                    [xAxisLabel]="xAxisLabel"
                                    [yAxisLabel]="yAxisLabel"
                                    [timeline]="timeline"
                                    [results]="multi"
                                >
                                </ngx-charts-line-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <!-- Excel button-->
                        <div class="export-button-wrapper col-lg-2">
                            <button (click)="exportExcel()" mat-raised-button color="primary">Baixar Excel <mat-icon> file_download </mat-icon></button>
                        </div>
                        <!-- Excel button normalizado-->
                        <div class="export-button-wrapper col-lg-2">
                            <button (click)="exportExcelNormalizado()" mat-raised-button color="primary">Baixar Excel Norm.<mat-icon> file_download </mat-icon></button>
                        </div>
                    </div>
                </div>
                <div class="container venda-id-wrapper">
                    <div class="row">
                        <div class="container">
                            <!-- Filtro VendaID -->
                            <mat-form-field appearance="outline">
                                <mat-label>Filtro de Venda ID</mat-label>
                                <input matInput placeholder="Insira o ID da Venda" (keyup)="applyVendaIdfilter($event)" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <!--Tabela-->
            <div class="container loaded-table-wrapper">
                <div class="container">
                    <div class="row">
                        <cdk-virtual-scroll-viewport [itemSize]="10" (scrolledIndexChange)="loadMore()">
                            <table id="customTable">
                                <tr>
                                    <!-- Botoes order by -->
                                    <th *ngFor="let column of header">
                                        <div *ngIf="orderByList.includes(column); else notButton">
                                            <button mat-button (click)="orderTable(column)">{{ column }}</button>
                                        </div>
                                        <ng-template #notButton>
                                            <div>
                                                {{ column }}
                                            </div>
                                        </ng-template>
                                    </th>
                                </tr>
                                <tr
                                    *cdkVirtualFor="let row of tableData; let i = index"
                                    [ngClass]="{ highlight: selectedIndex == i }"
                                    (click)="changeTableRow(i)"
                                    (keydown.arrowdown)="arrowDownEvent($event)"
                                    (keydown.arrowup)="arrowUpEvent($event)"
                                    tabindex="0"
                                >
                                    <td *ngFor="let column of header">
                                        {{ formatNumber(row[column]) }}
                                    </td>
                                </tr>
                            </table>
                        </cdk-virtual-scroll-viewport>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="top-overlay" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>
