import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Estruturacao } from 'src/app/interfaces/estruturacao'

@Component({
    selector: 'app-estruturacao-select',
    templateUrl: './estruturacao-select.component.html',
    styleUrls: ['./estruturacao-select.component.css'],
})
export class EstruturacaoSelectComponent implements OnInit {
    @Input('estruturacoes') estruturacoes: Estruturacao[] = []
    @Input('estruturacaoId') estruturacaoId: number = 0
    @Output() onEstruturacaoChange = new EventEmitter()
    constructor() {}

    emitEstruturacaoId(event: any) {
        this.onEstruturacaoChange.emit(event.value)
    }
    ngOnInit(): void {}
}
