import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Ativo } from 'src/app/interfaces/ativo';
import { Breadcrumb } from 'src/app/interfaces/breadcrumb';
import { ProjetosService } from 'src/app/services/projetos.service'
import { AtivoEditDialogComponent } from './ativo-edit-dialog/ativo-edit-dialog.component';

@Component({
  selector: 'app-ativo-list',
  templateUrl: './ativo-list.component.html',
  styleUrls: ['./ativo-list.component.css']
})
export class AtivoListComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public projetoService: ProjetosService) {
  }
  private ngUnsubscriber = new Subject()
  
  public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Cadastros', href: 'gestao/cadastros' },
        { text: 'Ativos', href: 'gestao/list/ativos' },
  ]

  public loading: boolean = false
  public ativos: Ativo[] = []

  createAtivoDialog(): void {
    const ativoDialog = this.dialog.open(AtivoEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            ativo: {ID:-1, Nome:""},
        },
    })

    ativoDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading = true
          this.createAtivo(params.ativo)
        }
        return
    })
  }

  createAtivo(ativo:Ativo):void{
    let { ID, ...ativoWithoutID} = ativo
    this.projetoService
        .createAtivo(ativoWithoutID)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'Ativo criado com sucesso') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar ativo', 'error-snackbar')
                }
                this.getAtivos()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar agente fiduciário' + errorData.error.message, 'error-snackbar')
              this.getAtivos()
            }
        )
  }

  updateAtivoDialog(ativo: any): void {
    const ativoDialog = this.dialog.open(AtivoEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            ativo: ativo,
            create: false,
        },
    })

    ativoDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading = true
          this.updateAtivo(params.ativo)
        }
        return
    })
  }

  updateAtivo(ativo:Ativo):void{
    this.projetoService
        .updateAtivo(ativo)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'Ativo atualizado com sucesso') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar ativo', 'error-snackbar')
                }
                this.getAtivos()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar ativo' + errorData.error.message, 'error-snackbar')
              this.getAtivos()
            }
        )
  }

  ngOnInit(): void {
    this.loading = true
    this.getAtivos()
  }

  getAtivos():void{
    this.projetoService
        .getAtivos()
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe((returnData: any) => {
            console.log(returnData)
            this.ativos = returnData.ativos
            this.loading = false
        })
  }

  openSnackbar(message: string, messageType: string): void {
    this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
  }

}
