<div>
    <cdk-virtual-scroll-viewport [itemSize]="60">
        <table id="customTable">
            <table id="customTable">
                <tr>
                    <th></th>
                    <th>Data Compra</th>
                    <th>Nome da Operação</th>
                    <th>Emissão</th>
                    <th>Série</th>
                    <th>Código IF</th>
                    <th>Quantidade</th>
                    <th>
                        Mtm <br />
                        ({{ dataBase.toLocaleDateString() }})
                    </th>
                    <th>
                        Duration <br />
                        ({{ dataBase.toLocaleDateString() }})
                    </th>
                    <th>Carteira</th>
                </tr>
                <tr *ngFor="let data of mtmCris">
                    <td style="border: none">
                        <button style="float: left" mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item hidden="true">
                                <span>Dummyautofocus</span>
                            </button>
                            <button mat-menu-item (click)="export(data)">
                                <mat-icon>file_download</mat-icon>
                                <span>Baixar Excel</span>
                            </button>
                        </mat-menu>
                    </td>
                    <td>
                        {{ data.DataCompra }}
                    </td>
                    <td>
                        {{ data.Nome }}
                    </td>

                    <td>
                        {{ data.EmissaoNro }}
                    </td>
                    <td>
                        {{ data.SerieNro }}
                    </td>
                    <td>
                        {{ data.CodigoIF }}
                    </td>
                    <td>
                        {{ formatNumber(data.QtdeCarteira) }}
                    </td>
                    <td>
                        {{ formatNumber(data.Mtm) }}
                    </td>
                    <td>
                        {{ formatNumber(data.Duration) }}
                    </td>
                    <td>
                        {{ data.Carteira }}
                    </td>
                </tr>
            </table>
        </table>
    </cdk-virtual-scroll-viewport>
</div>
