import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'
import { Cri } from 'src/app/interfaces/cri'
import { takeUntil } from 'rxjs/operators'
import { TriggerCard } from 'src/app/interfaces/trigger_card'
import { CriService } from 'src/app/services/cri.service'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
    selector: 'app-calculos',
    templateUrl: './calculos.component.html',
    styleUrls: ['./calculos.component.css'],
})
export class CalculosComponent implements OnInit, OnDestroy {
    constructor(private criService: CriService, private _snackBar: MatSnackBar) {}

    private ngUnsubscribe = new Subject()
    public loading: Boolean = false
    public cris: Cri[] = []
    public puCurva: string = 'PU Curva'
    public puMtm: string = 'PU Mtm'
    public triggerCards: TriggerCard[] = [
        {
            title: this.puCurva,
            description: 'Cálculo dos Preços Unitários dos CRIs na curva',
            hasDate: false,
        },
        {
            title: this.puMtm,
            description: 'Cálculo dos Preços Unitários dos CRIs segundo Marcação a Mercado',
            hasDate: true,
        },
    ]

    triggerCalculation(data: any): void {
        if (!data) return
        if (!data.trigger) return
        if (!data.event) return

        const event = data.event

        var formData = new FormData()
        formData.append('cri_ids', data.criIds)
        if (data.dataBase) formData.append('data_base', data.dataBase.toISOString().split('T')[0])

        if (event == this.puCurva) {
            console.log('disparo pu curva')
            this.criService.calculatePuCurva(formData).pipe(takeUntil(this.ngUnsubscribe)).subscribe()
        } else if (event == this.puMtm) {
            console.log('disparo mtm')
            this.criService.calculatePuMtm(formData).pipe(takeUntil(this.ngUnsubscribe)).subscribe()
        }

        this._snackBar.open(`${event} disparado. Quando concluído, uma notificação será enviada no canal do Slack`, 'Fechar', {
            duration: 10000,
        })
    }

    ngOnInit(): void {
        this.loading = true
        this.criService
            .getCris({})
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((returnData: any) => {
                this.cris = returnData.cri_data
                this.loading = false
            })
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.complete()
    }
}
