import { Component, OnInit } from '@angular/core'
import moment from 'moment'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { Cri } from 'src/app/interfaces/cri'
import { DurationTable } from 'src/app/interfaces/duration_table'
import { ControladoriaService } from 'src/app/services/controladoria-service.service'
import { CriService } from 'src/app/services/cri.service'

@Component({
    selector: 'app-duration',
    templateUrl: './duration.component.html',
    styleUrls: ['./duration.component.css'],
})
export class DurationComponent implements OnInit {
    public areCrisReady: boolean = false
    public isTableReady: boolean = false

    public cris: Cri[] = []
    public selectedBaseDate: string = ''
    public selectedCri: number = 0

    // Dados da Tabela
    public duration: number = 0
    public tableData: DurationTable[] = []
    public saldoDevedor: number = 0

    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Controladoria', href: 'controladoria' },
        { text: 'Duration', href: 'controladoria/duration' },
    ]

    constructor(private criService: CriService) {}

    private ngUnsubscribe = new Subject()

    selectCri(criID: number) {
        this.selectedCri = criID
    }

    areParametersReadyForCalculation() {
        if (this.selectedCri != 0 && moment(this.selectedBaseDate, 'YYYY-MM-DD', true).isValid()) {
            return true
        }
        return false
    }

    calculateDuration() {
        var formData = new FormData()
        formData.append('data_base', this.selectedBaseDate)
        formData.append('cri_id', this.selectedCri.toString())

        this.isTableReady = false
        this.criService
            .calculateDuration(formData)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((returnData: any) => {
                this.tableData = [...returnData.tabela_duration]
                this.duration = returnData.duration
                this.saldoDevedor = returnData.saldo_devedor
                this.isTableReady = true

                console.log(this.tableData)
            })
    }

    ngOnInit(): void {
        this.criService
            .getCris({})
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((returnData: any) => {
                this.cris = returnData.cri_data
                this.areCrisReady = true
            })
    }
}
