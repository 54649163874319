import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'
import { VortxService } from 'src/app/services/vortx.service'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { SiglaVortx } from 'src/app/interfaces/siglas_vortx'
import { takeUntil } from 'rxjs/operators'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
    selector: 'app-upload-vortx',
    templateUrl: './upload-vortx.component.html',
    styleUrls: ['./upload-vortx.component.css'],
})
export class UploadVortxComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject()
    public files: File[] = []
    public loading: Boolean = false
    public success: Boolean = false
    public error: Boolean = false
    public errorMessage: string = ''

    public lancamentos_sem_correspondencia: any[] = []

    // De-Para das siglas das contas vortx
    public siglasVortx: SiglaVortx[] = []

    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Controladoria', href: 'controladoria' },
        { text: 'Upload Carteira Vortx', href: 'controladoria/upload_vortx' },
    ]
    constructor(private vortxService: VortxService, private _snackBar: MatSnackBar) {}

    ngOnInit(): void {
        this.getApiSiglasVortx()
    }

    updateFiles(event: any): void {
        this.files = event
    }

    async uploadCarteira(): Promise<number> {
        this.lancamentos_sem_correspondencia = []
        for (var i = 0; i < this.files.length; i++) {
            let formData = new FormData()
            formData.append('file', this.files[i])
            var result: any = await this.vortxService.uploadCarteiraVortx(formData)
            console.log(result)
            if (result.message != 'ok') {
                return 500
            }
            this.lancamentos_sem_correspondencia = result.lancamentos_sem_correspondencia
        }
        return 200
    }

    async sendFiles(): Promise<void> {
        this.loading = true
        this.error = this.success = false
        var result: number = await this.uploadCarteira()
        if (result == 200) this.success = true
        else if (result == 500) {
            this.error = true
            this.errorMessage = 'Não foi possível atualizar o arquivo'
        }
        this.loading = false
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.complete()
    }

    getApiSiglasVortx(): void {
        this.vortxService
            .getSiglasVortx({})
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((returnData: any) => {
                this.siglasVortx = returnData.siglas
                console.log(this.siglasVortx)
            })
    }

    createSigla(): void {
        let sigla = { ID: 0, SiglaHabitat: '', SiglaVortx: '' }
        this.siglasVortx.push(sigla)
    }

    openSnackbar(message: string, messageType: string): void {
        this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
    }

    submit(): void {
        const hardCopy: any = Object.assign({}, this.siglasVortx)
        const values: any[] = Object.values(hardCopy)

        for (let i = 0; i < values.length; i++) {
            if (values[i].SiglaVortx == '' || values[i].SiglaHabitat == '') {
                this.openSnackbar('Preencha Todos os Campos', 'mat-simple-snackbar-action')
                return
            }
        }

        this.vortxService
            .updateSiglas({ siglas: this.siglasVortx })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.openSnackbar('Siglas salvas com sucesso', 'success-snackbar')
                    this.loading = false
                },
                (errorData: any) => {
                    this.openSnackbar('Erro ao salvar dados na API', 'error-snackbar')
                    this.loading = false
                }
            )
    }
}
