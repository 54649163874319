import { Component, HostListener, OnInit } from '@angular/core'
import { LancamentoConta } from 'src/app/interfaces/lancamento-conta'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { VortxService } from 'src/app/services/vortx.service'
import { Contas } from 'src/app/interfaces/contas'
import { MatDialog } from '@angular/material/dialog'
import { EditarContaDialogComponent } from './editar-conta-dialog/editar-conta-dialog.component'
import { NovaContaDialogComponent } from './nova-conta-dialog/nova-conta-dialog.component'
import { AppSettings } from 'src/app/constants/AppSettings'
import { ExcelService } from 'src/app/services/excel.service'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
    selector: 'app-lancamento-conta',
    templateUrl: './lancamento-conta.component.html',
    styleUrls: ['./lancamento-conta.component.css'],
})
export class LancamentoContaComponent implements OnInit {
    @HostListener('window:resize', ['$event'])
    getScreenSize(event?: any) {
        this.screenHeight = window.innerHeight
    }
    private ngUnsubscribe = new Subject()

    // Constantes
    public loading: boolean = false
    public screenHeight: number = -1
    private userToken = localStorage.getItem('token')

    // Conta selecionada
    public checkedConta: number = 13

    // Os dados mostrados na tabela
    public contaTitle: string = ''
    public filteredTableData: LancamentoConta[] = []
    public tableData: LancamentoConta[] = []
    public contas: Contas[] = []

    // Filtro de carteira
    public selectedCarteiras: string[] = []
    public carteiras: string[] = []

    constructor(private vortxService: VortxService, public dialog: MatDialog, private excel: ExcelService, private _snackBar: MatSnackBar) {
        this.getScreenSize()
    }

    ngOnInit(): void {
        this.loading = true

        this.getApiContas()
        this.getApiContaLancamentoData()
    }

    updateSelectedCarteiras(event: any) {
        this.selectedCarteiras = event

        this.filteredTableData = this.tableData.filter((lancamento) => {
            const carteira = lancamento.Carteira
            return this.selectedCarteiras.includes(carteira)
        })
    }

    clearDateFilter(event: any): void {
        this.filteredTableData = [...this.tableData]
    }

    applyDateFilter(event: any): void {
        const startDate = event.start
        const endDate = event.end
        this.filteredTableData = this.tableData.filter((lancamento) => {
            const datestr = lancamento.Data.split('/').reverse().join('-')
            const date = new Date(datestr)
            return date >= startDate && date <= endDate
        })
    }

    onChangeConta(event: any): void {
        this.loading = true

        this.checkedConta = event.value
        this.getApiContaLancamentoData()
    }

    getApiContaLancamentoData() {
        this.vortxService
            .getLancamentosPorConta({
                conta_id: this.checkedConta,
            })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((returnData: any) => {
                this.tableData = [...returnData.table_data]
                this.carteiras = []
                this.selectedCarteiras = []
                for (var i = 0; i < returnData.carteiras.length; i++) {
                    this.carteiras.push(returnData.carteiras[i].Nome)
                    if (returnData.carteiras[i].ID == 2) {
                        this.selectedCarteiras.push(returnData.carteiras[i].Nome)
                    }
                }
                this.contaTitle = returnData.conta_title
                this.loading = false
                this.filteredTableData = [...this.tableData]
            })
    }

    getApiContas() {
        this.vortxService
            .getContas({})
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((returnData: any) => {
                this.contas = returnData.contas
            })
    }

    novaConta() {
        const novaContaDialog = this.dialog.open(NovaContaDialogComponent, {
            width: '70%',
            height: 'auto',
            autoFocus: false,
            position: {
                top: '80px',
            },
            data: {},
        })
        novaContaDialog.afterClosed().subscribe((result) => {
            var params = result
            if (params.save) {
                this.criarNovaContaApi(params.conta)
            }
            return
        })
    }

    editarConta() {
        const editContaDialog = this.dialog.open(EditarContaDialogComponent, {
            width: '70%',
            height: 'auto',
            autoFocus: false,
            position: {
                top: '80px',
            },
            data: {
                conta: this.get_selected_conta(this.checkedConta),
            },
        })

        editContaDialog.afterClosed().subscribe((result) => {
            var params = result
            if (params.save) {
                this.editarContaApi(params.conta)
            }
            return
        })
    }

    get_selected_conta(conta_id: number): Contas {
        for (let i = 0; i < this.contas.length; i++) {
            if (this.contas[i].ID == conta_id) {
                return this.contas[i]
            }
        }
        return this.contas[0]
    }

    editarContaApi(conta: Contas) {
        this.vortxService
            .updateConta(conta)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    if (returnData.message == 'Conta editada com sucesso') {
                        this.openSnackbar(returnData.message, 'success-snackbar')
                    } else {
                        this.openSnackbar('Erro ao criar editar Conta', 'error-snackbar')
                    }
                },
                (errorData: any) => {
                    this.openSnackbar('Erro ao salvar dados na API: ' + errorData.error.message, 'error-snackbar')
                }
            )
    }

    criarNovaContaApi(conta: Contas) {
        this.vortxService
            .createConta(conta)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    if (returnData.message == 'Conta cadastrada com sucesso') {
                        this.openSnackbar(returnData.message, 'success-snackbar')
                    } else {
                        this.openSnackbar('Erro ao criar nova Conta', 'error-snackbar')
                    }
                },
                (errorData: any) => {
                    this.openSnackbar('Erro ao salvar dados na API: ' + errorData.error.message, 'error-snackbar')
                }
            )
    }

    exportExcel(event: any): void {
        console.log(event.value)

        if (event.value == 1) {
            const endpoint = AppSettings.API_ENDPOINT + 'vortx/lancamento_contabil/excel?user_token=' + this.userToken
            window.location.href = endpoint
        } else {
            var copy: any[] = this.filteredTableData.map((x) => Object.assign({}, x))

            var aux_array = copy.map((row) => {
                row.Data = new Date(row.Data)
                row.Data = this.formatDate(row.Data)
                return row
            })

            copy = [...aux_array]
            this.excel.exportExcel(copy, this.contaTitle + '.xlsx', ['Data', 'Valor', 'Obs', 'IsManual', 'Carteira'])
        }
    }

    formatDate(date: Date) {
        return [this.padTo2Digits(date.getDate()), this.padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/')
    }

    padTo2Digits(num: number) {
        return num.toString().padStart(2, '0')
    }

    editLancamentos() {
        if (!this.isTableDataValid()) {
            this.openSnackbar('Dados inválidos', 'error-snackbar')
        } else {
            this.vortxService
                .updateInsertLancamentoContabil({ lancamentos: this.filteredTableData })
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(
                    (returnData: any) => {
                        if (returnData.message == 'Lançamentos salvos com sucesso') {
                            this.openSnackbar('Lançamentos salvos com sucesso', 'success-snackbar')
                        } else {
                            this.openSnackbar('Erro ao salvar dados na API', 'error-snackbar')
                        }
                    },
                    (errorData: any) => {
                        this.openSnackbar('Erro ao salvar dados na API', 'error-snackbar')
                    }
                )
        }
    }

    isTableDataValid(): boolean {
        for (let i = 0; i < this.filteredTableData.length; i++) {
            if (this.filteredTableData[i].Data == '' || this.filteredTableData[i].ID < 0 || ![0, 1].includes(this.filteredTableData[i].IsManual)) {
                return false
            }
        }
        return true
    }

    openSnackbar(message: string, messageType: string): void {
        this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
    }

    createLancamento(): void {
        let lancamento = { ID: 0, Data: '', ContaID: this.checkedConta, Obs: '', Valor: -1, Carteira: 'CRI HABITAT-2', IsManual: 1 }
        this.filteredTableData.push(lancamento)
    }
}
