import { Component, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { EventEmitter } from '@angular/core'

interface Projeto {
    Projeto: string
}

interface Obra {
    ObraID: number
    ProjetoID: number
    Obra: string
    Projeto: string
}

@Component({
    selector: 'app-projetosobras',
    templateUrl: './projetosobras.component.html',
    styleUrls: ['./projetosobras.component.css'],
})
export class ProjetosobrasComponent implements OnInit {
    @Input('projetos') projetos: Projeto[]
    @Input('obras') obras: Obra[]
    @Output() onObraChange = new EventEmitter()
    @Output() onProjetoChange = new EventEmitter()

    constructor() {
        this.projetos = []
        this.obras = []
    }

    ngOnInit(): void {
        this.obraControl.disable()
        this.filteredProjetos = this.projetoControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterProjects(value))
        )
    }

    // Filter
    projetoControl = new FormControl()
    obraControl = new FormControl()
    declare filteredProjetos: Observable<string[]>
    declare filteredObras: Observable<string[]>

    // User interaction
    public declare displayObras: Obra[]
    public declare obraId: number
    public declare projetoId: number

    private _filterProjects(value: string): string[] {
        if (value) {
            const filterValue = value.toLowerCase()
            return this.projetos.map((projeto) => projeto.Projeto).filter((project) => project.toLowerCase().includes(filterValue))
        } else {
            return this.projetos.map((projeto) => projeto.Projeto)
        }
    }

    private _filterObras(value: string): string[] {
        if (value) {
            const filterValue = value.toLowerCase()
            return this.displayObras.map((obra) => obra.Obra).filter((obra) => obra.toLowerCase().includes(filterValue))
        } else {
            return this.displayObras.map((obra) => obra.Obra)
        }
    }

    clearProjeto(): void {
        this.projetoControl.reset()
        this.obraControl.reset()
        this.displayObras = []
        this.obraControl.disable()
    }

    changeProjeto(projeto: string) {
        this.displayObras = this.obras.filter((obra) => obra.Projeto == projeto)
        this.projetoId = this.obras.filter((obra) => obra.Projeto == projeto)[0].ProjetoID
        this.onProjetoChange.emit(this.projetoId)
        this.obraControl.enable()
        this.filteredObras = this.obraControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterObras(value))
        )
        if (this.displayObras.length == 1) {
            this.obraControl.setValue(this.displayObras[0].Obra)
            this.changeObra(this.displayObras[0].Obra)
        } else {
            this.obraControl.reset()
        }
    }

    changeObra(obra: string) {
        this.obraId = this.displayObras.filter((el) => el.Obra == obra)[0].ObraID
        this.onObraChange.emit(this.obraId)
    }
}
