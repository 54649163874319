import { Component, Input, OnChanges, OnInit } from '@angular/core';

interface Colors {
  'A+': string 
  A: string
  'A-': string
  B: string
  C: string
}

@Component({
    selector: 'app-notas-chart',
    templateUrl: './notas-chart.component.html',
    styleUrls: ['./notas-chart.component.css'],
})
export class NotasChartComponent implements OnChanges {
    @Input('chartData') chartData: any[] = []

    public options: any = {}
    public series: any = []

    public colors: Colors = { 'A+': '#1E8500', A: '#2CB804', 'A-': '#33D804', B: '#EAE708', C: '#F10B0B' }

    constructor() {}

    ngOnChanges(): void {
        this.mountChart()
    }

    mountChart() {
        this.groupChartBySaldoDevedor()
        const graphColors: any = []

        for (let i = 0; i < this.chartData.length; i++) {
            graphColors.push(this.colors[this.chartData[i].Rating as keyof Colors])
        }

        const series = this.chartData.map((obj) => obj.SaldoDevedor)
        const labels = this.chartData.map((obj) => obj.Rating)

        this.options = {
            series: series,
            chart: {
                type: 'donut',
            },
            labels: labels,
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#2C2C2C'],
                },
                dropShadow: {
                    enabled: false,
                },
                formatter: function (val: any, opts: any) {
                    return [parseInt(val) + '%']
                },
            },
            colors: graphColors,
            legend: {
                show: false,
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 300,
                        },
                    },
                },
            ],
        }
    }

    groupChartBySaldoDevedor(){
        let result : any[] = [];
        this.chartData.reduce(function(res, value) {
            if (!res[value.Rating]){
                res[value.Rating] = { Rating: value.Rating, SaldoDevedor:0}
                result.push(res[value.Rating])
            }
            res[value.Rating].SaldoDevedor += value.SaldoDevedor
            return res;
        }, {})
        this.chartData = result
    }
}
