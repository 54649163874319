import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core'
import { HostListener } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { BreakdownParcelasAtrasoTable } from 'src/app/interfaces/breakdown-parcelas-atraso-table'
import { DesempenhoProjetoTable } from 'src/app/interfaces/desempenho-projeto-table'
import { DictCard } from 'src/app/interfaces/dictcard'
import { DashboardProjetoService } from 'src/app/services/dashboard-projeto.service'
import { ProjetosService } from 'src/app/services/projetos.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { LTV } from 'src/app/interfaces/LTV'
import { Value } from 'src/app/interfaces/values'
import { Carteira } from 'src/app/interfaces/carteira'

@Component({
    selector: 'app-dashboardprojeto',
    templateUrl: './dashboardprojeto.component.html',
    styleUrls: ['./dashboardprojeto.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class DashboardprojetoComponent implements OnInit, OnDestroy {
    constructor(private dashboardProjetoService: DashboardProjetoService, private _snackBar: MatSnackBar, private projetosService: ProjetosService) {
        this.getScreenSize()
    }
    private ngUnsubscribe = new Subject()

    // Loading
    public loading: Boolean[] = []
    public loading_fluxo_futuro: Boolean[] = []
    public loadingValuesObras: Boolean[] = []

    // Projetos
    public projetos: [] = []
    public projetosCategorias: [] = []
    public projetoCategoriaId: number = -1
    public projetoId: number = -1
    public categoriaId: number = -1

    // Meses Base
    public mesesBase: any[] = []

    // Resumo emissao
    public resumoEmissaoApiData: any

    // Fluxo Futuro
    fluxoFuturoCategorias: string[] = []
    fluxoFuturoCurvasPmtsNames: string[] = []
    fluxoFuturoFluxoFuturoData: any = {}
    fluxoFuturoCategoriasNaCarteira: string[] = []
    obrasSiglas: string[] = []

    // Estoque
    public estoqueDictCardList: DictCard[] = []
    public valorEstoque: number = -1
    public valorEstoqueCriterioHabitat: number = -1

    // Value
    public valorParcelaAdimplente: number = -1
    public valorParcelaInadimplente: number = -1
    public valorParcelaInadimplenteCriterioHabitat: number = -1

    // Histórico Values
    public valuesCA: Value[] = []
    public valuesCI: Value[] = []
    public valuesE: Value[] = []
    
    public obras: string[] = []
    public valuesObras: any

    // Histórico LTVs
    public ltvsFull: LTV[] = []
    public ltvsAtual: LTV[] = []

    // Histórico Saldo Devedor
    public historicoSaldoDevedor: any[] = []

    // Seletor de Carteira
    public carteiras: Carteira[] = []
    public selectedCarteiraID = 2

    // Carrousel Controler
    public carrouselControler: boolean = true
    
    // Saldo devedor
    public saldoDevedorDictCardList: DictCard[] = []
    public valorSaldoDevedorAtual: number = -1
    public valorSaldoDevedorFull: number = -1
    
    // Tab Razao Fluxo Mensal
    public rfmRecebido: any[] = []
    public rfmPagamento: any[] = []
    public rfmRazoes: any[] = []
    public limiteRFM: number = 0
    public recebimentosPrevistos: any[] = []
    
    // Tab Razao Saldo Devedor
    public rsdVpdc: any[] = []
    public rsdSaldosDevedores: any[] = []
    public rsdRazoes: any[] = []
    public screenHeight: number = -1
    public limiteRSD: number = 0
    public fundosDeReservaManuais: any[] = []
    
    // Tab Desempenho
    public desempenhoTableDataProjeto: DesempenhoProjetoTable[] = []
    public desempenhoTableDataObras: DesempenhoProjetoTable[] = []
    public desempenhoObras: string[] = []
    public desempenhoTipoProjeto: string = ''
    
    // Tab Parcelas Em Atraso
    public parcelasEmAtrasoTableDataProjeto: BreakdownParcelasAtrasoTable[] = []
    public parcelasEmAtrasoTableDataObras: BreakdownParcelasAtrasoTable[] = []
    public parcelasEmAtrasoObras: string[] = []
    public parcelasEmAtrasoTipoProjeto: string = ''
    
    // Tab Estoque
    public selectedMesBase: string = ''
    public estoqueMetodologiaID: number = 1
    public estoqueData: any[] = []
    
    openSnackbar(message: string, messageType: string): void {
        this._snackBar.open(message, 'Fechar', {
            duration: 5000,
            panelClass: [messageType],
        })
    }
    
    @HostListener('window:resize', ['$event'])
    getScreenSize(event?: any) {
        this.screenHeight = window.innerHeight
    }
    
    isResumoReady(): boolean {
        return this.projetoCategoriaId != -1 && this.loading.length == 0
    }
    
    getTabelaResumo(params: any) {
        this.dashboardProjetoService
        .getTabelaResumoDashboard(params)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (returnData: any) => {
                // Carteira
                this.carteiras = returnData.carteiras
                this.selectedCarteiraID = returnData.tabela_resumo[0].CarteiraID
                this.getTabelaDesempenho({...params, carteira_id:this.selectedCarteiraID})

                // Estoque
                this.estoqueDictCardList = [
                    { name: 'Unidades', value: returnData.tabela_resumo[0].UnidadesEstoque },
                    { name: 'Preço Unitário', value: returnData.tabela_resumo[0].PrecoUnitarioEstoque },
                    { name: 'Valor', value: returnData.tabela_resumo[0].Estoque },
                    { name: 'ValorCriterioHabitat', value: returnData.tabela_resumo[0].Estoque * 0.6 },
                ]
                this.valorEstoque = returnData.tabela_resumo[0].Estoque
                this.valorEstoqueCriterioHabitat = this.valorEstoque * 0.6
                
                // Values
                this.valorParcelaAdimplente = returnData.tabela_resumo[0].ValueAdimplente
                this.valorParcelaInadimplente = returnData.tabela_resumo[0].ValueInadimplente
                this.valorParcelaInadimplenteCriterioHabitat = this.valorParcelaInadimplente * 0.6

                // Saldo Devedor
                this.saldoDevedorDictCardList = [
                    { name: 'Total', value: returnData.tabela_resumo[0].SaldoDevedorTotal },
                    { name: 'Subordinação', value: returnData.tabela_resumo[0].SaldoDevedorCategoria },
                    { name: returnData.tabela_resumo[0].Categoria, value: returnData.tabela_resumo[0].SaldoDevedorCategoriaSemHierarquia },
                    { name: `${this.getSelectedCarteiraSigla()} ${returnData.tabela_resumo[0].Categoria}`, value: returnData.tabela_resumo[0].SaldoDevedor }, // A diferença entre SD Carteira Categoria e SD Carteira é que que o primeiro é apenas da categoria selecionada e o outro é o total do projeto (todas as categorias)
                    { name: `${this.getSelectedCarteiraSigla()}`, value: returnData.tabela_resumo[0].SaldoDevedorCarteira }, //
                ]
                
                // Histórico Saldo Devedor
                this.historicoSaldoDevedor = []
                this.historicoSaldoDevedor = returnData.historico_saldo_devedor
                
                // LTVs
                this.ltvsAtual = []
                this.ltvsFull = []
                for (let i = 0; i < returnData.tabela_resumo.length; i++) {
                    this.ltvsAtual.push({ Data: returnData.tabela_resumo[i].DataBase, Valor: returnData.tabela_resumo[i].LTVAtual })
                    this.ltvsFull.push({ Data: returnData.tabela_resumo[i].DataBase, Valor: returnData.tabela_resumo[i].LTVFull })
                }
                
                // Razão Fluxo Mensal (RFM)
                this.limiteRFM = returnData.tabela_resumo[0].RazaoMinimaFluxoMensal
                
                this.rfmPagamento = []
                this.rfmRecebido = []
                this.rfmRazoes = []
                for (let i = returnData.tabela_resumo.length - 1; i > -1; i--) {
                    this.rfmPagamento.push(returnData.tabela_resumo[i].Pagamento)
                    this.rfmRecebido.push(returnData.tabela_resumo[i].Recebido)
                    this.rfmRazoes.push(returnData.tabela_resumo[i].RFM)
                }
                
                // Razão Saldo Devedor (RSD)
                this.limiteRSD = returnData.tabela_resumo[0].RazaoMinimaSaldoDevedor
                
                this.rsdSaldosDevedores = []
                this.rsdVpdc = []
                this.rsdRazoes = []
                for (let i = returnData.tabela_resumo.length - 1; i > -1; i--) {
                    this.rsdSaldosDevedores.push(returnData.tabela_resumo[i].RSDSaldosDevedores)
                    this.rsdVpdc.push(returnData.tabela_resumo[i].RSDVPDireitosCreditorios)
                    this.rsdRazoes.push(returnData.tabela_resumo[i].RSD)
                }

                // Values
                this.valuesCA = []
                this.valuesCI = []
                this.valuesE = []
                for (let i = 0; i < returnData.tabela_resumo.length; i++) {
                    this.valuesCA.push({ Data: returnData.tabela_resumo[i].DataBase, Valor: returnData.tabela_resumo[i].ValueAdimplente })
                    this.valuesCI.push({ Data: returnData.tabela_resumo[i].DataBase, Valor: returnData.tabela_resumo[i].ValueInadimplente * 0.6 })
                    this.valuesE.push({ Data: returnData.tabela_resumo[i].DataBase, Valor: returnData.tabela_resumo[i].Estoque * 0.6 })
                }

                this.loading.pop()
            },
            (errorData: any) => {
                if (params.carteira_id){
                    alert("Não existe dados da tabela resumo para essa carteira nesse mês base.")
                }
                this.loading.pop()
            }
        )
    }

    getValuesPorObra(params:any){
        this.dashboardProjetoService
            .getValuesPorObra(params)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.valuesObras = returnData.values
                    this.obras = returnData.obras
                    this.loadingValuesObras.pop()
                },
                (errorData: any) => {
                    this.loadingValuesObras.pop()
                }
            )
    }
    
    getDadosMascara(params: any) {
        this.dashboardProjetoService
        .getDadosMascara(params)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (returnData: any) => {
                // Se no futuro o dashboard precisar de mais dados de máscara, 
                // implementar no back nesse endpoint
                this.recebimentosPrevistos = returnData.recebimentos_previstos
                this.loading.pop()
            },
            (errorData: any) => {
                this.loading.pop()
            }
            )
        }
        
    getResumoEmissao(params: any) {
        this.dashboardProjetoService
        .getResumoEmissao(params)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (returnData: any) => {
                this.resumoEmissaoApiData = returnData
                this.loading.pop()
            },
            (errorData: any) => {
                this.loading.pop()
            }
            )
        }
            
    getFluxoFuturoProjeto(params: any) {
        this.dashboardProjetoService
        .getFluxoFuturo(params)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (returnData: any) => {
                this.fluxoFuturoCategorias = returnData.categorias
                this.fluxoFuturoCurvasPmtsNames = returnData.curvas_pmts_names
                this.fluxoFuturoFluxoFuturoData = returnData.fluxo_futuro
                this.fluxoFuturoCategoriasNaCarteira = returnData.categorias_na_carteira
                this.obrasSiglas = returnData.obras_siglas
                this.loading_fluxo_futuro.pop()
            },
        (errorData: any) => {
            this.loading_fluxo_futuro.pop()
        }
        )
    }

    changeCarteira(carteiraID:any){
        const params = {
            mes_base: this.selectedMesBase,
            projeto_id: this.projetoId,
            categoria_id: this.categoriaId,
            projeto_categoria_id: this.projetoCategoriaId,
            carteira_id: carteiraID
        }
        this.loading.push(true)
        this.getTabelaResumo(params)
    }

    getSelectedCarteiraSigla(){
        const foundCarteira = this.carteiras.find(carteira => carteira.ID === this.selectedCarteiraID);
        return foundCarteira?.Sigla;
    }
    
    isFluxoReady(): boolean {
        return !this.loading_fluxo_futuro.length
    }
    
    getTabelaDesempenho(params: any): void {
        this.dashboardProjetoService
        .getTabelaDesempenho(params)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (returnData: any) => {
                this.desempenhoTableDataProjeto = returnData.tabela_desempenho_projeto
                this.desempenhoTableDataObras = returnData.tabelas_desempenho_obras
                this.desempenhoObras = returnData.obras
                this.desempenhoTipoProjeto = returnData.tipo_projeto
                
                this.loading.pop()
            },
            (errorData: any) => {
                this.desempenhoTableDataProjeto = []
                this.loading.pop()
            }
            )
        }
        
    getTabelaParcelasEmAtraso(params: any): void {
        this.dashboardProjetoService
        .getTabelaParcelasEmAtraso(params)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (returnData: any) => {
                this.parcelasEmAtrasoTableDataProjeto = returnData.tabela_parcelas_em_atraso_projeto
                this.parcelasEmAtrasoTableDataObras = returnData.tabela_parcelas_em_atraso_obras
                this.parcelasEmAtrasoObras = returnData.obras
                this.parcelasEmAtrasoTipoProjeto = returnData.tipo_projeto
                
                this.loading.pop()
            },
            (errorData: any) => {
                this.loading.pop()
            }
            )
        }
            
    getEstoqueData(params: any): void {
        this.dashboardProjetoService
            .getEstoqueData(params)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
            (returnData: any) => {
                this.estoqueData = []
                this.estoqueData = returnData.estoque_data
                this.estoqueMetodologiaID = returnData.estoque_metodologia
                this.loading.pop()
            },
            (errorData: any) => {
                this.loading.pop()
            }
        )
    }
    
    getFundosDeReservaManuais(params: any): void {
        this.dashboardProjetoService
        .getFundosDeReservaManuais(params)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (returnData: any) => {
                this.fundosDeReservaManuais = returnData.fundos_de_reserva_manuais
                this.loading.pop()
            },
            (errorData: any) => {
                this.loading.pop()
            }
            )
    }
        
    updateProjetoCategoriaId(event: any): void {
        this.projetoCategoriaId = event.projetoCategoriaId
        this.categoriaId = event.categoriaId
        
        this.loading.push(true)
        this.getMesesBaseProjeto(this.projetoId, true)
    }
        
    loadDashboard(): void {
        this.updateMesBase(this.mesesBase[0])
    }
        
    updateMesBase(mesBase: any): void {
        this.selectedMesBase = mesBase
        const params = {
            mes_base: mesBase,
            projeto_id: this.projetoId,
            categoria_id: this.categoriaId,
            projeto_categoria_id: this.projetoCategoriaId,
        }
        for (var i = 0; i < 7; i++) this.loading.push(true)
        
        // Tabela Resumo contém Values, Estoque, Saldo Devedor, LTVs, RFM e RSD.
        this.getTabelaResumo(params)
        this.getResumoEmissao(params)
        this.getTabelaParcelasEmAtraso(params)
        this.getEstoqueData(params)
        this.getFundosDeReservaManuais(params)
        this.getDadosMascara(params)
        
        this.loadingValuesObras.push(true)
        this.getValuesPorObra(params)
        
        this.loading_fluxo_futuro.push(true)
        this.getFluxoFuturoProjeto(params)
    }
        
    updateProjetoId(projetoID: number): void {
        this.projetoId = projetoID
    }
    
    ngOnInit(): void {
        this.loading.push(true)
        this.projetosService
        .getProjetosCategoriasInCarteira({})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (returnData: any) => {
                this.projetos = returnData.projetos
                this.projetosCategorias = returnData.projetos_categorias
                this.loading.pop()
            },
            (errorData: any) => this.loading.pop()
        )
    }

    toggleSlides():void{
        this.carrouselControler = !this.carrouselControler
    }

    changeEvolucaoCarteiraChart(event:any){
        let obra = event.value
        console.log(obra)
    }
    
    getMesesBaseProjeto(projetoID: number, load_dashboard: boolean): void {
        this.projetosService
        .getMesesBaseProjeto(projetoID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (returnData: any) => {
                this.mesesBase = returnData.meses_base
                this.loading.pop()
                if (load_dashboard) {
                    this.loadDashboard()
                }
            },
            (errorData: any) => {
                this.loading.pop()
            }
        )
    }
        
    ngOnDestroy(): void {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.complete()
    }
}
            