import { Component, OnInit } from '@angular/core'
import { SubmoduleCard } from 'src/app/interfaces/submodule_card'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { Title } from '@angular/platform-browser'

@Component({
    selector: 'app-gestao',
    templateUrl: './gestao.component.html',
    styleUrls: ['./gestao.component.css'],
})
export class GestaoComponent implements OnInit {
    constructor(private titleService: Title) {
        this.titleService.setTitle('Gestão | Habitat App')
    }

    public submodules: SubmoduleCard[] = [
        {
            title: 'Dashboard CRI-Categoria',
            description: 'Dashboard com métricas dos CRIs-categorias.',
            href: 'gestao/dashboard_projeto',
        },
        {
            title: 'Upload de Bases das Servicers',
            description: 'Envio de bases das servicers.',
            href: 'gestao/upload_servicers',
        },
        {
            title: 'Upload CRIs',
            description: 'Fundo de reserva manual, mapa de unidades',
            href: 'gestao/upload_cris',
        },
        {
            title: 'CRIs',
            description: 'Consultar CRIs cadastrados',
            href: 'gestao/list/cris',
        },
        {
            title: 'CRIs-Categorias',
            description: 'Consultar CRIs-Categorias cadastrados',
            href: 'gestao/list/cris_categorias',
        },
        {
            title: 'Empreendimentos',
            description: 'Consultar os empreendimentos cadastrados',
            href: 'gestao/list/empreendimentos',
        },
        {
            title: 'Cadastros',
            description: 'Cadastros de carteiras, administrador e outros',
            href: 'gestao/cadastros',
        },
        {
            title: 'Tabela Resumo',
            description: 'Visualização da tabela Resumo',
            href: 'gestao/tabela_resumo',
        },
        {
            title: 'Notas CRI',
            description: 'Visualização da tela de notas',
            href: 'gestao/notas',
        },
        {
            title: 'Calendário',
            description: 'Visualização do calendário',
            href: 'gestao/calendario',
        },
    ]

    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
    ]

    ngOnInit(): void {}
}
