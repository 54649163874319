import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'app-tab-parcelas-atraso',
    templateUrl: './tab-parcelas-atraso.component.html',
    styleUrls: ['./tab-parcelas-atraso.component.css'],
})
export class TabParcelasAtrasoComponent implements OnInit {
    @Input('parcelasAtrasoTableDataProjeto') parcelasAtrasoTableDataProjeto: any[] = []
    @Input('parcelasAtrasoTableDataObras') parcelasAtrasoTableDataObras: any[] = []
    @Input('obras') obras: string[] = []
    @Input('tipoProjeto') tipoProjeto: string = ''

    public parcelasAtrasoTableDataAtual: any[] = []
    public obra_selected: string = ''
    public percentage_select: boolean = false

    public tableData: any[]
    public tableHeader: any[] = []
    public columnHeader: any[] = []

    constructor() {
        this.tableData = []
    }

    ngOnInit(): void {
        this.parcelasAtrasoTableDataAtual = JSON.parse(JSON.stringify(this.parcelasAtrasoTableDataProjeto))
        this.assembleTable()
        this.columnHeader = ['Em Dia', '1', '2', '3', '4', '5', '6+', '3+', 'Unidades Ativas']
        this.obra_selected = 'Total'
    }

    onObraChange(obra: any): void {
        this.obra_selected = obra
        if (obra == 'Total' || this.obras.length == 2) {
            this.parcelasAtrasoTableDataAtual = JSON.parse(JSON.stringify(this.parcelasAtrasoTableDataProjeto))
            if (this.percentage_select) {
                this.parcelasAtrasoTableDataAtual = this.calculate_percentages(this.parcelasAtrasoTableDataAtual)
            }
        } else {
            this.parcelasAtrasoTableDataAtual = JSON.parse(JSON.stringify(this.parcelasAtrasoTableDataObras[obra]))
            if (this.percentage_select) {
                this.parcelasAtrasoTableDataAtual = this.calculate_percentages(this.parcelasAtrasoTableDataAtual)
            }
        }
        this.assembleTable()
    }

    onValueOrPercentageSelect(selector: any): void {
        let obra: any = this.obra_selected
        if (selector == '%') {
            this.parcelasAtrasoTableDataAtual = this.calculate_percentages(this.parcelasAtrasoTableDataAtual)
            this.percentage_select = true
        } else {
            this.percentage_select = false
            if (obra == 'Total' || this.obras.length == 2) {
                this.parcelasAtrasoTableDataAtual = JSON.parse(JSON.stringify(this.parcelasAtrasoTableDataProjeto))
            } else {
                this.parcelasAtrasoTableDataAtual = JSON.parse(JSON.stringify(this.parcelasAtrasoTableDataObras[obra]))
            }
        }
        this.assembleTable()
    }

    calculate_percentages(table: any): any {
        for (let index = 0; index < table.length; index++) {
            let unidadesAtivas = table[index].UnidadesAtivas
            table[index].UnidadesEmdia /= unidadesAtivas
            table[index].Unidades1Atraso /= unidadesAtivas
            table[index].Unidades2Atrasos /= unidadesAtivas
            table[index].Unidades3Atrasos /= unidadesAtivas
            table[index].Unidades4Atrasos /= unidadesAtivas
            table[index].Unidades5Atrasos /= unidadesAtivas
            table[index].Unidades6MaisAtrasos /= unidadesAtivas
            table[index].Unidades3MaisAtrasos /= unidadesAtivas
            table[index].UnidadesAtivas /= unidadesAtivas
        }
        return table
    }

    assembleTable(): any {
        this.tableData = []
        this.tableHeader = []
        let row = []
        for (let index = this.parcelasAtrasoTableDataAtual.length - 1; index > -1; index--) {
            row.push(this.parcelasAtrasoTableDataAtual[index].MesBase)
            this.tableHeader = row
        }

        row = []
        for (let index = this.parcelasAtrasoTableDataAtual.length - 1; index > -1; index--) {
            row.push(this.parcelasAtrasoTableDataAtual[index].UnidadesEmdia)
        }
        this.tableData.push(row)

        row = []
        for (let index = this.parcelasAtrasoTableDataAtual.length - 1; index > -1; index--) {
            row.push(this.parcelasAtrasoTableDataAtual[index].Unidades1Atraso)
        }
        this.tableData.push(row)
        row = []
        for (let index = this.parcelasAtrasoTableDataAtual.length - 1; index > -1; index--) {
            row.push(this.parcelasAtrasoTableDataAtual[index].Unidades2Atrasos)
        }
        this.tableData.push(row)
        row = []
        for (let index = this.parcelasAtrasoTableDataAtual.length - 1; index > -1; index--) {
            row.push(this.parcelasAtrasoTableDataAtual[index].Unidades3Atrasos)
        }
        this.tableData.push(row)
        row = []
        for (let index = this.parcelasAtrasoTableDataAtual.length - 1; index > -1; index--) {
            row.push(this.parcelasAtrasoTableDataAtual[index].Unidades4Atrasos)
        }
        this.tableData.push(row)
        row = []
        for (let index = this.parcelasAtrasoTableDataAtual.length - 1; index > -1; index--) {
            row.push(this.parcelasAtrasoTableDataAtual[index].Unidades5Atrasos)
        }
        this.tableData.push(row)
        row = []
        for (let index = this.parcelasAtrasoTableDataAtual.length - 1; index > -1; index--) {
            row.push(this.parcelasAtrasoTableDataAtual[index].Unidades6MaisAtrasos)
        }
        this.tableData.push(row)
        row = []
        for (let index = this.parcelasAtrasoTableDataAtual.length - 1; index > -1; index--) {
            row.push(this.parcelasAtrasoTableDataAtual[index].Unidades3MaisAtrasos)
        }
        this.tableData.push(row)
        row = []
        for (let index = this.parcelasAtrasoTableDataAtual.length - 1; index > -1; index--) {
            row.push(this.parcelasAtrasoTableDataAtual[index].UnidadesAtivas)
        }
        this.tableData.push(row)
    }
}
