<div class="container-breakdown-notas">
    <div class="row filters">
        <!-- Filtro Mês -->
        <div class="col-lg-2">
            <mat-form-field appearance="standard">
                <mat-label>Mês Base</mat-label>
                <mat-select appearance="standard" [value]="mesBaseSelected" (selectionChange)="changeMesBase($event)">
                    <mat-option *ngFor="let mesBase of mesesBase" [value]="mesBase">
                        {{ mesBase }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-lg-3">

        </div>
        <div class="col-lg-2">
            <mat-form-field class="filtro" appearance="fill">
                <mat-label>Search</mat-label>
                <input matInput placeholder="" [value]="filterValue" (change)="filtrar($event)">
            </mat-form-field>
        </div>

        <!-- Filtro Carteira -->
        <div class="col-lg-1">
            <app-carteira-filter-resumo [carteiras]="carteiras" (onCarteiraChange)="filtrarCarteira($event)"></app-carteira-filter-resumo>
        </div>

        <!-- Filtro Servicer -->
        <div class="col-lg-1">
            <app-servicer-filter [servicers]="servicers" (onServicerChange)="filtrarServicer($event)"></app-servicer-filter>
        </div>

        <!-- Filtro Securitizadora -->
        <div class="col-lg-1">
            <app-securitizadora-filter [securitizadoras]="securitizadoras" (onSecuritizadoraChange)="filtrarSecuritizadora($event)">
            </app-securitizadora-filter>
        </div>

        <!-- Filtro Tipo -->
        <div class="col-lg-1">
            <app-tipo-filter [tipos]="tipos" (onTipoChange)="filtrarTipo($event)"> </app-tipo-filter>
        </div>

        <!-- Botão Baixar Excel -->
        <div class="col-lg-1">
            <button class="botao-baixar-excel" (click)="baixarExcel()" mat-raised-button color="primary">
                Baixar Excel <mat-icon> file_download </mat-icon>
            </button>
        </div>

    </div>
    <div class="row">
        <cdk-virtual-scroll-viewport [itemSize]="500">
            <table class="table table-bordered" matSort (matSortChange)="sortData($event)">
                <thead style="font-size:0.9rem;">
                    <tr>
                        <th class="fixed-column-1" mat-sort-header="Chave">CRI</th>
                        <th class="fixed-column-2" mat-sort-header="NotaObras">Nota Obras</th>
                        <th class="fixed-column-3" mat-sort-header="AvaliacaoCedente">Aval. Cedente</th>
                        <th class="fixed-column-4" mat-sort-header="Carencia">Carência</th>
                        <th class="fixed-column-5" mat-sort-header="RiscoSupl">Risco Supl.</th>
                        <th mat-sort-header="NotaObras2">Nota Obras</th>
                        <th class="thick-border-right" mat-sort-header="NotaObras3">Nota</th>
                        <th mat-sort-header="AvaliacaoCedente2">Aval. Cedente</th>
                        <th class="thick-border-right" mat-sort-header="AvaliacaoCedenteNota">Nota</th>
                        <th mat-sort-header="Carencia2">Carência / RiscoSupl.</th>
                        <th class="thick-border-right" mat-sort-header="NotaCarencia">Nota</th>
                        <th mat-sort-header="RFMAtual">RFM Atual</th>
                        <th mat-sort-header="RFMLimite">RFM Limite</th>
                        <th class="thick-border-right" mat-sort-header="RFMNota">Nota</th>
                        <th mat-sort-header="LTV">LTV</th>
                        <th class="thick-border-right" mat-sort-header="LTVNota">Nota</th>
                        <th mat-sort-header="StatusVendas">Status Vendas</th>
                        <th class="thick-border-right" mat-sort-header="NotaVendas">Nota</th>
                        <th mat-sort-header="Recebimentos">Razão Receb.</th>
                        <th class="thick-border-right" mat-sort-header="NotaRecebimentos">Nota</th>
                        <th mat-sort-header="Inadimplencia">Inad.</th>
                        <th class="thick-border-right" mat-sort-header="NotaInadimplencia">Nota</th>
                        <th class="fixed-nota-final" mat-sort-header="NotaFinal">Nota Final</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of filteredData">
                        <!-- Chave -->
                        <td class="fixed-column-1" style="font-size:0.9rem;">
                            <div *ngIf="row.Loading == false">
                                <button
                                    mat-icon-button
                                    [disableRipple]="true"
                                    onclick="this.blur()"
                                    class="refresh-button"
                                    (click)="refreshTableLine(row.ID, row.ProjetoCategoriaID, row.MesBase, row.Obras, row.NotaAvaliacaoCedente, row.Carencia, row.RiscoSuplementar, row.CarteiraID)"
                                >
                                    <mat-icon class="refresh-icon">refresh</mat-icon>
                                </button>
                                <span>&nbsp;&nbsp; |&nbsp;</span>
                                {{ row.Chave }}
                            </div>
    
                            <div *ngIf="row.Loading == true" class="spinner-wrapper black-mat-spinner">
                                <mat-progress-spinner [diameter]="19" mode="indeterminate"></mat-progress-spinner>
                                &nbsp;&nbsp; |&nbsp; {{ row.Chave }}
                            </div>
                        </td>
                        <!-- Input Obras -->
                        <td class="fixed-column-2">
                            <input
                                type="number"
                                matInput
                                [(ngModel)]="row.Obras"
                            />
                        </td>
                        <!-- Input Avaliacao -->
                        <td class="fixed-column-3">
                            <input
                                type="number"
                                matInput
                                [(ngModel)]="row.NotaAvaliacaoCedente"
                            />
                        </td>
                        <!-- Input Carencia -->
                        <td class="fixed-column-4">
                            <input
                                type="number"
                                matInput
                                [(ngModel)]="row.Carencia"
                            />
                        </td>
                        <!-- Input RiscoSuplementar -->
                        <td class="fixed-column-5">
                            <input
                                type="number"
                                matInput
                                [(ngModel)]="row.RiscoSuplementar"
                            />
                        </td>
                        <td>{{ row.Obras }}</td>
                        <td class="thick-border-right">{{ row.NotaObras }}</td>
                        <td>{{ row.NotaAvaliacaoCedente }}</td>
                        <td class="thick-border-right">{{ row.NotaAvaliacaoCedente }}</td>
                        <td>{{ row.Carencia }} / {{ row.RiscoSuplementar }}</td>
                        <td class="thick-border-right">{{ row.NotaCarenciaRiscoSup }}</td>
                        <td>{{ row.RFMAtual }}</td>
                        <td>{{ row.RFMLimite }}</td>
                        <td class="thick-border-right">{{ row.NotaRFM }}</td>
                        <td>{{ row.LTV }}</td>
                        <td class="thick-border-right">{{ row.NotaLTV }}</td>
                        <td>{{ row.StatusVendas }}</td>
                        <td class="thick-border-right">{{ row.NotaVendas }}</td>
                        <td>{{ row.RazaoRecebimento }}</td>
                        <td class="thick-border-right">{{ row.NotaRecebimentos }}</td>
                        <td>{{ row.Inadimplencia }}</td>
                        <td class="thick-border-right">{{ row.NotaInadimplencia }}</td>
                        <td class="fixed-nota-final">{{ row.NotaTotal.toFixed(2)}} | {{ row.Rating }}</td>
                        <!-- ({{ row.Rating }}) -->
                    </tr>
                </tbody>
            </table>
        </cdk-virtual-scroll-viewport>
    </div>
    <br><br>
    <div class="row salvar-modificacoes-container">
        <div class="col-2">
            <button (click)="recalculateAllNotas()" mat-raised-button color="primary">Recalcular Notas</button>
        </div>
    </div>
    <br><br>
    <mat-divider></mat-divider>
    <!-- Upload Inputs -->
    <div class="upload-container">
        <div>
            <div class="row">
                <div class="col-lg-12 titulo-upload">
                    <app-h2title [text]="'Upload Inputs'"></app-h2title>
                </div>
            </div>
        </div>
        <div style="margin-top: 20px">
            <div class="row">
                <div class="col-lg-12">
                    <p>Faça o upload do arquivo Excel contendo os valores dos inputs para calcular as notas. </p>
                </div>
            </div>
            <div class="col-lg-6">
                <div></div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <app-filepicker [extensions]="'.xlsx, .xls'" (onFilePick)="updateFile($event)"></app-filepicker>
                <button [disabled]="files.length == 0" style="margin-left: 20px" mat-raised-button color="primary" (click)="sendFiles()">
                    Enviar
                </button>
            </div>
        </div>
        <div *ngIf="files.length > 0" style="margin-top: 20px">
            <div class="row">
                <div class="col-lg-12">
                    <p>Arquivo selecionado:</p>
                    <ul *ngFor="let file of files">
                        <li>{{ file.name }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
