import { Component, OnInit, Input } from '@angular/core'

@Component({
    selector: 'app-value-chart',
    templateUrl: './value-chart.component.html',
    styleUrls: ['./value-chart.component.css'],
})
export class ValueChartComponent implements OnInit {
    @Input('valorParcelaAdimplente') valorParcelaAdimplente: number
    @Input('valorParcelaInadimplente') valorParcelaInadimplente: number
    @Input('valorEstoque') valorEstoque: number
    @Input('height') height: number = 200
    @Input('criterioHabitat') criterioHabitat: boolean
    public valueChartData: any[] = []

    public customColors = [
        { name: 'Adimplente', value: '#edb9b6' },
        { name: 'Inadimplente', value: '#c4170c' },
        { name: 'Estoque', value: '#999999' },
        { name: 'Inad * 60%', value: '#c4170c' },
        { name: 'Estoque * 60%', value: '#999999' },
    ]

    constructor() {
        this.valorParcelaAdimplente = this.valorParcelaInadimplente = this.valorEstoque = -1
        this.criterioHabitat = true
    }

    ngOnInit(): void {
        if (this.criterioHabitat == true) {
            this.valueChartData = [
                { name: 'Adimplente', value: this.valorParcelaAdimplente },
                { name: 'Inad * 60%', value: this.valorParcelaInadimplente },
                { name: 'Estoque * 60%', value: this.valorEstoque },
            ]
        } else {
            this.valueChartData = [
                { name: 'Adimplente', value: this.valorParcelaAdimplente },
                { name: 'Inadimplente', value: this.valorParcelaInadimplente },
                { name: 'Estoque', value: this.valorEstoque },
            ]
        }
    }
}
