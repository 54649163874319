import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
    selector: 'app-carteira-filter',
    templateUrl: './carteira-filter.component.html',
    styleUrls: ['./carteira-filter.component.css'],
})
export class CarteiraFilterComponent implements OnInit {
    @Input('carteiras') carteiras: string[] = []
    @Output() onCarteiraChange = new EventEmitter()

    emitCarteiras(event: any) {
        this.onCarteiraChange.emit(event.value)
    }

    ngOnInit(): void {}

    public formControlCarteira = new FormControl()
}
