import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Administrador } from 'src/app/interfaces/administrador';

@Component({
  selector: 'app-administrador-edit-dialog',
  templateUrl: './administrador-edit-dialog.component.html',
  styleUrls: ['./administrador-edit-dialog.component.css']
})
export class AdministradorEditDialogComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<AdministradorEditDialogComponent>) {}

    public administrador: Administrador = { ID: 0, Nome: '' }
    public create: boolean = false

    ngOnInit(): void {
      this.administrador = this.data.administrador
      this.create = this.data.create
    }

    save(): void {
        const params = {
            administrador: this.administrador,
            save: true,
        }
        this.dialogRef.close(params)
    }

    cancel(): void {
        const params = {
            save: false,
        }
        this.dialogRef.close(params)
    }

    isButtonDisabled(): boolean {
        return (this.administrador.Nome === '')
    }

}
