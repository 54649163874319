<div class="container" style="margin-bottom: 0px">
    <div class="row">
        <div class="col-12">
            <app-h2title [text]="'Fluxo Mensal'"></app-h2title>
        </div>
    </div>
</div>
<app-razao-fluxo-mensal 
    [recebido]="recebido" 
    [pagamento]="pagamento" 
    [razoes]="razoesFm" 
    [limiteRFM]="limiteRFM" 
    [recebimentosPrevistos]="recebimentosPrevistos">
</app-razao-fluxo-mensal>
<div class="container">
    <div class="row">
        <div class="col-12">
            <app-h2title [text]="'Saldo Devedor'"></app-h2title>
        </div>
    </div>
</div>
<app-razao-saldo-devedor 
    [razoes]="razoesSd" 
    [direitosCred]="direitosCred" 
    [saldoDevedor]="saldoDevedor"
    [fundosDeReservaManuais]="fundosDeReservaManuais"
    [limiteRSD]="limiteRSD">
</app-razao-saldo-devedor>
