<div style="padding: 10px 10px 10px 10px;">
    <h4>Editar Município</h4>

    <p>Selecione os campos a serem salvos</p>

    <div class="row" style="align-items: center">
        <div class="col-lg-3 col-md-12">
            <app-uf-auto-complete
                (onUFChange)="selectedUFID = $event"
                [unidadesFederativas]="unidadesFederativas"
                [ufId]="selectedUFID"
            >
            </app-uf-auto-complete>
        </div>
        <div class="col-lg-3 col-md-12">
            <app-municipio-auto-complete
                [municipios]="municipios"
                [municipioId]="cidadeRateio.CidadeID"
                [ufId]="selectedUFID"
                (onMunicipioChange)="cidadeRateio.CidadeID = $event"
            >
            </app-municipio-auto-complete>
        </div>
        <div class="col-lg-3 col-md-12">
            <mat-form-field appearance="standard" class="municipio-field">
                <mat-label> Município (%) </mat-label>
                <input type="number" matInput placeholder="% Município" [(ngModel)]="cidadeRateio.Rateio" />
            </mat-form-field>
        </div>
    </div>
    <div class="row" style="float: right">
        <div class="float-left">
            <button (click)="delete()" style="color: rgb(136, 2, 2); margin-right: 20px" mat-raised-button>Excluir</button>
        </div>
        <div style="margin-right: 20px">
            <button (click)="cancel()" mat-raised-button>Cancelar</button>
        </div>
        <div>
            <button (click)="save()" mat-raised-button color="primary" [disabled]="isButtonDisabled()">Salvar</button>
        </div>
    </div>
</div>

