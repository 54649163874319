import { Component, OnInit } from '@angular/core';
import { ProjetosService } from 'src/app/services/projetos.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DetailedNotaCri } from 'src/app/interfaces/detailed_nota_cri';
import { CriService } from 'src/app/services/cri.service';
import { TipoProjeto } from 'src/app/interfaces/tipo_projeto';
import { Emissor } from 'src/app/interfaces/emissor';
import { Servicer } from 'src/app/interfaces/servicer';
import { Sort } from '@angular/material/sort'
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSettings } from 'src/app/constants/AppSettings';
import { Carteira } from 'src/app/interfaces/carteira';

@Component({
    selector: 'app-breakdown-notas',
    templateUrl: './breakdown-notas.component.html',
    styleUrls: ['./breakdown-notas.component.css'],
})
export class BreakdownNotasComponent implements OnInit {
    private ngUnsubscribe = new Subject()
    userToken = localStorage.getItem('token')

    constructor(private projetoService: ProjetosService, private criService: CriService, private _snackBar: MatSnackBar) {}

    // Table Data
    public tableData: DetailedNotaCri[] = []
    public filteredData: DetailedNotaCri[] = []

    // loading
    public loading: boolean[] = []

    // Filtros
    public tipos: TipoProjeto[] = []
    public securitizadoras: Emissor[] = []
    public servicers: Servicer[] = []
    public carteiras: Carteira[] = []

    public filteredServicersIDs: number[] = []
    public filteredSecuritizadorasIDs: number[] = []
    public filteredTiposIDs: number[] = []
    public filteredCarteiras: number[] = []

    // Seleção do Mês Base
    public mesBaseSelected: string = ''
    public mesesBase: string[] = []

    // Upload File
    public selectedFile: any = null
    public files: File[] = []
    public loadingFile: boolean = false

    // Filtro
    public filterValue: string = ''

    ngOnInit(): void {
        for (let i = 0; i < 4; i++) {
            this.loading.push(true)
        }
        this.getMesesBase()
        this.getServicers()
        this.getSecuritizadora()
        this.getTipos()
        this.getCarteiras()
    }

    getNotasDetailedData(mesBase: string) {
        this.projetoService
            .getNotasDetailed(mesBase)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.tableData = [...returnData.notas_detailed]
                    this.filteredData = [...returnData.notas_detailed]
                    const aux_table = this.filteredData.map((obj) => {
                        return { ...obj, Loading: false }
                    })
                    this.filteredData = [...aux_table]
                    this.tableData = [...aux_table]
                    this.loading.pop()
                    this.filtrarCarteira([2])
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getMesesBase() {
        this.projetoService
            .getMesesBaseNotas()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.mesesBase = [...returnData.meses_base]
                    if (this.mesesBase.length == 0) {
                        this.loading.pop()
                        return
                    }
                    this.mesBaseSelected = this.mesesBase[0]
                    this.getNotasDetailedData(this.mesBaseSelected)
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getCarteiras(){
        this.projetoService
            .getCarteiras()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.carteiras = returnData.carteiras
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getServicers() {
        this.projetoService
            .getServicers()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.servicers = returnData.servicers
                    this.filteredServicersIDs = this.servicers.map((obj) => obj.id)
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getSecuritizadora(): void {
        this.criService
            .getEmissores()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.securitizadoras = returnData.emissores
                    this.filteredSecuritizadorasIDs = this.securitizadoras.map((obj) => obj.EmissorID)
                    this.loading.pop()
                },
                (errorData) => this.loading.pop()
            )
    }

    getTipos() {
        this.projetoService
            .getTiposProjeto()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.tipos = returnData.tipos
                    this.filteredTiposIDs = this.tipos.map((obj) => obj.TipoID)
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    filtrarTipo(tipoIDs: any) {
        if (tipoIDs.length == 0) {
            this.filteredTiposIDs = this.tipos.map((obj) => obj.TipoID)
        } else {
            this.filteredTiposIDs = tipoIDs
        }
        this.filtrarTabela()
    }

    filtrarSecuritizadora(securitizadoraIDs: any) {
        if (securitizadoraIDs.length == 0) {
            this.filteredSecuritizadorasIDs = this.securitizadoras.map((obj) => obj.EmissorID)
        } else {
            this.filteredSecuritizadorasIDs = securitizadoraIDs
        }
        this.filtrarTabela()
    }

    filtrarCarteira(carteiras: any) {
        if (carteiras.length == 0) {
            this.filteredCarteiras = []
        } else {
            this.filteredCarteiras = carteiras
        }
        this.filtrarTabela()
    }

    filtrarServicer(servicerIDs: any) {
        if (servicerIDs.length == 0) {
            this.filteredServicersIDs = this.servicers.map((obj) => obj.id)
        } else {
            this.filteredServicersIDs = servicerIDs
        }
        this.filtrarTabela()
    }

    filtrarTabela() {
        console.log(this.filteredData)
        this.filteredData = this.tableData.filter((linha_resumo) => {
            const securitizadoraID = linha_resumo.SecuritizadoraID
            const servicerID = linha_resumo.ServicerID
            const tipoID = linha_resumo.TipoProjetoID
            const carteiraID = linha_resumo.CarteiraID
            return (
                this.filteredServicersIDs.includes(servicerID) &&
                this.filteredSecuritizadorasIDs.includes(securitizadoraID) &&
                this.filteredTiposIDs.includes(tipoID) &&
                this.filteredCarteiras.includes(carteiraID)
            )
        })
    }

    changeMesBase(event: any) {
        this.mesBaseSelected = event.value
        this.loading.push(true)
        this.getNotasDetailedData(this.mesBaseSelected)
    }

    recalculateAllNotas(){
        let formData = new FormData()
        formData.append('notas', JSON.stringify(this.filteredData))
        this.projetoService
            .recalculateAllNotas(formData)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {},
                (errorData: any) => {})

        this.openSnackbar("As notas estão sendo recalculadas. Os resultados aparecerão no Slack. ", "sucess")
    }

    refreshTableLine(
        id: number,
        projetoCategoriaID: number,
        mesBase: number,
        notaObras: number,
        notaAvaliacaoCedente: number,
        carencia: number,
        riscoSuplementar: number,
        carteiraID: number
    ) {
        if (notaObras < 0 || notaObras > 10) {
            this.openSnackbar('Os valores de Nota Obras deve estar entre 0 e 10', 'error-snackbar')
            return
        } else if (notaAvaliacaoCedente < 0 || notaAvaliacaoCedente > 4) {
            this.openSnackbar('Os valores da Avaliação Cedente deve ser entre 0 e 4', 'error-snackbar')
            return
        } else if (carencia != 0 && carencia != 1) {
            this.openSnackbar('O valor da nota Carência deve ser 1 ou 0', 'error-snackbar')
            return
        } else if (riscoSuplementar != 0 && riscoSuplementar != 1) {
            this.openSnackbar('O valor da nota Risco Suplementar deve ser 1 ou 0', 'error-snackbar')
            return
        }

        const aux_table = this.filteredData.map((obj) => {
            if (obj.ID === id) {
                return { ...obj, Loading: true }
            }
            return obj
        })
        this.filteredData = [...aux_table]

        let formData = new FormData()
        formData.append('projeto_categoria_id', JSON.stringify(projetoCategoriaID))
        formData.append('mes_base', JSON.stringify(mesBase))
        formData.append('nota_obras', JSON.stringify(notaObras))
        formData.append('avaliacao_cedente', JSON.stringify(notaAvaliacaoCedente))
        formData.append('carencia', JSON.stringify(carencia))
        formData.append('risco_suplementar', JSON.stringify(riscoSuplementar))
        formData.append('carteira_id', JSON.stringify(carteiraID))

        this.projetoService
            .calculateNota(formData)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    const nota_cri: DetailedNotaCri = returnData.notas_infos
                    nota_cri.Loading = false

                    //atualizando a tabela filtrada
                    const elementIndex = this.filteredData.findIndex((element) => element.ID == nota_cri.ID)
                    let auxFilteredData = [...this.filteredData]
                    auxFilteredData[elementIndex] = nota_cri
                    this.filteredData = [...auxFilteredData]

                    //atualizando a tabela completa
                    const elementIndex2 = this.tableData.findIndex((element) => element.ID == nota_cri.ID)
                    let auxData = [...this.tableData]
                    auxData[elementIndex2] = nota_cri
                    this.tableData = [...auxData]
                },
                (errorData: any) => {
                    const aux_table = this.filteredData.map((obj) => {
                        if (obj.ID === id) {
                            return { ...obj, Loading: false }
                        }
                        return obj
                    })
                    this.filteredData = [...aux_table]

                    this.openSnackbar('Erro ao calcular nota para o cri', 'error-snackbar')
                }
            )
    }

    openSnackbar(message: string, messageType: string): void {
        this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
    }

    sortData(sort: Sort) {
        console.log(sort)

        const data = this.filteredData.slice()
        if (!sort.active || sort.direction === '') {
            this.filteredData = data
            return
        }

        this.filteredData = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc'
            switch (sort.active) {
                case 'Chave':
                    return compare(a.Chave, b.Chave, isAsc)
                case 'NotaObras':
                    return compare(a.Obras, b.Obras, isAsc)
                case 'AvaliacaoCedente':
                    return compare(a.NotaAvaliacaoCedente, b.NotaAvaliacaoCedente, isAsc)
                case 'Carencia':
                    return compare(a.Carencia, b.Carencia, isAsc)
                case 'RiscoSupl':
                    return compare(a.RiscoSuplementar, b.RiscoSuplementar, isAsc)
                case 'NotaObras2':
                    return compare(a.Obras, b.Obras, isAsc)
                case 'NotaObras3':
                    return compare(a.NotaObras, b.NotaObras, isAsc)
                case 'AvaliacaoCedente2':
                    return compare(a.NotaAvaliacaoCedente, b.NotaAvaliacaoCedente, isAsc)
                case 'AvaliacaoCedenteNota':
                    return compare(a.NotaAvaliacaoCedente, b.NotaAvaliacaoCedente, isAsc)
                case 'NotaCarencia':
                    return compare(a.NotaCarenciaRiscoSup, b.NotaCarenciaRiscoSup, isAsc)
                case 'RFMAtual':
                    return compare(a.RFMAtual, b.RFMAtual, isAsc)
                case 'RFMLimite':
                    return compare(a.RFMLimite, b.RFMLimite, isAsc)
                case 'RFMNota':
                    return compare(a.NotaRFM, b.NotaRFM, isAsc)
                case 'LTV':
                    return compare(a.LTV, b.LTV, isAsc)
                case 'LTVNota':
                    return compare(a.NotaLTV, b.NotaLTV, isAsc)
                case 'StatusVendas':
                    return compare(a.StatusVendas, b.StatusVendas, isAsc)
                case 'NotaVendas':
                    return compare(a.NotaVendas, b.NotaVendas, isAsc)
                case 'Recebimentos':
                    return compare(a.RazaoRecebimento, b.RazaoRecebimento, isAsc)
                case 'NotaRecebimentos':
                    return compare(a.NotaRecebimentos, b.NotaRecebimentos, isAsc)
                case 'Inadimplencia':
                    return compare(a.Inadimplencia, b.Inadimplencia, isAsc)
                case 'NotaInadimplencia':
                    return compare(a.NotaInadimplencia, b.NotaInadimplencia, isAsc)
                case 'Rating':
                    return compare(a.Rating, b.Rating, isAsc)
                case 'NotaFinal':
                    return compare(a.NotaTotal, b.NotaTotal, isAsc)
                default:
                    return 0
            }
        })
    }

    updateFile(event: any): void {
        this.files = []
        this.files.push(event[0])
    }

    sendFiles(): void {
        let formData = new FormData()

        formData.append('file', this.files[0])

        this.projetoService
            .uploadNotasInputs(formData)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {},
                (errorData: any) => {}
            )
        this.openSnackbar('Upload Feito. O resultado aparecerá no Slack quando concluído', 'success')
    }

    filtrar(event: any) {
        const filterString = this.normalizarString(event.target.value)
        console.log(filterString)
        this.filteredData = this.tableData.filter((obj) => {
            return this.normalizarString(obj.Chave).includes(filterString)
        })
    }

    normalizarString(str: string) {
        return str
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
    }

    baixarExcel() {
        const endpoint = AppSettings.API_ENDPOINT + 'gestao/notas/excel?mes_base=' + this.mesBaseSelected + '&user_token=' + this.userToken
        window.location.href = endpoint
    }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1)
}
