import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Breadcrumb } from 'src/app/interfaces/breadcrumb';
import { Setor } from 'src/app/interfaces/setor';
import { ProjetosService } from 'src/app/services/projetos.service'
import { SetorEditDialogComponent } from './setor-edit-dialog/setor-edit-dialog.component';
@Component({
  selector: 'app-setor-list',
  templateUrl: './setor-list.component.html',
  styleUrls: ['./setor-list.component.css']
})
export class SetorListComponent implements OnInit {

    constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public projetoService: ProjetosService) {
  }
  private ngUnsubscriber = new Subject()
  
  public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Cadastros', href: 'gestao/cadastros' },
        { text: 'Setores', href: 'gestao/list/setores' },
  ]

  public loading: boolean = false
  public setores: Setor[] = []

  createSetorDialog(): void {
    const setorDialog = this.dialog.open(SetorEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            setor: {ID:-1, Nome:""},
        },
    })

    setorDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading = true
          this.createSetor(params.setor)
        }
        return
    })
  }

  createSetor(setor:Setor):void{
    let { ID, ...setorWithoutID} = setor
    this.projetoService
        .createSetor(setorWithoutID)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'Setor criado com sucesso') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar setor', 'error-snackbar')
                }
                this.getSetores()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar setor' + errorData.error.message, 'error-snackbar')
              this.getSetores()
            }
        )
  }

  updateSetorDialog(setor: any): void {
    const setorDialog = this.dialog.open(SetorEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            setor: setor,
            create: false,
        },
    })

    setorDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading = true
          this.updateSetor(params.setor)
        }
        return
    })
  }

  updateSetor(setor:Setor):void{
    this.projetoService
        .updateSetor(setor)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'Setor atualizado com sucesso') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar setor', 'error-snackbar')
                }
                this.getSetores()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar setor' + errorData.error.message, 'error-snackbar')
              this.getSetores()
            }
        )
  }

  ngOnInit(): void {
    this.loading = true
    this.getSetores()
  }

  getSetores():void{
    this.projetoService
        .getSetores()
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe((returnData: any) => {
            console.log(returnData)
            this.setores = returnData.setores
            this.loading = false
        })
  }

  openSnackbar(message: string, messageType: string): void {
    this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
  }

}
