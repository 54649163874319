import { Component, OnInit } from '@angular/core'
import { AppSettings } from 'src/app/constants/AppSettings'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ControladoriaService } from 'src/app/services/controladoria-service.service'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { CriService } from 'src/app/services/cri.service'

interface Table {
    id: number
    name: string
    schema: string
    columns: String[]
    category: string
}

@Component({
    selector: 'app-controladoria',
    templateUrl: './controladoria.component.html',
    styleUrls: ['./controladoria.component.css'],
})
export class ControladoriaComponent implements OnInit {
    private ngUnsubscriber = new Subject()

    // Dynamic
    public selectedCategory: string = ''
    public loading: boolean = false
    public tables: Table[] = []
    public displayedTables: Table[] = []
    public selectedFile: any = null
    public selectedTable: Table | null = null

    // Consts
    public categories: string[] = []

    constructor(private controladoriaService: ControladoriaService, private snackBar: MatSnackBar, private criService: CriService) {}

    changeCategory(value: any) {
        this.selectedCategory = value
        this.displayedTables = this.tables.filter(function (el) {
            return el.category == value
        })
        this.selectedTable = null
    }

    changeTable(value: any) {
        this.selectedTable = this.tables.filter(function (el) {
            return el.id == value
        })[0]
    }

    updateFile(event: any): void {
        this.selectedFile = event.target.files[0]
    }

    formatTableName(table_name: string): string {
        var temp = table_name.replace('_', ' ')
        return temp[0].toUpperCase() + temp.slice(1)
    }

    canSubmit() {
        return this.selectedTable != null && this.selectedFile != null
    }

    uploadFile(): void {
        if (this.selectedTable == null) return

        let formData = new FormData()
        formData.append('file', this.selectedFile)
        formData.append('destination_table', this.selectedTable.name)
        formData.append('columns', JSON.stringify(this.selectedTable.columns))
        formData.append('schema', this.selectedTable.schema)

        this.loading = true
        this.controladoriaService
            .uploadFile(formData)
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData) => {
                    var data = JSON.parse(JSON.stringify(returnData))
                    this.loading = false
                    this.snackBar.open(data['message'], 'Fechar', {
                        duration: AppSettings.SNACKBAR_DURATION,
                        panelClass: ['success-snackbar'],
                    })
                    if (data["cri_ids"]){
                        var formData = new FormData()
                        formData.append('cri_ids', data["cri_ids"])
                        this.criService.calculatePuCurva(formData).pipe(takeUntil(this.ngUnsubscriber)).subscribe()
                    }
                },
                (errorData) => {
                    this.loading = false
                    var data = JSON.parse(JSON.stringify(errorData))
                    this.snackBar.open(data['error']['message'], 'Fechar', {
                        duration: AppSettings.SNACKBAR_DURATION,
                        panelClass: ['error-snackbar'],
                    })
                }
            )
    }

    ngOnInit(): void {
        this.loading = true
        this.controladoriaService
            .getTables()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    let data = JSON.parse(JSON.stringify(returnData))
                    for (var i = 0; i < data['tables'].length; i++) {
                        var element = data['tables'][i]
                        this.tables.push({
                            id: i,
                            columns: element['COLUMN_NAME'],
                            name: element['TABLE_NAME'],
                            schema: element['TABLE_SCHEMA'],
                            category: element['CATEGORY'],
                        })
                        if (!this.categories.includes(element['CATEGORY'])) this.categories.push(element['CATEGORY'])
                    }

                    this.loading = false
                },
                (errorData: any) => {
                    this.loading = false
                }
            )
    }
}
