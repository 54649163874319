import { Component, Input, OnInit } from '@angular/core'
import { SubmoduleCard } from 'src/app/interfaces/submodule_card'
@Component({
    selector: 'app-submodulecard',
    templateUrl: './submodulecard.component.html',
    styleUrls: ['./submodulecard.component.css'],
})
export class SubmodulecardComponent implements OnInit {
    @Input() declare cardInfo: SubmoduleCard
    constructor() {}

    ngOnInit(): void {}
}
