import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
    selector: 'app-exportexcelbutton',
    templateUrl: './exportexcelbutton.component.html',
    styleUrls: ['./exportexcelbutton.component.css'],
})
export class ExportexcelbuttonComponent implements OnInit {
    @Output() onClick = new EventEmitter()
    constructor() {}

    trigger(): void {
        this.onClick.emit()
    }

    ngOnInit(): void {}
}
