import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'app-feedback-popup',
    templateUrl: './feedback-popup.component.html',
    styleUrls: ['./feedback-popup.component.css'],
})
export class FeedbackPopupComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<FeedbackPopupComponent>) {}

    public message: string = this.data.message
    public messageType: string = this.data.messageType

    ngOnInit(): void {}

    close(): void {
        this.dialogRef.close()
    }
}
