<mat-form-field appearance="standard">
    <mat-select (selectionChange)="emitCarteiras($event)" placeholder="Filtrar por Carteira" [formControl]="formControlCarteira" multiple>
        <mat-select-trigger>
            {{ formControlCarteira.value ? formControlCarteira.value[0] : '' }}
            <span *ngIf="formControlCarteira.value?.length > 1" class="example-additional-selection">
                (+{{ formControlCarteira.value.length - 1 }} {{ formControlCarteira.value?.length === 2 ? 'other' : 'others' }})
            </span>
        </mat-select-trigger>
        <mat-option *ngFor="let carteira of carteiras" [value]="carteira">{{ carteira }}</mat-option>
    </mat-select>
</mat-form-field>
