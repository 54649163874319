<app-navbar></app-navbar>

<div class="bottom-overlay">
    <div [ngClass]="loading ? 'disabled-div' : ''">
        <!-- Titulo da pag -->
        <div class="container" style="margin-top: 25px">
            <div class="row">
                <div class="col-lg-12">
                    <app-h2title [text]="'Disparo de Cálculos'"></app-h2title>
                </div>
            </div>
        </div>
        <!-- Descricao -->
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <p>
                        Clique nos botões para disparar a rotina de cálculo. Uma mensagem será enviada no respectivo canal do Slack com o resultado da
                        operação.
                    </p>
                </div>
            </div>
        </div>
        <!-- Cards -->
        <div class="container" style="margin-bottom: 30px">
            <div *ngFor="let card of triggerCards">
                <app-triggercard [cris]="cris" [triggerCard]="card" (onTriggered)="triggerCalculation($event)"> </app-triggercard>
            </div>
        </div>
    </div>
</div>
