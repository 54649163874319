import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Projeto } from 'src/app/interfaces/projeto'
import { Empreendimento } from 'src/app/interfaces/empreendimento'
import { Municipio } from 'src/app/interfaces/municipio'
import { UnidadeFederativa } from 'src/app/interfaces/unidade_federativa'
import { MatSnackBar } from '@angular/material/snack-bar'
@Component({
    selector: 'app-empreendimento-form',
    templateUrl: './empreendimento-form.component.html',
    styleUrls: ['./empreendimento-form.component.css'],
})
export class EmpreendimentoFormComponent implements OnInit {
    constructor(private snackBar: MatSnackBar) {}
    @Input('projetos') projetos: Projeto[] = []
    @Input('municipios') municipios: Municipio[] = []
    @Input('unidadesFederativas') unidadesFederativas: UnidadeFederativa[] = []
    @Output() onSubmit = new EventEmitter()
    @Input('update') update: boolean = false
    @Input('empreendimento') empreendimento: Empreendimento = {
        ProjetoID: 0,
        FatorAjParcelaCorrigida: 0,
        UFID: -1,
        MunicipioID: -1,
        ConsiderarParcelaBalao: 0,
        Sigla: '',
        EstoquePrecoM2: 0,
        EstoquePercDesconto: 0,
        PadraoDeConstrucao: '',
        Endereco: '',
        LinkGoogleMaps: '',
        VGV: 0, 
    }

    public buttonText: string = ''
    public clearMunicipioForm: boolean = false

    openSnackbar() {
        this.snackBar.open('Preencha todos os campos', 'Fechar', { duration: 4000 })
    }

    submit() {
        if (this.empreendimento.ProjetoID < 1 || this.empreendimento.MunicipioID < 1 || this.empreendimento.UFID < 1) {
            this.openSnackbar()
            return
        }

        if (this.empreendimento.Sigla == '') {
            this.openSnackbar()
            return
        }

        if (this.empreendimento.FatorAjParcelaCorrigida == 0) {
            this.openSnackbar()
            return
        }

        const empreendimento_copy = JSON.parse(JSON.stringify(this.empreendimento))
        empreendimento_copy.EstoquePercDesconto = empreendimento_copy.EstoquePercDesconto / 100

        this.onSubmit.emit(empreendimento_copy)
    }

    ngOnInit(): void {
        this.buttonText = this.update ? 'Salvar alterações' : 'Cadastrar'
        this.empreendimento.EstoquePercDesconto = this.empreendimento.EstoquePercDesconto*100
    }
}
