import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Servicer } from 'src/app/interfaces/servicer'

@Component({
    selector: 'app-servicer-filter',
    templateUrl: './servicer-filter.component.html',
    styleUrls: ['./servicer-filter.component.css'],
})
export class ServicerFilterComponent implements OnInit {
    @Input('servicers') servicers: Servicer[] = []
    @Output() onServicerChange = new EventEmitter()

    emitTipo(event: any) {
        this.onServicerChange.emit(event.value)
    }

    ngOnInit(): void {}

    public formControl = new FormControl()
}
