import { Injectable } from '@angular/core'
import { Router, CanActivate } from '@angular/router'
import { AuthService } from './auth.service'

@Injectable({
    providedIn: 'root',
})
export class AuthRedirectService implements CanActivate {
    constructor(public auth: AuthService, public router: Router) {}
    async canActivate(): Promise<boolean> {
        var isAuthenticated = await this.auth.isAuthenticated()
        if (isAuthenticated) {
            this.router.navigate([''])
            return false
        }
        return true
    }
}
