<div class="outter-container">
    <div class="container">
        <div class="jumbotron" [ngClass]="loading ? 'disabled-div' : ''">
            <div class="back-button-div">
                <button (click)="goBack()" mat-icon-button>
                    <mat-icon>arrow_back</mat-icon>
                </button>
            </div>
            <h1>Cadastro</h1>
            <form>
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Email</label>
                    <input type="email" class="form-control" id="{{ emailField }}" />
                </div>
                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Senha</label>
                    <input type="password" class="form-control" id="{{ passwordField }}" />
                </div>
                <div class="col" id="formCol">
                    <div>
                        <button (click)="signUp($event)" type="submit" class="btn btn-primary">Cadastrar</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="hasError">
            <strong>{{ error }}</strong>
        </div>
    </div>
</div>
