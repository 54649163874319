<div *ngIf="title && description">
    <mat-accordion multi>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ title }}
                </mat-panel-title>
                <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>

            <div style="margin-left: 32px">
                <p>
                    {{ description }}
                </p>
            </div>

            <div *ngIf="submoduleCards.length">
                <div *ngFor="let submodule of submoduleCards">
                    <app-nestedsubmodulecard [cardInfo]="submodule"> </app-nestedsubmodulecard>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
