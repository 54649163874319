import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from 'src/app/interfaces/breadcrumb';
import { SubmoduleCard } from 'src/app/interfaces/submodule_card';
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'app-cadastros-card',
  templateUrl: './cadastros-card.component.html',
  styleUrls: ['./cadastros-card.component.css']
})

export class CadastrosCardComponent implements OnInit {
    constructor(private titleService: Title) {
        this.titleService.setTitle('Gestão | Habitat App')
    }

    public submodules: SubmoduleCard[] = [
        {
            title: 'Carteiras',
            description: 'Consultar e cadastrar carteiras.',
            href: 'gestao/list/carteiras',
        },
        {
            title: 'Administradores',
            description: 'Consultar e cadastrar administradores.',
            href: 'gestao/list/administradores',
        },
        {
            title: 'Agentes Fiduciários',
            description: 'Consultar e cadastrar agentes fiduciários.',
            href: 'gestao/list/agentes_fiduciarios',
        },
        {
            title: 'Tipos Projetos',
            description: 'Consultar e cadastrar tipos projetos.',
            href: 'gestao/list/tipos_projetos',
        },
        {
            title: 'Ativos',
            description: 'Consultar e cadastrar ativos.',
            href: 'gestao/list/ativos',
        },
        {
            title: 'Setores',
            description: 'Consultar e cadastrar setores.',
            href: 'gestao/list/setores',
        },
        {
            title: 'Emissores',
            description: 'Consultar e cadastrar emissores.',
            href: 'gestao/list/emissores',
        },
        {
            title: 'Servicers',
            description: 'Consultar e cadastrar servicers.',
            href: 'gestao/list/servicers',
        },
        {
            title: 'Grupos Econômicos',
            description: 'Consultar e cadastrar grupos econômicos.',
            href: 'gestao/list/grupos_economicos',
        },
    ]

    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Cadastros', href: 'cadastros' },
    ]

    ngOnInit(): void {}
}
