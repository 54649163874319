import { Component, OnInit, Input } from '@angular/core'

@Component({
    selector: 'app-tab-razoes',
    templateUrl: './tab-razoes.component.html',
    styleUrls: ['./tab-razoes.component.css'],
})
export class TabRazoesComponent implements OnInit {
    @Input('recebido') recebido: any[]
    @Input('pagamento') pagamento: any[]
    @Input('razoesFm') razoesFm: any[]

    @Input('razoesSd') razoesSd: any[]
    @Input('direitosCred') direitosCred: any[]
    @Input('saldoDevedor') saldoDevedor: any[]
    @Input('fundosDeReservaManuais') fundosDeReservaManuais: any[]
    @Input('limiteRFM') limiteRFM: any
    @Input('limiteRSD') limiteRSD: any
    @Input('recebimentosPrevistos') recebimentosPrevistos: any[]

    constructor() {
        this.recebido =
            this.pagamento =
            this.razoesFm =
            this.razoesSd =
            this.direitosCred =
            this.saldoDevedor =
            this.limiteRFM =
            this.limiteRSD =
            this.fundosDeReservaManuais =
            this.recebimentosPrevistos = []
    }

    ngOnInit(): void {}
}
