
<!-- Dados do Estoque -->
<div *ngIf="noData==false">
    <div class="container estoque-tab-container">
        
        <div class="row estoque-header">
            <div class="col-4">
                <p class="unidades-disponiveis">
                    Unidades Disponíveis: {{ unidadesDisponiveis }}
                </p>
                <p class="area-total-disponivel">
                    Área Total Disponível: {{ areaTotalDisponivel.toLocaleString() }} m²
                </p>
                <p class="preco-medio-unidade">
                    Preço Unid.: R$ {{ precoMedioUnidade.toLocaleString() }}
                </p>
            </div>

            <div class="col-8">
                <div class="table-wrapper">
                    <table id="estoque-table">
                        <tr>
                            <th><b>Preço/m² (R$)</b></th>
                            <th>Inicio Op.</th>
                            <th>12 Meses</th>
                            <th>6 Meses</th>
                        </tr>
                        <tr>
                            <td><b>Média</b></td>
                            <td>{{ precoM2MedioInicioOperacao.toLocaleString() }}</td>
                            <td>{{ precoM2Medio12Meses.toLocaleString() }}</td>
                            <td>{{ precoM2Medio6Meses.toLocaleString() }}</td>
                        </tr>
                        <tr>
                            <td><b>Mín.</b></td>
                            <td>{{ precoM2MinInicioOperacao.toLocaleString() }}</td>
                            <td>{{ precoM2Min12Meses.toLocaleString() }}</td>
                            <td>{{ precoM2Min6Meses.toLocaleString() }}</td>
                        </tr>
                        <tr>
                            <td><b>Máx.</b></td>
                            <td>{{ precoM2MaxInicioOperacao.toLocaleString() }}</td>
                            <td>{{ precoM2Max12Meses.toLocaleString() }}</td>
                            <td>{{ precoM2Max6Meses.toLocaleString() }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="estoque-chart">
                    <apx-chart
                        [series]="chartPrecoM2Options.series"
                        [chart]="chartPrecoM2Options.chart"
                        [yaxis]="chartPrecoM2Options.yaxis"
                        [xaxis]="chartPrecoM2Options.xaxis"
                        [labels]="chartPrecoM2Options.labels"
                        [stroke]="chartPrecoM2Options.stroke"
                        [title]="chartPrecoM2Options.title"
                        [colors]="chartPrecoM2Options.colors"
                        ></apx-chart>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="estoque-chart">
                    <apx-chart
                        [series]="chartParcelasOptions.series"
                        [chart]="chartParcelasOptions.chart"
                        [yaxis]="chartParcelasOptions.yaxis"
                        [xaxis]="chartParcelasOptions.xaxis"
                        [labels]="chartParcelasOptions.labels"
                        [stroke]="chartParcelasOptions.stroke"
                        [title]="chartParcelasOptions.title"
                        [colors]="chartParcelasOptions.colors"
                        ></apx-chart>
                </div>
            </div>
        </div>

        <div class="row reupload-button" *ngIf="!uploadFilesActive">
            <div class="col-lg-12">
                <button mat-raised-button (click)="uploadFilesActive = true">
                    Refazer Upload do Mapa de Unidades
                </button>
            </div>
        </div>
    </div>
</div>

<div class="container error-no-vendas" *ngIf="this.noVendasData">
    <p> O CRI não contém as informações de vendas do mês base. A base subiu corretamente? </p>
    <mat-divider></mat-divider>
</div>

<!-- Upload Unidades -->
<div class="container upload-unidades-container" *ngIf="uploadFilesActive">
    <div [ngClass]="loadingFileUpload ? 'disabled-div' : ''">
        <div>
            <div class="row">
                <div class="col-lg-12 titulo-upload-unidades">
                    <app-h2title [text]="'Upload do Mapa de Unidades'"></app-h2title>
                </div>
            </div>
        </div>
        <div style="margin-top: 20px">
            <div class="row">
                <div class="col-lg-12">
                    <p>Faça o upload do arquivo Excel contendo informações das unidades do projeto no mês base</p>
                </div>
            </div>
            <div class="col-lg-6">
                <div></div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <app-filepicker [extensions]="'.xlsx, .xls'" (onFilePick)="updateFiles($event)"></app-filepicker>
                <button [disabled]="files.length == 0" style="margin-left: 20px" mat-raised-button color="primary" (click)="sendFiles()">
                    Enviar
                </button>
            </div>
        </div>
        <div *ngIf="files.length > 0" style="margin-top: 20px">
            <div class="row">
                <div class="col-lg-12">
                    <p>Arquivo selecionado:</p>
                    <ul *ngFor="let file of files">
                        <li>{{ file.name }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="top-overlay" *ngIf="loadingFileUpload">
        <mat-spinner></mat-spinner>
    </div>
</div>