import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core'
import { FormControl } from '@angular/forms'
import { map, startWith } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { Municipio } from 'src/app/interfaces/municipio'

@Component({
    selector: 'app-municipio-auto-complete',
    templateUrl: './municipio-auto-complete.component.html',
    styleUrls: ['./municipio-auto-complete.component.css'],
})
export class MunicipioAutoCompleteComponent implements OnInit, OnChanges {
    @Input('municipios') municipios: Municipio[] = []
    @Input('ufId') ufId: number = -1
    @Input('municipioId') municipioId: number = -1
    @Output() onMunicipioChange = new EventEmitter()

    declare filteredMunicipios: Observable<string[]>

    public municipiosInUf: Municipio[] = []
    public municipioFormControl = new FormControl()
    public nomeMunicipio: string = ''
    constructor() {}

    private _filterMunicipios(value: string): string[] {
        if (value) {
            const filterValue = value.toLowerCase()
            return this.municipiosInUf.map((municipio) => municipio.Nome).filter((nome) => nome.toLowerCase().includes(filterValue))
        } else {
            return this.municipiosInUf.map((municipio) => municipio.Nome)
        }
    }

    clearMunicipio(): void {
        this.municipioFormControl.reset()
        this.onMunicipioChange.emit(-1)
    }

    changeMunicipio(event: string): void {
        const municipioId = this.municipiosInUf.filter((municipio) => municipio.Nome == event)[0].ID
        this.onMunicipioChange.emit(municipioId)
    }

    changeInputStatus(enable: boolean) {
        if (enable) this.municipioFormControl.enable()
        else this.municipioFormControl.disable()
    }

    init() {
        if (this.ufId < 1) {
            this.changeInputStatus(false)
            this.municipioFormControl.reset()
            this.nomeMunicipio = ''
            this.onMunicipioChange.emit(-1)
            return
        } else this.changeInputStatus(true)

        this.municipiosInUf = this.municipios.filter((m) => m.UFID == this.ufId)

        if (this.municipioId > 0) {
            const index = this.municipiosInUf.map((p) => p.ID).indexOf(this.municipioId)
            this.nomeMunicipio = this.municipiosInUf[index].Nome
        }

        this.filteredMunicipios = this.municipioFormControl.valueChanges.pipe(
            startWith(this.nomeMunicipio),
            map((value) => this._filterMunicipios(value))
        )

        if (this.nomeMunicipio) this.municipioFormControl.setValue(this.nomeMunicipio)
    }

    ngOnInit(): void {
        this.init()
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.init()
    }
}
