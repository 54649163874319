<app-navbar></app-navbar>

<!-- Feedback -->
<div class="alert alert-success" role="alert" *ngIf="success">
    <div class="container">
        <strong> Carteira enviada com sucesso </strong>
    </div>
</div>
<div class="alert alert-warning" role="alert" *ngIf="lancamentos_sem_correspondencia.length > 0">
    <div class="container">
        <h6>Lancamentos sem correspondência na tabela carteira.siglas</h6>
        <ul>
            <li *ngFor="let sigla of lancamentos_sem_correspondencia">{{ sigla }}</li>
        </ul>
    </div>
</div>
<div class="alert alert-danger" role="alert" *ngIf="error">
    <div class="container">
        <strong> {{ errorMessage }} </strong>
    </div>
</div>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="bottom-overlay">
    <div [ngClass]="loading ? 'disabled-div' : ''">
        <div class="container" style="margin-top: 30px">
            <div class="row">
                <div class="col-lg-12">
                    <app-h2title [text]="'Upload da Carteira Vortx'"></app-h2title>
                </div>
            </div>
        </div>
        <div class="container" style="margin-top: 20px">
            <div class="row">
                <div class="col-lg-12">
                    <p>Faça o upload do arquivo Excel enviado diariamente pela Vortx com a posição da carteira.</p>
                </div>
            </div>
            <div class="col-lg-6">
                <div></div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <app-filepicker [extensions]="'.xlsx, .xls'" (onFilePick)="updateFiles($event)"></app-filepicker>
                    <button [disabled]="files.length == 0" style="margin-left: 20px" mat-raised-button color="primary" (click)="sendFiles()">
                        Enviar
                    </button>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="files.length > 0" style="margin-top: 20px">
            <div class="row">
                <div class="col-lg-12">
                    <p>Arquivos selecionados:</p>
                    <ul *ngFor="let file of files">
                        <li>{{ file.name }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <hr />
    </div>
    <div class="top-overlay" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>

<div class="container" style="margin-bottom: 20px">
    <div class="row">
        <div class="col-lg-12" style="margin: 10px 0px 15px 0px">
            <app-h2title [text]="'De-Para Siglas'"></app-h2title>
        </div>
        <div class="row">
            <div class="col">
                <table id="sigla-table">
                    <tr>
                        <th>Sigla Vortx</th>
                        <th>Sigla Habitat</th>
                    </tr>
                    <tr *ngFor="let sigla of siglasVortx">
                        <td>
                            <input type="string" matInput placeholder="" [(ngModel)]="sigla.SiglaVortx" />
                        </td>
                        <td>
                            <input type="string" matInput placeholder="" [(ngModel)]="sigla.SiglaHabitat" />
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 20px">
        <button (click)="createSigla()" mat-raised-button class="create-button">+</button>
        <button (click)="submit()" mat-raised-button class="submit-button">Salvar</button>
    </div>
</div>
