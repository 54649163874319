<app-navbar style="position: fixed; top: 0; z-index: 1030; width: 100%"></app-navbar>

<div [ngStyle]="{ height: screenHeight - 70 }">
    <mat-drawer-container fixedInViewport="true" fixedTopGap="70" style="top: 70px">
        <mat-drawer mode="side" opened>
            <div class="container" style="margin-top: 20px">
                <div class="row" style="align-items: center">
                    <div class="col-lg-9">
                        <app-h2title [text]="'Contas'"> </app-h2title>
                    </div>
                </div>
            </div>
            <mat-radio-group class="seletor-contas" (change)="onChangeConta($event)">
                <mat-radio-button *ngFor="let conta of contas" class="botao-conta" [value]="conta.ID">
                    {{ conta.Nome }} - {{ conta.Descricao }}
                </mat-radio-button>
            </mat-radio-group>
            <div class="container" id="crud-area">
                <div class="row" style="align-items: center">
                    <div class="col-lg-9">
                        <button id="nova-conta-button" (click)="novaConta()" mat-menu-item>
                            <mat-icon id="nova-conta-icon">playlist_add</mat-icon>
                            <span id="nova-conta-text"> Nova Conta</span>
                        </button>
                    </div>
                </div>
                <div class="row" style="align-items: center">
                    <div class="col-lg-9">
                        <button id="nova-conta-button" (click)="editarConta()" mat-menu-item>
                            <mat-icon id="nova-conta-icon">edit</mat-icon>
                            <span id="nova-conta-text"> Editar Conta</span>
                        </button>
                    </div>
                </div>
            </div>
        </mat-drawer>
        <mat-drawer-content>
            <div class="bottom-overlay">
                <div [ngClass]="loading ? 'disabled-div' : ''" style="margin-top: 20px">
                    <div *ngIf="!loading">
                        <!-- Nome da conta -->
                        <div class="container" style="margin: left 10px; margin-bottom: 6px">
                            <div class="row" style="align-items: center">
                                <div class="col-lg-9">
                                    <app-h2title [text]="contaTitle"> </app-h2title>
                                </div>
                            </div>
                        </div>

                        <div class="container">
                            <div class="row">
                                <!-- Date picker -->
                                <div class="col-5">
                                    <app-daterangepicker
                                        (onDateRangeChange)="applyDateFilter($event)"
                                        (onDateRangeClear)="clearDateFilter($event)"
                                        [text]="'Intervalo de datas'"
                                    >
                                    </app-daterangepicker>
                                </div>

                                <!-- Seletor Carteira -->
                                <div class="col-lg-3">
                                    <app-carteira-filter [carteiras]="carteiras" (onCarteiraChange)="updateSelectedCarteiras($event)">
                                    </app-carteira-filter>
                                </div>

                                <!-- Exportar Excel -->
                                <div class="col-lg-2">
                                    <mat-form-field appearance="standard">
                                        <mat-label>Baixar Excel</mat-label>
                                        <mat-select appearance="standard" (selectionChange)="exportExcel($event)" placeholder="Baixar Excel">
                                            <mat-option [value]="1">Completo</mat-option>
                                            <mat-option [value]="2">Filtrado</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <!-- Tabela -->
                        <div class="container" style="margin-bottom: 30px">
                            <div class="row">
                                <div class="col-lg-12">
                                    <table id="lancamento-conta-table">
                                        <tr>
                                            <th style="min-width: 150px">Data</th>
                                            <th style="min-width: 120px">Valor</th>
                                            <th id="observacao-column">Observação</th>
                                            <th id="manual-column">Manual</th>
                                        </tr>
                                        <tr *ngFor="let data of filteredTableData">
                                            <td><input type="date" [(ngModel)]="data.Data" style="text-align: center" /></td>
                                            <td class="text-right">
                                                <app-formated-number-input [valor]="data.Valor" (output)="data.Valor = $event">
                                                </app-formated-number-input>
                                            </td>
                                            <td><input type="string" placeholder="" [(ngModel)]="data.Obs" /></td>
                                            <td><input type="number" placeholder="" [(ngModel)]="data.IsManual" /></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="container" style="margin-bottom: 70px">
                            <div class="row">
                                <div class="col">
                                    <button (click)="createLancamento()" mat-raised-button color="primary" style="float: left">+</button>
                                    <button (click)="editLancamentos()" mat-raised-button color="primary" style="float: right">
                                        Salvar Atualizações
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="top-overlay" *ngIf="loading">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
