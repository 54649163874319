import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
    selector: 'app-is-ativo-select',
    templateUrl: './is-ativo-select.component.html',
    styleUrls: ['./is-ativo-select.component.css'],
})
export class IsAtivoSelectComponent implements OnInit {
    @Input('isAtivo') isAtivo: number = 0
    @Output() onIsAtivoChange = new EventEmitter()

    constructor() {}

    emitIsAtivo(event: any) {
        this.onIsAtivoChange.emit(event.value)
    }

    ngOnInit(): void {}
}
