import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { MatDividerModule } from '@angular/material/divider'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LoginComponent } from './modules/login/login.component'
import { IndexComponent } from './modules/index/index.component'
import { AuthGuardService } from './services/auth-guard.service'
import { AuthService } from './services/auth.service'
import { AuthRedirectService } from './services/auth-redirect.service'
import { JwtHelperService } from '@auth0/angular-jwt'
import { JWT_OPTIONS } from '@auth0/angular-jwt'
import { RequestService } from './services/request.service'
import { HttpClientModule } from '@angular/common/http'
import { SignupComponent } from './modules/signup/signup.component'
import { NavbarComponent } from './shared/navbar/navbar.component'
import { UploadServicersComponent } from './modules/gestao/uploadservicers/uploadservicers.component'
import { MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { LoadingComponent } from './shared/loading/loading.component'
import { VisualizationsComponent } from './modules/gestao/visualizations/visualizations.component'
import { NgxChartsModule } from '@swimlane/ngx-charts'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core'
import { ControladoriaComponent } from './modules/controladoria/controladoria.component'
import { GestaoComponent } from './modules/gestao/gestao/gestao.component'
import { NotfoundComponent } from './modules/notfound/notfound.component'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { GestaoConfirmationdialogComponent } from './modules/gestao/uploadservicers/confirmationdialog/confirmationdialog.component'
import { MatTableModule } from '@angular/material/table'
import { MatCardModule } from '@angular/material/card'
import { SubmodulecardComponent } from './shared/submodulecard/submodulecard.component'
import { ContratosComponent } from './modules/gestao/contratos/contratos.component'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { ProjetosobrasComponent } from './shared/projetosobras/projetosobras.component'
import { DashboardprojetoComponent } from './modules/gestao/dashboardprojeto/dashboardprojeto.component'
import { ProjetosComponent } from './shared/projetos/projetos.component'
import { LinechartComponent } from './shared/linechart/linechart.component'
import { VerticalbarchartComponent } from './shared/verticalbarchart/verticalbarchart.component'
import { NumbercardComponent } from './shared/numbercard/numbercard.component'
import { MatTabsModule } from '@angular/material/tabs'
import { ProjetoscategoriasComponent } from './shared/projetoscategorias/projetoscategorias.component'
import { PiechartComponent } from './shared/piechart/piechart.component'
import { CrilistComponent } from './modules/controladoria/crilist/crilist.component'
import { CridetailComponent } from './modules/controladoria/cridetail/cridetail.component'
import { ControladoriaindexComponent } from './modules/controladoria/controladoriaindex/controladoriaindex.component'
import { H1titleComponent } from './shared/h1title/h1title.component'
import { H2titleComponent } from './shared/h2title/h2title.component'
import { DictcardComponent } from './shared/dictcard/dictcard.component'
import { DatepickerComponent } from './shared/datepicker/datepicker.component'
import { DaterangepickerComponent } from './shared/daterangepicker/daterangepicker.component'
import { ExportexcelbuttonComponent } from './shared/exportexcelbutton/exportexcelbutton.component'
import { UploadVortxComponent } from './modules/controladoria/upload-vortx/upload-vortx.component'
import { FilepickerComponent } from './shared/filepicker/filepicker.component'
import { MatExpansionModule } from '@angular/material/expansion'
import { SubmoduleexpansionComponent } from './shared/submoduleexpansion/submoduleexpansion.component'
import { TriggercardComponent } from './modules/controladoria/calculos/triggercard/triggercard.component'
import { NestedsubmodulecardComponent } from './shared/nestedsubmodulecard/nestedsubmodulecard.component'
import { ConfirmationdialogComponent } from './modules/controladoria/calculos/confirmationdialog/confirmationdialog.component'
import { CriselectorComponent } from './modules/controladoria/calculos/criselector/criselector.component'
import { MatChipsModule } from '@angular/material/chips'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatRadioModule } from '@angular/material/radio'
import { CalculosComponent } from './modules/controladoria/calculos/calculos.component'
import { SuccessSnackbarComponent } from './shared/success-snackbar/success-snackbar.component'
import { MatSidenavModule } from '@angular/material/sidenav'
import { SidenavDashboardComponent } from './modules/gestao/dashboardprojeto/sidenav-dashboard/sidenav-dashboard.component'
import { FluxofuturoDashboardComponent } from './modules/gestao/dashboardprojeto/fluxofuturo-dashboard/fluxofuturo-dashboard.component'
import { AbstractChartComponent } from './shared/abstract-chart/abstract-chart.component'
import { TabRazoesComponent } from './modules/gestao/dashboardprojeto/tab-razoes/tab-razoes.component'
import { RazaoFluxoMensalComponent } from './modules/gestao/dashboardprojeto/tab-razoes/razao-fluxo-mensal/razao-fluxo-mensal.component'
import { RazaoSaldoDevedorComponent } from './modules/gestao/dashboardprojeto/tab-razoes/razao-saldo-devedor/razao-saldo-devedor.component'
import { RazaoAbstractComponent } from './modules/gestao/dashboardprojeto/tab-razoes/razao-abstract/razao-abstract.component'
import { ValueChartComponent } from './modules/gestao/dashboardprojeto/value-chart/value-chart.component'
import { CriFilterComponent } from './modules/controladoria/common/cri-filter/cri-filter.component'
import { CarteiraFilterComponent } from './modules/controladoria/common/carteira-filter/carteira-filter.component'
import { MarcacaoMercadoComponent } from './modules/controladoria/marcacao-mercado/marcacao-mercado.component'
import { MtmTableComponent } from './modules/controladoria/marcacao-mercado/mtm-table/mtm-table.component'
import { MatMenuModule } from '@angular/material/menu'
import { MesbasePickerComponent } from './shared/mesbase-picker/mesbase-picker.component'
import { NovoProjetoComponent } from './modules/gestao/cadastro/novo-projeto/novo-projeto.component'
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs.component'
import { NovoProjetoCategoriaComponent } from './modules/gestao/cadastro/novo-projeto-categoria/novo-projeto-categoria.component'
import { NovoProjetoCategoriaCarteiraComponent } from './modules/gestao/cadastro/novo-projeto-categoria-carteira/novo-projeto-categoria-carteira.component'
import { ProjetoFormComponent } from './modules/gestao/common/projeto-form/projeto-form.component'
import { ProjetoListComponent } from './modules/gestao/list/projeto-list/projeto-list.component'
import { ProjetoUpdateComponent } from './modules/gestao/update/projeto-update/projeto-update.component'
import { SuccessAlertComponent } from './shared/success-alert/success-alert.component'
import { ErrorAlertComponent } from './shared/error-alert/error-alert.component'
import { CategoriasSelectComponent } from './shared/categorias-select/categorias-select.component'
import { IndexadorSelectComponent } from './shared/indexador-select/indexador-select.component'
import { ProjetoCategoriaFormComponent } from './modules/gestao/common/projeto-categoria-form/projeto-categoria-form.component'
import { ProjetoCategoriaListComponent } from './modules/gestao/list/projeto-categoria-list/projeto-categoria-list.component'
import { ProjetoCategoriaUpdateComponent } from './modules/gestao/update/projeto-categoria-update/projeto-categoria-update.component'
import { DemonstrativoCaixaComponent } from './modules/controladoria/demonstrativo-caixa/demonstrativo-caixa.component'
import { CriListTableComponent } from './modules/controladoria/crilist/cri-list-table/cri-list-table.component'
import { CriFormComponent } from './modules/controladoria/forms/cri-form/cri-form.component'
import { CriUpdateComponent } from './modules/controladoria/update/cri-update/cri-update.component'
import { MtmSelectComponent } from './shared/mtm-select/mtm-select.component'
import { RegraArredondamentoSelectComponent } from './shared/regra-arredondamento-select/regra-arredondamento-select.component'
import { TipoImovelSelectComponent } from './shared/tipo-imovel-select/tipo-imovel-select.component'
import { TipoAtivoSelectComponent } from './shared/tipo-ativo-select/tipo-ativo-select.component'
import { GrupoEconomicoSelectComponent } from './shared/grupo-economico-select/grupo-economico-select.component'
import { EstruturacaoSelectComponent } from './shared/estruturacao-select/estruturacao-select.component'
import { ProjetoCategoriaAutocompleteComponent } from './shared/projeto-categoria-autocomplete/projeto-categoria-autocomplete.component'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { RegrasArredondamentoViewComponent } from './modules/controladoria/regras-arredondamento-view/regras-arredondamento-view.component'
import { SingleFileUploadComponent } from './modules/gestao/uploadservicers/single-file-upload/single-file-upload.component'
import { SharepointTriggerComponent } from './modules/gestao/uploadservicers/sharepoint-trigger/sharepoint-trigger.component'
import { MatTooltipModule } from '@angular/material/tooltip'
import { NovoEmpreendimentoComponent } from './modules/gestao/cadastro/novo-empreendimento/novo-empreendimento.component'
import { EmpreendimentoFormComponent } from './modules/gestao/common/empreendimento-form/empreendimento-form.component'
import { MunicipioAutoCompleteComponent } from './shared/municipio-auto-complete/municipio-auto-complete.component'
import { UfAutoCompleteComponent } from './shared/uf-auto-complete/uf-auto-complete.component'
import { EmpreendimentoListComponent } from './modules/gestao/list/empreendimento-list/empreendimento-list.component'
import { EmpreendimentoUpdateComponent } from './modules/gestao/update/empreendimento-update/empreendimento-update.component'
import { CriCreateComponent } from './modules/controladoria/create/cri-create/cri-create.component'
import { EmissoresSelectComponent } from './shared/emissores-select/emissores-select.component'
import { IsAtivoSelectComponent } from './shared/is-ativo-select/is-ativo-select.component'
import { TabDesempenhoComponent } from './modules/gestao/dashboardprojeto/tab-desempenho/tab-desempenho.component'
import { DataCellComponent } from './modules/gestao/dashboardprojeto/tab-desempenho/data-cell/data-cell.component'
import { PercentageValueCellComponent } from './modules/gestao/dashboardprojeto/tab-desempenho/percentage-value-cell/percentage-value-cell.component'
import { ObrasSelectComponent } from './modules/gestao/dashboardprojeto/tab-desempenho/obras-select/obras-select.component'
import { TabParcelasAtrasoComponent } from './modules/gestao/dashboardprojeto/tab-parcelas-atraso/tab-parcelas-atraso.component'
import { PercentageSelectComponent } from './modules/gestao/dashboardprojeto/tab-parcelas-atraso/percentage-select/percentage-select.component'
import { IgxSparklineModule } from 'igniteui-angular-charts'
import { ParcelasAtrasoSparkLineComponent } from './modules/gestao/dashboardprojeto/tab-parcelas-atraso/parcelas-atraso-spark-line/parcelas-atraso-spark-line.component'
import { LancamentoContaComponent } from './modules/controladoria/lancamento-conta/lancamento-conta.component'
import { EditarContaDialogComponent } from './modules/controladoria/lancamento-conta/editar-conta-dialog/editar-conta-dialog.component'
import { NovaContaDialogComponent } from './modules/controladoria/lancamento-conta/nova-conta-dialog/nova-conta-dialog.component'
import { FeedbackPopupComponent } from './shared/feedback-popup/feedback-popup.component'
import { MovimentacaoCaixaComponent } from './modules/controladoria/movimentacao-caixa/movimentacao-caixa.component'
import { MatInputFormatedDirective } from './custom_directives/mat-input-formated.directive'
import { FormatedNumberInputComponent } from './modules/controladoria/movimentacao-caixa/formated-number-input/formated-number-input.component'
import { ClassificacaoAutocompleteComponent } from './modules/controladoria/movimentacao-caixa/classificacao-autocomplete/classificacao-autocomplete.component'
import { TabelaResumoComponent } from './modules/gestao/tabela-resumo/tabela-resumo.component'
import { MatSortModule } from '@angular/material/sort'
import { MatInputFormatedPercentageDirective } from './custom_directives/mat-input-formated-percentage.directive'
import { ServicerFilterComponent } from './modules/gestao/tabela-resumo/servicer-filter/servicer-filter.component'
import { SecuritizadoraFilterComponent } from './modules/gestao/tabela-resumo/securitizadora-filter/securitizadora-filter.component'
import { TipoFilterComponent } from './modules/gestao/tabela-resumo/tipo-filter/tipo-filter.component'
import { AumentoMensalSelectComponent } from './modules/gestao/tabela-resumo/aumento-mensal-select/aumento-mensal-select.component'
import { CarteiraFilterResumoComponent } from './modules/gestao/tabela-resumo/carteira-filter-resumo/carteira-filter-resumo.component'
import { CalculateTabelaResumoDialogComponent } from './modules/gestao/tabela-resumo/calculate-tabela-resumo-dialog/calculate-tabela-resumo-dialog.component'
import { DurationComponent } from './modules/controladoria/duration/duration.component'
import { CriSelectorComponent } from './modules/controladoria/duration/cri-selector/cri-selector.component'
import { LtvStackedChartComponent } from './modules/gestao/dashboardprojeto/ltv-stacked-chart/ltv-stacked-chart.component'
import { ValuesStackedChartComponent } from './modules/gestao/dashboardprojeto/values-stacked-chart/values-stacked-chart.component';
import { ConsolidarTabelaDialogComponent } from './modules/gestao/tabela-resumo/consolidar-tabela-dialog/consolidar-tabela-dialog.component';
import { DesbloquearTabelaDialogComponent } from './modules/gestao/tabela-resumo/desbloquear-tabela-dialog/desbloquear-tabela-dialog.component';
import { CarteiraSelectComponent } from './shared/carteira-select/carteira-select.component';
import { EventosDialogComponent } from './modules/gestao/dashboardprojeto/sidenav-dashboard/eventos-dialog/eventos-dialog.component';
import { CreateEventoDialogComponent } from './modules/gestao/dashboardprojeto/sidenav-dashboard/eventos-dialog/create-evento-dialog/create-evento-dialog.component';
import { UpdateEventoDialogComponent } from './modules/gestao/dashboardprojeto/sidenav-dashboard/eventos-dialog/update-evento-dialog/update-evento-dialog.component';
import { DeleteEventoDialogComponent } from './modules/gestao/dashboardprojeto/sidenav-dashboard/eventos-dialog/delete-evento-dialog/delete-evento-dialog.component';
import { TabEstoqueComponent } from './modules/gestao/dashboardprojeto/tab-estoque/tab-estoque.component';
import { NgApexchartsModule } from 'ng-apexcharts'
import { MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { MunicipiosComponent } from './modules/controladoria/municipios/municipios.component'
import { MatPaginatorModule } from '@angular/material/paginator';
import { EventosComponent } from './modules/gestao/calendario/eventos/eventos.component';
import { UploadcrisComponent } from './modules/gestao/uploadcris/uploadcris.component';
import { NotasComponent } from './modules/gestao/notas/notas.component';
import { ResumoNotasComponent } from './modules/gestao/notas/resumo-notas/resumo-notas.component';
import { BreakdownNotasComponent } from './modules/gestao/notas/breakdown-notas/breakdown-notas.component';
import { CriteriosNotasComponent } from './modules/gestao/notas/criterios-notas/criterios-notas.component';
import { NotasChartComponent } from './modules/gestao/notas/resumo-notas/notas-chart/notas-chart.component';
import { CalendarioComponent } from './modules/gestao/calendario/calendario.component';
import { LiberacoesFuturasComponent } from './modules/gestao/calendario/liberacoes-futuras/liberacoes-futuras.component';
import { SaldoDevedorStackedChartComponent } from './modules/gestao/dashboardprojeto/saldo-devedor-stacked-chart/saldo-devedor-stacked-chart.component';
import { CarteiraListComponent } from './modules/gestao/list/carteira-list/carteira-list.component';
import { CarteiraEditDialogComponent } from './modules/gestao/list/carteira-list/carteira-edit-dialog/carteira-edit-dialog.component';
import { CadastrosCardComponent } from './modules/gestao/cadastros-card/cadastros-card.component';
import { AdministradorListComponent } from './modules/gestao/list/administrador-list/administrador-list.component';
import { AdministradorEditDialogComponent } from './modules/gestao/list/administrador-list/administrador-edit-dialog/administrador-edit-dialog.component';
import { AgenteFiduciarioListComponent } from './modules/gestao/list/agente-fiduciario-list/agente-fiduciario-list.component';
import { AgenteFiduciarioEditDialogComponent } from './modules/gestao/list/agente-fiduciario-list/agente-fiduciario-edit-dialog/agente-fiduciario-edit-dialog.component';
import { AtivoListComponent } from './modules/gestao/list/ativo-list/ativo-list.component';
import { TipoProjetoListComponent } from './modules/gestao/list/tipo-projeto-list/tipo-projeto-list.component';
import { SetorListComponent } from './modules/gestao/list/setor-list/setor-list.component';
import { SetorEditDialogComponent } from './modules/gestao/list/setor-list/setor-edit-dialog/setor-edit-dialog.component';
import { TipoProjetoEditDialogComponent } from './modules/gestao/list/tipo-projeto-list/tipo-projeto-edit-dialog/tipo-projeto-edit-dialog.component';
import { AtivoEditDialogComponent } from './modules/gestao/list/ativo-list/ativo-edit-dialog/ativo-edit-dialog.component';
import { CidadeEditDialogComponent } from './modules/controladoria/forms/cidade-edit-dialog/cidade-edit-dialog.component';
import { EmissorListComponent } from './modules/gestao/list/emissor-list/emissor-list.component';
import { ServicerListComponent } from './modules/gestao/list/servicer-list/servicer-list.component';
import { EmissorEditDialogComponent } from './modules/gestao/list/emissor-list/emissor-edit-dialog/emissor-edit-dialog.component';
import { ServicerEditDialogComponent } from './modules/gestao/list/servicer-list/servicer-edit-dialog/servicer-edit-dialog.component';
import { GrupoEconomicoListComponent } from './modules/gestao/list/grupo-economico-list/grupo-economico-list.component';
import { GrupoEconomicoEditDialogComponent } from './modules/gestao/list/grupo-economico-list/grupo-economico-edit-dialog/grupo-economico-edit-dialog.component';
import { DistribuicaoComponent } from './modules/controladoria/distribuicao/distribuicao.component'
import { CustomDatePipe, MY_DATE_FORMATS } from './utils/dateformat';
import { TradesComponent } from './modules/controladoria/trades/trades.component'

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        IndexComponent,
        SignupComponent,
        NavbarComponent,
        UploadServicersComponent,
        LoadingComponent,
        VisualizationsComponent,
        ControladoriaComponent,
        GestaoComponent,
        NotfoundComponent,
        ConfirmationdialogComponent,
        SubmodulecardComponent,
        ContratosComponent,
        ProjetosobrasComponent,
        DashboardprojetoComponent,
        ProjetosComponent,
        LinechartComponent,
        VerticalbarchartComponent,
        NumbercardComponent,
        ProjetoscategoriasComponent,
        PiechartComponent,
        CrilistComponent,
        CridetailComponent,
        ControladoriaindexComponent,
        H1titleComponent,
        H2titleComponent,
        DictcardComponent,
        DatepickerComponent,
        DaterangepickerComponent,
        ExportexcelbuttonComponent,
        UploadVortxComponent,
        FilepickerComponent,
        SubmoduleexpansionComponent,
        TriggercardComponent,
        NestedsubmodulecardComponent,
        GestaoConfirmationdialogComponent,
        CriselectorComponent,
        CalculosComponent,
        SuccessSnackbarComponent,
        SidenavDashboardComponent,
        FluxofuturoDashboardComponent,
        AbstractChartComponent,
        TabRazoesComponent,
        RazaoFluxoMensalComponent,
        RazaoSaldoDevedorComponent,
        RazaoAbstractComponent,
        ValueChartComponent,
        CriFilterComponent,
        CarteiraFilterComponent,
        MarcacaoMercadoComponent,
        MtmTableComponent,
        MesbasePickerComponent,
        NovoProjetoComponent,
        BreadcrumbsComponent,
        NovoProjetoCategoriaComponent,
        NovoProjetoCategoriaCarteiraComponent,
        ProjetoFormComponent,
        ProjetoListComponent,
        ProjetoUpdateComponent,
        SuccessAlertComponent,
        ErrorAlertComponent,
        CategoriasSelectComponent,
        IndexadorSelectComponent,
        ProjetoCategoriaFormComponent,
        ProjetoCategoriaListComponent,
        ProjetoCategoriaUpdateComponent,
        DemonstrativoCaixaComponent,
        CriListTableComponent,
        CriFormComponent,
        CriUpdateComponent,
        MtmSelectComponent,
        RegraArredondamentoSelectComponent,
        TipoImovelSelectComponent,
        TipoAtivoSelectComponent,
        GrupoEconomicoSelectComponent,
        EstruturacaoSelectComponent,
        ProjetoCategoriaAutocompleteComponent,
        RegrasArredondamentoViewComponent,
        SingleFileUploadComponent,
        SharepointTriggerComponent,
        NovoEmpreendimentoComponent,
        EmpreendimentoFormComponent,
        MunicipioAutoCompleteComponent,
        UfAutoCompleteComponent,
        EmpreendimentoListComponent,
        EmpreendimentoUpdateComponent,
        CriCreateComponent,
        EmissoresSelectComponent,
        IsAtivoSelectComponent,
        TabDesempenhoComponent,
        DataCellComponent,
        PercentageValueCellComponent,
        ObrasSelectComponent,
        TabParcelasAtrasoComponent,
        PercentageSelectComponent,
        ParcelasAtrasoSparkLineComponent,
        LancamentoContaComponent,
        EditarContaDialogComponent,
        NovaContaDialogComponent,
        FeedbackPopupComponent,
        MovimentacaoCaixaComponent,
        MatInputFormatedDirective,
        FormatedNumberInputComponent,
        ClassificacaoAutocompleteComponent,
        TabelaResumoComponent,
        MatInputFormatedPercentageDirective,
        ServicerFilterComponent,
        SecuritizadoraFilterComponent,
        TipoFilterComponent,
        AumentoMensalSelectComponent,
        CarteiraFilterResumoComponent,
        CalculateTabelaResumoDialogComponent,
        DurationComponent,
        CriSelectorComponent,
        LtvStackedChartComponent,
        ValuesStackedChartComponent,
        ConsolidarTabelaDialogComponent,
        DesbloquearTabelaDialogComponent,
        CarteiraSelectComponent,
        EventosDialogComponent,
        CreateEventoDialogComponent,
        UpdateEventoDialogComponent,
        DeleteEventoDialogComponent,
        TabEstoqueComponent,
        MunicipiosComponent,
        EventosComponent,
        UploadcrisComponent,
        NotasComponent,
        ResumoNotasComponent,
        BreakdownNotasComponent,
        CriteriosNotasComponent,
        NotasChartComponent,
        CalendarioComponent,
        LiberacoesFuturasComponent,
        SaldoDevedorStackedChartComponent,
        CarteiraListComponent,
        CarteiraEditDialogComponent,
        CadastrosCardComponent,
        AdministradorListComponent,
        AdministradorEditDialogComponent,
        AgenteFiduciarioListComponent,
        AgenteFiduciarioEditDialogComponent,
        AtivoListComponent,
        TipoProjetoListComponent,
        SetorListComponent,
        SetorEditDialogComponent,
        TipoProjetoEditDialogComponent,
        AtivoEditDialogComponent,
        CidadeEditDialogComponent,
        EmissorListComponent,
        ServicerListComponent,
        EmissorEditDialogComponent,
        ServicerEditDialogComponent,
        GrupoEconomicoListComponent,
        GrupoEconomicoEditDialogComponent,
        DistribuicaoComponent,
        TradesComponent,
        CustomDatePipe
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatButtonModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        NgxChartsModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatTableModule,
        MatCardModule,
        ScrollingModule,
        MatAutocompleteModule,
        MatTabsModule,
        MatExpansionModule,
        MatChipsModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSidenavModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatTooltipModule,
        IgxSparklineModule,
        MatSortModule,
        MatDividerModule,
        NgApexchartsModule,
        MatDialogModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatNativeDateModule,
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
        AuthGuardService,
        AuthService,
        JwtHelperService,
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        RequestService,
        AuthRedirectService,
        MatNativeDateModule,
        MatDatepickerModule,
        {
            provide: MatDialogRef,
            useValue: {},
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
