import { Component, OnInit, Input, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { EventEmitter } from '@angular/core'
import { Projeto } from 'src/app/interfaces/projeto'

@Component({
    selector: 'app-projetos',
    templateUrl: './projetos.component.html',
    styleUrls: ['./projetos.component.css'],
})
export class ProjetosComponent implements OnInit {
    @Input('projetos') projetos: Projeto[]
    @Input('projetoId') projetoId: number = 0
    @Output() onProjetoChange = new EventEmitter()

    projetoControl = new FormControl()
    declare filteredProjetos: Observable<string[]>
    public nomeProjeto: string = ''

    constructor() {
        this.projetos = []
    }

    ngOnInit(): void {
        if (this.projetoId != 0) {
            const index = this.projetos.map((p) => p.ID).indexOf(this.projetoId)
            this.nomeProjeto = this.projetos[index].Sigla
        }

        this.filteredProjetos = this.projetoControl.valueChanges.pipe(
            startWith(this.nomeProjeto),
            map((value) => this._filterProjects(value))
        )

        if (this.nomeProjeto) this.projetoControl.setValue(this.nomeProjeto)
    }

    private _filterProjects(value: string): string[] {
        if (value) {
            const filterValue = value.toLowerCase()
            return this.projetos.map((projeto) => projeto.Sigla).filter((project) => project.toLowerCase().includes(filterValue))
        } else {
            return this.projetos.map((projeto) => projeto.Sigla)
        }
    }

    changeProjeto(event: string): void {
        const projetoId = this.projetos.filter((projeto) => projeto.Sigla == event)[0].ID
        this.onProjetoChange.emit(projetoId)
    }

    clearProjeto(): void {
        this.projetoControl.reset()
    }
}
