import { Component, Inject, Input, OnInit } from '@angular/core';
import { Carteira } from 'src/app/interfaces/carteira';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Administrador } from 'src/app/interfaces/administrador';

@Component({
  selector: 'app-carteira-edit-dialog',
  templateUrl: './carteira-edit-dialog.component.html',
  styleUrls: ['./carteira-edit-dialog.component.css']
})
export class CarteiraEditDialogComponent implements OnInit {
  

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<CarteiraEditDialogComponent>) {}
    public administradores: Administrador[] = this.data.administradores
    public carteira: Carteira = this.data.carteira
    public create: boolean = this.data.create

    ngOnInit(): void {
    }

    save(): void {
        const params = {
            carteira: this.carteira,
            save: true,
        }
        this.dialogRef.close(params)
    }

    cancel(): void {
        const params = {
            save: false,
        }
        this.dialogRef.close(params)
    }

    isButtonDisabled(): boolean {
        return (this.carteira.Nome === '' || 
                this.carteira.Sigla === '' || 
                this.carteira.CNPJ == "")
    }

}
