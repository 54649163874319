<app-navbar></app-navbar>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="bottom-overlay" style="margin-top: 15px">
    <div [ngClass]="loading ? 'disabled-div' : ''">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <app-h2title [text]="'CRIs-Categorias'"></app-h2title>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p>
                        Para ir à tela de edição de CRIs-Categorias, clique no ícone de edição à esquerda da linha do CRI-Categoria que deseja editar.
                    </p>
                </div>
            </div>
        </div>
        <div class="container" style="margin-bottom: 50px">
            <div class="row">
                <cdk-virtual-scroll-viewport [itemSize]="60" style="border: 1px solid rgb(243, 241, 241)">
                    <table id="customTable">
                        <tr>
                            <th></th>
                            <th>CRI</th>
                            <th>Categoria</th>
                            <th>Valor Emissão</th>
                            <th>TIRR</th>
                            <th>Data Emissão</th>
                            <th>Data Vencimento</th>
                            <th>Indexador</th>
                        </tr>
                        <tr *ngFor="let pc of projetosCategorias">
                            <td>
                                <button mat-icon-button>
                                    <a style="text-decoration: none; color: black" href="gestao/edit/cri_categoria?projeto_categoria_id={{ pc.ID }}">
                                        <mat-icon>mode_edit_outline</mat-icon>
                                    </a>
                                </button>
                            </td>
                            <td>{{ pc.CRI }}</td>
                            <td>{{ pc.Categoria }}</td>
                            <td>{{ pc.ValorEmissao.toLocaleString() }}</td>
                            <td>{{ pc.TIRR }}%</td>
                            <td>{{ formatDate(pc.DataEmissao) }}</td>
                            <td>{{ formatDate(pc.DataVencto) }}</td>
                            <td>{{ pc.Indexador }}</td>
                        </tr>
                    </table>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <a href="gestao/create/cri_categoria" style="margin-top: 20px; margin-bottom: 30px" mat-raised-button color="primary">
                        <mat-icon style="margin-left: -10px">add</mat-icon>
                        Cadastrar Novo CRI-Categoria
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
