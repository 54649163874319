<app-navbar></app-navbar>

<div class="bottom-overlay">
    <div [ngClass]="loading ? 'disabled-div' : ''">
        <div class="container" style="margin-top: 20px">
            <div class="row">
                <div class="col-12">
                    <app-h2title [text]="'Marcação a Mercado'"></app-h2title>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-lg-3" *ngIf="mtmCris.length > 0">
                    <app-cri-filter [cris]="mtmCris" [selectedCarteiras]="selectedCarteiras" (onCriChange)="updateDisplayedCris($event)">
                    </app-cri-filter>
                </div>
                <div class="col-lg-3" *ngIf="mtmCris.length > 0">
                    <app-carteira-filter [carteiras]="carteiras" (onCarteiraChange)="updateSelectedCarteiras($event)"> </app-carteira-filter>
                </div>
                <div class="col-lg-3">
                    <app-datepicker [text]="'Selecione uma data base'" [availableDates]="availableDates" (onDateChange)="updateDataBase($event)">
                    </app-datepicker>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row" style="margin-bottom: 50px" *ngIf="mtmCris.length > 0">
                <div class="col-12">
                    <app-mtm-table (onClick)="exportExcel($event)" [mtmCris]="mtmCrisDisplay" [dataBase]="dataBase"> </app-mtm-table>
                </div>
            </div>
        </div>
    </div>
    <div class="top-overlay" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>
