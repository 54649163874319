import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
    selector: 'app-percentage-select',
    templateUrl: './percentage-select.component.html',
    styleUrls: ['./percentage-select.component.css'],
})
export class PercentageSelectComponent implements OnInit {
    @Input('obras') obras: string[] = []
    @Output() onPercentageChange = new EventEmitter()

    selected = '#'
    selectors = ['#', '%']

    constructor() {}

    emitPercentageSelect(event: any) {
        this.onPercentageChange.emit(event.value)
    }

    ngOnInit(): void {}
}
