<div class="container" style="margin-top: 20px">
    <div class="row">
        <div class="col-3">
            <mat-form-field class="filtro" appearance="fill">
                <mat-label>Filtrar</mat-label>
                <input matInput placeholder="" [value]="filterValue" (change)="filtrar($event)">
            </mat-form-field>
        </div>
        <div class="col-4 my-auto">
            <mat-slide-toggle 
                [color]="'primary'" 
                [checked]="!filterDatasFuturas"
                (change)="filtrarDatasFuturas(!$event.checked)"> 
                    Incluir Histórico 
            </mat-slide-toggle>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="mat-0elevation-z8 tableWrapper">
                <table mat-table [dataSource]="filteredDataSource">
                    
                    <ng-container matColumnDef="nome">
                        <th mat-header-cell *matHeaderCellDef> CRI</th>
                        <td mat-cell *matCellDef="let element"> {{ element.Nome }} </td>
                    </ng-container>
                    <ng-container matColumnDef="data">
                        <th mat-header-cell *matHeaderCellDef> Data </th>
                        <td mat-cell *matCellDef="let element"> {{ formatDate(element.DataLiberacao) }} </td>
                    </ng-container>
                    <ng-container matColumnDef="descricao">
                        <th mat-header-cell *matHeaderCellDef> Comprado </th>
                        <td mat-cell *matCellDef="let element"> 
                            <div *ngIf="element.Comprado">
                                Sim
                            </div>
                            <div *ngIf="!element.Comprado">
                                Não
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="qtdeLiberada">
                        <th mat-header-cell *matHeaderCellDef> Qtd Liberada </th>
                        <td mat-cell *matCellDef="let element"> {{ element.QtdeLiberacao }}</td>
                    </ng-container>
                    <ng-container matColumnDef="qtdeNaCarteira">
                        <th mat-header-cell *matHeaderCellDef> Qtd Carteira </th>
                        <td mat-cell *matCellDef="let element"> {{ element.QtdeLiberacao }}</td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10, 15, 20]"
                    showFirstLastButtons 
                    aria-label="Select page">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>