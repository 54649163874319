<div>
    <mat-form-field appearance="standard" class="selector">
        <mat-select [(value)]="selectedObra" 
                    (selectionChange)="changeObra($event)">
            <mat-option [value]="'Total'">
                Todas as Obras
            </mat-option>
            <mat-option *ngFor="let obra of obras" [value]="obra">
                {{ obra }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div class="chart-wrapper">
    <ngx-charts-bar-vertical-stacked
        [scheme]="colorScheme"
        [results]="data"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [animations]="animations"
        [showDataLabel]="true"
        [showGridLines]="showGridLines"
        [barPadding]="barPadding"
        [dataLabelFormatting]="formatDataLabel"
    >
    </ngx-charts-bar-vertical-stacked>
</div>
