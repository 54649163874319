import { Component, OnInit, Input, DoCheck, ElementRef } from '@angular/core'
import { intToString } from 'igniteui-angular-core'
import { RazaoAbstractComponent } from 'src/app/modules/gestao/dashboardprojeto/tab-razoes/razao-abstract/razao-abstract.component'

@Component({
    selector: 'app-razao-saldo-devedor',
    templateUrl: './razao-saldo-devedor.component.html',
    styleUrls: ['./razao-saldo-devedor.component.css'],
})
export class RazaoSaldoDevedorComponent extends RazaoAbstractComponent implements OnInit, DoCheck {
    @Input('razoes') razoes: any[]
    @Input('direitosCred') direitosCred: any[]
    @Input('saldoDevedor') saldoDevedor: any[]
    @Input('fundosDeReservaManuais') fundosDeReservaManuais: any[]
    @Input('limiteRSD') limiteRSD: any

    public razoesChart: any[] = []
    public dcChart: any[] = []
    public sdChart: any[] = []
    public frChart: any[] = []
    public fundoDeReservaManualChart: any[] = []
    public yScaleMin: number = Infinity
    public yScaleMax: number = 0
    public height: number = 160
    public customColorsRazoes: any[] = []
    public customColorsDc: any[] = [{ name: 'VPDireitosCreditorios', value: '#c4170c' }]

    public opened_tab = false
    public noLimit = false

    //reference line (limite da razao fluxo mensal)
    public refline: any
    public refvalue: any

    ngDoCheck() {
        this.refvalue = new ElementRef(document.getElementById('limit-rsd-value'))
        this.refline = new ElementRef(document.getElementById('limit-line-sd'))
        if (this.refline.nativeElement != null && this.refvalue.nativeElement != null && this.opened_tab == false) {
            this.refline.nativeElement.style.top = this.getRefTopCoordinate(this.yScaleMax, this.limiteRSD, 0.69, 11.4)
            this.refvalue.nativeElement.style.top = this.getRefTopCoordinate(this.yScaleMax, this.limiteRSD, 0.4, 10.5)
            this.opened_tab = true
        }
    }

    getRefTopCoordinate(yScaleMax: number, limiteRSD: number, rem_top: number, rem_bot: number) {
        let rems = (rem_bot - rem_top) * (1 - limiteRSD / yScaleMax) + rem_top
        let str_rems = intToString(rems).replace(',', '.') + 'rem'
        return str_rems
    }

    getRefValueTopCoordinate(yScaleMax: number, limiteRSD: number) {
        let valor_rem_top = 0.4
        let valor_rem_bot = 10.5

        let rems = (valor_rem_bot - valor_rem_top) * (1 - limiteRSD / yScaleMax) + valor_rem_top
        let str_rems = intToString(rems).replace(',', '.') + 'rem'
        return str_rems
    }

    public customColorsSd: any[] = [
        { name: 'SDLiq', value: '#c4170c' },
        { name: 'FundoReserva', value: '#0cb9c4' },
        { name: 'Senior', value: '#e5e5e5' },
        { name: 'Mez. Senior', value: '#b2b2b2' },
        { name: 'Mez. Sub', value: '#999999' },
        { name: 'Subordinada', value: '#7f7f7f' },
    ]

    public customColorsFundoDeReserva: any[] = [
        { name: 'Real', value: '#c4170c' },
        { name: 'Teórico', value: '#999999' },
    ]

    constructor() {
        super()
        this.razoes = this.direitosCred = this.saldoDevedor = this.fundosDeReservaManuais = []
    }

    ngOnInit(): void {
        this.opened_tab = false
        if (this.limiteRSD == 0) {
            this.noLimit = true
        }

        var aux = this.getChartData(this.direitosCred, false)
        this.dcChart = aux.chartData
        this.yScaleMin = aux.yMin
        this.yScaleMax = aux.yMax

        aux = this.getChartData(this.saldoDevedor, false, this.yScaleMax, this.yScaleMin)
        this.sdChart = aux.chartData
        this.yScaleMin = aux.yMin
        this.yScaleMax = aux.yMax

        aux = this.getRazaoChartData(this.razoes)
        this.razoesChart = aux.razaoChart
        this.customColorsRazoes = aux.customColors

        this.yScaleMax = Math.max.apply(
            Math,
            this.razoesChart.map(function (o) {
                return o.value
            })
        )

        if (this.yScaleMax < this.limiteRSD) {
            this.yScaleMax = this.limiteRSD
        }

        if (this.fundosDeReservaManuais.length > 0) {
            const fundoDeReservaManualChart = []
            fundoDeReservaManualChart.push({ name: 'Teórico', series: this.sdChart[0].series })
            fundoDeReservaManualChart.push({ name: 'Real', series: this.fundosDeReservaManuais })
            this.fundoDeReservaManualChart = fundoDeReservaManualChart
        }

        console.log(this.fundoDeReservaManualChart)
    }
}
