import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { MovCaixaClassificacao } from 'src/app/interfaces/mov_caixa_classificacao'

@Component({
    selector: 'app-classificacao-autocomplete',
    templateUrl: './classificacao-autocomplete.component.html',
    styleUrls: ['./classificacao-autocomplete.component.css'],
})
export class ClassificacaoAutocompleteComponent implements OnInit {
    @Input('classificacoes') classificacoes: MovCaixaClassificacao[] = []
    @Input('classificacaoID') classificacaoID: number = 0
    @Output() onClassificacaoChange = new EventEmitter()

    public classificacaoControl = new FormControl()
    declare filteredClassificacoes: Observable<string[]>
    public nomeClassificacao: string = ''

    constructor() {}

    ngOnInit(): void {
        if (this.classificacaoID > 0) {
            const index = this.classificacoes.map((c) => c.ID).indexOf(this.classificacaoID)
            this.nomeClassificacao = this.classificacoes[index].Nome
        }

        this.filteredClassificacoes = this.classificacaoControl.valueChanges.pipe(
            startWith(this.nomeClassificacao),
            map((value) => this._filterClassificacao(value))
        )

        if (this.nomeClassificacao) this.classificacaoControl.setValue(this.nomeClassificacao)
    }

    private _filterClassificacao(value: string): string[] {
        if (value) {
            const filterValue = value.toLowerCase()
            return this.classificacoes.map((c) => c.Nome).filter((nome) => nome.toLowerCase().includes(filterValue))
        } else {
            return this.classificacoes.map((c) => c.Nome)
        }
    }

    changeClassificacao(event: string): void {
        const classificacaoID: number = this.classificacoes.filter((c) => c.Nome == event)[0].ID
        this.onClassificacaoChange.emit(classificacaoID)
    }

    clearClassificacao(): void {
        this.classificacaoControl.reset()
    }

    emitClassificacaoID(event: any) {
        this.onClassificacaoChange.emit(event.value)
    }
}
