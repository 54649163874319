import { Component, OnInit } from '@angular/core'
import { SubmoduleCard } from 'src/app/interfaces/submodule_card'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
@Component({
    selector: 'app-controladoriaindex',
    templateUrl: './controladoriaindex.component.html',
    styleUrls: ['./controladoriaindex.component.css'],
})
export class ControladoriaindexComponent implements OnInit {
    constructor() {}

    // Submodules
    public submodules: SubmoduleCard[] = [
        {
            title: 'Cadastros Controladoria',
            description: 'Envio de arquivos para manutenção das funcionalidades de Controladoria',
            href: 'controladoria/upload_bases',
        },
        {
            title: 'Tabela de Distribuição',
            description: 'Tabela de distribuição',
            href: 'controladoria/distribuicao',
        },
        {
            title: 'Tabela de Trades',
            description: 'Tabelas de trades',
            href: 'controladoria/trades',
        },
        {
            title: 'Carteira Vortx',
            description: 'Envio do arquivo da Carteira Vortx',
            href: 'controladoria/upload_vortx',
        },
        {
            title: 'Lançamento por Conta',
            description: 'Visualização dos lançamentos por carteira e conta',
            href: 'controladoria/lancamento_conta',
        },
        {
            title: 'Demonstrativo de Caixa',
            description: 'Envio do arquivo de Demonstrativo de Caixa',
            href: 'controladoria/demonstrativo_caixa',
        },
        {
            title: 'Movimentação Caixa',
            description: 'Movimentação de Caixa',
            href: 'controladoria/movimentacao_caixa',
        },
        {
            title: 'Cálculos',
            description: 'Disparo de rotinas de cálculo via Python',
            href: 'controladoria/calculos',
        },
        {
            title: 'CRIs',
            description: 'Visualização dos CRIs em acompanhamento e suas evoluções diárias',
            href: 'controladoria/cri_list',
        },
        {
            title: 'Marcação a Mercado',
            description: 'Marcação a mercado dos CRIs em diferentes datas bases',
            href: 'controladoria/marcacao_mercado',
        },
        {
            title: 'Regras de Arredondamento',
            description: 'Visualizar regras de arredondamento',
            href: 'controladoria/regras_arredondamento',
        },
        {
            title: 'Municípios',
            description: 'Listagem de municípios',
            href: 'controladoria/municipios',
        },
    ]

    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Controladoria', href: 'controladoria' },
    ]

    ngOnInit(): void {}
}
