<mat-form-field appearance="standard">
    <mat-label>Classificação</mat-label>
    <input
        type="text"
        placeholder="Selecione uma Classificação"
        matInput
        [formControl]="classificacaoControl"
        [matAutocomplete]="autoClassificacao"
    />
    <mat-autocomplete autoActiveFirstOption #autoClassificacao="matAutocomplete" (optionSelected)="changeClassificacao($event.option.value)">
        <mat-option *ngFor="let classificacao of filteredClassificacoes | async" [value]="classificacao">
            {{ classificacao }}
        </mat-option>
    </mat-autocomplete>
    <button mat-icon-button matSuffix (click)="clearClassificacao()">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>
