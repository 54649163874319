import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'app-data-cell',
    templateUrl: './data-cell.component.html',
    styleUrls: ['./data-cell.component.css'],
})
export class DataCellComponent implements OnInit {
    @Input('value') value: number

    constructor() {
        this.value = 0
    }

    ngOnInit(): void {
        this.value = Math.round(this.value)
    }

    ngOnChanges(): void {
        this.value = Math.round(this.value)
    }
}
