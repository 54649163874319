<app-navbar></app-navbar>
<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="container"> 
    <div class="row">
        <div class="col-12 calendario-title">
            <app-h2title [text]="'Calendário'"></app-h2title>
        </div>
    </div>
</div>
<div>
    <mat-tab-group dynamicHeight="true" mat-align-tabs="center">
        
        <!-- Eventos -->
        <mat-tab label="Eventos">
            <app-eventos></app-eventos>
        </mat-tab>

        <!-- Liberações Futuras -->
        <mat-tab label="Liberações">
            <app-liberacoes-futuras></app-liberacoes-futuras>
        </mat-tab>

    </mat-tab-group>
</div>