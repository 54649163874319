<app-navbar></app-navbar>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="bottom-overlay" style="margin-top: 20px">
    <div [ngClass]="loading ? 'disabled-div' : ''">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <app-h2title [text]="'CRIs'"></app-h2title>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p>Para ir à tela de edição, clique no ícone de edição à esquerda da linha do projeto que deseja editar.</p>
                </div>
            </div>
        </div>
        <div *ngIf="!loading" class="container" style="margin-top: 30px">
            <div class="row">
                <div class="col-12" style="margin-bottom: 50px">
                    <cdk-virtual-scroll-viewport [itemSize]="60">
                        <table id="customTable">
                            <tr>
                                <th></th>

                                <th>CRI</th>
                                <th>TIRR Ponderada (%)</th>
                                <th>VGV</th>
                                <th>Limite Razão Fluxo Mensal (%)</th>
                                <th>Limite Razão Saldo Devedor (%)</th>
                                <th>Metodologia Fundo Reserva</th>
                                <th>Valor Fundo de Reserva (PMT/R$/%)</th>
                            </tr>
                            <tr *ngFor="let projeto of projetos">
                                <td>
                                    <button mat-icon-button>
                                        <a style="text-decoration: none; color: black" href="gestao/edit/cri?projeto_id={{ projeto.ID }}">
                                            <mat-icon>mode_edit_outline</mat-icon>
                                        </a>
                                    </button>
                                </td>
                                <td>{{ projeto.Sigla }}</td>
                                <td>{{ projeto.TIRR }}</td>
                                <td>{{ formatString(projeto.VGV) }}</td>
                                <td>{{ projeto.LimRazaoFluxoMensal }}</td>
                                <td>{{ projeto.LimRazaoSaldoDevedor }}</td>
                                <td>{{ projeto.MetodologiaFundoReserva }}</td>
                                <td>{{ formatString(projeto.Valor) }}</td>
                            </tr>
                        </table>
                    </cdk-virtual-scroll-viewport>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <a href="gestao/create/cri" style="margin-top: 20px; margin-bottom: 30px" mat-raised-button color="primary">
                            <mat-icon style="margin-left: -10px">add</mat-icon>
                            Cadastrar Novo CRI
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="top-overlay" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>
