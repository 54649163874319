<div class="container" style="margin-top: 5px; max-width: 900px">
    <div class="row" style="margin-top: 0px">
        <table class="parcelas-atraso-table">
            <thead>
                <tr>
                    <th style="min-width: 270px">
                        <app-obras-select class="obras-selector" [obras]="obras" (onObraChange)="onObraChange($event)"> </app-obras-select>
                        <div class="selector-separator"></div>
                        <app-percentage-select class="percentage-selector" (onPercentageChange)="onValueOrPercentageSelect($event)">
                        </app-percentage-select>
                    </th>
                    <th *ngFor="let j of [0, 1, 2, 3, 4]">
                        {{ tableHeader[j] }}
                    </th>
                    <th class="current-month-header">
                        {{ tableHeader[5] }}
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="percentage_select == false">
                <tr *ngFor="let i of [0, 1, 2, 3, 4, 5, 6]">
                    <td class="column-header">{{ columnHeader[i] }}</td>
                    <td *ngFor="let j of [0, 1, 2, 3, 4]">
                        {{ tableData[i][j] }}
                    </td>
                    <td class="current-month-data-cell">
                        {{ tableData[i][5] }}
                    </td>
                    <td>
                        <app-parcelas-atraso-spark-line [data]="tableData[i]"> </app-parcelas-atraso-spark-line>
                    </td>
                </tr>
                <tr>
                    <td class="column-header-6plus">{{ columnHeader[7] }}</td>
                    <td *ngFor="let j of [0, 1, 2, 3, 4]" class="row-6plus">
                        {{ tableData[7][j] }}
                    </td>
                    <td class="current-month-data-cell-6plus">
                        {{ tableData[7][5] }}
                    </td>
                    <td>
                        <app-parcelas-atraso-spark-line class="row-6plus" [data]="tableData[7]"> </app-parcelas-atraso-spark-line>
                    </td>
                </tr>
                <tr>
                    <td class="column-header">{{ columnHeader[8] }}</td>
                    <td *ngFor="let j of [0, 1, 2, 3, 4]">
                        {{ tableData[8][j] }}
                    </td>
                    <td class="current-month-data-cell">
                        {{ tableData[8][5] }}
                    </td>
                    <td>
                        <app-parcelas-atraso-spark-line [data]="tableData[8]"> </app-parcelas-atraso-spark-line>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="percentage_select == true">
                <tr *ngFor="let i of [0, 1, 2, 3, 4, 5, 6]">
                    <td class="column-header">{{ columnHeader[i] }}</td>
                    <td *ngFor="let j of [0, 1, 2, 3, 4]">
                        <app-percentage-value-cell [value]="tableData[i][j]"></app-percentage-value-cell>
                    </td>
                    <td class="current-month-data-cell">
                        <app-percentage-value-cell [value]="tableData[i][5]"></app-percentage-value-cell>
                    </td>
                    <td>
                        <app-parcelas-atraso-spark-line [data]="tableData[i]"> </app-parcelas-atraso-spark-line>
                    </td>
                </tr>
                <tr>
                    <td class="column-header-6plus">{{ columnHeader[7] }}</td>
                    <td *ngFor="let j of [0, 1, 2, 3, 4]" class="row-6plus">
                        <app-percentage-value-cell [value]="tableData[7][j]"></app-percentage-value-cell>
                    </td>
                    <td class="current-month-data-cell-6plus">
                        <app-percentage-value-cell [value]="tableData[7][5]"></app-percentage-value-cell>
                    </td>
                    <td>
                        <app-parcelas-atraso-spark-line class="row-6plus" [data]="tableData[7]"> </app-parcelas-atraso-spark-line>
                    </td>
                </tr>
                <tr>
                    <td class="column-header">{{ columnHeader[8] }}</td>
                    <td *ngFor="let j of [0, 1, 2, 3, 4]">
                        <app-percentage-value-cell [value]="tableData[8][j]"></app-percentage-value-cell>
                    </td>
                    <td class="current-month-data-cell">
                        <app-percentage-value-cell [value]="tableData[8][5]"></app-percentage-value-cell>
                    </td>
                    <td>
                        <app-parcelas-atraso-spark-line [data]="tableData[8]"> </app-parcelas-atraso-spark-line>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
