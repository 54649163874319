<app-navbar></app-navbar>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="bottom-overlay">
    <div [ngClass]="loading ? 'disabled-div' : ''">
        <div *ngIf="canDisplay()">
            <div class="container" style="margin-top: 30px">
                <div class="row">
                    <div class="col-12">
                        <app-h2title [text]="'Envio Individual de Arquivos'"></app-h2title>
                        <button
                            style="padding-bottom: 5px; margin-left: 10px"
                            matTooltipClass="my-tooltip"
                            mat-icon-button
                            matTooltip="{{ getTooltipText(true) }}"
                        >
                            <mat-icon>info</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>Use essa modalidade para enviar arquivos pontuais. Preencha todos os campos e selecione o arquivo que deseja enviar</p>
                    </div>
                </div>
            </div>
            <div>
                <app-single-file-upload [servicers]="servicers" [projetos]="projetos" [fileNames]="fileNames" (onSubmit)="submitFile($event)">
                </app-single-file-upload>
            </div>

            <div class="container" style="margin-top: 75px; margin-bottom: 35px">
                <hr />
            </div>

            <div class="container">
                <div class="row" style="align-items: center; margin-bottom: 15px">
                    <div class="col-12">
                        <app-h2title [text]="'Disparar Envio de Arquivos no Sharepoint'"></app-h2title>
                        <button
                            style="padding-bottom: 5px; margin-left: 10px"
                            matTooltipClass="my-tooltip"
                            mat-icon-button
                            matTooltip="{{ getTooltipText(false) }}"
                        >
                            <mat-icon>info</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>Caso queira enviar todos os arquivos no Sharepoint, faça o disparo por aqui.</p>
                    </div>
                </div>
            </div>
            <div style="margin-bottom: 400px">
                <app-sharepoint-trigger (onSubmit)="triggerUpload($event)" [servicers]="servicers"> </app-sharepoint-trigger>
            </div>
        </div>
    </div>

    <div class="top-overlay" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>
