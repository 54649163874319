import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Breadcrumb } from 'src/app/interfaces/breadcrumb';
import { DashboardProjetoService } from 'src/app/services/dashboard-projeto.service';

@Component({
    selector: 'app-uploadcris',
    templateUrl: './uploadcris.component.html',
    styleUrls: ['./uploadcris.component.css'],
})
export class UploadcrisComponent implements OnInit {
    constructor(private dashboardService: DashboardProjetoService, private _snackBar: MatSnackBar) {}

    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Upload Cris', href: 'gestao/upload_cris' },
    ]

    private ngUnsubscribe = new Subject()

    // Unidades Upload
    public selectedUnidadesFile: any = null
    public filesUnidades: File[] = []
    
    // Fundo Reserva Manual Upload
    public selectedFundoReservaFile: any = null
    public filesFundoReserva: File[] = []
    
    public loadingFileUpload: boolean = false

    ngOnInit(): void {}

    updateUnidadesFile(event: any): void {
        this.filesUnidades = []
        this.filesUnidades.push(event[0])
    }

    sendUnidadesFiles(): void {
        this.loadingFileUpload = true
        let formData = new FormData()

        formData.append('file', this.filesUnidades[0])

        this.dashboardService
            .uploadUnidades(formData)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    let message = returnData.message
                    this.loadingFileUpload = false
                    if (message == 'Upload de unidades concluído com sucesso') {
                        this.openSnackbar('Unidades enviadas com sucesso', 'success-snackbar')
                    } else {
                        this.openSnackbar(
                            'Não foi possível atualizar o arquivo. O arquivo deve conter as colunas: TOTAL ÁREA | ÁREA PRIVATIVA | ÁREA COMUM | UNIDADE | OBRA',
                            'error-snackbar'
                        )
                    }
                },
                (errorData: any) => {
                    this.loadingFileUpload = false
                    this.openSnackbar(
                        'Não foi possível atualizar o arquivo. O arquivo deve conter as colunas: TOTAL ÁREA | ÁREA PRIVATIVA | ÁREA COMUM | UNIDADE | OBRA',
                        'error-snackbar'
                    )
                }
            )
    }

    updateFundoReservaFile(event: any): void {
        this.filesFundoReserva = []
        this.filesFundoReserva.push(event[0])
    }

    sendFundoReservaFiles(): void {
        this.loadingFileUpload = true
        let formData = new FormData()

        formData.append('file', this.filesFundoReserva[0])

        this.dashboardService
            .uploadFundoReserva(formData)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    let message = returnData.message
                    this.loadingFileUpload = false
                    if (message == 'OK') {
                        this.openSnackbar('Fundos de Reserva Manuais enviados com sucesso', 'success-snackbar')
                    } else {
                        this.openSnackbar(
                            'Não foi possível atualizar o arquivo. O arquivo deve conter as colunas: Projeto | Valor | MesBase',
                            'error-snackbar'
                        )
                    }
                },
                (errorData: any) => {
                    this.loadingFileUpload = false
                    this.openSnackbar(
                        'Não foi possível atualizar o arquivo. O arquivo deve conter as colunas: Projeto | Valor | MesBase',
                        'error-snackbar'
                    )
                }
            )
    }

    openSnackbar(message: string, messageType: string): void {
        this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
    }
}
