<app-navbar></app-navbar>
<div *ngIf="success">
    <app-success-alert [text]="'CRI atualizado com sucesso'"></app-success-alert>
</div>
<div *ngIf="error">
    <app-error-alert [text]="errorMessage"></app-error-alert>
</div>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="bottom-overlay">
    <div *ngIf="!loading.length">
        <div style="margin-top: 20px" *ngIf="canDisplay()">
            <app-cri-form
                (onSubmit)="submit($event)"
                (forceUpdate)="forceUpdate()"
                [gruposEconomicos]="gruposEconomicos"
                [regrasArredondamento]="regrasArredondamento"
                [indexadores]="indexadores"
                [carteiras]="carteiras"
                [cri]="cri"
                [municipios]="municipios"
                [municipiosRateios]="municipiosRateios"
                [unidadesFederativas]="unidadesFederativas"
                [mtms]="mtms"
                [projetosCategorias]="projetosCategorias"
                [create]="false"
            >
            </app-cri-form>
        </div>
    </div>
    <div class="top-overlay" *ngIf="loading.length > 0">
        <mat-spinner></mat-spinner>
    </div>
</div>
