<div style="padding: 10px 10px 10px 10px;">
    <h4>Editar Carteira</h4>

    <p>Selecione os campos a serem editados</p>

    <div class="row" style="align-items: center">
        <div class="col-lg-3 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Nome </mat-label>
                <input type="string" matInput placeholder="Nome" [(ngModel)]="carteira.Nome" />
            </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Sigla </mat-label>
                <input type="string" matInput placeholder="" [(ngModel)]="carteira.Sigla" />
            </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> CNPJ </mat-label>
                <input type="string" matInput placeholder="" [(ngModel)]="carteira.CNPJ" />
            </mat-form-field>
        </div>
    </div>
    <div class="row" style="align-items: center">
        <div class="col-lg-3 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> ISIN </mat-label>
                <input type="string" matInput placeholder="ISIN" [(ngModel)]="carteira.ISIN" />
            </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Classificação ANBIMA  </mat-label>
                <input type="string" matInput placeholder="" [(ngModel)]="carteira.ClassANBIMA" />
            </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label>Administrador</mat-label>
                <mat-select [(value)]="carteira.AdministradorID" (selectionChange)="carteira.AdministradorID = $event.value">
                    <mat-option *ngFor="let administrador of administradores" [value]="administrador.ID">
                        {{ administrador.Nome }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-12">
            <app-datepicker
                (onDateChange)="carteira.IPO = $event"
                [date]="carteira.IPO"
                [text]="'Data IPO'"
            ></app-datepicker>
        </div>
    </div>
    <div class="row" style="float: right">
        <button (click)="cancel()" style="margin-right: 20px" mat-raised-button>Cancelar</button>
        <button (click)="save()" mat-raised-button color="primary" [disabled]="isButtonDisabled()">Salvar</button>
    </div>
</div>