import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from 'src/app/interfaces/breadcrumb';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.css']
})
export class CalendarioComponent implements OnInit {

  constructor() { }

  public breadcrumbList: Breadcrumb[] = [
      { text: 'Home', href: '' },
      { text: 'Gestão', href: 'gestao' },
      { text: 'Calendário', href: 'gestao/calendario' },
  ]
  ngOnInit(): void {
  }

  

}
