<app-navbar style="position: fixed; top: 0; z-index: 1030; width: 100%"></app-navbar>
<div style="margin-top: 88px"></div>
<div class="row title" style="align-items: center">
    <div class="col-lg-10">
        <app-h2title [text]="'Tabela de Distribuições'"> </app-h2title>
    </div>
</div>

<div class="row filters">
    <!-- Filtro Carteira -->
    <div class="col-xl-1 col-lg-2">
        <app-carteira-filter-resumo [carteiras]="carteirasOptions" (onCarteiraChange)="filtrarCarteira($event)"></app-carteira-filter-resumo>
    </div>
    <div class="col-xl-3 col-lg-6">
        <app-criselector style="position: relative; top: 15px" [cris]="crisOptions" (onCriChange)="selectCris($event)"></app-criselector>
    </div>
    <div class="col-xl-2 col-lg-2">
        <mat-form-field appearance="fill">
            <mat-label>Data Início</mat-label>
            <input matInput [matDatepicker]="picker1" [(ngModel)]="filters.dataInicio">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-xl-2 col-lg-2">
        <mat-form-field appearance="fill">
            <mat-label>Data Fim</mat-label>
            <input matInput [matDatepicker]="picker2" [(ngModel)]="filters.dataFim">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-lg-4" style="align-items: center;display: flex; text-align: left; padding-left: 0">
        <button mat-raised-button color="primary" style="min-width: 80px;" (click)="onClickBuscar()">Buscar</button>
        <button style="margin-left: 10px; min-width: 110px;" mat-raised-button color="primary" (click)="exportExcel($event)">Exportar <mat-icon> file_download </mat-icon></button>
        <button style="margin-left: 10px; min-width: 180px;" (click)="recalcularDistribuicoes()" mat-raised-button>
            Recalcular Distribuições
        </button>
    </div>
    
</div>
<div class="spinner" *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>
<div *ngIf="dataReady && !loading">
    <div class="row table-container">
        <cdk-virtual-scroll-viewport [itemSize]="10000">
            <table class="tabela" matSort (matSortChange)="sortData($event)">
                <tr>
                    <th mat-sort-header="Carteira">Carteira</th>
                    <th mat-sort-header="CRI">CRI</th>
                    <th mat-sort-header="Data">Data</th>
                    <th mat-sort-header="Saldo">Saldo</th>
                    <th mat-sort-header="Juros">Juros</th>
                    <th mat-sort-header="CM">CM Mês</th>
                    <th mat-sort-header="AmortTotal">Amort Total</th>
                    <th mat-sort-header="CMAcumulada">CM Final</th>
                    <th mat-sort-header="JurosDistribuido">Juros Distribuido</th>
                    <th mat-sort-header="CMDistribuida">CM Distribuida</th>
                    <th mat-sort-header="PercJuros">%Distrib. Juros</th>
                    <th mat-sort-header="PercCM">%Distrib. CM</th>
                    <th mat-sort-header="AmortClean">Amort Clean</th>
                    <th mat-sort-header="TotalDistribuido">Total Distribuído</th>
                </tr>

                <tr *ngFor="let data of distribuicoes">
                    <td class="nome-carteira-column">
                       {{data.Carteira}} 
                    </td>
                    <td class="">
                       {{data.NomeCri}} 
                    </td>
                    <td class="">
                       {{data.Data | customDate }}
                    </td>
                    <td class="align-right">
                       {{data.Saldo}} 
                    </td>
                    <td class="align-right">
                       {{data.Juros.toLocaleString('pt-BR', { maximumFractionDigits: 4 }) }} 
                    </td>
                    <td class="align-right">
                       {{data.CM.toLocaleString('pt-BR', { maximumFractionDigits: 4 }) }} 
                    </td>
                    <td class="align-right">
                       {{data.AmortTotal.toLocaleString('pt-BR', { maximumFractionDigits: 4 }) }} 
                    </td>
                    <td class="align-right">
                       {{data.CMAcumulada.toLocaleString('pt-BR', { maximumFractionDigits: 4 }) }} 
                    </td>
                    <td class="align-right">
                       {{data.JurosDistribuido.toLocaleString('pt-BR', { maximumFractionDigits: 4 }) }} 
                    </td>
                    <td class="align-right">
                       {{data.CMDistribuida.toLocaleString('pt-BR', { maximumFractionDigits: 4 }) }} 
                    </td>
                    <td class="align-right">
                        <input
                            class="azul"
                            type="text"
                            matInput
                            appMatInputFormatedPercentage
                            [value]="data.PercJuros.toString()"
                            [decimalPoints]="4"
                            (output)="setPercJuros(data.ID, $event)"
                        />
                    </td>
                    <td class="align-right">
                       <input
                            class="azul"
                            type="text"
                            matInput
                            appMatInputFormatedPercentage
                            [value]="data.PercCM.toString()"
                            [decimalPoints]="4"
                            (output)="setPercCM(data.ID, $event)"
                        />
                    </td>
                    <td class="align-right">
                       {{data.AmortClean.toLocaleString('pt-BR', { maximumFractionDigits: 4 })}} 
                    </td>
                    <td class="align-right">
                       {{data.TotalDistribuido.toLocaleString('pt-BR', { maximumFractionDigits: 4 })}} 
                    </td>
                </tr>

            </table>
        </cdk-virtual-scroll-viewport>
    </div>
    <div class="row salvar-modificacoes-container">
        <div class="col-1 salvar-modificaoes-button-wrapper">
            <button (click)="salvarModificacoes()" mat-raised-button color="primary" style="float: left">
                Salvar Modificações
            </button>
        </div>
    </div>
</div>

