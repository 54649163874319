import { Injectable } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'
import { RequestService } from './request.service'
import { AppSettings } from 'src/app/constants/AppSettings'

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(public jwtHelper: JwtHelperService, private request: RequestService) {}

    public async isAuthenticated(): Promise<boolean> {
        const token = localStorage.getItem('token')
        var ret = false
        if (token != null) {
            await this.request
                .postRequest(AppSettings.API_ENDPOINT + 'authenticate', { token: token })
                .toPromise()
                .then(
                    (returnData) => {
                        ret = !this.jwtHelper.isTokenExpired(token)
                    },
                    (errorData) => {
                        ret = false
                    }
                )
        } else {
            ret = false
        }

        return ret
    }
}
