import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core'

@Component({
    selector: 'app-tab-desempenho',
    templateUrl: './tab-desempenho.component.html',
    styleUrls: ['./tab-desempenho.component.css'],
})
export class TabDesempenhoComponent implements OnInit {
    @Input('desempenhoTableDataProjeto') desempenhoTableDataProjeto: any[]
    @Input('desempenhoTableDataObras') desempenhoTableDataObras: any[]
    @Input('desempenhoObras') desempenhoObras: string[]
    @Input('desempenhoTipoProjeto') tipoProjeto: string = ''

    public mesBase: string = ''
    public desempenhoTableDataAtual: any[]
    public obra_selected: string
    public inadimplenciaChartData: any[] = []
    public vendasChartData: any[] = []

    public chartHeight: number = 300
    public chartYmin: number = 0
    public customColorsChart: any

    public razaoVendasEstoque: number = 0
    public mediaVendas: number = 0
    public mediaDistratos: number = 0

    constructor(private _cdRef: ChangeDetectorRef) {
        this.desempenhoTableDataProjeto = []
        this.desempenhoTableDataObras = []
        this.desempenhoTableDataAtual = []
        this.desempenhoObras = []
        this.obra_selected = ''
        this.inadimplenciaChartData = []
    }

    ngOnInit(): void {
        this.desempenhoTableDataAtual = this.desempenhoTableDataProjeto
        this.obra_selected = 'Total'
        this.assembleInadimplenciaChartData()
        this.assembleVendasChartData()
        this.setMetricasTable(this.desempenhoTableDataAtual)
        this.mesBase = this.formatMesBase(this.desempenhoTableDataAtual[0].MesBase)

        this.customColorsChart = [
            { name: 'Total', value: '#E71010' }, // Total é sempre mostrado como vermelho no gráfico
        ]
        for (var index = 1; index < this.desempenhoObras.length; index++) {
            var nome_obra = this.desempenhoObras[index]
            var color_dict = { name: nome_obra, value: '#DFD9D9' } // Obras não selecionadas são cinza no gráfico
            this.customColorsChart.push(color_dict)
        }
    }

    onObraChange(obra: any): void {
        if (obra == 'Total' || this.desempenhoObras.length == 2) {
            this.desempenhoTableDataAtual = this.desempenhoTableDataProjeto
            for (var index = 1; index < this.customColorsChart.length; index++) {
                this.customColorsChart[index].value = '#DFD9D9' // Obras não selecionadas são cinza no gráfico
            }
        } else {
            for (var index = 1; index < this.customColorsChart.length; index++) {
                if (this.customColorsChart[index].name == obra) {
                    this.customColorsChart[index].value = '#4C63D8' // Obra selecionada é azul no gráfico
                } else {
                    this.customColorsChart[index].value = '#DFD9D9' // Obras não selecionadas são cinza no gráfico
                }
            }
            this.desempenhoTableDataAtual = this.desempenhoTableDataObras[obra]
        }
        this.customColorsChart = [...this.customColorsChart] // Isso faz com que os valores no gráfico sejam atualizados
        this.setMetricasTable(this.desempenhoTableDataAtual)

        this.obra_selected = obra
    }

    assembleInadimplenciaChartData(): void {
        var chartData: any[] = []
        
        for (var index = 0; index < this.desempenhoObras.length; index++) {
            var nome_obra: any
            nome_obra = this.desempenhoObras[index]
            if (nome_obra == 'Total') {
                var totalProjetoData = { name: nome_obra, series: [{}] }
                totalProjetoData.series.shift()
                for (var k = this.desempenhoTableDataProjeto.length - 1; k > -1; k--) {
                    var mesBaseTotal = this.desempenhoTableDataProjeto[k].MesBase.toString()
                    var inadimplenciaTotal = this.desempenhoTableDataProjeto[k].Inadimplencia * 100
                    var seriesValueTotal = { name: mesBaseTotal, value: inadimplenciaTotal }
                    if (mesBaseTotal != 'N/A') {
                        totalProjetoData.series.push(seriesValueTotal)
                    }
                }

                chartData.push(totalProjetoData)
            } else {
                var obraData = { name: nome_obra, series: [{}] }
                obraData.series.shift()
                for (var j = this.desempenhoTableDataObras[nome_obra].length - 1; j > -1; j--) {
                    var mesBase = this.desempenhoTableDataObras[nome_obra][j].MesBase.toString()
                    var inadimplencia = this.desempenhoTableDataObras[nome_obra][j].Inadimplencia * 100
                    var seriesValue = { name: mesBase, value: inadimplencia }
                    if (mesBase != 'N/A') {
                        obraData.series.push(seriesValue)
                    }
                }

                chartData.push(obraData)
            }
        }
        this.inadimplenciaChartData = chartData
    }

    assembleVendasChartData(): void {
        var chartData: any[] = []

        for (var index = 0; index < this.desempenhoObras.length; index++) {
            var nome_obra: any
            nome_obra = this.desempenhoObras[index]

            if (nome_obra == 'Total') {
                var totalProjetoData = { name: nome_obra, series: [{}] }
                totalProjetoData.series.shift()
                for (var k = this.desempenhoTableDataProjeto.length - 1; k > -1; k--) {
                    var mesBaseTotal = this.desempenhoTableDataProjeto[k].MesBase.toString()
                    var vendasTotal = this.desempenhoTableDataProjeto[k].Vendido * 100
                    var seriesValueTotal = { name: mesBaseTotal, value: vendasTotal }
                    if (mesBaseTotal != 'N/A') {
                        totalProjetoData.series.push(seriesValueTotal)
                    }
                }

                chartData.push(totalProjetoData)
            } else {
                var obraData = { name: nome_obra, series: [{}] }
                obraData.series.shift()
                for (var j = this.desempenhoTableDataObras[nome_obra].length - 1; j > -1; j--) {
                    var mesBase = this.desempenhoTableDataObras[nome_obra][j].MesBase.toString()
                    var vendas = this.desempenhoTableDataObras[nome_obra][j].Vendido * 100
                    var seriesValue = { name: mesBase, value: vendas }
                    if (mesBase != 'N/A') {
                        obraData.series.push(seriesValue)
                    }
                }

                chartData.push(obraData)
            }
        }
        this.vendasChartData = chartData
    }

    setMetricasTable(current_table: any): void {
        if (current_table[0].MesBase == 'N/A' || current_table[1].MesBase == 'N/A') {
            this.razaoVendasEstoque = 0
        } else {
            this.razaoVendasEstoque = (current_table[0].VendasMes - current_table[0].Distratos) / current_table[1].Estoque
        }
        this.mediaVendas = this.calculate_media_vendas(current_table)
        this.mediaDistratos = this.calculate_media_distratos(current_table)
    }

    calculate_media_vendas(current_table: any): number {
        let mediaVendas = 0
        let length = current_table.length
        for (let index = 0; index < current_table.length; index++) {
            if (current_table[index].MesBase == 'N/A') {
                length--
            } else {
                mediaVendas = mediaVendas + current_table[index].VendasMes
            }
        }
        mediaVendas = mediaVendas / length
        return mediaVendas
    }

    calculate_media_distratos(current_table: any): number {
        let mediaDistratos = 0
        let length = current_table.length
        for (let index = 0; index < current_table.length; index++) {
            if (current_table[index].MesBase == 'N/A') {
                length--
            } else {
                mediaDistratos = mediaDistratos + current_table[index].Distratos
            }
        }
        mediaDistratos = mediaDistratos / length
        return mediaDistratos
    }

    formatMesBase(mesBase: number) {
        let mes = mesBase.toString().slice(4, 6)
        let ano = mesBase.toString().slice(0, 4)
        let mesBaseString = mes + '/' + ano

        return mesBaseString
    }
}
