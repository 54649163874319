import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GrupoEconomico } from 'src/app/interfaces/grupo_economico';

@Component({
  selector: 'app-grupo-economico-edit-dialog',
  templateUrl: './grupo-economico-edit-dialog.component.html',
  styleUrls: ['./grupo-economico-edit-dialog.component.css']
})
export class GrupoEconomicoEditDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<GrupoEconomicoEditDialogComponent>) {}

    public grupoEconomico: GrupoEconomico = this.data.grupoEconomico
    public create: boolean = this.data.create

    ngOnInit(): void {
    }

    save(): void {
        const params = {
            grupoEconomico: this.grupoEconomico,
            save: true,
        }
        this.dialogRef.close(params)
    }

    cancel(): void {
        const params = {
            save: false,
        }
        this.dialogRef.close(params)
    }

    isButtonDisabled(): boolean {
        return (this.grupoEconomico.Grupo === '')
    }

}
