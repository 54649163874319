<app-navbar style="position: fixed; top: 0; z-index: 1030; width: 100%"></app-navbar>
<div style="margin-top: 88px"></div>
<div class="row title" style="align-items: center">
    <div class="col-lg-10">
        <app-h2title [text]="'Tabela de Trades'"> </app-h2title>
    </div>
</div>

<div class="row filters">
    <!-- Filtro Carteira -->
    <div class="col-xl-1 col-lg-2">
        <app-carteira-filter-resumo [carteiras]="carteirasOptions" (onCarteiraChange)="filtrarCarteira($event)"></app-carteira-filter-resumo>
    </div>
    <div class="col-xl-3 col-lg-6">
        <app-criselector style="position: relative; top: 15px" [cris]="crisOptions" (onCriChange)="selectCris($event)"></app-criselector>
    </div>
    <div class="col-lg-2">
        <mat-form-field appearance="fill">
            <mat-label>Data Início</mat-label>
            <input matInput [matDatepicker]="picker1" [(ngModel)]="filters.dataInicio">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-lg-2">
        <mat-form-field appearance="fill">
            <mat-label>Data Fim</mat-label>
            <input matInput [matDatepicker]="picker2" [(ngModel)]="filters.dataFim">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-xl-3 col-md-12" style="align-items: center;display: flex;">
        <button style="margin-left: 10px;" mat-raised-button color="primary" (click)="onClickBuscar()">Buscar</button>
        <button style="margin-left: 10px;" mat-raised-button color="primary" (click)="exportExcel($event)">Exportar <mat-icon> file_download </mat-icon></button>
    </div>
</div>
<div class="spinner" *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>
<div *ngIf="dataReady && !loading">
    <div class="row table-container">
        <cdk-virtual-scroll-viewport [itemSize]="10000">
            <table class="tabela" matSort (matSortChange)="sortData($event)">
                <tr>
                    <th mat-sort-header="Carteira">Carteira</th>
                    <th mat-sort-header="CRI">CRI</th>
                    <th mat-sort-header="Data">Data</th>
                    <th mat-sort-header="Saldo">Saldo</th>
                    <th mat-sort-header="CV">CV</th>
                    <th mat-sort-header="Compra">Compra</th>
                    <th mat-sort-header="Venda">Venda</th>
                    <th mat-sort-header="Data">TaxaCompra</th>
                    <th mat-sort-header="Data">TaxaVenda</th>
                    <th mat-sort-header="PUCompra">PU Compra</th>
                    <th mat-sort-header="PUVenda">PU Venda</th>
                    <th mat-sort-header="PUCompraMedio">PU Compra Médio</th>
                    <th mat-sort-header="PUAquisicaoVenda">PU Aquisição Venda</th>
                    <th mat-sort-header="GanhoK">Ganho K</th>
                </tr>

                <tr *ngFor="let data of trades">
                    <td class="nome-carteira-column">
                       {{data.Carteira}} 
                    </td>
                    <td class="">
                       {{data.NomeCri}} 
                    </td>
                    <td class="">
                        {{data.Data | customDate }}
                    </td>
                    <td class="align-right">
                       {{data.Saldo}} 
                    </td>
                    <td class="">
                       {{data.CV}} 
                    </td>
                    <td class="align-right">
                       {{data.Compra}} 
                    </td>
                    <td class="align-right">
                       {{data.Venda}} 
                    </td>
                    <td class="align-right">
                       {{data.TaxaCompra}} 
                    </td>
                    <td class="align-right">
                       {{data.TaxaVenda}} 
                    </td>
                    <td class="align-right">
                       {{data.PUCompra?.toLocaleString('pt-BR', { maximumFractionDigits: 4 })}} 
                    </td>
                    <td class="align-right">
                       {{data.PUVenda?.toLocaleString('pt-BR', { maximumFractionDigits: 4 })}} 
                    </td>
                    <td class="align-right">
                       {{data.PUCompraMedio?.toLocaleString('pt-BR', { maximumFractionDigits: 4 })}} 
                    </td>
                    <td class="align-right">
                       {{data.PUAquisicaoVenda?.toLocaleString('pt-BR', { maximumFractionDigits: 4 })}} 
                    </td>
                    <td class="align-right">
                       {{data.GanhoK?.toLocaleString('pt-BR', { maximumFractionDigits: 4 })}} 
                    </td>
                </tr>
            </table>
        </cdk-virtual-scroll-viewport>
    </div>
</div>