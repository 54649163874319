import { Component, OnInit } from '@angular/core';
import { Emissor } from 'src/app/interfaces/emissor';
import { ResumoNotaCri } from 'src/app/interfaces/resumo_nota_cri';
import { Servicer } from 'src/app/interfaces/servicer';
import { TipoProjeto } from 'src/app/interfaces/tipo_projeto';
import { CriService } from 'src/app/services/cri.service';
import { ProjetosService } from 'src/app/services/projetos.service';
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs';
import { Sort } from '@angular/material/sort'
import { Carteira } from 'src/app/interfaces/carteira';

@Component({
    selector: 'app-resumo-notas',
    templateUrl: './resumo-notas.component.html',
    styleUrls: ['./resumo-notas.component.css'],
})
export class ResumoNotasComponent implements OnInit {
    private ngUnsubscribe = new Subject()

    constructor(private projetoService: ProjetosService, private criService: CriService) {}

    // Table Data
    public tableData: ResumoNotaCri[] = []
    public filteredData: ResumoNotaCri[] = []

    // loading
    public loading: boolean[] = []

    // filtros
    public tipos: TipoProjeto[] = []
    public securitizadoras: Emissor[] = []
    public servicers: Servicer[] = []
    public carteiras: Carteira[] = []

    public filteredServicersIDs: number[] = []
    public filteredSecuritizadorasIDs: number[] = []
    public filteredTiposIDs: number[] = []
    public filteredCarteiras: number[] = []

    // Seleção do Mês Base
    public mesBaseSelected: string = ''
    public mesesBase: string[] = []

    // Filtro
    public filterValue: string = ''

    ngOnInit(): void {
        for (let i = 0; i < 5; i++) {
            this.loading.push(true)
        }
        this.getMesesBase()
        this.getServicers()
        this.getSecuritizadora()
        this.getTipos()
        this.getCarteiras()
    }

    getMesesBase() {
        this.projetoService
            .getMesesBaseNotas()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.mesesBase = [...returnData.meses_base]
                    if (this.mesesBase.length == 0) {
                        this.loading.pop()
                        return
                    }
                    this.mesBaseSelected = this.mesesBase[0]
                    this.getResumoNotasData(this.mesBaseSelected)
                },
                (errorData: any) => {}
            )
    }

    getResumoNotasData(mesBase: string) {
        this.projetoService
            .getNotasResumo(mesBase)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    console.log(returnData)
                    this.tableData = [...returnData.notas_resumo].slice()
                    this.filteredData = [...returnData.notas_resumo].slice()
                    // const aux_table = this.filteredData.map((obj) => {
                    //     return { ...obj, Loading: false }
                    // })
                    this.loading.pop()

                    this.filtrarCarteira([2])
                },
                (errorData: any) => {
                    this.loading.pop()
                    console.log(errorData)
                }
            )
    }

    getServicers() {
        this.projetoService
            .getServicers()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.servicers = returnData.servicers
                    this.filteredServicersIDs = this.servicers.map((obj) => obj.id)
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getSecuritizadora(): void {
        this.criService
            .getEmissores()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.securitizadoras = returnData.emissores
                    this.filteredSecuritizadorasIDs = this.securitizadoras.map((obj) => obj.EmissorID)
                    this.loading.pop()
                },
                (errorData) => this.loading.pop()
            )
    }

    getCarteiras(){
        this.projetoService
            .getCarteiras()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.carteiras = returnData.carteiras
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getTipos() {
        this.projetoService
            .getTiposProjeto()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.tipos = returnData.tipos
                    this.filteredTiposIDs = this.tipos.map((obj) => obj.TipoID)
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    filtrarTipo(tipoIDs: any) {
        if (tipoIDs.length == 0) {
            this.filteredTiposIDs = this.tipos.map((obj) => obj.TipoID)
        } else {
            this.filteredTiposIDs = tipoIDs
        }
        this.filtrarTabela()
    }

    filtrarSecuritizadora(securitizadoraIDs: any) {
        if (securitizadoraIDs.length == 0) {
            this.filteredSecuritizadorasIDs = this.securitizadoras.map((obj) => obj.EmissorID)
        } else {
            this.filteredSecuritizadorasIDs = securitizadoraIDs
        }
        this.filtrarTabela()
    }

    filtrarCarteira(carteiras: any) {
        if (carteiras.length == 0) {
            this.filteredCarteiras = []
        } else {
            this.filteredCarteiras = carteiras
        }
        this.filtrarTabela()
    }

    filtrarServicer(servicerIDs: any) {
        if (servicerIDs.length == 0) {
            this.filteredServicersIDs = this.servicers.map((obj) => obj.id)
        } else {
            this.filteredServicersIDs = servicerIDs
        }
        this.filtrarTabela()
    }

    filtrarTabela() {
        this.filteredData = this.tableData.filter((linha_resumo) => {
            const securitizadoraID = linha_resumo.SecuritizadoraID
            const servicerID = linha_resumo.ServicerID
            const tipoID = linha_resumo.TipoProjetoID
            const carteiraID = linha_resumo.CarteiraID
            console.log("carteiraID", carteiraID)
            return (
                this.filteredServicersIDs.includes(servicerID) &&
                this.filteredSecuritizadorasIDs.includes(securitizadoraID) &&
                this.filteredTiposIDs.includes(tipoID) &&
                this.filteredCarteiras.includes(carteiraID)
            )
        })
    }

    changeMesBase(event: any) {
        this.mesBaseSelected = event.value
        this.loading.push(true)
        this.getResumoNotasData(this.mesBaseSelected)
    }

    sortData(sort: Sort) {
        const data = this.filteredData.slice()
        if (!sort.active || sort.direction === '') {
            this.filteredData = data
            return
        }

        this.filteredData = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc'
            switch (sort.active) {
                case 'Chave':
                    return compare(a.Chave, b.Chave, isAsc)
                case 'MesBaseProjeto':
                    return compare(a.MesBaseProjeto, b.MesBaseProjeto, isAsc)
                case 'Nota':
                    return compare(a.Nota, b.Nota, isAsc)
                case 'Rating':
                    return compare(a.Rating, b.Rating, isAsc)
                default:
                    return 0
            }
        })
    }

    convertNotaToBase10(nota: number) {
        let notaBase10 = (nota * 10 - 10) / (4 - 1)
        return notaBase10.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    }

    filtrar(event: any) {
        const filterString = this.normalizar_string(event.target.value)
        console.log(filterString)
        this.filteredData = this.tableData.filter((obj) => {
            return this.normalizar_string(obj.Chave).includes(filterString)
        })
    }

    normalizar_string(str: string) {
        return str
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
    }
}
  
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1)
}