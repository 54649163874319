import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
    constructor(private router: Router) {}

    navigate(event: any, path: string): void {
        event.preventDefault()
        this.router.navigate([path])
    }

    logOut(event: any) {
        event.preventDefault()
        localStorage.removeItem('token')
        this.router.navigate(['login'])
    }
    ngOnInit(): void {}
}
