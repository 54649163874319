import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'app-desbloquear-tabela-dialog',
    templateUrl: './desbloquear-tabela-dialog.component.html',
    styleUrls: ['./desbloquear-tabela-dialog.component.css'],
})
export class DesbloquearTabelaDialogComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DesbloquearTabelaDialogComponent>) {}
    ngOnInit(): void {}

    save(): void {
        const params = {
            save: true,
        }
        this.dialogRef.close(params)
    }

    cancel(): void {
        const params = {
            save: false,
        }
        this.dialogRef.close(params)
    }
}
