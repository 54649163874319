import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { ExpandedCri } from 'src/app/interfaces/expanded_cri'
import { Emissor } from '../interfaces/emissor'
import { GrupoEconomico } from '../interfaces/grupo_economico'

@Injectable({
    providedIn: 'root',
})
export class CriService {
    constructor(private http: RequestService) {}

    private controladoriaBlueprintEndpoint: string = this.http.apiEndpoint + 'controladoria/'

    public getCris(data: any) {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'cris', data)
    }

    public getCrisPUsOnDate(data: any) {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'pus_on_date', data)
    }

    public getCriPu(data: any) {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'pu', data)
    }

    public calculatePuCurva(data: any) {
        return this.http.postRequest(this.controladoriaBlueprintEndpoint + 'pu_curva', data)
    }

    public calculatePuMtm(data: any) {
        return this.http.postRequest(this.controladoriaBlueprintEndpoint + 'pu_mtm', data)
    }

    public getMtmDates() {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'mtm_dates', {})
    }

    public getMtmOnDate(data: any) {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'mtm_on_date', data)
    }

    public getCri(data: any) {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'cri', data)
    }

    public getEmissores() {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'emissores', {})
    }

    public updateEmissor(emissor: Emissor){
        let formData = new FormData()
        formData.append('emissor', JSON.stringify(emissor))
        return this.http.putRequest(this.controladoriaBlueprintEndpoint + 'emissores/update', formData)
    }

    public createEmissor(emissor: any){
        let formData = new FormData()
        formData.append('emissor', JSON.stringify(emissor))
        return this.http.postRequest(this.controladoriaBlueprintEndpoint + 'emissores/create', formData)
    }

    public getMtms() {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'mtms', {})
    }

    public getRegrasArredondamento() {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'regras_arredondamento', {})
    }

    public getTiposImovel() {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'tipos_imoveis', {})
    }

    public getTiposAtivo() {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'tipos_ativos', {})
    }

    public getGruposEconomicos() {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'grupos_economicos', {})
    }

    public updateGrupoEconomico(grupoEconomico: GrupoEconomico){
        let formData = new FormData()
        formData.append('grupo_economico', JSON.stringify(grupoEconomico))
        return this.http.putRequest(this.controladoriaBlueprintEndpoint + 'grupos_economicos/update', formData)
    }

    public createGrupoEconomico(grupoEconomico: any){
        let formData = new FormData()
        formData.append('grupo_economico', JSON.stringify(grupoEconomico))
        return this.http.postRequest(this.controladoriaBlueprintEndpoint + 'grupos_economicos/create', formData)
    }

    public getEstruturacoes() {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'estruturacoes', {})
    }

    public getLiberacoes(data: any) {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'liberacoes', data)
    }

    public getCompras(data: any) {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'compras', data)
    }

    public getDatasPagamentoCri(data: any) {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'datas_pagamento', data)
    }

    public getIndexadoresCri(data: any) {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'indexadores_cri', data)
    }

    public getTIRRCri(data: any) {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'tirrs_cri', data)
    }

    public updateCri(cri: ExpandedCri) {
        let formData = new FormData()
        formData.append('cri', JSON.stringify(cri))

        return this.http.putRequest(this.controladoriaBlueprintEndpoint + 'cri/update', formData)
    }

    public createCri(cri: ExpandedCri) {
        let formData = new FormData()
        formData.append('cri', JSON.stringify(cri))

        return this.http.postRequest(this.controladoriaBlueprintEndpoint + 'cri/create', formData)
    }

    public calculateDuration(data: any) {
        return this.http.postRequest(this.controladoriaBlueprintEndpoint + 'duration', data)
    }

    public getLiberacoesFuturas(){
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'liberacoes_futuras', {})
    }

    public getDistribuicoes(params: any){
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'distribuicoes/get', params)
    }
    
    public editInputDistribuicoes(distribuicaoInputs: any){

        return this.http.postRequest(this.controladoriaBlueprintEndpoint + 'distribuicoes_inputs/edit', distribuicaoInputs)
    }

    public calculateDistribuicoes(data: any) {
        return this.http.postRequest(this.controladoriaBlueprintEndpoint + 'distribuicoes/calculate', data)
    }
    
    public getTrades(params: any){
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'trades/get', params)
    }
}
