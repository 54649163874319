<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label>Nome</mat-label>
                <input matInput placeholder="Nome do CRI" [(ngModel)]="cri.Nome" />
            </mat-form-field>
        </div>
        <div class="col-lg-4 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label>Código IF</mat-label>
                <input matInput placeholder="Código IF do CRI" [(ngModel)]="cri.CodigoIF" />
            </mat-form-field>
        </div>
        <div class="col-lg-4 col-md-12">
            <app-projeto-categoria-autocomplete
                (onProjCatChange)="cri.ProjetoCategoriaID = $event"
                [projetosCategorias]="projetosCategorias"
                [projetoCategoriaId]="cri.ProjetoCategoriaID"
            >
            </app-projeto-categoria-autocomplete>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label>Número da Emissão</mat-label>
                <input type="number" matInput placeholder="Emissão do CRI" [(ngModel)]="cri.EmissaoNro" />
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label>Número da Série</mat-label>
                <input type="number" matInput placeholder="Série do CRI" [(ngModel)]="cri.SerieNro" />
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> TIRR (%) </mat-label>
                <input type="number" matInput placeholder="TIRR" [(ngModel)]="cri.TIRR" />
                <mat-hint align="start"
                    ><strong>{{ cri.TIRR }}%</strong>
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <app-mtm-select (onMtmChange)="cri.MtmID = $event" [mtms]="mtms" [mtmId]="cri.MtmID"></app-mtm-select>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label>Lastro</mat-label>
                <input matInput placeholder="Lastro" [(ngModel)]="cri.Lastro" />
            </mat-form-field>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> PU Inicial </mat-label>
                <input type="number" matInput placeholder="PU Inicial" [(ngModel)]="cri.PUInicial" />
                <mat-hint align="start"
                    ><strong>{{ cri.PUInicial ? cri.PUInicial.toLocaleString() : '' }}</strong>
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Dia da PMT (Dia do Mês) </mat-label>
                <input type="number" matInput placeholder="Dia da PMT" [(ngModel)]="cri.DiaDoMesPmt" />
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Primeiro DCT </mat-label>
                <input type="number" matInput placeholder="Quantidade de dias no primeiro ciclo" [(ngModel)]="cri.PrimeiroDCT" />
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <app-regra-arredondamento-select
                [regrasArredondamento]="regrasArredondamento"
                [regraArredondamentoId]="cri.ArredRegraID"
                (onRegraChange)="cri.ArredRegraID = $event"
            >
            </app-regra-arredondamento-select>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label>Regra Fator de Juros</mat-label>
                <mat-select [(value)]="cri.FatorJurosRegra" (selectionChange)="cri.FatorJurosRegra = $event.value">
                    <mat-option *ngFor="let number of [1,2]" [value]="number">
                        {{ number }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label>Regra Correção Monetária</mat-label>
                <mat-select [(value)]="cri.CorrecaoMonetariaRegra" (selectionChange)="cri.CorrecaoMonetariaRegra = $event.value">
                    <mat-option *ngFor="let number of [1,2]" [value]="number">
                        {{ number }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
<!-- Municipios -->
<div class="container" *ngIf='this.municipiosRateios.length > 0' >
    <br /><br />
    <h6>Municípios</h6>
    <br />
    <div class="row municipio-row" *ngFor="let obj of municipiosRateios">
        <div class="col-md-3 municipio">
            {{ renderCidadeNome(obj.CidadeID) }}
        </div>
        <div class="col-md-3">
            {{ (obj.Rateio * 100).toLocaleString() +' %' }}
        </div>
        <div class="col-md-3">
            <button mat-icon-button (click)="openCidadeDialog(false, obj)">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
    </div>
</div>
<div class="container">
    <div class="row soma-message" *ngIf="testSomaMunicipiosRateios()">
        *Atenção: a soma dos percentuais dos municípios não é 100%
    </div>
</div>
<div class="container">
    <div class="row add-municipio-button">
        <button mat-icon-button (click)="openCidadeDialog(true)">
            <mat-icon>add</mat-icon> Adicionar Município
        </button>
    </div>
</div>

<br /><br />

<!-- Datas -->
<div class="container">
    <div class="row">
        <div class="col-lg-3 col-md-12">
            <app-datepicker (onDateChange)="cri.DataEmissao = $event" [date]="cri.DataEmissao" [text]="'Data de Emissão'"></app-datepicker>
        </div>
        <div class="col-lg-3 col-md-12">
            <app-datepicker (onDateChange)="cri.DataVencto = $event" [date]="cri.DataVencto" [text]="'Data de Vencimento'"></app-datepicker>
        </div>
        <div class="col-lg-3 col-md-12">
            <app-datepicker
                (onDateChange)="cri.DataPrimeiroPmt = $event"
                [date]="cri.DataPrimeiroPmt"
                [text]="'Data da Primeira PMT'"
            ></app-datepicker>
        </div>
        <div class="col-lg-3 col-md-12">
            <app-datepicker (onDateChange)="cri.DataInicioPU = $event" [date]="cri.DataInicioPU" [text]="'Data de Início PU'"></app-datepicker>
        </div>
    </div>
</div>

<div class="container">
    <div class="row" style="align-items: center">
        <div class="col-lg-4 col-md-6">
            <mat-slide-toggle [color]="'primary'" [(ngModel)]="cri.PodeTerCorrNeg"> Pode ter correção negativa? </mat-slide-toggle>
        </div>
        <div class="col-lg-2 col-md-6">
            <mat-slide-toggle [color]="'primary'" [(ngModel)]="cri.IsDU"> Dia Útil? </mat-slide-toggle>
        </div>
        <div class="col-lg-2 col-md-6">
            <mat-slide-toggle [color]="'primary'" [(ngModel)]="cri.Opcional"> Opcional? </mat-slide-toggle>
        </div>
        <div class="col-lg-4 col-md-6">
            <mat-slide-toggle [color]="'primary'" [(ngModel)]="cri.AcumulaPrimeiroAno"> Acumula 1º ano? </mat-slide-toggle>
        </div>
    </div>
</div>
<br /><br />

<!-- Liberações -->
<div class="container">
    <h6>Liberações</h6>
    <div class="row" style="align-items: center" *ngFor="let liberacao of cri.Liberacoes; let i = index">
        <div class="col-lg-4 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Data Vigência </mat-label>
                <input type="date" matInput placeholder="Data Vigência" [(ngModel)]="liberacao.Data" />
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Quantidade </mat-label>
                <input type="number" matInput placeholder="Quantidade" [(ngModel)]="liberacao.Qtde" />
            </mat-form-field>
        </div>

        <div class="col-lg-1 col-md-12">
            <button (click)="deleteLiberacaoFormField(i)" mat-icon-button class="delete-button"><mat-icon>delete_outline</mat-icon></button>
        </div>
    </div>
    <div class="row" style="align-items: center">
        <div class="col-lg-1 col-md-12">
            <button (click)="createLiberacaoFormField()" mat-raised-button class="create-button">+</button>
        </div>
    </div>
</div>
<br />

<!-- Compras -->
<div class="container">
    <h6>Compras e Vendas</h6>
    <div class="row" style="align-items: center" *ngFor="let compra of cri.ComprasCri; let i = index">
        <div class="col-lg-2 col-md-12">
            <app-carteira-select
                (onCarteiraChange)="compra.CarteiraID = $event"
                [carteiraId]="compra.CarteiraID"
                [carteiras]="carteiras"
            >
            </app-carteira-select>
        </div>
        <div class="col-lg-10"></div>
        <div class="col-lg-3 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Data Compra </mat-label>
                <input type="date" matInput placeholder="Data Compra" [(ngModel)]="compra.Data" />
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Quantidade </mat-label>
                <input type="number" matInput placeholder="Quantidade" [(ngModel)]="compra.Qtde" />
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> PU </mat-label>
                <input type="number" matInput placeholder="PU" [(ngModel)]="compra.PU" />
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Taxa Compra</mat-label>
                <input type="number" matInput placeholder="Taxa Compra" [(ngModel)]="compra.TaxaCompra" />
            </mat-form-field>
        </div>

        <div class="col-lg-2"></div>
        
        <div class="col-lg-3 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Data Venda </mat-label>
                <input type="date" matInput placeholder="Data" [(ngModel)]="compra.DataVenda" />
            </mat-form-field>
        </div>

        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Quantidade Venda </mat-label>
                <input type="number" matInput placeholder="Venda" [(ngModel)]="compra.QtdeVenda" />
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> PU Venda </mat-label>
                <input type="number" matInput placeholder="PU Venda" [(ngModel)]="compra.PuVenda" />
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Taxa Venda</mat-label>
                <input type="number" matInput placeholder="Taxa Venda" [(ngModel)]="compra.TaxaVenda" />
            </mat-form-field>
        </div>

        <div class="col-lg-1 col-md-12">
            <button (click)="deleteCompraFormField(i)" mat-icon-button class="delete-button"><mat-icon>delete_outline</mat-icon></button>
        </div>
    </div>
    <div class="row" style="align-items: center">
        <div class="col-lg-1 col-md-12">
            <button (click)="createCompraFormField()" mat-raised-button class="create-button">+</button>
        </div>
    </div>
</div>
<br />


<!-- Indexadores -->
<div class="container">
    <h6>Indexadores</h6>
    <div class="row" style="align-items: center" *ngFor="let indexadorCri of cri.IndexadoresCri; let i = index">
        <div class="col-lg-4 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Data Vigência </mat-label>
                <input type="date" matInput placeholder="Data Vigência" [(ngModel)]="indexadorCri.DataVigencia" />
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <app-indexador-select
                (onIndexadorChange)="indexadorCri.IndexMensalID = $event"
                [indexadorId]="indexadorCri.IndexMensalID"
                [indexadores]="indexadores"
            >
            </app-indexador-select>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Offset </mat-label>
                <input type="number" matInput placeholder="Offset" [(ngModel)]="indexadorCri.IndexOffset" />
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Mês Correção </mat-label>
                <input type="number" matInput placeholder="Mês Correção" [(ngModel)]="indexadorCri.MesCorrecao" />
            </mat-form-field>
        </div>

        <div class="col-lg-1 col-md-12">
            <button (click)="deleteIndexadorFormField(i)" mat-icon-button class="delete-button"><mat-icon>delete_outline</mat-icon></button>
        </div>
    </div>
    <div class="row" style="align-items: center">
        <div class="col-lg-1 col-md-12">
            <button (click)="createIndexadorCriFormField()" mat-raised-button class="create-button">+</button>
        </div>
    </div>
</div>
<br />

<!-- TIRR -->
<div class="container">
    <h6>TIRR</h6>
    <div class="row" style="align-items: center" *ngFor="let TIRR of cri.TIRRs; let i = index">
        <div class="col-lg-4 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> Data Vigência </mat-label>
                <input type="date" matInput placeholder="Data Vigência" [(ngModel)]="TIRR.DataVigencia" />
            </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label> TIRR </mat-label>
                <input type="number" matInput placeholder="TIRR" [(ngModel)]="TIRR.TIRR" />
            </mat-form-field>
        </div>

        <div class="col-lg-1 col-md-12">
            <button (click)="deleteTIRRFormField(i)" mat-raised-button class="delete-button"><mat-icon>delete_outline</mat-icon></button>
        </div>
    </div>
    <div class="row" style="align-items: center">
        <div class="col-lg-1 col-md-12">
            <button (click)="createTIRRFormField()" mat-raised-button class="create-button">+</button>
        </div>
    </div>
</div>
<br />

<!-- Datas de Pagamento -->
<div *ngIf="create == false">
    <div class="container">
        <h6>Datas de Pagamento</h6>
        <br />
        <cdk-virtual-scroll-viewport [itemSize]="60">
            <div class="row">
                <div class="col-lg-12">
                    <table id="customTable" class="tabela-data-pagamentos">
                        <tr>
                            <th>Data</th>
                            <th>TaxaAmortOrd</th>
                            <th>TaxaAmortExtra</th>
                            <th>HasAmort</th>
                            <th>HasJuros</th>
                            <th>IsAniversario</th>
                            <th></th>
                        </tr>
                        <tr *ngFor="let dataPagamento of cri.DatasPagamento; let i = index">
                            <td>
                                <input type="string" placeholder="Data" [(ngModel)]="dataPagamento.Data" />
                            </td>
                            <td>
                                <input type="number" placeholder="TaxaAmortOrd" [(ngModel)]="dataPagamento.TaxaAmortOrd" />
                            </td>
                            <td>
                                <input type="number" placeholder="TaxaAmortExtra" [(ngModel)]="dataPagamento.TaxaAmortExtra" />
                            </td>
                            <td>
                                <input type="number" matInput placeholder="HasAmort" [(ngModel)]="dataPagamento.HasAmort" />
                            </td>
                            <td>
                                <input type="number" matInput placeholder="HasJuros" [(ngModel)]="dataPagamento.HasJuros" />
                            </td>
                            <td>
                                <input type="number" matInput placeholder="IsAniversario" [(ngModel)]="dataPagamento.IsAniversario" />
                            </td>
                            <td>
                                <button (click)="deleteDataPagamaneto(i)" mat-icon-button>
                                    <mat-icon>delete_outline</mat-icon>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button (click)="createDataPagamaneto()" mat-icon-button>
                                    <mat-icon>add</mat-icon>
                                </button>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
</div>

<div class="container" style="margin-bottom: 70px;">
    <div class="row" style="float: right">
        <div class="col-12">
            <button (click)="submit()" mat-raised-button color="primary">Salvar Atualizações</button>
        </div>
    </div>
</div>
