import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Administrador } from 'src/app/interfaces/administrador';
import { Breadcrumb } from 'src/app/interfaces/breadcrumb';
import { ProjetosService } from 'src/app/services/projetos.service'
import { AdministradorEditDialogComponent } from './administrador-edit-dialog/administrador-edit-dialog.component';

@Component({
  selector: 'app-administrador-list',
  templateUrl: './administrador-list.component.html',
  styleUrls: ['./administrador-list.component.css']
})
export class AdministradorListComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public projetoService: ProjetosService) {
  }
  private ngUnsubscriber = new Subject()
  
  public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Administradores', href: 'gestao/list/administradores' },
  ]

  public loading: boolean = false
  public administradores: Administrador[] = []

  createAdministradorDialog(): void {
    const administradorDialog = this.dialog.open(AdministradorEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            administrador: {ID:-1, Nome:""},
        },
    })

    administradorDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading = true
          this.createAdministrador(params.administrador)
        }
        return
    })
  }

  createAdministrador(administrador:Administrador):void{
    let { ID, ...administradorWithoutID} = administrador
    this.projetoService
        .createAdministrador(administradorWithoutID)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'Administrador criado com sucesso') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar administrador', 'error-snackbar')
                }
                this.getAdministradores()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar administrador' + errorData.error.message, 'error-snackbar')
              this.getAdministradores()
            }
        )
  }

  updateAdministradorDialog(administrador: any): void {
    const administradorDialog = this.dialog.open(AdministradorEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            administrador: administrador,
            create: false,
        },
    })

    administradorDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading = true
          this.updateAdministrador(params.administrador)
        }
        return
    })
  }

  updateAdministrador(administrador:Administrador):void{
    this.projetoService
        .updateAdministrador(administrador)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'Administrador atualizado com sucesso') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar administrador', 'error-snackbar')
                }
                this.getAdministradores()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar administrador' + errorData.error.message, 'error-snackbar')
              this.getAdministradores()
            }
        )
  }

  ngOnInit(): void {
    this.loading = true
    this.getAdministradores()
  }

  getAdministradores():void{
    this.projetoService
        .getAdministradores()
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe((returnData: any) => {
            console.log(returnData)
            this.administradores = returnData.administradores
            this.loading = false
        })
  }

  openSnackbar(message: string, messageType: string): void {
    this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
  }

}
