import { Component, OnInit, Input } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Subject } from 'rxjs'
import { AppSettings } from 'src/app/constants/AppSettings'
import { DictCard } from 'src/app/interfaces/dictcard'
import { Evento } from 'src/app/interfaces/eventos'
import { EventosDialogComponent } from './eventos-dialog/eventos-dialog.component'

@Component({
    selector: 'app-sidenav-dashboard',
    templateUrl: './sidenav-dashboard.component.html',
    styleUrls: ['./sidenav-dashboard.component.css'],
})
export class SidenavDashboardComponent implements OnInit {
    @Input('resumoEmissaoApiData') resumoEmissaoApiData: any
    @Input('projetoID') projetoID: number = -1

    private ngUnsubscribe = new Subject()
    public projetoDictCardList: DictCard[] = []
    public emissaoDictCardList: DictCard[] = []
    public dataBase: string = ''
    public eventos: Evento[] = []
    public obras: any[] = []
    public cris: any[] = []
    public hasLiberacoesFuturas: Boolean = false
    constructor(public dialog: MatDialog, private _snackBar: MatSnackBar) {}

    formatString(input: string): string {
        const appSettings = new AppSettings()
        return appSettings.formatString(input)
    }

    ngOnInit(): void {
        this.projetoDictCardList = this.resumoEmissaoApiData.projeto_data
        this.emissaoDictCardList = this.resumoEmissaoApiData.emissao_data
        this.dataBase = this.resumoEmissaoApiData.data_base
        this.eventos = this.resumoEmissaoApiData.eventos
        this.obras = this.resumoEmissaoApiData.obras
        this.cris = this.resumoEmissaoApiData.cris
        for (let i = 0; i < this.cris.length; i++) {
            if (this.cris[i].LiberacoesFuturas.length > 0) {
                this.hasLiberacoesFuturas = true
            }
        }

        let datas = this.eventos.map((obj) => obj.Data)
        if (this.projectHasEventsInCurrentMonth(datas)) {
            this.openSnackbar('O CRI contém eventos nesse mês', 'warning')
        }
    }

    projectHasEventsInCurrentMonth(datas: any): boolean {
        let currentDate = new Date()

        for (let data of datas) {
            let data_obj = new Date(data)

            if (currentDate.getMonth() == data_obj.getMonth() && currentDate.getFullYear() == data_obj.getFullYear()) {
                return true
            }
        }
        return false
    }

    openEventosDialog(): void {
        const eventosDialog = this.dialog.open(EventosDialogComponent, {
            width: '70%',
            height: 'auto',
            autoFocus: false,
            position: {
                top: '80px',
            },
            data: {
                eventos: this.eventos,
                projetoID: this.projetoID,
            },
        })

        eventosDialog.afterClosed().subscribe((result) => {
            var params = result
            this.eventos = [...params.eventos]
            return
        })
    }

    openSnackbar(message: string, messageType: string): void {
        this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
    }
}
