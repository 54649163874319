<form class="example-form">
    <mat-form-field appearance="standard">
        <mat-label>CRIs</mat-label>
        <input type="text" placeholder="Selecione um CRI" matInput [formControl]="projetoControl" [matAutocomplete]="autoProjeto" />
        <mat-autocomplete autoActiveFirstOption #autoProjeto="matAutocomplete" (optionSelected)="changeProjeto($event.option.value)">
            <mat-option *ngFor="let project of filteredProjetos | async" [value]="project">
                {{ project }}
            </mat-option>
        </mat-autocomplete>
        <button mat-icon-button matSuffix (click)="clearProjeto()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
</form>
