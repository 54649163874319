import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { Component, ElementRef, ViewChild, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { Cri } from 'src/app/interfaces/cri'

@Component({
    selector: 'app-criselector',
    templateUrl: './criselector.component.html',
    styleUrls: ['./criselector.component.css'],
})
export class CriselectorComponent implements OnInit {
    @Input('cris') allCris: Cri[]
    @Output('onCriChange') criChange = new EventEmitter()

    visible = true
    selectable = true
    removable = true
    separatorKeysCodes: number[] = [ENTER, COMMA]
    criControl = new FormControl()

    declare filteredCris: Observable<string[]>
    selectedCris: Cri[] = []

    @ViewChild('criInput') declare criInput: ElementRef<HTMLInputElement>
    @ViewChild('auto') declare matAutocomplete: MatAutocomplete

    constructor() {
        this.allCris = []
        this.filteredCris = this.criControl.valueChanges.pipe(
            startWith(''),
            map((val: string | null) =>
                val
                    ? this._filter(val)
                    : this.allCris
                          .map((cri) => cri.Nome)
                          .sort()
                          .slice()
            )
        )
    }

    remove(cri: Cri): void {
        const index = this.selectedCris.indexOf(cri)
        this.selectedCris.splice(index, 1)[0]

        this.emit()
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const criName = event.option.viewValue

        if (!this.selectedCris.map((cri) => cri.Nome).includes(criName)) {
            const index = this.allCris.map((cri) => cri.Nome).indexOf(criName)
            const criTemp = this.allCris[index]
            this.selectedCris.push(criTemp)
            this.emit()
        }

        this.criInput.nativeElement.value = ''
        this.criControl.setValue(null)
    }

    emit(): void {
        this.criChange.emit(this.selectedCris.map((cri) => cri.CRIID))
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase()
        return this.allCris
            .map((cri) => cri.Nome)
            .sort()
            .filter((cri) => cri.toLowerCase().includes(filterValue))
    }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['allCris'] && !changes['allCris'].firstChange) {
            this.selectedCris = [];
            this.criInput.nativeElement.value = ''; 
            this.criControl.setValue(null);
            this.emit(); 
        }
    }
}
