import { Injectable } from '@angular/core'
import { RequestService } from './request.service'

@Injectable({
    providedIn: 'root',
})
export class ContratosService {
    constructor(private http: RequestService) {}

    private contratosBlueprintEndpoint: string = this.http.apiEndpoint + 'gestao/contratos/'

    public getContratosFluxoFuturo(data: any) {
        return this.http.getRequest(this.contratosBlueprintEndpoint + 'fluxo_futuro', data)
    }
}
