import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core'

@Component({
    selector: 'app-cri-filter',
    templateUrl: './cri-filter.component.html',
    styleUrls: ['./cri-filter.component.css'],
})
export class CriFilterComponent implements OnInit, OnChanges {
    @Input('cris') cris: any[] = []
    @Input('selectedCarteiras') selectedCarteiras: string[] = []
    @Output() onCriChange = new EventEmitter()

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges(changes: any): void {
        if ('selectedCarteiras' in changes && !changes.selectedCarteiras.firstChange) {
            this.onCriChange.emit(this.filterByCarteira())
        }
    }

    public criName = ''

    clearCri(): void {
        this.criName = ''
        if (this.selectedCarteiras.length > 0) this.onCriChange.emit(this.filterByCarteira())
        else this.onCriChange.emit(this.cris)
    }

    filterByCriName(event: any) {
        this.criName = event.target.value
        var cris = this.cris
        if (this.selectedCarteiras.length > 0) cris = this.filterByCarteira()

        if (this.criName.length > 0) {
            cris = cris.filter((cri) => cri.Nome.toLowerCase().includes(this.criName.toLowerCase()))
        }
        this.onCriChange.emit(cris)
    }

    filterByCarteira() {
        return this.cris.filter((cri) => this.selectedCarteiras.includes(cri.Carteira))
    }
}
