<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <mat-form-field appearance="standard">
                <mat-label>Nome do Empreendimento</mat-label>
                <input matInput [(ngModel)]="empreendimento.Sigla" />
            </mat-form-field>
        </div>
        <div class="col-lg-4 col-md-12">
            <app-projetos
                [projetoId]="empreendimento.ProjetoID"
                (onProjetoChange)="empreendimento.ProjetoID = $event"
                [projetos]="projetos"
            ></app-projetos>
        </div>
        <div class="col-lg-4 col-md-4">
            <mat-form-field appearance="standard">
                <mat-label>VGV</mat-label>
                <input type="number" matInput [ngModel]="empreendimento.VGV" (ngModelChange)="empreendimento.VGV = $event" />
                <mat-hint align="start">{{ empreendimento.VGV?empreendimento.VGV.toLocaleString():"" }}</mat-hint>
            </mat-form-field>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12">
            <mat-form-field appearance="standard">
                <mat-label>Fator de Ajuste da Parcela (%)</mat-label>
                <input type="number" matInput [(ngModel)]="empreendimento.FatorAjParcelaCorrigida" />
                <mat-hint align="start"><strong>Porcentagem da parcela dentro da operação</strong> </mat-hint>
                <mat-hint align="end"
                    >{{ empreendimento.FatorAjParcelaCorrigida ? empreendimento.FatorAjParcelaCorrigida.toLocaleString() : '' }}%</mat-hint
                >
            </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <app-uf-auto-complete
                (onUFChange)="empreendimento.UFID = $event"
                [unidadesFederativas]="unidadesFederativas"
                [ufId]="empreendimento.UFID"
            >
            </app-uf-auto-complete>
        </div>
        <div class="col-lg-5 col-md-6 col-sm-12">
            <app-municipio-auto-complete
                [municipios]="municipios"
                [municipioId]="empreendimento.MunicipioID"
                [ufId]="empreendimento.UFID"
                (onMunicipioChange)="empreendimento.MunicipioID = $event"
            >
            </app-municipio-auto-complete>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-3 col-md-12">
                <mat-form-field appearance="standard">
                <mat-label> Estoque - Preço M²</mat-label>
                <input type="number" matInput [ngModel]="empreendimento.EstoquePrecoM2" (ngModelChange)="empreendimento.EstoquePrecoM2 = $event" />
            </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-12" >
            <mat-form-field appearance="standard">
                <mat-label> Estoque - Desconto Comissão</mat-label>
                <input type="number" matInput [ngModel]="empreendimento.EstoquePercDesconto" (ngModelChange)="empreendimento.EstoquePercDesconto = $event" />
                <span matSuffix>% </span>
            </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-12" >
            <mat-form-field appearance="standard">
                <mat-label>Padrão de Construção</mat-label>
                <mat-select [(value)]="empreendimento.PadraoDeConstrucao" (selectionChange)="empreendimento.PadraoDeConstrucao = $event.value">
                    <mat-option *ngFor="let option of ['Baixo', 'Médio', 'Alto', 'Altíssimo']" [value]="option">
                        {{ option }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-12" >
            <mat-form-field appearance="standard">
                <mat-label>Endereço</mat-label>
                <input matInput [(ngModel)]="empreendimento.Endereco" />
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-12" >
            <mat-form-field appearance="standard">
                <mat-label>Link Google Maps</mat-label>
                <input matInput [(ngModel)]="empreendimento.LinkGoogleMaps" />
            </mat-form-field>
        </div>
    </div>
</div>

<div class="container" style="margin-top: 10px">
    <div class="row">
        <div class="col-6">
            <mat-slide-toggle [(ngModel)]="empreendimento.ConsiderarParcelaBalao" color="primary">Considera Parcela Balão?</mat-slide-toggle>
        </div>
    </div>
</div>

<div class="container" style="margin-top: 10px">
    <div class="row" style="float: right">
        <button (click)="submit()" mat-raised-button color="primary">{{ buttonText }}</button>
    </div>
</div>
