import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { CriService } from 'src/app/services/cri.service'
import { ExcelService } from 'src/app/services/excel.service'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'

@Component({
    selector: 'app-crilist',
    templateUrl: './crilist.component.html',
    styleUrls: ['./crilist.component.css'],
})
export class CrilistComponent implements OnInit, OnDestroy {
    constructor(private criService: CriService, private excel: ExcelService) {}

    private ngUnsubscribe = new Subject()
    public loading: Boolean = false
    public ready: Boolean = false

    // Date var
    public puDate: Date = new Date()

    // Display variables
    public displayCris: any[] = []
    public carteiras: string[] = []
    public allCris: any[] = []
    public selectedCarteiras: string[] = []

    // Excel
    public header: string[] = []

    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Controladoria', href: 'controladoria' },
        { text: 'CRIs', href: 'controladoria/cri_list' },
    ]

    updateSelectedCarteiras(event: any) {
        this.selectedCarteiras = event
    }

    updateDisplayedCris(event: any) {
        this.displayCris = event
    }

    changeDate(event: any): void {
        this.puDate = event
        this.getPu()
    }

    ngOnInit(): void {
        this.loading = true
        this.getPu()
        this.ready = true
    }

    getPu(): void {
        const stringDate: string = this.puDate.toISOString().split('T')[0]
        this.criService
            .getCrisPUsOnDate({ pu_date: stringDate })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((returnData: any) => {
                this.allCris = this.displayCris = returnData.data
                this.header = returnData.header
                this.carteiras = []
                for (var i = 0; i < this.allCris.length; i++)
                    if (!this.carteiras.includes(this.allCris[i].Carteira)) this.carteiras.push(this.allCris[i].Carteira)
                this.loading = false
            })
    }

    getDisplayDate(): string {
        if (this.puDate == null) return new Date().toISOString().split('T')[0]
        else return this.puDate.toISOString().split('T')[0]
    }

    exportExcel(): void {
        this.excel.exportExcel(this.allCris, this.getExcelFileName(), this.header)
    }

    getExcelFileName(): string {
        const aux: string[] = this.puDate.toISOString().split('T')[0].split('-')
        return 'CRIs_' + aux[2] + '_' + aux[1] + '_' + aux[0] + '.xlsx'
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.complete()
    }
}
