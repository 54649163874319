<div class="row">
    <div class="col-12">
      <div class="row">
        <mat-form-field appearance="standard" class="col-3">
          <mat-select [(value)]="selectedOption" (selectionChange)="changeChart($event, 'categoria')">
            <mat-option [value]="'Todas as Categorias'">
                Todas as Categorias
            </mat-option>
            <mat-option *ngFor="let categoria of categorias" [value]="categoria">
              {{ categoria }}
            </mat-option>
            <mat-option [value]="'Razão Fluxo Mensal'">
              Razão Fluxo Mensal
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-3">
          <mat-select [(value)]="selectedObra" (selectionChange)="changeChart($event, 'obra')">
            <mat-option [value]="'Total'">
              Todas as Obras
            </mat-option>
            <mat-option *ngFor="let obra of obrasSiglas" [value]="obra">
              {{ obra }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-12">
        <div class="estoque-chart">
            <apx-chart
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [stroke]="chartOptions.stroke"
                [dataLabels]="chartOptions.dataLabels"
                [labels]="chartOptions.labels"
                [xaxis]="chartOptions.xaxis"
                [yaxis]="chartOptions.yaxis"
                [colors]="chartOptions.colors"
                [grid]="chartOptions.grid"
                [legend]="chartOptions.legend"
                [fill]="chartOptions.fill"
                [annotations]="chartOptions.annotations"
            ></apx-chart>
        </div>
    </div>
</div>