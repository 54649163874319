import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Sort } from '@angular/material/sort'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { AppSettings } from 'src/app/constants/AppSettings'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { Carteira } from 'src/app/interfaces/carteira'
import { Emissor } from 'src/app/interfaces/emissor'
import { ResumoCri } from 'src/app/interfaces/resumo_cri'
import { Servicer } from 'src/app/interfaces/servicer'
import { TipoProjeto } from 'src/app/interfaces/tipo_projeto'
import { CriService } from 'src/app/services/cri.service'
import { ProjetosService } from 'src/app/services/projetos.service'
import { CalculateTabelaResumoDialogComponent } from './calculate-tabela-resumo-dialog/calculate-tabela-resumo-dialog.component'
import { ConsolidarTabelaDialogComponent } from './consolidar-tabela-dialog/consolidar-tabela-dialog.component'
import { DesbloquearTabelaDialogComponent } from './desbloquear-tabela-dialog/desbloquear-tabela-dialog.component'

@Component({
    selector: 'app-tabela-resumo',
    templateUrl: './tabela-resumo.component.html',
    styleUrls: ['./tabela-resumo.component.css'],
})
export class TabelaResumoComponent implements OnInit {
    private ngUnsubscribe = new Subject()

    private userToken = localStorage.getItem('token')
    constructor(private projetoService: ProjetosService, private criService: CriService, private _snackBar: MatSnackBar, public dialog: MatDialog) {}

    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Tabela Resumo', href: 'gestao/tabela_resumo' },
    ]
    // table data
    public tableData: ResumoCri[] = []
    public filteredData: ResumoCri[] = []
    // loading
    public loading: boolean[] = []

    // filtros
    public tipos: TipoProjeto[] = []
    public securitizadoras: Emissor[] = []
    public servicers: Servicer[] = []
    public carteiras: Carteira[] = []

    public filteredServicersIDs: number[] = []
    public filteredSecuritizadorasIDs: number[] = []
    public filteredTiposIDs: number[] = []
    public filteredCarteiras: number[] = []

    // Valor/Variação Selection
    public variacaoSelected: boolean = false

    // Seleção do Mês Base
    public mesBaseSelected: string = ''
    public mesesBase: string[] = []

    // Pode ou não salvar alterações ou fazer update por linha
    public resumoBlocked: boolean = true

    ngOnInit(): void {
        for (let i = 0; i < 6; i++) {
            this.loading.push(true)
        }
        this.getMesesBase()
        this.getServicers()
        this.getSecuritizadora()
        this.getTipos()
        this.getCarteiras()
    }

    getMesesBase() {
        this.projetoService
            .getMesesBaseResumo()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.mesesBase = [...returnData.meses_base]
                    this.mesBaseSelected = this.mesesBase[0]
                    this.getIsTabelaConsolidada(this.mesBaseSelected)
                    this.getTabelaResumoData(this.mesBaseSelected)
                },
                (errorData: any) => {}
            )
    }

    getIsTabelaConsolidada(mesBase: string) {
        this.projetoService
            .getIsTabelaConsolidada(mesBase)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.resumoBlocked = returnData.consolidado
                    this.loading.pop()
                },
                (errorData: any) => {}
            )
    }

    getTabelaResumoData(mesBase: string) {
        this.projetoService
            .getTabelaResumo(mesBase)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.tableData = [...returnData.tabela_resumo].slice()
                    this.filteredData = [...returnData.tabela_resumo].slice()
                    const aux_table = this.filteredData.map((obj) => {
                        return { ...obj, Loading: false }
                    })
                    this.filteredData = [...aux_table]
                    this.tableData = [...aux_table]
                    this.loading.pop()

                    console.log(returnData)
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getServicers() {
        this.projetoService
            .getServicers()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.servicers = returnData.servicers
                    this.filteredServicersIDs = this.servicers.map((obj) => obj.id)
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getSecuritizadora(): void {
        this.criService
            .getEmissores()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.securitizadoras = returnData.emissores
                    this.filteredSecuritizadorasIDs = this.securitizadoras.map((obj) => obj.EmissorID)
                    this.loading.pop()
                },
                (errorData) => this.loading.pop()
            )
    }
            

    getTipos() {
        this.projetoService
            .getTiposProjeto()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.tipos = returnData.tipos
                    this.filteredTiposIDs = this.tipos.map((obj) => obj.TipoID)
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getCarteiras(){
        this.projetoService
            .getCarteiras()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.carteiras = returnData.carteiras
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    refreshResumoLine(id: number) {
        const aux_table = this.filteredData.map((obj) => {
            if (obj.ID === id) {
                return { ...obj, Loading: true }
            }
            return obj
        })
        this.filteredData = [...aux_table]

        this.projetoService
            .updateResumo(id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    const resumo_cri: ResumoCri = returnData.resumo_cri
                    if (!resumo_cri.ID){
                        let auxFilteredData = this.filteredData.filter(obj => obj.ID !== id)
                        this.filteredData = [...auxFilteredData]
                        
                        let auxData = this.tableData.filter(obj => obj.ID !== id)
                        this.tableData = [...auxData]
                    }

                    resumo_cri.Loading = false

                    //atualizando a tabela filtrada
                    const elementIndex = this.filteredData.findIndex((element) => element.ID == resumo_cri.ID)
                    let auxFilteredData = [...this.filteredData]
                    auxFilteredData[elementIndex] = resumo_cri
                    this.filteredData = [...auxFilteredData]

                    //atualizando a tabela completa
                    const elementIndex2 = this.tableData.findIndex((element) => element.ID == resumo_cri.ID)
                    let auxData = [...this.tableData]
                    auxData[elementIndex] = resumo_cri
                    this.tableData = [...auxData]
                },
                (errorData: any) => {
                    const aux_table = this.filteredData.map((obj) => {
                        if (obj.ID === id) {
                            return { ...obj, Loading: false }
                        }
                        return obj
                    })
                    this.filteredData = [...aux_table]

                    this.openSnackbar('Erro ao calcular resumo para o cri', 'error-snackbar')
                }
            )
    }

    filtrarTipo(tipoIDs: any) {
        if (tipoIDs.length == 0) {
            this.filteredTiposIDs = this.tipos.map((obj) => obj.TipoID)
        } else {
            this.filteredTiposIDs = tipoIDs
        }
        this.filtrarTabela()
    }

    filtrarSecuritizadora(securitizadoraIDs: any) {
        if (securitizadoraIDs.length == 0) {
            this.filteredSecuritizadorasIDs = this.securitizadoras.map((obj) => obj.EmissorID)
        } else {
            this.filteredSecuritizadorasIDs = securitizadoraIDs
        }
        this.filtrarTabela()
    }

    filtrarCarteira(carteiras: any) {
        if (carteiras.length == 0) {
            this.filteredCarteiras = this.carteiras.map ((obj)=>obj.ID)
        } else {
            this.filteredCarteiras = carteiras
        }
        this.filtrarTabela()
    }

    filtrarServicer(servicerIDs: any) {
        if (servicerIDs.length == 0) {
            this.filteredServicersIDs = this.servicers.map((obj) => obj.id)
        } else {
            this.filteredServicersIDs = servicerIDs
        }
        this.filtrarTabela()
    }

    filtrarTabela() {
        this.filteredData = this.tableData.filter((linha_resumo) => {
            const securitizadoraID = linha_resumo.SecuritizadoraID
            const servicerID = linha_resumo.ServicerID
            const carteiraID = linha_resumo.CarteiraID
            return (
                this.filteredServicersIDs.includes(servicerID) &&
                this.filteredSecuritizadorasIDs.includes(securitizadoraID) &&
                this.filteredCarteiras.includes(carteiraID)
            )
        })
    }

    baixarExcel() {
        const endpoint = AppSettings.API_ENDPOINT + 'gestao/tabela_resumo/excel?mes_base=' + this.mesBaseSelected + '&user_token=' + this.userToken
        window.location.href = endpoint
    }

    onValueOrAumentoSelect(value: any) {
        if (value == '#') {
            this.variacaoSelected = false
        } else {
            this.variacaoSelected = true
        }
    }

    changeMesBase(event: any) {
        this.mesBaseSelected = event.value
        this.loading.push(true)
        this.loading.push(true)
        this.getIsTabelaConsolidada(this.mesBaseSelected)
        this.getTabelaResumoData(this.mesBaseSelected)
    }

    changeComentarios(comentario: any, id: number){
        this.tableData = this.tableData.map((obj) => {
            if (obj.ID === id) {
                return { ...obj, Comentarios: comentario }
            }

            return obj
        })
        this.filteredData = this.filteredData.map((obj) => {
            if (obj.ID === id) {
                return { ...obj, Comentarios: comentario }
            }

            return obj
        })
    }

    setManual(id: number, value: any, property: string, propertyManual: string) {
        const isValueModified = this.isValueModified(id, value, property, propertyManual)
        var isManual = 0
        if (value != 0 && isValueModified) {
            this.tableData = this.tableData.map((obj) => {
                if (obj.ID === id) {
                    return { ...obj, [property]: value, [propertyManual]: 1 }
                }
                isManual = 1
                return obj
            })
            console.log(this.tableData)
        } else {
            this.tableData = this.tableData.map((obj) => {
                if (obj.ID === id) {
                    return { ...obj, [property]: value, [propertyManual]: 0 }
                }
                isManual = 0
                return obj
            })
        }

        const elementIndex = this.filteredData.findIndex((element) => element.ID == id)
        let auxFilteredData = [...this.filteredData]
        auxFilteredData[elementIndex][property] = value
        auxFilteredData[elementIndex][propertyManual] = isManual
        this.filteredData = [...auxFilteredData]
    }

    isValueModified(id: number, value: any, property: string, isManualProperty: string) {
        var linha = this.tableData.filter((obj) => {
            return obj.ID === id
        })

        var value_antigo: number | string | boolean = linha[0][property]
        if (linha[0][isManualProperty] == 1) {
            return true
        }

        if (typeof value_antigo == 'number') {
            value_antigo = parseFloat(value_antigo.toFixed(0))
        }

        if (value_antigo == value) {
            return false
        }
        return true
    }

    setPercObras(id: number, value: any) {
        this.tableData = this.tableData.map((obj) => {
            if (obj.ID === id) {
                return { ...obj, PercObras: value }
            }

            return obj
        })
        this.filteredData = this.filteredData.map((obj) => {
            if (obj.ID === id) {
                return { ...obj, PercObras: value }
            }

            return obj
        })
    }

    salvarModificacoesManuais() {
        this.tableData = this.filteredData.map((obj) => {
            return obj
        })

        this.projetoService
            .updateManualInputs({ tabela_resumo: this.tableData })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                async (returnData: any) => {
                    if (returnData.message == 'Tabela Resumo salva com sucesso') {
                        this.openSnackbar('Modificações Salvas com Sucesso', 'success-snackbar')
                    } else {
                        this.openSnackbar('Erro ao salvar dados na API', 'error-snackbar')
                    }
                    await new Promise((r) => setTimeout(r, 800))

                    this.loading.push(true)
                    this.getTabelaResumoData(this.mesBaseSelected)
                },
                (errorData: any) => {
                    this.openSnackbar('Erro ao salvar dados na API', 'error-snackbar')
                }
            )
    }

    openSnackbar(message: string, messageType: string): void {
        this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
    }

    calculateTabelaResumoTotalDialog() {
        const calculateTabelaDialog = this.dialog.open(CalculateTabelaResumoDialogComponent, {
            width: '30%',
            height: 'auto',
            autoFocus: false,
            position: {
                top: '80px',
            },
            data: {},
        })

        calculateTabelaDialog.afterClosed().subscribe((result) => {
            var params = result
            if (params.save) {
                this.calculateTabelaResumoTotal(params.mesBase)
            }
            return
        })
    }

    calculateTabelaResumoTotal(mesBase: any) {
        var formData = new FormData()
        formData.append('mes_base', mesBase)
        this.projetoService.calculateTabelaResumo(formData).pipe(takeUntil(this.ngUnsubscribe)).subscribe()
        this._snackBar.open(
            `Cálculo da Tabela Resumo para o mês ${mesBase} disparado. Quando concluído, uma notificação será enviada no canal do Slack`,
            'Fechar',
            {
                duration: 10000,
            }
        )
    }

    isResumoBlocked(): boolean {
        return this.resumoBlocked
    }

    desbloquearTabelaResumoDialog() {
        const dialog = this.dialog.open(DesbloquearTabelaDialogComponent, {
            width: '30%',
            height: 'auto',
            autoFocus: false,
            position: {
                top: '80px',
            },
            data: {},
        })

        dialog.afterClosed().subscribe((result) => {
            var params = result
            if (params.save) {
                this.desbloquearResumo(this.mesBaseSelected)
            }
            return
        })
    }
    consolidarTabelaResumoDialog() {
        const dialog = this.dialog.open(ConsolidarTabelaDialogComponent, {
            width: '30%',
            height: 'auto',
            autoFocus: false,
            position: {
                top: '80px',
            },
            data: {},
        })

        dialog.afterClosed().subscribe((result) => {
            var params = result
            if (params.save) {
                this.consolidarResumo(this.mesBaseSelected)
            }
            return
        })
    }

    desbloquearResumo(mesBase: string) {
        this.projetoService
            .desbloquearResumo(mesBase)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    console.log(returnData.message)
                    if (returnData.message == 'sucesso') {
                        this._snackBar.open('A tabela foi desbloqueada com sucesso.', 'Fechar', {
                            duration: 10000,
                            panelClass: 'success-snackbar',
                        })
                        this.resumoBlocked = false
                    } else {
                        this._snackBar.open('O servidor encontrou um erro ao desbloquear a tabela.', 'Fechar', {
                            duration: 10000,
                            panelClass: 'error-snackbar',
                        })
                    }
                },
                (errorData: any) => {
                    this._snackBar.open('O servidor encontrou um erro ao desbloquear a tabela.', 'Fechar', {
                        duration: 10000,
                        panelClass: 'error-snackbar',
                    })
                }
            )
    }

    consolidarResumo(mesBase: string) {
        this.projetoService
            .consolidarResumo(mesBase)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    if (returnData.message == 'sucesso') {
                        this._snackBar.open('A tabela foi consolidada com sucesso.', 'Fechar', {
                            duration: 10000,
                            panelClass: 'success-snackbar',
                        })
                        this.resumoBlocked = true
                    } else {
                        this._snackBar.open('O servidor encontrou um erro ao consolidar a tabela.', 'Fechar', {
                            duration: 10000,
                            panelClass: 'error-snackbar',
                        })
                    }
                },
                (errorData: any) => {
                    this._snackBar.open('O servidor encontrou um erro ao consolidar a tabela.', 'Fechar', {
                        duration: 10000,
                        panelClass: 'error-snackbar',
                    })
                }
            )
    }

    sortData(sort: Sort) {
        console.log(sort)

        const data = this.filteredData.slice()
        if (!sort.active || sort.direction === '') {
            this.filteredData = data
            return
        }

        this.filteredData = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc'
            switch (sort.active) {
                case 'CRI':
                    return compare(a.Chave, b.Chave, isAsc)
                case 'Carteira':
                    return compare(a.Carteira, b.Carteira, isAsc)
                case 'SaldoDevedorTabelaResumo':
                    return compare(a.SaldoDevedor, b.SaldoDevedor, isAsc)
                case 'PercFundo':
                    return compare(a.PercFundo, b.PercFundo, isAsc)
                case 'RazaoFluxoMensal':
                    return compare(a.RazaoFluxoMensal, b.RazaoFluxoMensal, isAsc)
                case 'RazaoFluxoMensalMin':
                    return compare(a.RazaoMinimaFluxoMensal, b.RazaoMinimaFluxoMensal, isAsc)
                case 'RazaoSaldoDevedor':
                    return compare(a.RazaoSaldoDevedor, b.RazaoSaldoDevedor, isAsc)
                case 'RazaoSaldoDevedorMin':
                    return compare(a.RazaoMinimaSaldoDevedor, b.RazaoMinimaSaldoDevedor, isAsc)
                case 'LTVAtual':
                    return compare(a.LTVAtual, b.LTVAtual, isAsc)
                case 'LTVFull':
                    return compare(a.LTVFull, b.LTVFull, isAsc)
                case 'PercInadimplencia':
                    return compare(a.PercInadimplencia, b.PercInadimplencia, isAsc)
                case 'PercVendas':
                    return compare(a.PercVendas, b.PercVendas, isAsc)
                case 'PercObras':
                    return compare(a.PercObras, b.PercObras, isAsc)
                case 'Comentarios':
                    return compare(a.Comentarios, b.Comentarios, isAsc)
                case 'VarPercObras':
                    return compare(a.VarPercObras, b.VarPercObras, isAsc)
                case 'VarLTVAtual':
                    return compare(a.VarLTVAtual, b.VarLTVAtual, isAsc)
                case 'VarLTVFull':
                    return compare(a.VarLTVFull, b.VarLTVFull, isAsc)
                case 'VarPercFundo':
                    return compare(a.VarPercFundo, b.VarPercFundo, isAsc)
                case 'VarPercInadimplencia':
                    return compare(a.VarPercInadimplencia, b.VarPercInadimplencia, isAsc)
                case 'VarPercVendas':
                    return compare(a.VarPercVendas, b.VarPercVendas, isAsc)
                case 'VarRazaoFluxoMensal':
                    return compare(a.VarRazaoFluxoMensal, b.VarRazaoFluxoMensal, isAsc)
                case 'VarRazaoSaldoDevedor':
                    return compare(a.VarRazaoSaldoDevedor, b.VarRazaoSaldoDevedor, isAsc)
                case 'VarSaldoDevedor':
                    return compare(a.VarSaldoDevedor, b.VarSaldoDevedor, isAsc)
                default:
                    return 0
            }
        })
    }
}

function compare(a: number | string | null, b: number | string | null, isAsc: boolean) {
    if (a === b) {
        return 0;
    }
    const aIsNull = a === null || a === 'null';
    const bIsNull = b === null || b === 'null';
    if (aIsNull && bIsNull) {
        return 0;
    } else if (aIsNull) {
        return isAsc ? -1 : 1;
    } else if (bIsNull) {
        return isAsc ? 1 : -1;
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
