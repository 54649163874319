import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ProjetosService } from 'src/app/services/projetos.service';
import { takeUntil } from 'rxjs/operators'
import { CriterioNota } from 'src/app/interfaces/criterio-nota';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CriterioRating } from 'src/app/interfaces/criterio-rating';

const NOTAS_OBRAS = 0
const NOTAS_VENDAS = 4
const NOTAS_RECEBIMENTOS = 3
const NOTAS_INADIMPLENCIA = 5
const NOTAS_LTV = 6
const NOTAS_RFM = 7

@Component({
    selector: 'app-criterios-notas',
    templateUrl: './criterios-notas.component.html',
    styleUrls: ['./criterios-notas.component.css'],
})
export class CriteriosNotasComponent implements OnInit {
    private ngUnsubscribe = new Subject()
    constructor(private projetoService: ProjetosService, private _snackBar: MatSnackBar) {}

    ngOnInit(): void {
        this.getCriterios()
    }

    public criteriosData: CriterioNota[] = []
    public criteriosRating: CriterioRating[] = []

    getCriterios() {
        this.projetoService
            .getCriteriosNotas()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.criteriosData = returnData.criterios_notas
                    this.criteriosRating = returnData.criterios_ratings
                },
                (errorData: any) => {
                    console.log(errorData)
                    alert('Erro ao pegar dados dos critérios no banco')
                }
            )
    }

    formatPercentages(value: number) {
        let strValue = (value * 100).toFixed(0)
        return strValue + '%'
    }

    convertStringToFloat(value: string): number {
        return parseFloat(value)
    }

    salvarAtualizacoes() {
        if (!this.validateInputs()){
            return 
        }

        const formData = new FormData()
        formData.append('criterios_notas', JSON.stringify(this.criteriosData))
        formData.append('criterios_ratings', JSON.stringify(this.criteriosRating))

        this.projetoService
            .updateCriteriosNotas(formData)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) =>{
                    this.openSnackbar('Critérios atualizados com sucesso', 'success-snackbar')
                    this.getCriterios()
                },
                (errorData: any) =>{
                    this.openSnackbar('Erro interno do servidor', 'error-snackbar')
                }
            )
    }

    validateInputs() {
        let sum = 0 
        for (let criterio of this.criteriosData){
            console.log(criterio.PesoEmObrasRFM)
            sum += criterio.PesoEmObrasRFM
        } 

        if (sum != 1){
            this.openSnackbar('Erro ao salvar os inputs de pesos. Os pesos estão somando 100%?', 'error-snackbar')
            return false
        }

        // Notas Obras
        if (parseFloat(this.criteriosData[NOTAS_OBRAS].CriterioNota3) >= 10 || 
            parseFloat(this.criteriosData[NOTAS_OBRAS].CriterioNota2) >= 10 ||
            parseFloat(this.criteriosData[NOTAS_OBRAS].CriterioNota1) >= 10)
        {
            this.openSnackbar('As notas de obras devem ser de 0 a 10', 'error-snackbar')
            return false
        }

        if (
            this.isLargerThan(this.criteriosData[NOTAS_OBRAS].CriterioNota1, this.criteriosData[NOTAS_OBRAS].CriterioNota2) ||
            this.isLargerThan(this.criteriosData[NOTAS_OBRAS].CriterioNota1, this.criteriosData[NOTAS_OBRAS].CriterioNota3) ||
            this.isLargerThan(this.criteriosData[NOTAS_OBRAS].CriterioNota2, this.criteriosData[NOTAS_OBRAS].CriterioNota3)
        ) {
            this.openSnackbar('Notas Obras: valores incorretos', 'error-snackbar')
            return false
        }

        if (
            this.isLargerEqualThan(this.criteriosData[NOTAS_VENDAS].CriterioNota1, this.criteriosData[NOTAS_VENDAS].CriterioNota2) ||
            this.isLargerEqualThan(this.criteriosData[NOTAS_VENDAS].CriterioNota1, this.criteriosData[NOTAS_VENDAS].CriterioNota3) ||
            this.isLargerEqualThan(this.criteriosData[NOTAS_VENDAS].CriterioNota2, this.criteriosData[NOTAS_VENDAS].CriterioNota3)
        ) {
            this.openSnackbar('Status Vendas: valores incorretos', 'error-snackbar')
            return false
        }

        if (this.isLargerEqualThan(this.criteriosData[NOTAS_INADIMPLENCIA].CriterioNota3, this.criteriosData[NOTAS_INADIMPLENCIA].CriterioNota2)) {
            this.openSnackbar('Status Inadimplência: valores incorretos', 'error-snackbar')
            return false
        }

        if (
            this.isLargerEqualThan(this.criteriosData[NOTAS_LTV].CriterioNota4, this.criteriosData[NOTAS_LTV].CriterioNota2) ||
            this.isLargerEqualThan(this.criteriosData[NOTAS_LTV].CriterioNota4, this.criteriosData[NOTAS_LTV].CriterioNota3) ||
            this.isLargerEqualThan(this.criteriosData[NOTAS_LTV].CriterioNota3, this.criteriosData[NOTAS_LTV].CriterioNota2)
        ) {
            this.openSnackbar('LTV: valores incorretos', 'error-snackbar')
            return false
        }

        if (
            this.isLargerEqualThan(this.criteriosData[NOTAS_RECEBIMENTOS].CriterioNota1, this.criteriosData[NOTAS_RECEBIMENTOS].CriterioNota2) ||
            this.isLargerEqualThan(this.criteriosData[NOTAS_RECEBIMENTOS].CriterioNota1, this.criteriosData[NOTAS_RECEBIMENTOS].CriterioNota3) ||
            this.isLargerEqualThan(this.criteriosData[NOTAS_RECEBIMENTOS].CriterioNota2, this.criteriosData[NOTAS_RECEBIMENTOS].CriterioNota3)
        ) {
            this.openSnackbar('Recebimentos: valores incorretos', 'error-snackbar')
            return false
        }

        if (
            !this.isBetween0and1(this.criteriosData[NOTAS_VENDAS].CriterioNota1) ||
            !this.isBetween0and1(this.criteriosData[NOTAS_VENDAS].CriterioNota2) ||
            !this.isBetween0and1(this.criteriosData[NOTAS_VENDAS].CriterioNota3) ||
            !this.isBetween0and1(this.criteriosData[NOTAS_INADIMPLENCIA].CriterioNota3) ||
            !this.isBetween0and1(this.criteriosData[NOTAS_INADIMPLENCIA].CriterioNota2) ||
            !this.isBetween0and1(this.criteriosData[NOTAS_LTV].CriterioNota4) ||
            !this.isBetween0and1(this.criteriosData[NOTAS_LTV].CriterioNota3) ||
            !this.isBetween0and1(this.criteriosData[NOTAS_LTV].CriterioNota2) ||
            !this.isBetween0and1(this.criteriosData[NOTAS_RFM].CriterioNota3) ||
            !this.isBetween0and1(this.criteriosData[NOTAS_RFM].CriterioNota1) ||
            !this.isBetween0and1(this.criteriosData[NOTAS_RECEBIMENTOS].CriterioNota3) ||
            !this.isBetween0and1(this.criteriosData[NOTAS_RECEBIMENTOS].CriterioNota2) ||
            !this.isBetween0and1(this.criteriosData[NOTAS_RECEBIMENTOS].CriterioNota1)
        ) {
            this.openSnackbar('Os valores de porcentagem devem estar entre 0 e 1', 'error-snackbar')
            return false
        }

        if (
            this.criteriosRating[0].Criterio < this.criteriosRating[1].Criterio ||
            this.criteriosRating[0].Criterio < this.criteriosRating[2].Criterio ||
            this.criteriosRating[0].Criterio < this.criteriosRating[3].Criterio ||
            this.criteriosRating[1].Criterio < this.criteriosRating[2].Criterio ||
            this.criteriosRating[1].Criterio < this.criteriosRating[3].Criterio ||
            this.criteriosRating[2].Criterio < this.criteriosRating[3].Criterio ||
            this.criteriosRating[0].Criterio < 0 ||
            this.criteriosRating[1].Criterio < 0 ||
            this.criteriosRating[2].Criterio < 0 ||
            this.criteriosRating[3].Criterio < 0 ||
            this.criteriosRating[4].Criterio < 0 ||
            this.criteriosRating[0].Criterio >= 10 ||
            this.criteriosRating[1].Criterio >= 10 ||
            this.criteriosRating[2].Criterio >= 10 ||
            this.criteriosRating[3].Criterio >= 10 ||
            this.criteriosRating[4].Criterio >= 10
        ) {
            this.openSnackbar('Ratings: valores incorretos', 'error-snackbar')
            return false
        }
        
        return true
    }

    isBetween0and1(value:string){
        return parseFloat(value) >= 0 && parseFloat(value) <= 1
    }

    isLargerThan(value1:string, value2:string){
        return parseFloat(value1) > parseFloat(value2) 
    }

    isLargerEqualThan(value1:string, value2:string){
        return parseFloat(value1) >= parseFloat(value2) 
    }


    openSnackbar(message: string, messageType: string): void {
        this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
    }
}
