<app-navbar></app-navbar>

<div *ngIf="success">
    <app-success-alert [text]="'Projeto-Categoria criado com sucesso'"></app-success-alert>
</div>
<div *ngIf="error">
    <app-error-alert [text]="errorMessage"></app-error-alert>
</div>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="bottom-overlay" style="margin-top: 15px">
    <div [ngClass]="loading.length > 0 ? 'disabled-div' : ''">
        <div *ngIf="projetos.length > 0">
            <app-projeto-categoria-form
                (onSubmit)="submit($event)"
                [projetos]="projetos"
                [categorias]="categorias"
                [indexadores]="indexadores"
                [carteiras]="carteiras"
            >
            </app-projeto-categoria-form>
        </div>
    </div>
</div>
