import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ProjetosService } from 'src/app/services/projetos.service'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { Projeto } from 'src/app/interfaces/projeto'
import { MetodologiaFundoReserva } from 'src/app/interfaces/metodologia_fundo_reserva'
import { TipoProjeto } from 'src/app/interfaces/tipo_projeto'
import { Servicer } from 'src/app/interfaces/servicer'
import { MetodologiaLTV } from 'src/app/interfaces/metodologia_ltv'
import { MetodologiaEstoque } from 'src/app/interfaces/metodologia_estoque'
import { MetodologiaRFM } from 'src/app/interfaces/metodologia_rfm'
import { MetodologiaInadimplencia } from 'src/app/interfaces/metodologia_inadimplencia'
import { AgenteFiduciario } from 'src/app/interfaces/agende-fiduciario'
import { CriService } from 'src/app/services/cri.service'
import { Emissor } from 'src/app/interfaces/emissor'
import { Setor } from 'src/app/interfaces/setor'
import { Ativo } from 'src/app/interfaces/ativo'
import { GrupoEconomico } from 'src/app/interfaces/grupo_economico'

@Component({
    selector: 'app-projeto-update',
    templateUrl: './projeto-update.component.html',
    styleUrls: ['./projeto-update.component.css'],
})
export class ProjetoUpdateComponent implements OnInit, OnDestroy {
    constructor(private activatedRoute: ActivatedRoute, 
                private projetoService: ProjetosService,
                private criService: CriService) {
        this.projetoId = this.activatedRoute.snapshot.queryParams['projeto_id']
        this.projeto = {
            ID: 0,
            Sigla: '',
            TipoID: 0,
            AtivoID: 0,
            SetorID: 0,
            ServicerID: 0,
            MetodologiaLTV_ID: 0,
            MetodologiaEstoqueID: 0,
            DespesasAnual: 0,
            DespesasMensal: 0,
            ParcelasInadimplencia: 3,
            ValorFundoReserva: 0,
            MetodologiaFundoReservaID: 0,
            LimRazaoSaldoDevedor: 0,
            LimRazaoFluxoMensal: 0,
            FundosReservasManuais: [],
            MetodologiaRFM_ID: 0,
            TemObra: 1,
            MetodologiaInadimplenciaID: 1,
            MaxFundoReserva: 0,
            MinFundoReserva: 0,
            AgenteFiduciarioID: -1, 
            EmissorID: -1,
            GrupoEconomicoID: 0, 
            VPNormalizado: 1,
        }
    }

    public ready: boolean = false
    private ngUnsubscriber = new Subject()
    public loading: boolean[] = []

    public projeto: Projeto
    
    public servicers: Servicer[] = []
    public metodologiasFundoReserva: MetodologiaFundoReserva[] = []
    public metodologiasLTV: MetodologiaLTV[] = []
    public metodologiasEstoque: MetodologiaEstoque[] = []
    public metodologiasRFM: MetodologiaRFM[] = []
    public metodologiasInadimplencia: MetodologiaInadimplencia[] = []
    public agentesFiduciarios: AgenteFiduciario[] = []
    public tiposProjeto: TipoProjeto[] = []
    public projetoId: number = 0
    public emissores: Emissor[] = []
    public gruposEconomicos: GrupoEconomico[] = []
    public setores: Setor[] = []
    public ativos: Ativo[] = []

    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'CRIs', href: 'gestao/list/cris' },
    ]

    public error: boolean = false
    public success: boolean = false
    public errorMessage: string = ''

    getProjetoDetails() {
        this.projetoService
            .getProjeto({ projeto_id: this.projetoId })
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.projeto = returnData.projeto

                    this.breadcrumbList.push({ text: this.projeto.Sigla, href: 'gestao/edit/projeto?projeto_id=' + this.projeto.ID })
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getServicers() {
        this.projetoService
            .getServicers()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.servicers = returnData.servicers
                    this.loading.pop()
                },

                (errorData: any) => this.loading.pop()
            )
    }

    getMetodologiasFundoReserva() {
        this.projetoService
            .getMetodologiasFundoReserva()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.metodologiasFundoReserva = returnData.metodologias
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getTiposProjeto() {
        this.projetoService
            .getTiposProjeto()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.tiposProjeto = returnData.tipos
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getMetodologiasLTV() {
        this.projetoService
            .getMetodologiasLTV()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.metodologiasLTV = returnData.metodologias_LTV
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getMetodologiasEstoque() {
        this.projetoService
            .getMetodologiasEstoque()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.metodologiasEstoque = returnData.metodologias_estoque
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getMetodologiasRFM() {
        this.projetoService
            .getMetodologiasRFM()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    console.log(returnData)
                    this.metodologiasRFM = returnData.metodologias_rfm
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getMetodologiasInadimplencia() {
        this.projetoService
            .getMetodologiasInadimplencia()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.metodologiasInadimplencia = returnData.metodologias_inadimplencia
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getAgentesFiduciarios() {
        this.projetoService
                .getAgentesFiduciarios()
                .pipe(takeUntil(this.ngUnsubscriber))
                .subscribe(
                    (returnData: any) => {
                        this.agentesFiduciarios = returnData.agentes_fiduciarios
                        this.loading.pop()
                    },
                    (errorData: any) => this.loading.pop()
                )
    }

    getEmissores() {
        this.criService
                .getEmissores()
                .pipe(takeUntil(this.ngUnsubscriber))
                .subscribe(
                    (returnData: any) => {
                        this.emissores = returnData.emissores
                        this.loading.pop()
                    },
                    (errorData: any) => this.loading.pop()
                )
    }

    getAtivos() {
        this.projetoService
            .getAtivos()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.ativos = returnData.ativos
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    getSetores() {
        this.projetoService
            .getSetores()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.setores = returnData.setores
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )
    }

    updateProjeto(event: any) {
        const projeto = event.projeto

        this.error = this.success = false
        this.loading.push(true)
        this.projetoService
            .updateProjeto(projeto)
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.success = true
                    this.loading.pop()
                },
                (errorData: any) => {
                    this.error = true
                    this.errorMessage = errorData['error']['message']
                    this.loading.pop()
                }
            )
    }

    getGruposEconomicos() {
        this.criService
            .getGruposEconomicos()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.gruposEconomicos = returnData.grupos_economicos
                    this.loading.pop()
                },
                (error) => this.loading.pop()
            )
    }

    ngOnInit(): void {
        this.loading.push(true, true, true, true, true, true, true, true, true, true, true, true, true)
        this.getMetodologiasFundoReserva()
        this.getServicers()
        this.getTiposProjeto()
        this.getProjetoDetails()
        this.getMetodologiasLTV()
        this.getMetodologiasEstoque()
        this.getMetodologiasRFM()
        this.getMetodologiasInadimplencia()
        this.getAgentesFiduciarios()
        this.getEmissores()
        this.getAtivos()
        this.getSetores()
        this.getGruposEconomicos()
        this.ready = true
    }

    ngOnDestroy() {
        this.ngUnsubscriber.next()
        this.ngUnsubscriber.complete()
    }
}
