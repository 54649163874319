<app-navbar></app-navbar>
<div class="jumbotron">
    <div class="container">
        <h1>População</h1>
        <div class="row">
            <div class="filter-container">
                <mat-form-field appearance="outline">
                    <mat-label>Países</mat-label>
                    <mat-select [formControl]="paises" multiple (selectionChange)="updateFilter($event)">
                        <mat-select-trigger>
                            {{ paises.value ? paises.value[0] : '' }}
                            <span *ngIf="paises.value?.length > 1" class="example-additional-selection">
                                (+{{ paises.value.length - 1 }} {{ paises.value?.length === 2 ? 'outro' : 'outros' }})
                            </span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let single of multi" [value]="single.name">{{ single.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="date-container">
                    <mat-form-field appearance="outline" class="datePicker">
                        <mat-label>Data de Início</mat-label>
                        <input matInput [matDatepicker]="dataInicio" readonly (dateChange)="changeDate($event)" />
                        <mat-datepicker-toggle matSuffix [for]="dataInicio">
                            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #dataInicio></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="datePicker">
                        <mat-label>Data de Fim</mat-label>
                        <input matInput [matDatepicker]="dataFim" readonly (dateChange)="changeDate($event)" />
                        <mat-datepicker-toggle matSuffix [for]="dataFim">
                            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #dataFim></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div #containerRef>
                    <ngx-charts-line-chart
                        [view]="[containerRef.offsetWidth, 400]"
                        [scheme]="colorScheme"
                        [legend]="legend"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="xAxis"
                        [yAxis]="yAxis"
                        [xAxisLabel]="xAxisLabel"
                        [yAxisLabel]="yAxisLabel"
                        [timeline]="timeline"
                        [results]="displayData"
                    >
                    </ngx-charts-line-chart>
                </div>
            </div>
        </div>
    </div>
</div>
