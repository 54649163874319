import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { TipoImovel } from 'src/app/interfaces/tipo_imovel'
@Component({
    selector: 'app-tipo-imovel-select',
    templateUrl: './tipo-imovel-select.component.html',
    styleUrls: ['./tipo-imovel-select.component.css'],
})
export class TipoImovelSelectComponent implements OnInit {
    @Input('tiposImovel') tiposImovel: TipoImovel[] = []
    @Input('tipoImovelId') tipoImovelId: number = 0
    @Output() onTipoImovelChange = new EventEmitter()

    constructor() {}

    emitTipoImovelId(event: any) {
        this.onTipoImovelChange.emit(event.value)
    }

    ngOnInit(): void {}
}
