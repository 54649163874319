import { Injectable } from '@angular/core'
import * as XLSX from 'xlsx'

@Injectable({
    providedIn: 'root',
})
export class ExcelService {
    constructor() {}

    public convertListOfJsonToExcel(data: any[], header: string[]): any {
        // Copying
        var copy: any[] = data.map((x) => Object.assign({}, x))

        if (header) this.removeFields(copy, header)

        const workBook = XLSX.utils.book_new()
        const workSheet = XLSX.utils.json_to_sheet(copy, { header: header })

        XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet')
        return workBook
    }

    public removeFields(copy: any[], header: string[]): void {
        for (var i = 0; i < copy.length; i++) {
            for (var key in copy[i]) {
                if (!header.includes(key)) delete copy[i][key]
            }
        }
    }

    public exportExcel(data: any[], fileName: string, header: string[]): void {
        const workBook = this.convertListOfJsonToExcel(data, header)
        XLSX.writeFile(workBook, fileName)
    }
}
