import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AgenteFiduciario } from 'src/app/interfaces/agende-fiduciario'
import { Ativo } from 'src/app/interfaces/ativo'
import { Emissor } from 'src/app/interfaces/emissor'
import { FundoReserva } from 'src/app/interfaces/fundo_reserva'
import { GrupoEconomico } from 'src/app/interfaces/grupo_economico'
import { MetodologiaEstoque } from 'src/app/interfaces/metodologia_estoque'
import { MetodologiaInadimplencia } from 'src/app/interfaces/metodologia_inadimplencia'
import { MetodologiaLTV } from 'src/app/interfaces/metodologia_ltv'
import { MetodologiaRFM } from 'src/app/interfaces/metodologia_rfm'
import { Projeto } from 'src/app/interfaces/projeto'
import { Razoes } from 'src/app/interfaces/razoes'
import { Servicer } from 'src/app/interfaces/servicer'
import { Setor } from 'src/app/interfaces/setor'

@Component({
    selector: 'app-projeto-form',
    templateUrl: './projeto-form.component.html',
    styleUrls: ['./projeto-form.component.css'],
})
export class ProjetoFormComponent implements OnInit {
    @Input('projeto') projeto: Projeto
    @Input('tiposProjeto') tiposProjeto: any[] = []
    @Input('ativos') ativos: Ativo[] = []
    @Input('setores') setores: Setor[] = []
    @Input('metodologiasFundoReserva') metodologiasFundoReserva: any[] = []
    @Input('metodologiasLTV') metodologiasLTV: MetodologiaLTV[] = []
    @Input('metodologiasEstoque') metodologiasEstoque: MetodologiaEstoque[] = []
    @Input('metodologiasRFM') metodologiasRFM: MetodologiaRFM[] = []
    @Input('metodologiasInadimplencia') metodologiasInadimplencia: MetodologiaInadimplencia[] = []
    @Input('servicers') servicers: Servicer[] = []
    @Input('gruposEconomicos') gruposEconomicos: GrupoEconomico[] = []
    @Input('emissores') emissores: Emissor[] = []
    @Input('agentesFiduciarios') agentesFiduciarios: AgenteFiduciario[] = []
    @Input('update') update: boolean = false

    @Output() onSubmit = new EventEmitter()

    MetodologiaFundoReserva = {
        PMTS_FUTURAS: 1,
        FIXO: 2,
        PERCENTUAL: 3,
    }

    public buttonText: string = ''

    constructor(private _snackBar: MatSnackBar) {
        this.projeto = {
            ID: 0,
            Sigla: '',
            TipoID: 0,
            AtivoID: 0,
            SetorID: 0,
            ServicerID: 0,
            MetodologiaLTV_ID: 0,
            MetodologiaEstoqueID: 1,
            DespesasAnual: 0,
            DespesasMensal: 0,
            ParcelasInadimplencia: 3,
            LimRazaoFluxoMensal: 0,
            LimRazaoSaldoDevedor: 0,
            MetodologiaFundoReservaID: 0,
            ValorFundoReserva: 0,
            FundosReservasManuais: [],
            MetodologiaRFM_ID: 0,
            TemObra: 1,
            MetodologiaInadimplenciaID: 1,
            MaxFundoReserva: 0,
            MinFundoReserva: 0,
            AgenteFiduciarioID: -1,
            EmissorID: -1,
            GrupoEconomicoID: 0,
            VPNormalizado: 1,
        }
    }

    showSnackbar(message: string) {
        this._snackBar.open(message, 'Fechar', {
            duration: 5000,
        })
    }

    areValuesEmpty(obj: Object): boolean {
        const objValues = Object.values(obj)
        if (objValues.includes('')) return true
        if (objValues.includes(NaN)) return true
        if (objValues.includes(null)) return true

        return false
    }

    submit() {
        const projeto_copy = JSON.parse(JSON.stringify(this.projeto))

        if (projeto_copy.MetodologiaFundoReservaID == this.MetodologiaFundoReserva.PERCENTUAL) {
            projeto_copy.ValorFundoReserva /= 100
        }
        projeto_copy.LimRazaoSaldoDevedor /= 100
        projeto_copy.LimRazaoFluxoMensal /= 100

        for (let i = 0; i < projeto_copy.FundosReservasManuais.length; i++) {
            const valor = projeto_copy.FundosReservasManuais[i].Valor
            const mesBase = parseInt(projeto_copy.FundosReservasManuais[i].MesBase.toString())

            if (!this.validateMesBase(mesBase)) {
                this.showSnackbar('Campos do Fundo de Reserva Manual inválidos')
                return
            }
        }

        this.onSubmit.emit({
            projeto: projeto_copy,
        })
    }

    validateMesBase(mesBase: number): boolean {
        if (mesBase.toString().length != 6) return false
        if (parseInt(mesBase.toString().slice(-2)) < 0 || parseInt(mesBase.toString().slice(-2)) > 12) return false
        if (parseInt(mesBase.toString().slice(0, 4)) < 2000 || parseInt(mesBase.toString().slice(0, 4)) > 2100) return false

        return true
    }

    deleteFundoReservaManual(index: number) {
        this.projeto.FundosReservasManuais.splice(index, 1)
    }

    createFundoReservaManual() {
        const projetoID = this.projeto.ID
        const fundoReservaManual = { ID: 0, MesBase: 0, Valor: 0, ProjetoID: projetoID }
        this.projeto.FundosReservasManuais.push(fundoReservaManual)
    }

    ngOnInit(): void {
        if (this.projeto.MetodologiaFundoReservaID == this.MetodologiaFundoReserva.PERCENTUAL) {
            this.projeto.ValorFundoReserva = +(this.projeto.ValorFundoReserva * 100).toFixed(2)
        }
        this.projeto.LimRazaoSaldoDevedor = +(this.projeto.LimRazaoSaldoDevedor * 100).toFixed(2)
        this.projeto.LimRazaoFluxoMensal = +(this.projeto.LimRazaoFluxoMensal * 100).toFixed(2)
        this.buttonText = this.update ? 'Salvar alterações' : 'Cadastrar'
    }
}
