<div class="container">
    <div class="row">
        <div class="col-lg-8">
            <mat-form-field appearance="standard">
                <mat-label>Servicers</mat-label>
                <mat-select (selectionChange)="updateServicers($event)" [formControl]="formControl" multiple>
                    <mat-option *ngFor="let servicer of servicers" [value]="servicer">{{ servicer }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-lg-4">
            <app-mesbase-picker (onMesBaseChange)="updateMesbase($event)"></app-mesbase-picker>
        </div>
    </div>
</div>
<div class="container">
    <div class="row" style="float: right">
        <button [disabled]="isDisabled()" mat-raised-button color="primary" (click)="submit()">Disparar</button>
    </div>
</div>
