import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LoginComponent } from '../app/modules/login/login.component'
import { IndexComponent } from './modules/index/index.component'
import { SignupComponent } from '../app/modules/signup/signup.component'
import { AuthGuardService } from '../app/services/auth-guard.service'
import { AuthRedirectService } from '../app/services/auth-redirect.service'
import { UploadServicersComponent } from './modules/gestao/uploadservicers/uploadservicers.component'
import { VisualizationsComponent } from './modules/gestao/visualizations/visualizations.component'
import { ControladoriaComponent } from './modules/controladoria/controladoria.component'
import { GestaoComponent } from './modules/gestao/gestao/gestao.component'
import { NotfoundComponent } from './modules/notfound/notfound.component'
import { ContratosComponent } from './modules/gestao/contratos/contratos.component'
import { DashboardprojetoComponent } from './modules/gestao/dashboardprojeto/dashboardprojeto.component'
import { ControladoriaindexComponent } from './modules/controladoria/controladoriaindex/controladoriaindex.component'
import { CrilistComponent } from './modules/controladoria/crilist/crilist.component'
import { CridetailComponent } from './modules/controladoria/cridetail/cridetail.component'
import { UploadVortxComponent } from './modules/controladoria/upload-vortx/upload-vortx.component'
import { CalculosComponent } from './modules/controladoria/calculos/calculos.component'
import { MarcacaoMercadoComponent } from './modules/controladoria/marcacao-mercado/marcacao-mercado.component'
import { NovoProjetoComponent } from './modules/gestao/cadastro/novo-projeto/novo-projeto.component'
import { NovoProjetoCategoriaComponent } from './modules/gestao/cadastro/novo-projeto-categoria/novo-projeto-categoria.component'
import { NovoProjetoCategoriaCarteiraComponent } from './modules/gestao/cadastro/novo-projeto-categoria-carteira/novo-projeto-categoria-carteira.component'
import { ProjetoListComponent } from './modules/gestao/list/projeto-list/projeto-list.component'
import { ProjetoUpdateComponent } from './modules/gestao/update/projeto-update/projeto-update.component'
import { ProjetoCategoriaListComponent } from './modules/gestao/list/projeto-categoria-list/projeto-categoria-list.component'
import { ProjetoCategoriaUpdateComponent } from './modules/gestao/update/projeto-categoria-update/projeto-categoria-update.component'
import { DemonstrativoCaixaComponent } from './modules/controladoria/demonstrativo-caixa/demonstrativo-caixa.component'
import { CriUpdateComponent } from './modules/controladoria/update/cri-update/cri-update.component'
import { RegrasArredondamentoViewComponent } from './modules/controladoria/regras-arredondamento-view/regras-arredondamento-view.component'
import { NovoEmpreendimentoComponent } from './modules/gestao/cadastro/novo-empreendimento/novo-empreendimento.component'
import { EmpreendimentoListComponent } from './modules/gestao/list/empreendimento-list/empreendimento-list.component'
import { EmpreendimentoUpdateComponent } from './modules/gestao/update/empreendimento-update/empreendimento-update.component'
import { CriCreateComponent } from './modules/controladoria/create/cri-create/cri-create.component'
import { LancamentoContaComponent } from './modules/controladoria/lancamento-conta/lancamento-conta.component'
import { MovimentacaoCaixaComponent } from './modules/controladoria/movimentacao-caixa/movimentacao-caixa.component'
import { TabelaResumoComponent } from './modules/gestao/tabela-resumo/tabela-resumo.component'
import { DurationComponent } from './modules/controladoria/duration/duration.component'
import { MunicipiosComponent } from './modules/controladoria/municipios/municipios.component'
import { EventosComponent } from './modules/gestao/calendario/eventos/eventos.component'
import { UploadcrisComponent } from './modules/gestao/uploadcris/uploadcris.component'
import { NotasComponent } from './modules/gestao/notas/notas.component'
import { CalendarioComponent } from './modules/gestao/calendario/calendario.component'
import { CarteiraListComponent } from './modules/gestao/list/carteira-list/carteira-list.component'
import { CadastrosCardComponent } from './modules/gestao/cadastros-card/cadastros-card.component'
import { AdministradorListComponent } from './modules/gestao/list/administrador-list/administrador-list.component'
import { AgenteFiduciarioListComponent } from './modules/gestao/list/agente-fiduciario-list/agente-fiduciario-list.component'
import { SetorListComponent } from './modules/gestao/list/setor-list/setor-list.component'
import { AtivoListComponent } from './modules/gestao/list/ativo-list/ativo-list.component'
import { TipoProjetoListComponent } from './modules/gestao/list/tipo-projeto-list/tipo-projeto-list.component'
import { EmissorListComponent } from './modules/gestao/list/emissor-list/emissor-list.component'
import { ServicerListComponent } from './modules/gestao/list/servicer-list/servicer-list.component'
import { GrupoEconomicoListComponent } from './modules/gestao/list/grupo-economico-list/grupo-economico-list.component'
import { DistribuicaoComponent } from './modules/controladoria/distribuicao/distribuicao.component'
import { TradesComponent } from './modules/controladoria/trades/trades.component'

const routes: Routes = [
    { path: 'login', component: LoginComponent, canActivate: [AuthRedirectService] },
    { path: 'signup', component: SignupComponent, canActivate: [AuthRedirectService] },
    { path: '', component: IndexComponent, canActivate: [AuthGuardService] },
    {
        path: 'gestao',
        canActivate: [AuthGuardService],
        children: [
            { path: '', component: GestaoComponent },
            { path: 'upload_servicers', component: UploadServicersComponent },
            { path: 'upload_cris', component: UploadcrisComponent },
            { path: 'reports', component: VisualizationsComponent },
            { path: 'contratos', component: ContratosComponent },
            { path: 'tabela_resumo', component: TabelaResumoComponent },
            { path: 'notas', component: NotasComponent},
            { path: 'dashboard_projeto', component: DashboardprojetoComponent },
            { path: 'calendario', component: CalendarioComponent },
            { path: 'cadastros', component: CadastrosCardComponent },
            {
                path: 'create',
                children: [
                    { path: 'cri', component: NovoProjetoComponent},
                    { path: 'cri_categoria', component: NovoProjetoCategoriaComponent},
                    { path: 'projeto_categoria_carteira', component: NovoProjetoCategoriaCarteiraComponent},
                    { path: 'empreendimento', component: NovoEmpreendimentoComponent },
                ],
            },
            {
                path: 'list',
                children: [
                    { path: 'cris', component: ProjetoListComponent },
                    { path: 'cris_categorias', component: ProjetoCategoriaListComponent },
                    { path: 'empreendimentos', component: EmpreendimentoListComponent },
                    { path: 'carteiras', component: CarteiraListComponent },
                    { path: 'administradores', component: AdministradorListComponent },
                    { path: 'agentes_fiduciarios', component: AgenteFiduciarioListComponent },
                    { path: 'setores', component: SetorListComponent },
                    { path: 'ativos', component: AtivoListComponent },
                    { path: 'tipos_projetos', component: TipoProjetoListComponent },
                    { path: 'emissores', component: EmissorListComponent },
                    { path: 'servicers', component: ServicerListComponent },
                    { path: 'grupos_economicos', component: GrupoEconomicoListComponent },
                ],
            },
            {
                path: 'edit',
                children: [
                    { path: 'cri', component: ProjetoUpdateComponent },
                    { path: 'cri_categoria', component: ProjetoCategoriaUpdateComponent },
                    { path: 'empreendimento', component: EmpreendimentoUpdateComponent },
                ],
            },
        ],
    },
    {
        path: 'controladoria',
        canActivate: [AuthGuardService],
        children: [
            { path: '', component: ControladoriaindexComponent },
            { path: 'upload_bases', component: ControladoriaComponent },
            { path: 'cri_list', component: CrilistComponent },
            { path: 'distribuicao', component: DistribuicaoComponent },
            { path: 'trades', component: TradesComponent },
            { path: 'pu', component: CridetailComponent },
            { path: 'upload_vortx', component: UploadVortxComponent },
            { path: 'lancamento_conta', component: LancamentoContaComponent },
            { path: 'calculos', component: CalculosComponent },
            { path: 'marcacao_mercado', component: MarcacaoMercadoComponent },
            { path: 'demonstrativo_caixa', component: DemonstrativoCaixaComponent },
            { path: 'movimentacao_caixa', component: MovimentacaoCaixaComponent },
            { path: 'regras_arredondamento', component: RegrasArredondamentoViewComponent },
            { path: 'duration', component: DurationComponent },
            { path: 'municipios', component: MunicipiosComponent },
            {
                path: 'edit',
                children: [{ path: 'cri', component: CriUpdateComponent }],
            },
            {
                path: 'create',
                children: [{ path: 'cri', component: CriCreateComponent }],
            },
        ],
    },
    { path: '**', component: NotfoundComponent },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
