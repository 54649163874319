<div class="container">
    <div class="row">
        <div class="col-md-12 col-lg-6">
            <!--
                <img src="assets/habitat_logo.png" alt="">
            -->
            <div>
                <h1>Habitat Capital Partners</h1>
            </div>
        </div>
        <div class="col-md-12 col-lg-6">
            <div class="jumbotron">
                <h1>Login</h1>
                <form>
                    <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">Email</label>
                        <input type="email" class="form-control" id="{{ emailField }}" />
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">Senha</label>
                        <input type="password" class="form-control" id="{{ passwordField }}" />
                    </div>
                    <div class="col" id="formCol">
                        <div>
                            <a href="/signup">Primeiro acesso</a>
                        </div>
                        <div>
                            <button (click)="logIn($event)" type="submit" class="btn btn-primary">Entrar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
