<div class="outter-container">
    <div class="container">
        <div class="alert alert-info" role="alert">
            <h4 class="alert-heading">Oops!</h4>
            <hr />
            <p>Parece que você está tentando acessar uma página que não existe ou que foi removida.</p>
            <p>Clique no botão para retornar à navegação</p>
            <button mat-raised-button (click)="buttonClick($event)">Voltar para o Início</button>
        </div>
    </div>
</div>
