import { Component, OnInit, Renderer2 } from '@angular/core'
import { RequestService } from 'src/app/services/request.service'
import { Router } from '@angular/router'
import { AppSettings } from 'src/app/constants/AppSettings'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
    public emailField = 'emailField'
    public passwordField = 'passwordField'
    constructor(private request: RequestService, private router: Router, private rendered: Renderer2) {}

    logIn(event: any) {
        event.preventDefault()
        var email = (<HTMLInputElement>document.getElementById(this.emailField)).value
        var password = (<HTMLInputElement>document.getElementById(this.passwordField)).value
        this.request.postRequest(AppSettings.API_ENDPOINT + 'login', { email: email, password: password }).subscribe(
            (returnData) => {
                // Writes in local storage
                var aux = JSON.parse(JSON.stringify(returnData))
                localStorage.setItem('token', aux['token'])

                this.router.navigate([''])
            },
            (error) => {
                alert(error['error']['message'])
            }
        )
    }
    ngOnInit(): void {}
    ngOnDestroy(): void {}
}
