import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Emissor } from 'src/app/interfaces/emissor'

@Component({
    selector: 'app-emissores-select',
    templateUrl: './emissores-select.component.html',
    styleUrls: ['./emissores-select.component.css'],
})
export class EmissoresSelectComponent implements OnInit {
    @Input('emissores') emissores: Emissor[] = []
    @Input('emissorId') emissorId: number = 0
    @Output() onEmissorChange = new EventEmitter()

    constructor() {}

    emitEmissorID(event: any) {
        this.onEmissorChange.emit(event.value)
    }

    ngOnInit(): void {}
}
