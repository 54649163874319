import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Categoria } from 'src/app/interfaces/categoria'
import { IndexadorMensal } from 'src/app/interfaces/indexador_mensal'
import { ProjetoCategoria } from 'src/app/interfaces/projeto_categoria'
import { Projeto } from 'src/app/interfaces/projeto'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Carteira } from 'src/app/interfaces/carteira'

@Component({
    selector: 'app-projeto-categoria-form',
    templateUrl: './projeto-categoria-form.component.html',
    styleUrls: ['./projeto-categoria-form.component.css'],
})
export class ProjetoCategoriaFormComponent implements OnInit {
    @Input('projetos') projetos: Projeto[] = []
    @Input('categorias') categorias: Categoria[] = []
    @Input('indexadores') indexadores: IndexadorMensal[] = []
    @Input('carteiras') carteiras: Carteira[] = []
    @Input('update') update: boolean = false
    @Input('projetoCategoria') projetoCategoria: ProjetoCategoria = {
        ProjetoID: 0,
        CategoriaID: 0,
        ValorEmissao: 0,
        DataEmissao: '',
        DataVencto: '',
        TIRR: 0,
        IndexMensalID: 0,
        CarteiraID: 0,
    }
    @Output() onSubmit = new EventEmitter()

    public buttonText: string = ''
    submit() {
        if (Object.values(this.projetoCategoria).some((v) => v == null || v == undefined || v == '' || v == NaN || v == 0)) {
            this._snackBar.open('Preencha os campos', 'Fechar', { duration: 5000 })
            return
        }

        this.onSubmit.emit(this.projetoCategoria)
    }

    updateDataEmissao(date: Date) {
        this.projetoCategoria.DataEmissao = date.toISOString().split('T')[0]
    }

    updateDataVencimento(date: Date) {
        this.projetoCategoria.DataVencto = date.toISOString().split('T')[0]
    }

    constructor(private _snackBar: MatSnackBar) {}

    ngOnInit(): void {
        this.buttonText = this.update ? 'Salvar alterações' : 'Cadastrar'
    }
}
