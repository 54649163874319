<app-navbar></app-navbar>

<div *ngIf="success">
    <app-success-alert [text]="'Empreendimento alterado com sucesso'"></app-success-alert>
</div>
<div *ngIf="error">
    <app-error-alert [text]="errorMessage"></app-error-alert>
</div>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="bottom-overlay">
    <div style="margin-top: 20px" [ngClass]="loading.length ? 'disabled-div' : ''">
        <div *ngIf="canDisplay()">
            <app-empreendimento-form
                [projetos]="projetos"
                [municipios]="municipios"
                (onSubmit)="updateEmpreendimento($event)"
                [unidadesFederativas]="unidadesFederativas"
                [empreendimento]="empreendimento"
                [update]="true"
            ></app-empreendimento-form>
        </div>
    </div>
    <div *ngIf="loading.length" class="top-overlay">
        <mat-spinner></mat-spinner>
    </div>
</div>
