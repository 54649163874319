<form>
    <mat-form-field appearance="standard">
        <mat-label>UF</mat-label>
        <input type="text" placeholder="Selecione a UF" matInput [formControl]="UFFormControl"
            [matAutocomplete]="autoUF">
        <mat-autocomplete autoActiveFirstOption #autoUF="matAutocomplete"
            (optionSelected)="changeUF($event.option.value)">
            <mat-option *ngFor="let uf of filteredUFs | async" [value]="uf">
                {{uf}}
            </mat-option>
        </mat-autocomplete>
        <button mat-icon-button matSuffix (click)="clearUF()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
</form>