import { Component, Input, OnInit } from '@angular/core'
import { Value } from 'src/app/interfaces/values'

@Component({
    selector: 'app-values-stacked-chart',
    templateUrl: './values-stacked-chart.component.html',
    styleUrls: ['./values-stacked-chart.component.css'],
})
export class ValuesStackedChartComponent implements OnInit {
    @Input('valueCA') valueCA: Value[] = []
    @Input('valueCI') valueCI: Value[] = []
    @Input('valueE') valueE: Value[] = []
    @Input('valuesPorObra') valuesPorObra: any[] = []
    @Input('obras') obras: string[] = []
    
    // options
    showXAxis: boolean = true
    showYAxis: boolean = true
    gradient: boolean = false
    showLegend: boolean = false
    showXAxisLabel: boolean = true
    showYAxisLabel: boolean = true
    xAxisLabel: string = ''
    yAxisLabel: string = ''
    animations: boolean = true
    showGridLines: boolean = false
    barPadding: number = 45
    
    data: any[] = []
    selectedObra: string = 'Total'

    colorScheme = {
        domain: ['#E87D7D', '#EE0404', '#8E8E8E'],
    }

    constructor() {}

    ngOnInit(): void {
        this.assembleValuesData(this.selectedObra)
    }

    changeObra(event:any){
        let obra = event.value
        this.selectedObra = obra
        this.assembleValuesData(obra)
    }

    assembleValuesData(obra:string): void {
        if ((obra) == "Total"){
            this.assembleValuesDataTotal()
        } else [
             this.assembleValuesDataObra(obra)
        ]
    }

    assembleValuesDataTotal():void{
        this.data = []
        for (let i = this.valueCA.length - 1; i > -1; i--) {
            this.data.push({
                name: this.formatDate(i),
                series: [
                    {
                        name: 'Adimplente',
                        value: this.valueCA[i].Valor,
                    },
                    {
                        name: 'Inadimplente',
                        value: this.valueCI[i].Valor,
                    },
                    {
                        name: 'Estoque',
                        value: this.valueE[i].Valor,
                    },
                ],
            })
        }
    }

    assembleValuesDataObra(obra:string):void{
        this.data = []
        for (let i = 0; i<this.valuesPorObra.length; i++){
            if (this.valuesPorObra[i][0].Sigla == obra){
                for (let j=this.valuesPorObra[i].length-1; j > -1; j--){
                    this.data.push({
                        name: this.formatDate(j),
                        series: [
                            {
                                name: 'Adimplente',
                                value: this.valuesPorObra[i][j].Adimplente,
                            },
                            {
                                name: 'Inadimplente',
                                value: this.valuesPorObra[i][j].Inadimplente,
                            },
                            {
                                name: 'Estoque',
                                value: this.valuesPorObra[i][j].Estoque,
                            },
                        ],
                    })
                }
            }
        }
    }

    formatDataLabel(value: number) {
        return (value / 1e6).toFixed(2) + ' MM'
    }

    formatDate(index: number) {
        let date_options = { year: 'numeric', month: '2-digit' }
        let str_month_year = new Date(this.valueCA[index].Data).toLocaleDateString('pt-br')
        let month = str_month_year.split('/')[1]
        let year = str_month_year.split('/')[2]

        if (index == this.valueCA.length - 1 || month == '01') {
            return month + '/' + year
        } else return month
    }
}
