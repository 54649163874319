import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Contas } from 'src/app/interfaces/contas'

@Component({
    selector: 'app-editar-conta-dialog',
    templateUrl: './editar-conta-dialog.component.html',
    styleUrls: ['./editar-conta-dialog.component.css'],
})
export class EditarContaDialogComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditarContaDialogComponent>) {}

    public conta: Contas = this.data.conta

    ngOnInit(): void {}

    save(): void {
        const params = {
            conta: this.conta,
            save: true,
        }
        this.dialogRef.close(params)
    }

    cancel(): void {
        const params = {
            conta: this.conta,
            save: false,
        }
        this.dialogRef.close(params)
    }

    isButtonDisabled(): boolean {
        return this.conta.Nome == ''
    }
}
