<div class="gastos-pie-chart">
    <apx-chart  
        [series]="options.series"
        [chart]="options.chart"
        [labels]="options.labels"
        [dataLabels]="options.dataLabels"
        [colors]="options.colors"
        [legend]="options.legend"
        [responsive]="options.responsive">
    </apx-chart>
</div>