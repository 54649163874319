<div style="padding: 10px 10px 10px 10px">
    <h4>Desbloquear Tabela Resumo</h4>

    <p class="texto">Os dados da tabela para o mês base selecionado já foram consolidados. Deseja desbloquear para modificações?</p>

    <div class="row" style="float: right">
        <button (click)="cancel()" style="margin-right: 20px" mat-raised-button>Cancelar</button>
        <button (click)="save()" mat-raised-button color="primary">Desbloquear Mês</button>
    </div>
</div>
