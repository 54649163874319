<app-navbar></app-navbar>

<div *ngIf="success">
    <app-success-alert [text]="'CRI-Categoria atualizado com sucesso'"></app-success-alert>
</div>
<div *ngIf="error">
    <app-error-alert [text]="errorMessage"></app-error-alert>
</div>

<div class="bottom-overlay" style="margin-top: 15px">
    <div [ngClass]="loading.length > 0 ? 'disabled-div' : ''">
        <app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

        <div style="margin-top: 15" *ngIf="canDisplay()">
            <app-projeto-categoria-form
                [projetos]="projetos"
                [categorias]="categorias"
                [indexadores]="indexadores"
                [projetoCategoria]="projetoCategoria"
                [update]="true"
                [carteiras]="carteiras"
                (onSubmit)="submit($event)"
            >
            </app-projeto-categoria-form>
        </div>
    </div>

    <div *ngIf="loading.length > 0" class="top-overlay">
        <mat-spinner></mat-spinner>
    </div>
</div>
