<div #verticalBarChartRef>
    <ngx-charts-bar-vertical
        id="chart"
        [view]="[verticalBarChartRef.offsetWidth, height]"
        [results]="data"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [gradient]="gradient"
        [yScaleMax]="yScaleMax"
        [yScaleMin]="yScaleMin"
        [showGridLines]="showGridLines"
        [barPadding]="barPadding"
        [showDataLabel]="showDataLabel"
        [customColors]="customColors"
    >
    </ngx-charts-bar-vertical>
</div>
