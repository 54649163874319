<app-navbar></app-navbar>

<app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs>

<div class="bottom-overlay">
    <div [ngClass]="loading ? 'disabled-div' : ''">
        <div class="container" style="margin-top: 20px">
            <div class="row">
                <div class="col-12">
                    <app-h2title [text]="'Empreendimentos'"></app-h2title>
                </div>
            </div>
        </div>

        <div *ngIf="!loading" class="container" style="margin-bottom: 20px; margin-top: 15px">
            <div class="row">
                <div class="col-lg-12">
                    <cdk-virtual-scroll-viewport style="height: 500px; border: 1px solid rgb(243, 241, 241)" itemSize="50">
                        <table style="width: 100%" id="customTable">
                            <tr>
                                <th></th>
                                <th>Empreendimento</th>
                                <th>CRI</th>
                                <th>Fator de Ajuste Parcela (%)</th>
                                <th>Considera parcela Balão?</th>
                                <th>UF</th>
                                <th>Município</th>
                            </tr>
                            <tr *ngFor="let emp of empreendimentos">
                                <td>
                                    <a href="gestao/edit/empreendimento?obra_id={{ emp.ID }}">
                                        <mat-icon>edit</mat-icon>
                                    </a>
                                </td>
                                <td>{{ emp.Obra }}</td>
                                <td>{{ emp.Projeto }}</td>
                                <td>{{ emp.FatorAjParcelaCorrigida }}</td>
                                <td>
                                    <div *ngIf="emp.ConsiderarParcelaBalao; else cross">
                                        <mat-icon>done</mat-icon>
                                    </div>
                                    <ng-template #cross>
                                        <mat-icon>close</mat-icon>
                                    </ng-template>
                                </td>
                                <td>{{ emp.UF }}</td>
                                <td>{{ emp.Municipio }}</td>
                            </tr>
                        </table>
                    </cdk-virtual-scroll-viewport>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <a href="gestao/create/empreendimento" style="margin-top: 20px; margin-bottom: 30px" mat-raised-button color="primary">
                        <mat-icon style="margin-left: -10px">add</mat-icon>
                        Cadastrar Novo Empreendimento
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="top-overlay">
        <div *ngIf="loading">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>
