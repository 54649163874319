import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Breadcrumb } from 'src/app/interfaces/breadcrumb';
import { TipoProjeto } from 'src/app/interfaces/tipo_projeto';
import { ProjetosService } from 'src/app/services/projetos.service'
import { TipoProjetoEditDialogComponent } from './tipo-projeto-edit-dialog/tipo-projeto-edit-dialog.component';

@Component({
  selector: 'app-tipo-projeto-list',
  templateUrl: './tipo-projeto-list.component.html',
  styleUrls: ['./tipo-projeto-list.component.css']
})
export class TipoProjetoListComponent implements OnInit {

    constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public projetoService: ProjetosService) {
  }
  private ngUnsubscriber = new Subject()
  
  public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Cadastros', href: 'gestao/cadastros' },
        { text: 'Tipos Projetos', href: 'gestao/list/tipos_projetos' },
  ]

  public loading: boolean = false
  public tiposProjetos: TipoProjeto[] = []

  createTipoProjetoDialog(): void {
    const tipoProjetoDialog = this.dialog.open(TipoProjetoEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            tipoProjeto: {ID:-1, Nome:""},
        },
    })

    tipoProjetoDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading = true
          this.createTipoProjeto(params.tipoProjeto)
        }
        return
    })
  }

  createTipoProjeto(tipoProjeto:TipoProjeto):void{
    let { TipoID, ...tipoProjetoWithoutID} = tipoProjeto

    this.projetoService
        .createTipoProjeto(tipoProjetoWithoutID)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'Tipo Projeto criado com sucesso') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar tipo projeto', 'error-snackbar')
                }
                this.getTiposProjetos()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar tipo projeto' + errorData.error.message, 'error-snackbar')
              this.getTiposProjetos()
            }
        )
  }

  updateTipoProjetoDialog(tipoProjeto: any): void {
    const tipoProjetoDialog = this.dialog.open(TipoProjetoEditDialogComponent, {
        width: '70%',
        height: 'auto',
        autoFocus: false,
        position: {
            top: '100px',
        },
        data: {
            tipoProjeto: tipoProjeto,
            create: false,
        },
    })

    tipoProjetoDialog.afterClosed().subscribe((result:any) => {
        var params = result
        if (params.save) {
          this.loading = true
          this.updateTipoProjeto(params.tipoProjeto)
        }
        return
    })
  }

  updateTipoProjeto(tipoProjeto:TipoProjeto):void{
    this.projetoService
        .updateTipoProjeto(tipoProjeto)
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe(
            (returnData: any) => {
                if (returnData.message == 'Tipo Projeto atualizado com sucesso') {
                    this.openSnackbar(returnData.message, 'success-snackbar')
                } else {
                    this.openSnackbar('Erro ao criar/editar tipo projeto', 'error-snackbar')
                }
                this.getTiposProjetos()
            },
            (errorData: any) => {
              this.openSnackbar('Erro ao criar/editar tipo projeto' + errorData.error.message, 'error-snackbar')
              this.getTiposProjetos()
            }
        )
  }

  ngOnInit(): void {
    this.loading = true
    this.getTiposProjetos()
  }

  getTiposProjetos():void{
    this.projetoService
        .getTiposProjeto()
        .pipe(takeUntil(this.ngUnsubscriber))
        .subscribe((returnData: any) => {
            console.log(returnData)
            this.tiposProjetos = returnData.tipos
            this.loading = false
        })
  }

  openSnackbar(message: string, messageType: string): void {
    this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
  }

}
