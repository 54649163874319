import { Component, OnInit, EventEmitter, Output } from '@angular/core'
import { MatDatepicker } from '@angular/material/datepicker'
import { FormControl } from '@angular/forms'
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import * as _moment from 'moment'
import { Moment } from 'moment'

const moment = _moment
const today = new Date()
const janDoismilVinte = new Date(2020, 0, 1)
const delta = today.getTime() - janDoismilVinte.getTime()

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
}

@Component({
    selector: 'app-mesbase-picker',
    templateUrl: './mesbase-picker.component.html',
    styleUrls: ['./mesbase-picker.component.css'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class MesbasePickerComponent implements OnInit {
    @Output() onMesBaseChange = new EventEmitter()
    date = new FormControl(moment(today.getTime() - delta))

    chosenYearHandler(normalizedYear: Moment) {
        const ctrlValue = this.date.value
        ctrlValue.year(normalizedYear.year())
        this.date.setValue(ctrlValue)
    }

    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        const ctrlValue = this.date.value
        ctrlValue.month(normalizedMonth.month())
        this.date.setValue(ctrlValue)
        const date = ctrlValue._d

        const year = date.getUTCFullYear()
        const month = date.getUTCMonth() + 1

        let strMonth = month.toString()
        if (strMonth.length == 1) {
            strMonth = '0' + strMonth
        }
        const stringDate = year.toString() + strMonth

        this.onMesBaseChange.emit(stringDate)

        datepicker.close()
    }

    constructor() {}

    ngOnInit(): void {
        this.date.disable()
    }
}
