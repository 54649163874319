<form class="example-form">
    <mat-form-field appearance="standard">
        <input type="text" placeholder="Selecione um Projeto Categoria" matInput [formControl]="projCatControl" [matAutocomplete]="autoProjCat" />
        <mat-autocomplete autoActiveFirstOption #autoProjCat="matAutocomplete" (optionSelected)="changeProjetoCategoria($event.option.value)">
            <mat-option *ngFor="let pc of filteredProjCat | async" [value]="pc">
                {{ pc }}
            </mat-option>
        </mat-autocomplete>
        <button mat-icon-button matSuffix (click)="clearProjeto()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
</form>
