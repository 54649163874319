export function dateToString(date:Date, format:string) {

  if (typeof(date) == 'string'){
    date = new Date(date)
  }

  if (format === "MM/YYYY"){
        return date.toLocaleDateString().slice(3)
    } else if (format === "YYYY-MM-DD"){
        const offset = date.getTimezoneOffset()
        date = new Date(date.getTime() - (offset*60*1000))
        return date.toISOString().split('T')[0]
    } else if (format === "DD/MM/YYYY"){
        return date.toLocaleDateString('pt-br')
    } else if (format == "monthShortName") {
        const monthShortNames = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
        "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
        return monthShortNames[date.getMonth()]
    } else if (format == "long"){
    }
    return date.toLocaleDateString()
}