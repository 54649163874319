import { Injectable } from '@angular/core'
import { RequestService } from './request.service'

@Injectable({
    providedIn: 'root',
})
export class CaixaService {
    constructor(private http: RequestService) {}
    private demonstrativoCaixaBlueprintEndpoint: string = this.http.apiEndpoint + 'demonstrativo_caixa/'

    public uploadDemonstrativoCaixa(data: any) {
        return this.http.promisePostRequest(this.demonstrativoCaixaBlueprintEndpoint + 'upload', data)
    }

    public getMovimentacaoCaixa(data: any) {
        return this.http.getRequest(this.demonstrativoCaixaBlueprintEndpoint + 'movimentacao', data)
    }

    public insertUpdateMovimentacao(data: any) {
        return this.http.postRequest(this.demonstrativoCaixaBlueprintEndpoint + 'movimentacao/create_update', data)
    }

    public getClassificacoes(data: any) {
        return this.http.getRequest(this.demonstrativoCaixaBlueprintEndpoint + 'classificacao', data)
    }
}
