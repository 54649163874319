import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'

@Component({
    selector: 'app-formated-number-input',
    templateUrl: './formated-number-input.component.html',
    styleUrls: ['./formated-number-input.component.css'],
})
export class FormatedNumberInputComponent implements OnInit, OnChanges {
    @Input('valor') value: number = 0
    public strValue: string = ''
    @Output('output') output = new EventEmitter()

    constructor() {}

    ngOnInit(): void {
        this.strValue = this.value.toString()
    }

    onOutputChange(value: number): void {
        this.output.emit(value)
    }

    ngOnChanges(): void {}
}
