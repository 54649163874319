import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DashboardProjetoService } from 'src/app/services/dashboard-projeto.service';
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs';

@Component({
    selector: 'app-tab-estoque',
    templateUrl: './tab-estoque.component.html',
    styleUrls: ['./tab-estoque.component.css'],
})
export class TabEstoqueComponent implements OnInit, AfterViewChecked {
    @Input('estoqueData') estoqueData: any
    @Input('estoqueMetodologiaID') estoqueMetodologiaID: number = 2
    @Input('projetoID') projetoID: number = -1
    @Input('mesBase') mesBase: string = ''

    private ngUnsubscribe = new Subject()

    // File Upload
    public noUnidadesData: boolean = true
    public noVendasData: boolean = true
    public noData: boolean = true
    public selectedFile: any = null
    public files: File[] = []
    public uploadFilesActive: boolean = false
    public loadingFileUpload: boolean = false

    // Estoque Data
    public unidadesDisponiveis: number = 0
    public areaTotalDisponivel: number = 0
    precoM2MaxInicioOperacao: number = 0
    precoM2MinInicioOperacao: number = 0
    precoM2MedioInicioOperacao: number = 0
    precoM2Max12Meses: number = 0
    precoM2Min12Meses: number = 0
    precoM2Medio12Meses: number = 0
    precoM2Max6Meses: number = 0
    precoM2Min6Meses: number = 0
    precoM2Medio6Meses: number = 0
    precoMedioUnidade: number = 0

    // Gráfico 1 - Preço por M2
    curvaPrecoM2: number[] = []
    curvaNovasVendas: number[] = []
    curvaDatas: string[] = []

    public chartPrecoM2Options: any
    public zoomElement: any
    public zoomClicked1: boolean = false
    public zoomClicked2: boolean = false

    // Gráfico 2 - Qtd Parcelas Das Novas Vendas 
    curvaParcelas: any[] = []
    curvaDatasParcelas: string[] = []
    public chartParcelasOptions: any
    constructor(private dashboardService: DashboardProjetoService, private _snackBar: MatSnackBar) {}

    ngOnInit(): void {
        this.noUnidadesData = this.estoqueData.no_unidades_data
        this.noVendasData = this.estoqueData.no_vendas_data

        this.noData = this.noVendasData || this.noUnidadesData
        if (!this.noData) {
            this.unidadesDisponiveis = this.estoqueData.unidades_disponiveis
            this.areaTotalDisponivel = this.estoqueData.total_m2_disponivel

            this.precoM2MaxInicioOperacao = this.estoqueData.preco_m2_max_inicio_operacao
            this.precoM2MinInicioOperacao = this.estoqueData.preco_m2_min_inicio_operacao
            this.precoM2MedioInicioOperacao = this.estoqueData.preco_m2_medio_inicio_operacao
            this.precoM2Max12Meses = this.estoqueData.preco_m2_max_12_meses
            this.precoM2Min12Meses = this.estoqueData.preco_m2_min_12_meses
            this.precoM2Medio12Meses = this.estoqueData.preco_m2_medio_12_meses
            this.precoM2Max6Meses = this.estoqueData.preco_m2_max_6_meses
            this.precoM2Min6Meses = this.estoqueData.preco_m2_min_6_meses
            this.precoM2Medio6Meses = this.estoqueData.preco_m2_medio_6_meses
            this.precoMedioUnidade = this.estoqueData.preco_medio_unidade

            this.curvaDatas = []
            this.curvaPrecoM2 = []
            this.curvaNovasVendas = []
            for (let i = 0; i < this.estoqueData.curva_preco_m2_ao_longo_do_tempo.length; i++) {
                let mesStr: string = this.estoqueData.curva_preco_m2_ao_longo_do_tempo[i].Mes.toString()
                this.curvaDatas.push(this.formatDate(i, mesStr))
                this.curvaPrecoM2.push(this.estoqueData.curva_preco_m2_ao_longo_do_tempo[i].PrecoM2)
                this.curvaNovasVendas.push(this.estoqueData.curva_vendas_novas_por_mes[i].QuantidadeVendas)
            }

            this.curvaParcelas = this.estoqueData.curva_parcelas_totais_das_vendas_por_mes

            // console.log("Curvas Parcelas")
            // console.log(this.curvaParcelas)
            
        }

        if (this.noData){
            this.uploadFilesActive = true
        }

        this.assembleChartPrecoM2()
        this.assembleChartParcelas()

        this.zoomElement = new ElementRef(document.getElementsByClassName('apexcharts-reset-icon'))
    }

    assembleChartPrecoM2(){
        
        this.chartPrecoM2Options = {
            colors: ['#E87D7D', '#999999'],
            series: [
                {
                    name: 'Novas Vendas',
                    type: 'column',
                    data: this.curvaNovasVendas,
                },
                {
                    name: 'Preço/m² médio',
                    type: 'line',
                    data: this.curvaPrecoM2,
                },
            ],
            chart: {
                height: 350,
                type: 'line',
            },
            stroke: {
                width: [0, 2],
            },
            title: {
                text: 'Evolução Preço/m²',
            },
            dataLabels: {
                enabled: false,
                enabledOnSeries: [1],
            },
            labels: this.curvaDatas,
            xaxis: {
                tickAmount: 8,
                labels: {
                    hideOverlappingLabels: true,
                },
            },
            yaxis: [
                {
                    title: {
                        text: 'Novas Vendas',
                    },
                },
                {
                    opposite: true,
                    title: {
                        text: 'Preço/m² médio',
                    },
                },
            ],
        }
    }

    assembleChartParcelas(){
        this.chartParcelasOptions = {
            colors: ['#E87D7D', '#999999'],
            series: [
                {
                    name: 'Parcelas',
                    data: this.curvaParcelas,
                },
            ],
            chart: {
                height: 350,
                type: 'scatter',
            },
            title: {
                text: 'Concentração Parcelas Novas Vendas',
            },
            xaxis: {
                labels: {
                    format: 'MM/yyyy',
                    hideOverlappingLabels: true,
                },
                type: 'datetime',
            },
            yaxis: [
                {
                    title: {
                        text: 'Quantidade Parcelas',
                    },
                },
            ],
        }
    }

    formatDate(index: number, date: string) {
        let year = date.slice(0, 4)
        let month = date.slice(4, 6)

        if (index == 0 || month == '01') {
            return month + '/' + year
        } else return month
    }

    ngAfterViewChecked() {
        this.zoomElement = new ElementRef(document.getElementsByClassName('apexcharts-reset-icon'))
        if (this.zoomElement.nativeElement.length == 1 && !this.zoomClicked1) {
            this.zoomElement.nativeElement[0].click()
            this.zoomClicked1 = true
        }
        if (this.zoomElement.nativeElement.length == 2 && !this.zoomClicked2) {
            this.zoomElement.nativeElement[0].click()
            this.zoomElement.nativeElement[1].click()
            this.zoomClicked2 = true
        }
    }

    updateFiles(event: any): void {
        this.files = []
        this.files.push(event[0])
    }

    sendFiles(): void {
        this.loadingFileUpload = true
        let formData = new FormData()

        formData.append('file', this.files[0])
        formData.append('projeto_id', this.projetoID.toString())
        formData.append('mes_base', this.mesBase)

        this.dashboardService
            .uploadUnidades(formData)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    let message = returnData.message
                    this.loadingFileUpload = false
                    if (message == 'Upload de unidades concluído com sucesso') {
                        this.openSnackbar('Unidades enviadas com sucesso', 'success-snackbar')
                    } else {
                        this.openSnackbar(
                            'Não foi possível atualizar o arquivo. O arquivo deve conter as colunas: TOTAL ÁREA | ÁREA PRIVATIVA | ÁREA COMUM | UNIDADE | OBRA',
                            'error-snackbar'
                        )
                    }
                },
                (errorData: any) => {
                    this.loadingFileUpload = false
                    this.openSnackbar(
                        'Não foi possível atualizar o arquivo. O arquivo deve conter as colunas: TOTAL ÁREA | ÁREA PRIVATIVA | ÁREA COMUM | UNIDADE | OBRA',
                        'error-snackbar'
                    )
                }
            )
    }

    openSnackbar(message: string, messageType: string): void {
        this._snackBar.open(message, 'Fechar', { duration: 5000, panelClass: [messageType] })
    }
}
