<app-navbar style="position: fixed; top: 0; z-index: 1030; width: 100%"></app-navbar>
<div style="margin-top: 88px"></div>
<!-- <app-breadcrumbs [breadcrumbList]="breadcrumbList"></app-breadcrumbs> -->

<div class="row title" style="align-items: center">
    <div class="col-lg-10">
        <app-h2title [text]="'Tabela Resumo'"> </app-h2title>
    </div>
</div>

<div class="row filters">
    <!-- Filtro Mês -->
    <div class="col-lg-1">
        <mat-form-field appearance="standard">
            <mat-label>Mês Base</mat-label>
            <mat-select appearance="standard" [value]="mesBaseSelected" (selectionChange)="changeMesBase($event)">
                <mat-option *ngFor="let mesBase of mesesBase" [value]="mesBase">
                    {{ mesBase }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-lg-1"></div>

    <!-- Filtro Carteira -->
    <div class="col-lg-1">
        <app-carteira-filter-resumo [carteiras]="carteiras" (onCarteiraChange)="filtrarCarteira($event)"></app-carteira-filter-resumo>
    </div>

    <!-- Filtro Servicer -->
    <div class="col-lg-2">
        <app-servicer-filter [servicers]="servicers" (onServicerChange)="filtrarServicer($event)"></app-servicer-filter>
    </div>

    <!-- Filtro Securitizadora -->
    <div class="col-lg-2">
        <app-securitizadora-filter [securitizadoras]="securitizadoras" (onSecuritizadoraChange)="filtrarSecuritizadora($event)">
        </app-securitizadora-filter>
    </div>

    <!-- Filtro Tipo -->
    <div class="col-lg-2">
        <!-- <app-tipo-filter [tipos]="tipos" (onTipoChange)="filtrarTipo($event)"> </app-tipo-filter> -->
    </div>

    <div class="col-lg-1">
        <app-aumento-mensal-select class="aumento-mensal-selector" (onChange)="onValueOrAumentoSelect($event)"> </app-aumento-mensal-select>
    </div>

    <!-- Botão Baixar Excel -->
    <div class="col-lg-1">
        <button class="botao-baixar-excel" (click)="baixarExcel()" mat-raised-button color="primary">
            Baixar Excel <mat-icon> file_download </mat-icon>
        </button>
    </div>
</div>
<div *ngIf="loading.length == 0">
    <div class="row table-container">
        <cdk-virtual-scroll-viewport [itemSize]="200">

            <table class="tabela-resumo" matSort (matSortChange)="sortData($event)">
                <!-- Header do valor atual -->
                <tr *ngIf="variacaoSelected == false">
                    <th class="chave-column" mat-sort-header="CRI">CRI</th>
                    
                    <th mat-sort-header="Carteira">Carteira</th>
                    <th mat-sort-header="SaldoDevedorTabelaResumo">Saldo Devedor</th>
                    <th mat-sort-header="PercFundo">% Fundo</th>
                    <th mat-sort-header="RazaoFluxoMensal">RFM</th>
                    <th mat-sort-header="RazaoFluxoMensalMin">Lim. RFM</th>
                    <th mat-sort-header="RazaoSaldoDevedor">RSD</th>
                    <th mat-sort-header="RazaoSaldoDevedorMin">Lim. RSD</th>
                    <th mat-sort-header="LTVAtual">LTV Atual</th>
                    <th mat-sort-header="LTVFull">LTV Full</th>
                    <th mat-sort-header="PercInadimplencia">% Inad</th>
                    <th mat-sort-header="PercVendas">% Vendas</th>
                    <th mat-sort-header="PercObras">% Obras</th>
                    <th class="comentario-column" mat-sort-header="Comentarios">Comentários</th>
                </tr>
    
                <!-- Header da variação -->
                <tr *ngIf="variacaoSelected == true">
                    <th class="chave-column" mat-sort-header="CRI">Ativo</th>
                    <th mat-sort-header="Carteira">Fundo</th>
                    <th mat-sort-header="VarSaldoDevedor">Saldo Devedor</th>
                    <th mat-sort-header="VarPercFundo">% Fundo</th>
                    <th mat-sort-header="VarRazaoFluxoMensal">RFM</th>
                    <th mat-sort-header="RazaoFluxoMensalMin">Lim. RFM</th>
                    <th mat-sort-header="VarRazaoSaldoDevedor">RSD</th>
                    <th mat-sort-header="RazaoSaldoDevedorMin">Lim. RSD</th>
                    <th mat-sort-header="VarLTVAtual">LTV Atual</th>
                    <th mat-sort-header="VarLTVFull">LTV Full</th>
                    <th mat-sort-header="VarPercInadimplencia">% Inad</th>
                    <th mat-sort-header="VarPercVendas">% Vendas</th>
                    <th mat-sort-header="VarPercObras">% Obras</th>
                    <th class="comentario-column" mat-sort-header="Comentarios">Comentários</th>
                </tr>
    
                <tr *ngFor="let data of filteredData">
                    <!-- Chave -->
                    <td class="chave-column" style="text-align: left">
                        <div *ngIf="data.Loading == false">
                            <button
                                *ngIf="isResumoBlocked() == false"
                                mat-icon-button
                                [disableRipple]="true"
                                onclick="this.blur()"
                                class="refresh-button"
                                (click)="refreshResumoLine(data.ID)"
                            >
                                <mat-icon class="refresh-icon">refresh</mat-icon>
                            </button>
                            <span *ngIf="isResumoBlocked() == false">&nbsp; |</span>
                            {{ data.Chave }}
                        </div>
    
                        <div *ngIf="data.Loading == true" class="spinner-wrapper black-mat-spinner">
                            <mat-progress-spinner [diameter]="19" mode="indeterminate"></mat-progress-spinner>
                            &nbsp; | {{ data.Chave }}
                        </div>
                    </td>

                    <td class="nome-carteira-column">
                       {{data.Carteira}} 
                    </td>
    
                    <!-- Saldo Devedor -->
                    <td class="saldo-devedor-column" *ngIf="variacaoSelected == false">
                        {{ data.SaldoDevedorTabelaResumo.toLocaleString('pt-BR', { maximumFractionDigits: 0 }) }}
                    </td>
                    <td *ngIf="variacaoSelected == true" class="variacao-cell">
                        {{ data.VarSaldoDevedor.toLocaleString('pt-BR', { maximumFractionDigits: 0 }) }}%
                    </td>
    
                    <!-- % Fundo -->
                    <td *ngIf="variacaoSelected == false">{{ data.PercFundo.toLocaleString('pt-BR', { maximumFractionDigits: 0 }) }}%</td>
                    <td *ngIf="variacaoSelected == true" class="variacao-cell">
                        {{ data.VarPercFundo.toLocaleString('pt-BR', { maximumFractionDigits: 0 }) }}%
                    </td>
    
                    <!-- Razão Fluxo Mensal -->
                    <td
                        *ngIf="variacaoSelected == false"
                        [ngClass]="{ azul: data.RazaoFluxoMensalIsManual == 1, vermelho: data.RazaoFluxoMensal < data.RazaoMinimaFluxoMensal }"
                    >
                        <input
                            type="text"
                            matInput
                            appMatInputFormatedPercentage
                            [value]="data.RazaoFluxoMensal.toString()"
                            [decimalPoints]="0"
                            (output)="setManual(data.ID, $event, 'RazaoFluxoMensal', 'RazaoFluxoMensalIsManual')"
                        />
                    </td>
                    <td *ngIf="variacaoSelected == true" class="variacao-cell">
                        {{ data.VarRazaoFluxoMensal.toLocaleString('pt-BR', { maximumFractionDigits: 1 }) }}%
                    </td>
    
                    <!-- Razão Mínima Fluxo Mensal -->
                    <td>{{ data.RazaoMinimaFluxoMensal }}%</td>
    
                    <!-- Razão Saldo Devedor -->
                    <td
                        *ngIf="variacaoSelected == false"
                        [ngClass]="{ azul: data.RazaoSaldoDevedorIsManual == 1, vermelho: data.RazaoSaldoDevedor < data.RazaoMinimaSaldoDevedor }"
                    >
                        <input
                            type="text"
                            matInput
                            appMatInputFormatedPercentage
                            [value]="data.RazaoSaldoDevedor.toString()"
                            [decimalPoints]="0"
                            (output)="setManual(data.ID, $event, 'RazaoSaldoDevedor', 'RazaoSaldoDevedorIsManual')"
                        />
                    </td>
                    <td *ngIf="variacaoSelected == true" class="variacao-cell">
                        {{ data.VarRazaoSaldoDevedor.toLocaleString('pt-BR', { maximumFractionDigits: 1 }) }}%
                    </td>
    
                    <!-- Razão Mínima Saldo Devedor -->
                    <td>{{ data.RazaoMinimaSaldoDevedor }}%</td>
    
                    <!-- LTV ATUAL -->
                    <td *ngIf="variacaoSelected == false" [ngClass]="{ azul: data.LTVAtualIsManual == 1, vermelho: data.LTVAtual > 60 }">
                        <input
                            type="text"
                            matInput
                            appMatInputFormatedPercentage
                            [value]="data.LTVAtual.toString()"
                            [decimalPoints]="0"
                            (output)="setManual(data.ID, $event, 'LTVAtual', 'LTVAtualIsManual')"
                        />
                    </td>
                    <td *ngIf="variacaoSelected == true" class="variacao-cell">
                        {{ data.VarLTVAtual.toLocaleString('pt-BR', { maximumFractionDigits: 1 }) }}%
                    </td>
    
                    <!-- LTV FULL -->
                    <td *ngIf="variacaoSelected == false" [ngClass]="{ azul: data.LTVFullIsManual == 1, vermelho: data.LTVFull > 60 }">
                        <input
                            type="text"
                            matInput
                            appMatInputFormatedPercentage
                            [value]="data.LTVFull.toString()"
                            [decimalPoints]="0"
                            (output)="setManual(data.ID, $event, 'LTVFull', 'LTVFullIsManual')"
                        />
                    </td>
                    <td *ngIf="variacaoSelected == true" class="variacao-cell">
                        {{ data.VarLTVFull.toLocaleString('pt-BR', { maximumFractionDigits: 1 }) }}%
                    </td>
    
                    <!-- % Inadimplência -->
                    <td *ngIf="variacaoSelected == false" [ngClass]="{ azul: data.PercInadimplenciaIsManual == 1 }">
                        <input
                            type="text"
                            matInput
                            appMatInputFormatedPercentage
                            [value]="data.PercInadimplencia.toString()"
                            [decimalPoints]="0"
                            (output)="setManual(data.ID, $event, 'PercInadimplencia', 'PercInadimplenciaIsManual')"
                        />
                    </td>
                    <td *ngIf="variacaoSelected == true" class="variacao-cell">
                        {{ data.VarPercInadimplencia.toLocaleString('pt-BR', { maximumFractionDigits: 1 }) }}%
                    </td>
    
                    <!-- % Venda -->
                    <td *ngIf="variacaoSelected == false" [ngClass]="{ azul: data.PercVendasIsManual == 1 }">
                        <input
                            type="text"
                            matInput
                            appMatInputFormatedPercentage
                            [value]="data.PercVendas.toString()"
                            [decimalPoints]="0"
                            (output)="setManual(data.ID, $event, 'PercVendas', 'PercVendasIsManual')"
                        />
                    </td>
                    <td *ngIf="variacaoSelected == true" class="variacao-cell">
                        {{ data.VarPercVendas.toLocaleString('pt-BR', { maximumFractionDigits: 1 }) }}%
                    </td>
    
                    <!-- % Obras -->
                    <td *ngIf="variacaoSelected == false">
                        <input
                            class="azul"
                            type="text"
                            matInput
                            appMatInputFormatedPercentage
                            [value]="data.PercObras.toString()"
                            [decimalPoints]="0"
                            (output)="setPercObras(data.ID, $event)"
                        />
                    </td>
                    <td *ngIf="variacaoSelected == true" class="variacao-cell">
                        {{ data.VarPercObras.toLocaleString('pt-BR', { maximumFractionDigits: 1 }) }}%
                    </td>
    
                    <!-- Comentarios  -->
                    <td><input style="text-align: left" type="string" placeholder="" [(ngModel)]="data.Comentarios" /></td>
                </tr>
            </table>
        </cdk-virtual-scroll-viewport>
    </div>

    <div class="row salvar-modificacoes-container">
        <div class="col-2">
            <button (click)="calculateTabelaResumoTotalDialog()" mat-raised-button color="primary">Calcular Tabela Resumo</button>
        </div>
        <div class="col-8"></div>
        <div class="col-1 consolidar-tabela-button-wrapper">
            <div *ngIf="isResumoBlocked() == true">
                <button
                    mat-icon-button
                    style="float: right"
                    [disableRipple]="true"
                    onclick="this.blur()"
                    class="consolidar-tabela-button"
                    (click)="desbloquearTabelaResumoDialog()"
                >
                    <mat-icon class="refresh-icon">lock</mat-icon>
                </button>
            </div>
            <div *ngIf="isResumoBlocked() == false">
                <button
                    mat-icon-button
                    style="float: right"
                    [disableRipple]="true"
                    onclick="this.blur()"
                    class="consolidar-tabela-button"
                    (click)="consolidarTabelaResumoDialog()"
                >
                    <mat-icon class="refresh-icon">lock_open</mat-icon>
                </button>
            </div>
        </div>
        <div class="col-1 salvar-modificaoes-button-wrapper">
            <button (click)="salvarModificacoesManuais()" mat-raised-button color="primary" style="float: left" [disabled]="isResumoBlocked()">
                Salvar Modificações
            </button>
        </div>
    </div>
</div>
<div class="container" *ngIf="loading.length != 0">
    <mat-spinner class="top-overlay"></mat-spinner>
</div>
