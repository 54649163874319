import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ProjetosService } from 'src/app/services/projetos.service'
import { Projeto } from 'src/app/interfaces/projeto'
import { Razoes } from 'src/app/interfaces/razoes'
import { FundoReserva } from 'src/app/interfaces/fundo_reserva'
import { Servicer } from 'src/app/interfaces/servicer'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { TipoProjeto } from 'src/app/interfaces/tipo_projeto'
import { MetodologiaFundoReserva } from 'src/app/interfaces/metodologia_fundo_reserva'
import { MetodologiaLTV } from 'src/app/interfaces/metodologia_ltv'
import { MetodologiaEstoque } from 'src/app/interfaces/metodologia_estoque'
import { MetodologiaRFM } from 'src/app/interfaces/metodologia_rfm'
import { MetodologiaInadimplencia } from 'src/app/interfaces/metodologia_inadimplencia'
import { AgenteFiduciario } from 'src/app/interfaces/agende-fiduciario'
import { Emissor } from 'src/app/interfaces/emissor'
import { CriService } from 'src/app/services/cri.service'
import { Setor } from 'src/app/interfaces/setor'
import { Ativo } from 'src/app/interfaces/ativo'
import { GrupoEconomico } from 'src/app/interfaces/grupo_economico'

@Component({
    selector: 'app-novo-projeto',
    templateUrl: './novo-projeto.component.html',
    styleUrls: ['./novo-projeto.component.css'],
})
export class NovoProjetoComponent implements OnInit, OnDestroy {
    constructor(private projetoService: ProjetosService, private criService: CriService) {}
    public loading: boolean[] = []
    public error: boolean = false
    public errorMessage: string = ''
    public success: boolean = false

    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'CRIs', href: 'gestao/list/cris' },
        { text: 'Cadastro CRI', href: 'gestao/create/cri' },
    ]

    private ngUnsubscriber = new Subject()

    public servicers: Servicer[] = []
    public emissores: Emissor[] = []
    public gruposEconomicos: GrupoEconomico[] = []
    public metodologiasFundoReserva: MetodologiaFundoReserva[] = []
    public metodologiasLTV: MetodologiaLTV[] = []
    public metodologiasEstoque: MetodologiaEstoque[] = []
    public tiposProjeto: TipoProjeto[] = []
    public metodologiasRFM: MetodologiaRFM[] = []
    public metodologiasInadimplencia: MetodologiaInadimplencia[] = []
    public agentesFiduciarios: AgenteFiduciario[] = []
    public setores: Setor[] = []
    public ativos: Ativo[] = []

    createProject(event: any) {
        let projeto: Projeto = event.projeto
        projeto.Sigla = projeto.Sigla.trim()

        let razoes: Razoes = event.razoes
        let fundoReserva: FundoReserva = event.fundoReserva

        this.error = this.success = false
        this.loading.push(true)
        this.projetoService.createProjeto(projeto, razoes, fundoReserva).subscribe(
            (returnData: any) => {
                this.loading.pop()
                this.success = true
            },
            (errorData: any) => {
                this.loading.pop()
                this.error = true
                this.errorMessage = errorData.message
            }
        )
    }

    ngOnInit(): void {
        this.loading.push(true, true, true, true, true, true, true, true, true, true, true)

        this.projetoService
            .getMetodologiasFundoReserva()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.metodologiasFundoReserva = returnData.metodologias
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )

        this.projetoService
            .getTiposProjeto()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.tiposProjeto = returnData.tipos
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )

        this.projetoService
            .getServicers()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.servicers = returnData.servicers
                    this.loading.pop()
                },
                (errorData) => this.loading.pop()
            )

        this.projetoService
            .getMetodologiasLTV()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.metodologiasLTV = returnData.metodologias_LTV
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )

        this.projetoService
            .getMetodologiasEstoque()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.metodologiasEstoque = returnData.metodologias_estoque
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )

        this.projetoService
            .getMetodologiasRFM()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.metodologiasRFM = returnData.metodologias_rfm
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )

        this.projetoService
            .getMetodologiasInadimplencia()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.metodologiasInadimplencia = returnData.metodologias_inadimplencia
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )

        this.projetoService
            .getAgentesFiduciarios()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.agentesFiduciarios = returnData.agentes_fiduciarios
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )

        this.criService
            .getEmissores()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.emissores = returnData.emissores
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )

        this.projetoService
            .getAtivos()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.ativos = returnData.ativos
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )

        this.projetoService
            .getSetores()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.setores = returnData.setores
                    this.loading.pop()
                },
                (errorData: any) => this.loading.pop()
            )

        this.criService
            .getGruposEconomicos()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.gruposEconomicos = returnData.grupos_economicos
                    this.loading.pop()
                },
                (error) => this.loading.pop()
            )

    }

    ngOnDestroy() {
        this.ngUnsubscriber.next()
        this.ngUnsubscriber.complete()
    }
}
