import { Component, OnInit, Input, OnChanges } from '@angular/core'

@Component({
    selector: 'app-success-snackbar',
    templateUrl: './success-snackbar.component.html',
    styleUrls: ['./success-snackbar.component.css'],
})
export class SuccessSnackbarComponent implements OnInit {
    @Input('text') text: string = ''
    constructor() {}

    ngOnInit(): void {}
}
