import { Component, OnInit } from '@angular/core'
import { RequestService } from 'src/app/services/request.service'
import { AppSettings } from 'src/app/constants/AppSettings'
import { Router } from '@angular/router'

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {
    constructor(private request: RequestService, private router: Router) {}
    public emailField = 'emailField'
    public passwordField = 'passwordField'
    public hasError: boolean = false
    public error: string = ''
    public loading: boolean = false

    goBack(): void {
        this.router.navigate(['login'])
    }

    signUp(event: any) {
        event.preventDefault()
        this.loading = true
        var email = (<HTMLInputElement>document.getElementById(this.emailField)).value
        var password = (<HTMLInputElement>document.getElementById(this.passwordField)).value

        this.request.postRequest(AppSettings.API_ENDPOINT + 'signup', { email: email, password: password }).subscribe(
            (returnData) => {
                var aux = JSON.parse(JSON.stringify(returnData))
                localStorage.setItem('token', aux['token'])
                this.loading = false
                this.router.navigate([''])
            },

            (errorData) => {
                var aux = JSON.parse(JSON.stringify(errorData))
                this.error = aux['error']['message']
                this.hasError = true
                this.loading = false
            }
        )
    }
    ngOnInit(): void {}
}
