import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
    selector: 'app-obras-select',
    templateUrl: './obras-select.component.html',
    styleUrls: ['./obras-select.component.css'],
})
export class ObrasSelectComponent implements OnInit {
    @Input('obras') obras: string[] = []
    @Output() onObraChange = new EventEmitter()

    selected = 'Total'

    constructor() {}

    emitObra(event: any) {
        this.onObraChange.emit(event.value)
    }

    ngOnInit(): void {
        if (this.obras.length == 2) {
            this.obras = [this.obras[1]]
            this.selected = this.obras[0]
        }
    }
}
