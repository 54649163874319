import { Injectable } from '@angular/core'
import { RequestService } from './request.service'

@Injectable({
    providedIn: 'root',
})
export class GeoService {
    constructor(private http: RequestService) {}

    private geoBlueprintEndpoint: string = this.http.apiEndpoint + 'geo/'

    public getMunicipios() {
        return this.http.getRequest(this.geoBlueprintEndpoint + 'municipios', {})
    }

    public getMunicipiosDetailedList() {
        return this.http.getRequest(this.geoBlueprintEndpoint + 'municipios/detailed_list', {})
    }

    public updateMunicipio() {
        return this.http.putRequest(this.geoBlueprintEndpoint + 'municipios/update', {})
    }

    public createMunicipio() {
        return this.http.postRequest(this.geoBlueprintEndpoint + 'municipios/update', {})
    }
    
    public deleteMunicipio(municipioID: number) {
        return this.http.postRequest(this.geoBlueprintEndpoint + 'municipios/update', {})
    }
    
    public getUnidadesFederativas() {
        return this.http.getRequest(this.geoBlueprintEndpoint + 'unidades_federativas', {})
    }
    
    public getMunicipiosRateios(criID:number){
        return this.http.getRequest(this.geoBlueprintEndpoint + 'municipios_rateios', {cri_id:criID})
    }

    public createCidadeRateio(cidadeRateio:any) {
        let formData = new FormData()
        formData.append('municipio_rateio', JSON.stringify(cidadeRateio))
        return this.http.postRequest(this.geoBlueprintEndpoint + 'municipios_rateios/create', formData)
    }

    public updateCidadeRateio(cidadeRateio:any) {
        let formData = new FormData()
        formData.append('municipio_rateio', JSON.stringify(cidadeRateio))
        return this.http.putRequest(this.geoBlueprintEndpoint + 'municipios_rateios/update', formData)
    }

    public deleteCidadeRateio(municipioRateioID: any) {
        let formData = new FormData()
        formData.append('municipio_rateio_id', municipioRateioID)
        return this.http.postRequest(this.geoBlueprintEndpoint + 'municipios_rateios/delete', formData)
    }
}
