import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class RequestService {
    constructor(private http: HttpClient) {}
    public apiEndpoint = this.getEndpoint()

    private userToken = localStorage.getItem('token')

    private getUserToken(): string {
        const token = localStorage.getItem('token')
        return token ? token : ''
    }

    private getHeader(): HttpHeaders {
        return new HttpHeaders({'Access-Control-Allow-Origin':'*'})
    }

    private getEndpoint(): string {
        const localFlaskHost: string = 'http://localhost:5001/'
        const remoteFlaskHost: string = 'https://habitatapi.com/'

        const host: string = window.location.host
        const ret: string = host.includes('localhost') ? localFlaskHost : remoteFlaskHost

        return ret
    }

    public promisePostRequest(url: string, data: any) {
        if (data instanceof FormData) {
            data.append('user_token', this.userToken ? this.userToken : '')
        } else {
            Object.assign(data, { user_token: this.userToken })
        }

        return this.http
            .post(url, data, {headers:this.getHeader()})
            .toPromise()
            .then((data) => {
                return data
            })
            .catch((error) => {
                return error
            })
    }

    public getRequest(url: string, data: any): Observable<Object> {
        if (data instanceof FormData) {
            data.append('user_token', this.userToken ? this.userToken : '')
        } else {
            Object.assign(data, { user_token: this.userToken })
        }

        return this.http.get(url, {
            params: data,
            headers: this.getHeader()
        })
    }

    public postRequest(url: string, data: any, headers?: any) {
        if (data instanceof FormData) {
            data.append('user_token', this.userToken ? this.userToken : '')
        } else {
            Object.assign(data, { user_token: this.userToken })
        }

        console.log(url)
        return this.http.post(url, data, { headers: this.getHeader() })
    }

    public putRequest(url: string, data: any) {
        return this.http.put(url, data)
    }
}
