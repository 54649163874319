import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { Breadcrumb } from 'src/app/interfaces/breadcrumb';
import { EventoDetailed } from 'src/app/interfaces/eventos_detailed';
import { ProjetosService } from 'src/app/services/projetos.service';
import { MatPaginator } from '@angular/material/paginator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-eventos',
    templateUrl: './eventos.component.html',
    styleUrls: ['./eventos.component.css'],
})
export class EventosComponent implements OnInit {
    constructor(public projetosService: ProjetosService) {}

    private ngUnsubscriber = new Subject()

    public displayedColumns: string[] = ['cri', 'data', 'descricao']
    public filteredDataSource = new MatTableDataSource<EventoDetailed>()
    public dataSource: EventoDetailed[] = []

    public filterValue: string = ''

    @ViewChild(MatPaginator) paginator: MatPaginator

    ngOnInit(): void {
        this.filteredDataSource.paginator = this.paginator
        this.getEventos()
    }

    getEventos(): void {
        this.projetosService
            .getEventos()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    const eventos = returnData.eventos.map((obj: any) => {
                        return {
                            ID: obj.ID,
                            ProjetoID: obj.ProjetoID,
                            Descricao: obj.Descricao,
                            Data: obj.Data,
                            Ativo: obj.Ativo,
                            SiglaCRI: obj.SiglaCRI,
                        }
                    })
                    Object.assign(this.dataSource, eventos)
                    this.filteredDataSource = new MatTableDataSource<EventoDetailed>(eventos)
                    this.filteredDataSource.paginator = this.paginator
                },
                (errorData: any) => {}
            )
    }

    filtrar(event: any) {
        const filterString = this.normalizar_string(event.target.value)
        const filteredData = this.dataSource.filter((obj) => {
            return this.normalizar_string(obj.Descricao).includes(filterString) || this.normalizar_string(obj.SiglaCRI).includes(filterString)
        })

        this.filteredDataSource = new MatTableDataSource<EventoDetailed>(filteredData)
        this.filteredDataSource.paginator = this.paginator
    }

    normalizar_string(str: string) {
      return str
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
    }

    formatDate(date:string) {
      const [year, month, day] = date.split('-')
      const result = [day, month, year].join('/')
      return result
    }

}
