import { Component, ViewChild, OnInit, OnDestroy, Input } from '@angular/core'
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import { FormControl } from '@angular/forms'
import { Observable, Subject } from 'rxjs'
import { ProjetosService } from 'src/app/services/projetos.service'
import { takeUntil } from 'rxjs/operators'
import { ContratosService } from 'src/app/services/contratos.service'
import { AppSettings } from 'src/app/constants/AppSettings'

interface Projeto {
    Projeto: string
}

interface Obra {
    ObraID: number
    ProjetoID: number
    Obra: string
    Projeto: string
}

interface OrderBy {
    field: string
    ascending: boolean
}

@Component({
    selector: 'app-fluxofuturo',
    templateUrl: './contratos.component.html',
    styleUrls: ['./contratos.component.css'],
})
export class ContratosComponent implements OnInit, OnDestroy {
    constructor(private projetosService: ProjetosService, private contratosService: ContratosService) {}

    private ngUnsubscribe = new Subject()
    @ViewChild(CdkVirtualScrollViewport)
    // Const
    declare viewport: CdkVirtualScrollViewport
    private userToken = localStorage.getItem('token')

    @Input('projetoID') projetoID: number = -1
    public obras: Obra[] = []
    public obrasNames: string[] = []

    // Summary header
    public header: string[] = []

    // Table
    public tableData: any[] = []
    public normalizedData: any[] = []
    public tableColumns: string[] = []
    public loading: boolean = false
    public selectedIndex: number = -1
    private initialOffset: number = 0
    private busy: boolean = false

    // Chart
    public legend: boolean = false
    public showLabels: boolean = true
    public animations: boolean = true
    public xAxis: boolean = true
    public yAxis: boolean = true
    public showYAxisLabel: boolean = true
    public showXAxisLabel: boolean = false
    public xAxisLabel: string = 'Mês Index'
    public yAxisLabel: string = 'Valor da Parcela'
    public timeline: boolean = false
    public multi: Object[] = []

    // VendaID filter
    private vendaId: string = ''
    // Order by
    public orderByList: string[] = []
    public orderBy: OrderBy[] = []
    public orderByStatement: string = ''

    // User interaction
    public declare obraId: number

    // Export excel file
    public error = false

    exportExcel(): void {
        const endpoint =
            AppSettings.API_ENDPOINT + 'gestao/contratos/download_fluxo_futuro?user_token=' + this.userToken + '&' + 'projeto_id=' + this.projetoID

        window.location.href = endpoint
    }

    exportExcelNormalizado(): void {
        const endpoint =
            AppSettings.API_ENDPOINT +
            'gestao/contratos/download_fluxo_futuro_normalizado?user_token=' +
            this.userToken +
            '&' +
            'projeto_id=' +
            this.projetoID

        window.location.href = endpoint
    }

    orderTable(field: string): void {
        this.loading = true
        this.orderByStatement = 'order by ' + field
        const orderByField = this.orderBy.filter((el) => el.field == field)[0]
        if (orderByField.ascending) this.orderByStatement = this.orderByStatement + ' asc'
        else this.orderByStatement = this.orderByStatement + ' desc'
        this.initialOffset = 0
        this.makeRequest()
        orderByField.ascending = !orderByField.ascending
    }

    makeRequest(loadMore: Boolean = false): void {
        this.contratosService
            .getContratosFluxoFuturo({
                offset: this.initialOffset,
                obra_id: this.obraId,
                order_by: this.orderByStatement,
                venda_id: this.vendaId,
            })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    if (!loadMore) {
                        this.tableData = returnData.data
                        this.normalizedData = returnData.data_normalized
                        if (this.orderByList.length == 0) this.orderByList = returnData.order_by
                        if (this.header.length == 0) this.header = returnData.header
                        if (this.tableColumns.length == 0) this.tableColumns = returnData.table_columns

                        if (this.orderByList.length != this.orderBy.length)
                            for (var i = 0; i < this.orderByList.length; i++) this.orderBy.push({ ascending: true, field: this.orderByList[i] })
                        this.selectedIndex = 0
                        // console.log(this.tableData)
                        this.updateChartData()
                    } else {
                        this.tableData = this.tableData.concat(returnData.data)
                        this.normalizedData = this.normalizedData.concat(returnData.data_normalized)
                        this.busy = false
                    }
                    this.loading = false
                },
                (errorData: any) => {
                    // console.log(errorData)
                    this.loading = false
                }
            )
    }

    onObraChange(obraName: any) {
        this.loading = true
        this.vendaId = ''
        let obra = this.obras.filter(obj => {
            return obj.Obra === obraName
        })
        this.obraId = obra[0].ObraID
        this.initialOffset = 0
        this.makeRequest()
    }

    loadMore(): void {
        if (this.busy || this.obraId == null) return

        const end = this.viewport.getRenderedRange().end
        const total = this.viewport.getDataLength()

        if (end > total - 50) {
            this.busy = true
            this.initialOffset++
            this.makeRequest(true)
        }
    }

    changeTableRow(index: number): void {
        this.selectedIndex = index
        this.updateChartData()
    }

    arrowDownEvent(event: any): void {
        event.preventDefault()
        if (this.selectedIndex < this.tableData.length - 1) {
            this.selectedIndex++
            this.viewport.scrollToIndex(this.selectedIndex)
            this.updateChartData()
        }
    }

    arrowUpEvent(event: any): void {
        event.preventDefault()
        if (this.selectedIndex > 0) {
            this.selectedIndex--
            this.viewport.scrollToIndex(this.selectedIndex)
            this.updateChartData()
        }
    }

    applyVendaIdfilter(event: any): void {
        this.vendaId = event.target.value
        this.initialOffset = 0
        this.makeRequest()
    }

    formatNumber(str: any): string {
        if (typeof str == 'string') return str
        else return Number(str).toLocaleString('pt-br', { minimumFractionDigits: 2 })
    }

    updateChartData(): void {
        var selectedContract = this.tableData[this.selectedIndex]
        const vendaId = selectedContract.VendaID
        var normalizedContract = this.normalizedData.filter(Boolean).filter((contract) => contract.VendaID === vendaId)[0]

        var mesIndex: number[] = Object.keys(selectedContract)
            .filter(Number)
            .map((i) => Number(i))
            .sort()
        var series: Object[] = []
        var normalizedSeries: Object[] = []
        for (var i = 0; i < mesIndex.length; i++) {
            series.push({ name: mesIndex[i], value: selectedContract[mesIndex[i]] })
            normalizedSeries.push({ name: mesIndex[i], value: normalizedContract[mesIndex[i]] })
        }
        this.multi = [
            { name: 'Original', series: series },
            { name: 'Normalizado', series: normalizedSeries },
        ]
    }

    ngOnInit(): void {
        this.loading = true
        this.projetosService
            .getObras(this.projetoID)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (returnData: any) => {
                    this.obras = returnData.obras
                    
                    for (let i=0; i < this.obras.length; i++){
                        this.obrasNames.push(this.obras[i].Obra)
                    }
                    this.loading = false
                    if (this.obrasNames.length > 0){
                        this.onObraChange(this.obrasNames[0])
                    }
                },
                (errorData: any) => {}
            )
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.complete()
    }
}
