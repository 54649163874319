<div class="container">
    <div class="row justify-content-center card-wrapper">
        <div class="col-9 pesos-card">
            <mat-card class="mat-elevation-z3">
                <h3>Pesos</h3>
                <div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Em Obras | Com RFM</th>
                                <th>Em Obras | Sem RFM</th>
                                <th>Entregue | Com RFM</th>
                                <th>Entregue | Sem RFM</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let criterio of criteriosData">
                                <td>{{ criterio.Nome }}</td>
                                <td>
                                    <input
                                        class="pesos-input"
                                        type="text"
                                        matInput
                                        appMatInputFormatedPercentage
                                        [value]="(criterio.PesoEmObrasRFM * 100).toString()"
                                        [decimalPoints]="0"
                                        (output)="criterio.PesoEmObrasRFM = $event/100"
                                    />
                                </td>
                                <td>{{ formatPercentages(criterio.PesoEmObrasNaoRFM) }}</td>
                                <td>{{ formatPercentages(criterio.PesoEntregueRFM) }}</td>
                                <td>{{ formatPercentages(criterio.PesoEntregueNaoRFM) }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row justify-content-end" >
                        <div class="col-3 salvar-atualizacoes-button">
                            <button (click)="salvarAtualizacoes()" mat-raised-button color="primary">Salvar Atualizações</button>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="col-3 ratings-card">
            <mat-card class="mat-elevation-z3">
                <h3>Ratings</h3>
                <mat-card-content>
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Rating</th>
                                <th>Nota</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosRating[0].Criterio"
                                    />
                                </td>
                                <td>{{ criteriosRating[0].Rating }}</td>
                                <td>>= {{ criteriosRating[0].Criterio }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosRating[1].Criterio"
                                        />
                                    </td>
                                <td>{{ criteriosRating[1].Rating }}</td>
                                <td>>= {{ criteriosRating[1].Criterio }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                    class="criterios-inputs"
                                    type="number"
                                    matInput
                                    [(ngModel)]="criteriosRating[2].Criterio"
                                    />
                                </td>
                                <td>{{ criteriosRating[2].Rating }}</td>
                                <td>>= {{ criteriosRating[2].Criterio }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosRating[3].Criterio"
                                    />
                                </td>
                                <td>{{ criteriosRating[3].Rating }}</td>
                                <td>> {{ criteriosRating[3].Criterio }}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>{{ criteriosRating[4].Rating }}</td>
                                <td><= {{ criteriosRating[3].Criterio }}</td>
                            </tr>
                        </tbody>
                        <!-- <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[0].CriterioNota3"
                                    />
                                </td>
                                <td>Bom</td>
                                <td>3</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[0].CriterioNota2"
                                    />
                                </td>
                                <td>Regular</td>
                                <td>2</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[0].CriterioNota1"
                                    />
                                </td>
                                <td>Ruim</td>
                                <td>1</td>
                            </tr>
                        </tbody> -->
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row justify-content-start criterios-cards">
        <div class="col-md-4 col-xl-3">
            <mat-card class="mat-elevation-z3">
                <h3>Notas Obras</h3>
                <mat-card-content>
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Criterio</th>
                                <th>Nota</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ criteriosData[0].CriterioNota4 }}</td>
                                <td>Excelente</td>
                                <td>4</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[0].CriterioNota3"
                                    />
                                </td>
                                <td>Bom</td>
                                <td>3</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[0].CriterioNota2"
                                    />
                                </td>
                                <td>Regular</td>
                                <td>2</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[0].CriterioNota1"
                                    />
                                </td>
                                <td>Ruim</td>
                                <td>1</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-md-4 col-xl-3">
            <mat-card class="mat-elevation-z3">
                <h3>Avaliação Cedente</h3>
                <mat-card-content>
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Criterio</th>
                                <th>Nota</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th></th>
                                <td>Excelente</td>
                                <td>4</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th></th>
                                <td>Bom</td>
                                <td>3</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th></th>
                                <td>Regular</td>
                                <td>2</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th></th>
                                <td>Ruim</td>
                                <td>1</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-md-4 col-xl-3">
            <mat-card class="mat-elevation-z3">
                <h3>Carência / Risco Supl.</h3>
                <mat-card-content>
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Criterio</th>
                                <th>Nota</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>0/0</td>
                                <td>Sem Obs.</td>
                                <td>4</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>1/0</td>
                                <td>Situação Pontual</td>
                                <td>3</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>0/1</td>
                                <td>Situação Recorrente</td>
                                <td>2</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>1/1</td>
                                <td>Situação Alerta</td>
                                <td>1</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-md-4 col-xl-3">
            <mat-card class="mat-elevation-z3">
                <h3>Status Vendas</h3>
                <mat-card-content>
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Criterio</th>
                                <th>Nota</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>>90%</td>
                                <td>4</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[4].CriterioNota3"
                                    />
                                </td>
                                <td><90%</td>
                                <td>3</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[4].CriterioNota2"
                                    />
                                </td>
                                <td><50%</td>
                                <td>2</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[4].CriterioNota1"
                                    />
                                </td>
                                <td><30%</td>
                                <td>1</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-md-4 col-xl-3">
            <mat-card class="mat-elevation-z3">
                <h3>Status Inadimplência</h3>
                <mat-card-content>
                    <p>Corte para média interna: 
                        <input
                            class="criterios-inputs"
                            type="text"
                            matInput
                            appMatInputFormatedPercentage
                            [value]="(convertStringToFloat(criteriosData[5].CriterioNota4) * 100).toString()"
                            [decimalPoints]="0"
                            (output)="criteriosData[5].CriterioNota4 = ($event/100).toString()"
                        />
                    </p>
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Criterio</th>
                                <th>Nota</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>Menor que a MI</td>
                                <td>4</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[5].CriterioNota3"
                                    />
                                </td>
                                <td>Até {{convertStringToFloat(criteriosData[5].CriterioNota3) * 100 }}% maior que a MI</td>
                                <td>3</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[5].CriterioNota2"
                                    />
                                </td>
                                <td>Até {{convertStringToFloat(criteriosData[5].CriterioNota2) * 100 }}% maior que a MI</td>
                                <td>2</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>Maior que {{convertStringToFloat(criteriosData[5].CriterioNota2) * 100 }}% da MI</td>
                                <td>1</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-md-4 col-xl-3">
            <mat-card class="mat-elevation-z3">
                <h3>LTV</h3>
                <mat-card-content>
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Criterio</th>
                                <th>Nota</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[6].CriterioNota4"
                                    />
                                </td>
                                <td>Até {{ convertStringToFloat(criteriosData[6].CriterioNota4) * 100 }}%</td>
                                <td>4</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[6].CriterioNota3"
                                    />
                                </td>
                                <td>{{ convertStringToFloat(criteriosData[6].CriterioNota4) * 100 }}% - {{ convertStringToFloat(criteriosData[6].CriterioNota3) * 100 }}%</td>
                                <td>3</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[6].CriterioNota2"
                                    />
                                </td>
                                <td>{{ convertStringToFloat(criteriosData[6].CriterioNota3) * 100 }}% - {{ convertStringToFloat(criteriosData[6].CriterioNota2) * 100 }}%</td>
                                <td>2</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>{{ convertStringToFloat(criteriosData[6].CriterioNota2) * 100 }}% - 100%</td>
                                <td>1</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-md-4 col-xl-3">
            <mat-card class="mat-elevation-z3">
                <h3>RFM</h3>
                <mat-card-content>
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Criterio</th>
                                <th>Nota</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>Muito Enquadrado</td>
                                <td>4</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[7].CriterioNota3"
                                    />
                                </td>
                                <td>Enquadrado até {{ convertStringToFloat(criteriosData[7].CriterioNota3) * 100 }}%</td>
                                <td>3</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>Desenquadrado</td>
                                <td>2</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[7].CriterioNota1"
                                    />
                                </td>
                                <td>Inferior a {{ convertStringToFloat(criteriosData[7].CriterioNota1) * 100 }}%</td>
                                <td>1</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-md-4 col-xl-3">
            <mat-card class="mat-elevation-z3">
                <h3>Recebimentos</h3>
                <mat-card-content>
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Criterio</th>
                                <th>Nota</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>>{{ convertStringToFloat(criteriosData[3].CriterioNota3) * 100 }}%</td>
                                <td>4</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[3].CriterioNota3"
                                    />
                                </td>
                                <td><{{ convertStringToFloat(criteriosData[3].CriterioNota3) * 100 }}%</td>
                                <td>3</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[3].CriterioNota2"
                                    />
                                </td>
                                <td><{{ convertStringToFloat(criteriosData[3].CriterioNota2) * 100 }}%</td>
                                <td>2</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        class="criterios-inputs"
                                        type="number"
                                        matInput
                                        [(ngModel)]="criteriosData[3].CriterioNota1"
                                    />
                                </td>
                                <td><{{ convertStringToFloat(criteriosData[3].CriterioNota1) * 100 }}%</td>
                                <td>1</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>


