import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { RegraArredondamento } from 'src/app/interfaces/regra_arredondamento'
import { CriService } from 'src/app/services/cri.service'

@Component({
    selector: 'app-regras-arredondamento-view',
    templateUrl: './regras-arredondamento-view.component.html',
    styleUrls: ['./regras-arredondamento-view.component.css'],
})
export class RegrasArredondamentoViewComponent implements OnInit, OnDestroy {
    constructor(private criService: CriService) {}
    public loading: boolean = false
    private ngUnsubscriber = new Subject()
    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Controladoria', href: 'controladoria' },
        { text: 'Regras de Arredondamento', href: 'controladoria/regras_arredondamento' },
    ]

    public regrasArredondamento: RegraArredondamento[] = []

    removeBackSlash(str: string): string {
        return str.replace(/\\\//g, '/')
    }

    ngOnInit(): void {
        this.loading = true
        this.criService
            .getRegrasArredondamento()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    console.log(returnData)
                    this.regrasArredondamento = returnData.regras_arredondamento
                    this.loading = false
                },
                (error) => (this.loading = false)
            )
    }

    ngOnDestroy(): void {
        this.ngUnsubscriber.next()
        this.ngUnsubscriber.complete()
    }
}
