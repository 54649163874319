import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'
import { TipoProjeto } from 'src/app/interfaces/tipo_projeto'

@Component({
    selector: 'app-tipo-filter',
    templateUrl: './tipo-filter.component.html',
    styleUrls: ['./tipo-filter.component.css'],
})
export class TipoFilterComponent implements OnInit {
    @Input('tipos') tipos: TipoProjeto[] = []
    @Output() onTipoChange = new EventEmitter()

    emitTipo(event: any) {
        this.onTipoChange.emit(event.value)
    }

    ngOnInit(): void {}

    public formControlTipo = new FormControl()
}
