import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { RegraArredondamento } from 'src/app/interfaces/regra_arredondamento'
@Component({
    selector: 'app-regra-arredondamento-select',
    templateUrl: './regra-arredondamento-select.component.html',
    styleUrls: ['./regra-arredondamento-select.component.css'],
})
export class RegraArredondamentoSelectComponent implements OnInit {
    @Input('regrasArredondamento') regrasArredondamento: RegraArredondamento[] = []
    @Input('regraArredondamentoId') regraArredondamentoId: number = 0
    @Output() onRegraChange = new EventEmitter()

    constructor() {}

    emitRegraArredondamentoId(event: any) {
        this.onRegraChange.emit(event.value)
    }

    ngOnInit(): void {}
}
