<app-navbar style="position: fixed; top: 0; z-index: 1030; width: 100%"></app-navbar>

<div [ngStyle]="{ height: screenHeight - 70 }">
    <mat-drawer-container fixedInViewport="true" fixedTopGap="70" style="top: 70px">
        <mat-drawer mode="side" opened *ngIf="!loading">
            <!-- Nome do cri -->
            <div class="container">
                <div class="row">
                    <div class="col-lg-12" style="margin-top: 20px">
                        <app-h2title [text]="pageTitle"></app-h2title>
                    </div>
                </div>
            </div>
            <!-- Header -->
            <div class="container" style="font-size: 0.75rem; margin-bottom: 20px">
                <div class="row">
                    <div class="col-lg-6">
                        {{ criHeader.EmissaoNro }}ª Emissão - {{ criHeader.SerieNro }}ª Série<br />Código IF:
                        {{ criHeader.CodigoIF }}
                    </div>
                </div>
            </div>
            <!-- Details -->
            <div class="container">
                <hr />
            </div>
            <div *ngFor="let data of criData">
                <div class="col-12">
                    <div class="sidenav-title">{{ data.name }}</div>
                </div>
                <div class="col-12">
                    <div class="sidenav-element">{{ data.value }}</div>
                </div>
            </div>
            <div class="col-12" style="margin-bottom: 7px" *ngIf="indexadores.length == 1">
                <div class="sidenav-title">Indexador</div>
            </div>
            <div class="col-12" style="margin-bottom: 7px" *ngIf="indexadores.length > 1">
                <div class="sidenav-title">Indexadores</div>
            </div>
            <div *ngFor="let indexador of indexadores">
                <div class="col-12">
                    <div class="sidenav-element">{{ indexador.DataVigencia }} - {{ indexador.IndexNome }}</div>
                </div>
            </div>
            <div class="col-12" style="margin-bottom: 7px">
                <div class="sidenav-title">Quantidade Liberada</div>
            </div>
            <div class="col-12">
                <div class="sidenav-element">{{ quantidadeLiberada }}</div>
            </div>
            <div class="col-12" style="margin-bottom: 7px"  *ngIf="liberacoesFuturas.length > 0">
                <div class="sidenav-title">Liberações Futuras</div>
            </div>
            <div *ngFor="let liberacao of liberacoesFuturas">
                <div class="col-12">
                    <div class="sidenav-element">{{ dateToString(liberacao.Data, 'DD/MM/YYYY') }} - {{ liberacao.Qtde}}</div>
                </div>
            </div>
            <div class="col-12" style="margin-bottom: 7px">
                <div class="sidenav-title">Carteiras</div>
            </div>
            <div *ngFor="let item of quantidadesCarteiras">
                <div class="col-12">
                    <div class="sidenav-element">{{ item.Nome }} - {{ item.Qtde}}</div>
                </div>
            </div>

        </mat-drawer>
        <mat-drawer-content>
            <div class="bottom-overlay">
                <div [ngClass]="loading ? 'disabled-div' : ''" style="margin-top: 20px">
                    <div *ngIf="!loading">
                        <!-- Date picker + Export button -->
                        <div class="container">
                            <div class="row" style="align-items: center">
                                <div class="col-lg-9">
                                    <app-h2title [text]="'Evolução do PU'"> </app-h2title>
                                </div>
                                <div class="col-lg-3">
                                    <app-exportexcelbutton (onClick)="exportExcel()"></app-exportexcelbutton>
                                </div>
                            </div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-5">
                                    <app-daterangepicker
                                        (onDateRangeChange)="applyDateFilter($event)"
                                        (onDateRangeClear)="clearDateFilter($event)"
                                        [text]="'Clique no ícone para filtrar por datas'"
                                    >
                                    </app-daterangepicker>
                                </div>
                            </div>
                        </div>
                        <!-- Chart -->
                        <div class="container" style="margin-bottom: 30px">
                            <div class="row">
                                <div class="col-lg-12">
                                    <app-linechart
                                        [xAxisLabel]="xAxisLabel"
                                        [yAxisLabel]="yAxisLabel"
                                        [data]="chartData"
                                        [xAxisTickFormatting]="dateTickFormatting"
                                    >
                                    </app-linechart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <hr />
                    </div>
                    <div class="container" style="margin-bottom: 30px">
                        <div class="row">
                            <div class="col-lg-9" style="margin-bottom: 30px">
                                <app-h2title [text]="'Datas de Pagamento'"> </app-h2title>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <cdk-virtual-scroll-viewport [itemSize]="200">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <table id="customTable">
                                                <tr>
                                                    <th>Data</th>
                                                    <th>TaxaAmortOrd</th>
                                                    <th>TaxaAmortExtra</th>
                                                    <th>HasAmort</th>
                                                    <th>HasJuros</th>
                                                    <th>IsAniversario</th>
                                                </tr>
                                                <tr *ngFor="let dataPagamento of datasPagamento">
                                                    <td>{{ dataPagamento.Data }}</td>
                                                    <td>{{ dataPagamento.TaxaAmortOrd }}</td>
                                                    <td>{{ dataPagamento.TaxaAmortExtra }}</td>
                                                    <td>{{ dataPagamento.HasAmort }}</td>
                                                    <td>{{ dataPagamento.HasJuros }}</td>
                                                    <td>{{ dataPagamento.IsAniversario }}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </cdk-virtual-scroll-viewport>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="top-overlay" *ngIf="loading">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
