import { Component, EventEmitter, OnInit, Output } from '@angular/core'

@Component({
    selector: 'app-aumento-mensal-select',
    templateUrl: './aumento-mensal-select.component.html',
    styleUrls: ['./aumento-mensal-select.component.css'],
})
export class AumentoMensalSelectComponent implements OnInit {
    @Output() onChange = new EventEmitter()

    selected = '#'
    selectors = ['#', 'Δ']

    constructor() {}

    emitSelection(event: any) {
        this.onChange.emit(event.value)
    }

    ngOnInit(): void {}
}
