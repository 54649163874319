import { Component, OnInit, Input, Output, SimpleChanges } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { EventEmitter } from '@angular/core'

interface Projeto {
    Projeto: string
}

interface ProjetoCategoria {
    ProjetoID: number
    Projeto: string
    Categoria: string
    CategoriaID: number
    ProjetoCategoriaID: number
}

@Component({
    selector: 'app-projetoscategorias',
    templateUrl: './projetoscategorias.component.html',
    styleUrls: ['./projetoscategorias.component.css'],
})
export class ProjetoscategoriasComponent implements OnInit {
    @Input('projetos') projetos: Projeto[]
    @Input('projetosCategorias') projetosCategorias: ProjetoCategoria[]
    @Input('mesesBase') mesesBase: string[] = []
    @Output() onCategoriaChange = new EventEmitter()
    @Output() onProjetoChange = new EventEmitter()
    @Output() onMesBaseChange = new EventEmitter()

    // User interaction
    public declare displayCategorias: ProjetoCategoria[]
    public declare projetoCategoriaId: number
    public declare projetoId: number

    // Filter
    projetoControl = new FormControl()
    categoriaControl = new FormControl()
    declare filteredProjetos: Observable<string[]>
    declare filteredCategorias: Observable<string[]>

    //
    mesBaseSelected: string = ''

    constructor() {
        this.projetos = []
        this.projetosCategorias = []
    }

    ngOnInit(): void {
        this.categoriaControl.disable()
        this.filteredProjetos = this.projetoControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterProjects(value))
        )
    }

    private _filterProjects(value: string): string[] {
        if (value) {
            const filterValue = value.toLowerCase()
            return this.projetos.map((projeto) => projeto.Projeto).filter((project) => project.toLowerCase().includes(filterValue))
        } else {
            return this.projetos.map((projeto) => projeto.Projeto)
        }
    }

    private _filterObras(value: string): string[] {
        if (value) {
            const filterValue = value.toLowerCase()
            return this.displayCategorias.map((projCat) => projCat.Categoria).filter((obra) => obra.toLowerCase().includes(filterValue))
        } else {
            return this.displayCategorias.map((projCat) => projCat.Categoria)
        }
    }

    clearProjeto(): void {
        this.projetoControl.reset()
        this.categoriaControl.reset()
        this.displayCategorias = []
        this.categoriaControl.disable()
    }

    changeProjeto(projeto: string) {
        this.displayCategorias = this.projetosCategorias.filter((projCat) => projCat.Projeto == projeto)
        this.projetoId = this.projetosCategorias.filter((projCat) => projCat.Projeto == projeto)[0].ProjetoID
        this.onProjetoChange.emit(this.projetoId)
        this.categoriaControl.enable()
        this.filteredCategorias = this.categoriaControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterObras(value))
        )
        if (this.displayCategorias.length == 1) {
            this.categoriaControl.setValue(this.displayCategorias[0].Categoria)
            this.changeCategoria(this.displayCategorias[0].Categoria)
        } else {
            this.categoriaControl.reset()
            this.mesBaseSelected = ''
        }
    }

    changeCategoria(event: string) {
        const projetoCategoria = this.projetosCategorias.filter((projCat) => projCat.ProjetoID == this.projetoId && projCat.Categoria == event)[0]
        const projetoCategoriaId = projetoCategoria.ProjetoCategoriaID
        const categoriaId = projetoCategoria.CategoriaID
        this.onCategoriaChange.emit({ projetoCategoriaId: projetoCategoriaId, categoriaId: categoriaId })
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes?.projetos || changes?.projetosCategorias) && !changes?.thisMesesBase) {
            return
        }
        this.mesBaseSelected = this.mesesBase[0]
    }

    changeMesBase() {
        this.onMesBaseChange.emit(this.mesBaseSelected)
    }
}
