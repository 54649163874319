<div style="padding: 30px 30px 0px 30px">
    <h3>{{ data.title }}</h3>
    <hr />

    <p>
        {{ data.subtitle }}
    </p>

    <div class="row" style="align-items: center">
        <div class="col-6">
            <mat-radio-group aria-labelledby="cri-selector" [(ngModel)]="selectedOption">
                <div *ngFor="let option of options">
                    <div class="row" style="margin-left: 15px">
                        <div class="col-12">
                            <mat-radio-button [value]="option">
                                <div class="text-wrapper">
                                    {{ option }}
                                </div>
                            </mat-radio-button>
                        </div>
                    </div>
                </div>
            </mat-radio-group>
        </div>
        <div class="col-6" *ngIf="displayDatepicker()">
            <app-datepicker [text]="'Escolha data base'" (onDateChange)="updateDate($event)"></app-datepicker>
        </div>
    </div>

    <div class="row" *ngIf="selectedOption === options[1]" style="margin-top: 10px">
        <div class="col-12">
            <app-criselector [cris]="cris" (onCriChange)="updateSelectedCris($event)"></app-criselector>
        </div>
    </div>

    <div class="row" style="float: right">
        <button (click)="close()" style="margin-right: 20px" mat-raised-button>Cancelar</button>
        <button (click)="confirm()" mat-raised-button color="primary" [disabled]="isButtonDisabled()">Calcular</button>
    </div>
</div>
