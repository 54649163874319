import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { AppSettings } from 'src/app/constants/AppSettings'

@Component({
    selector: 'app-mtm-table',
    templateUrl: './mtm-table.component.html',
    styleUrls: ['./mtm-table.component.css'],
})
export class MtmTableComponent implements OnInit {
    @Input('mtmCris') mtmCris: any[] = []
    @Input('dataBase') dataBase: Date = new Date()
    @Output() onClick = new EventEmitter()

    export(data: any) {
        console.log(data)
        this.onClick.emit({
            criId: data.CRIID,
            Nome: data.Nome,
        })
    }

    formatNumber(input: string) {
        const appSettings = new AppSettings()
        return appSettings.formatStringAsCurrency(input)
    }

    constructor() {}

    ngOnInit(): void {}
}
