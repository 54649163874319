import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'
import { Empreendimento } from 'src/app/interfaces/empreendimento'
import { Municipio } from 'src/app/interfaces/municipio'
import { Projeto } from 'src/app/interfaces/projeto'
import { UnidadeFederativa } from 'src/app/interfaces/unidade_federativa'
import { GeoService } from 'src/app/services/geo.service'
import { ProjetosService } from 'src/app/services/projetos.service'

@Component({
    selector: 'app-empreendimento-update',
    templateUrl: './empreendimento-update.component.html',
    styleUrls: ['./empreendimento-update.component.css'],
})
export class EmpreendimentoUpdateComponent implements OnInit {
    constructor(
        private titleService: Title,
        private projetoService: ProjetosService,
        private activatedRoute: ActivatedRoute,
        private geoService: GeoService
    ) {
        this.obraId = this.activatedRoute.snapshot.queryParams['obra_id']
    }

    public declare empreendimento: Empreendimento
    private ngUnsubscriber = new Subject()
    public loading: boolean[] = []
    public obraId: number = -1
    public projetos: Projeto[] = []
    public unidadesFederativas: UnidadeFederativa[] = []
    public municipios: Municipio[] = []
    public error: boolean = false
    public success: boolean = false
    public errorMessage: string = ''

    public breadcrumbList: Breadcrumb[] = [
        { text: 'Home', href: '' },
        { text: 'Gestão', href: 'gestao' },
        { text: 'Empreendimentos', href: 'gestao/list/empreendimentos' },
    ]

    canDisplay(): boolean {
        if (!this.projetos.length) return false
        if (!this.municipios.length) return false
        if (!this.unidadesFederativas.length) return false
        if (!this.empreendimento.ID) return false

        if (this.breadcrumbList.length == 3) {
            this.breadcrumbList.push({
                text: this.empreendimento.Sigla,
                href: `gestao/edit/empreendimento?obra_id=${this.obraId}`,
            })
        }
        return true
    }

    updateEmpreendimento(empreendimento: Empreendimento) {
        this.loading.push(true)
        this.error = this.success = false
        this.errorMessage = ''

        this.projetoService
            .updateEmpreendimento(empreendimento)
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.success = true
                    this.loading.pop()
                },
                (errorData: any) => {
                    this.error = true
                    this.errorMessage = errorData['error']['message']
                    this.loading.pop()
                }
            )
    }

    getEmpreendimento() {
        this.projetoService
            .getEmpreendimento(this.obraId)
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    this.empreendimento = returnData.obra[0]
                    this.titleService.setTitle(`${this.empreendimento.Sigla} | Empreendimento`)
                    this.loading.pop()
                },
                (error) => this.loading.pop()
            )
    }

    getProjetos() {
        this.projetoService
            .getProjetos()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    // console.log(returnData)

                    this.projetos = returnData.projetos

                    this.titleService
                    this.loading.pop()
                },
                (error) => this.loading.pop()
            )
    }

    getMunicipios() {
        this.geoService
            .getMunicipios()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    console.log(returnData)

                    this.municipios = returnData.municipios
                    this.loading.pop()
                },
                (error) => this.loading.pop()
            )
    }

    getUnidadesFederativas() {
        this.geoService
            .getUnidadesFederativas()
            .pipe(takeUntil(this.ngUnsubscriber))
            .subscribe(
                (returnData: any) => {
                    console.log(returnData)
                    this.unidadesFederativas = returnData.unidades_federativas
                    this.loading.pop()
                },
                (error) => this.loading.pop()
            )
    }

    ngOnInit(): void {
        this.loading.push(true, true, true, true)
        this.getUnidadesFederativas()
        this.getEmpreendimento()
        this.getMunicipios()
        this.getProjetos()
    }
}
