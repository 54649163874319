import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { GestaoConfirmationdialogComponent } from '../confirmationdialog/confirmationdialog.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
    selector: 'app-single-file-upload',
    templateUrl: './single-file-upload.component.html',
    styleUrls: ['./single-file-upload.component.css'],
})
export class SingleFileUploadComponent implements OnInit {
    @Input('servicers') servicers: any[] = []
    @Input('projetos') projetos: any[] = []
    @Input('fileNames') fileNames: any[] = []

    @Output() onSubmit = new EventEmitter()

    constructor(public dialog: MatDialog) {}

    public file: any
    public declare selectedProject: any
    public declare selectedUploadFile: any
    public selectedServicer: string = ''
    public mesBase: string = ''
    public displayProjetos: any[] = []
    public displayFileNames: any[] = []

    updateFile(event: any): void {
        this.file = event.target.files[0]
    }

    canChooseFile(): Boolean {
        if ([this.selectedProject, this.selectedUploadFile].includes(null)) return false
        if (this.selectedServicer == '') return false
        if (this.mesBase == '') return false

        return true
    }

    canSubmit(): Boolean {
        if (this.canChooseFile() && this.file != null) return true
        else return false
    }

    updateUploadFile(data: any): void {
        this.selectedUploadFile = data.value
    }

    updateMesBase(data: any): void {
        this.mesBase = data
    }

    updateProject(data: any): void {
        const temp = this.displayProjetos.filter(function (el) {
            return el.ProjetoID == data.value
        })
        this.selectedProject = temp[0]
    }

    updateServicer(data: any): void {
        this.selectedServicer = data.value
        this.displayProjetos = this.projetos.filter((el) => el.Servicer == data.value).sort((a, b) => (a.Projeto > b.Projeto ? 1 : -1))
        this.displayFileNames = this.fileNames.filter((el) => el.Servicer == data.value).sort((a, b) => (a.FileName > b.FileName ? 1 : -1))
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(GestaoConfirmationdialogComponent, {
            data: {
                fileType: this.selectedUploadFile.FileName,
                mesBase: this.mesBase,
                servicer: this.selectedServicer,
                file: this.file,
                projectName: this.selectedProject.Projeto,
            },
        })
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.onSubmit.emit({
                    projetoId: this.selectedProject.ProjetoID,
                    mesBase: this.mesBase,
                    file: this.file,
                    servicer: this.selectedServicer,
                })
            }
        })
    }

    ngOnInit(): void {}
}
