import { Component, OnInit, Input } from '@angular/core'
import { Breadcrumb } from 'src/app/interfaces/breadcrumb'

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.css'],
})
export class BreadcrumbsComponent implements OnInit {
    @Input('breadcrumbList') breadcrumbList: Breadcrumb[] = []
    constructor() {}

    ngOnInit(): void {}
}
