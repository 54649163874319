<cdk-virtual-scroll-viewport [itemSize]="60">
    <div class="row">
        <div class="col-lg-12">
            <table id="customTable">
                <tr>
                    <th></th>
                    <th>Nome da Operação</th>
                    <th>Emissão</th>
                    <th>Série</th>
                    <th>Código IF</th>
                    <th>
                        PU <br />
                        ({{ getDisplayDate() }})
                    </th>
                    <th>Quantidade</th>
                    <th>Saldo Devedor</th>
                    <th>Carteira</th>
                </tr>
                <tr *ngFor="let data of displayCris">
                    <td>
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item hidden="true">
                                <span>Dummyautofocus</span>
                            </button>
                            <a href="controladoria/pu?criid={{ data.CRIID }}" mat-menu-item>
                                <mat-icon>timeline</mat-icon>
                                <span>Preços Unitários</span>
                            </a>
                            <a href="controladoria/edit/cri?criid={{ data.CRIID }}" mat-menu-item>
                                <mat-icon>edit</mat-icon>
                                <span>Editar</span>
                            </a>
                        </mat-menu>
                    </td>
                    <td>
                        {{ data.Nome }}
                    </td>

                    <td>
                        {{ data.Emissao }}
                    </td>
                    <td>
                        {{ data.Serie }}
                    </td>
                    <td>
                        {{ data.CodigoIF }}
                    </td>
                    <td>
                        {{ formatNumber(data.PU) }}
                    </td>
                    <td>
                        {{ formatNumber(data.Qtde) }}
                    </td>
                    <td>
                        {{ formatNumber(data.SaldoDevedor) }}
                    </td>
                    <td>
                        {{ data.Carteira }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</cdk-virtual-scroll-viewport>
