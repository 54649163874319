<mat-form-field appearance="standard">
    <mat-label>Clique no ícone para escolher o Mês Base</mat-label>
    <input matInput [matDatepicker]="dp" [formControl]="date" />
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker
        disabled="false"
        #dp
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event)"
        (monthSelected)="chosenMonthHandler($event, dp)"
        panelClass="example-month-picker"
    >
    </mat-datepicker>
</mat-form-field>
