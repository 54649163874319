<div class="container">
    <div class="row filters">
        <!-- Filtro Mês -->
        <div class="col-lg-2">
            <mat-form-field appearance="standard">
                <mat-label>Mês Base</mat-label>
                <mat-select appearance="standard" [value]="mesBaseSelected" (selectionChange)="changeMesBase($event)">
                    <mat-option *ngFor="let mesBase of mesesBase" [value]="mesBase">
                        {{ mesBase }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-lg-2">
        </div>

        <!-- Filtro Carteira -->
        <div class="col-lg-2">
            <app-carteira-filter-resumo [carteiras]="carteiras" (onCarteiraChange)="filtrarCarteira($event)"></app-carteira-filter-resumo>
        </div>

        <!-- Filtro Servicer -->
        <div class="col-lg-2">
            <app-servicer-filter [servicers]="servicers" (onServicerChange)="filtrarServicer($event)"></app-servicer-filter>
        </div>

        <!-- Filtro Securitizadora -->
        <div class="col-lg-2">
            <app-securitizadora-filter [securitizadoras]="securitizadoras" (onSecuritizadoraChange)="filtrarSecuritizadora($event)">
            </app-securitizadora-filter>
        </div>

        <!-- Filtro Tipo -->
        <div class="col-lg-2">
            <app-tipo-filter [tipos]="tipos" (onTipoChange)="filtrarTipo($event)"> </app-tipo-filter>
        </div>

        <!-- Botão Baixar Excel -->
        <!-- <div class="col-lg-1">
            <button class="botao-baixar-excel" (click)="baixarExcel()" mat-raised-button color="primary">
                Baixar Excel <mat-icon> file_download </mat-icon>
            </button>
        </div> -->

    </div>
    <div class="row notas-content">
        <div class="col-4">
            <app-notas-chart [chartData]="filteredData"></app-notas-chart>
        </div>
        <div class="col-8">
            <div class="row justify-content-end">
                <mat-form-field class="filtro" appearance="fill">
                    <mat-label>Search</mat-label>
                    <input matInput placeholder="" [value]="filterValue" (change)="filtrar($event)">
                </mat-form-field>
            </div>
            <cdk-virtual-scroll-viewport [itemSize]="800">
                <table class="table table-md" matSort (matSortChange)="sortData($event)">
                    <thead>
                        <tr>
                            <th mat-sort-header="Chave">CRI</th>
                            <th mat-sort-header="MesBaseProjeto">Data Base</th>
                            <th mat-sort-header="Nota">Nota</th>
                            <th mat-sort-header="Rating">Rating</th>
                            <th>Farol</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of filteredData">
                            <td>{{ data.Chave }}</td>
                            <td>{{ data.MesBaseProjeto }}</td>
                            <td>{{ convertNotaToBase10(data.Nota) }}</td>
                            <td class="text-center">{{ data.Rating }}</td>
                            <td class=text-center>
                                <div class="farol-verde" *ngIf="data.Rating.includes('A')"></div>
                                <div class="farol-amarelo" *ngIf="data.Rating.includes('B')"></div>
                                <div class="farol-vermelho" *ngIf="data.Rating.includes('C')"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
<div>
