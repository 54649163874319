import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { ExpandedCri } from 'src/app/interfaces/expanded_cri'
import { RegraArredondamento } from 'src/app/interfaces/regra_arredondamento'
import { Emissor } from 'src/app/interfaces/emissor'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Mtm } from 'src/app/interfaces/mtm'
import { IndexadorMensal } from 'src/app/interfaces/indexador_mensal'
import { GrupoEconomico } from 'src/app/interfaces/grupo_economico'
import { ProjetocategoriaExpanded } from 'src/app/interfaces/projeto_categoria_expanded'
import { Carteira } from 'src/app/interfaces/carteira'
import { Municipio } from 'src/app/interfaces/municipio'
import { UnidadeFederativa } from 'src/app/interfaces/unidade_federativa'
import { MunicipioRateio } from 'src/app/interfaces/municipio-rateio'
import { MatDialog } from '@angular/material/dialog'
import { CidadeEditDialogComponent } from '../cidade-edit-dialog/cidade-edit-dialog.component'

@Component({
    selector: 'app-cri-form',
    templateUrl: './cri-form.component.html',
    styleUrls: ['./cri-form.component.css'],
})
export class CriFormComponent implements OnInit {
    @Input('cri') cri: ExpandedCri
    @Input('regrasArredondamento') regrasArredondamento: RegraArredondamento[] = []
    @Input('emissores') emissores: Emissor[] = []
    @Input('municipios') municipios: Municipio[] = []
    @Input('municipiosRateios') municipiosRateios: MunicipioRateio[] = []
    @Input('unidadesFederativas') unidadesFederativas: UnidadeFederativa[] = []
    @Input('mtms') mtms: Mtm[] = []
    @Input('indexadores') indexadores: IndexadorMensal[] = []
    @Input('carteiras') carteiras: Carteira[] = []
    @Input('gruposEconomicos') gruposEconomicos: GrupoEconomico[] = []
    @Input('projetosCategorias') projetosCategorias: ProjetocategoriaExpanded[] = []
    @Input('create') create: Boolean = false
    @Output() onSubmit = new EventEmitter()
    @Output() forceUpdate = new EventEmitter()

    constructor(private _snackBar: MatSnackBar,
                public dialog: MatDialog) {
        this.cri = {
            CRIID: -1,
            Nome: '',
            SerieNro: -1,
            CodigoIF: '',
            EmissaoNro: -1,
            IsDU: -1,
            MtmID: -1,
            TIRR: -1,
            DataEmissao: '',
            DataVencto: '',
            DataInicioPU: '',
            DataPrimeiroPmt: '',
            DiaDoMesPmt: -1,
            PUInicial: -1,
            ArredRegraID: -1,
            PodeTerCorrNeg: -1,
            AcumulaPrimeiroAno: -1,
            ProjetoCategoriaID: -1,
            PrimeiroDCT: -1,
            Liberacoes: [],
            ComprasCri: [],
            IndexadoresCri: [],
            TIRRs: [],
            DatasPagamento: [],
            Opcional: false,
            Lastro: "",
            CorrecaoMonetariaRegra: 1,
            FatorJurosRegra: 1,
        }
    }

    ngOnInit(): void {
        
    }

    ngOnChanges(): void {}

    openSnackbar() {
        this._snackBar.open('Preencha todos os campos', 'Fechar', { duration: 5000 })
    }

    fixDates(str: string): string {
        const dateAux = new Date(str)
        return dateAux.toISOString().split('T')[0]
    }

    testSomaMunicipiosRateios(){
        const sum = this.municipiosRateios.reduce((acc, cur) => acc + cur.Rateio, 0);

        if (sum == 0){
            return false
        }

        return sum !== 1;
    }

    submit() {
        const hardCopy: any = Object.assign({}, this.cri)
        delete hardCopy['VolumeEmissao']

        if (this.create) {
            delete hardCopy['CRIID']
        }

        const values: any[] = Object.values(hardCopy)

        // if (values.includes(-1) || values.includes("") || values.includes(null)) {
        //   this.openSnackbar()
        //   return
        // }                // TODO: Fazar validação

        this.cri.DataEmissao = this.fixDates(this.cri.DataEmissao)
        this.cri.DataInicioPU = this.fixDates(this.cri.DataInicioPU)
        this.cri.DataPrimeiroPmt = this.fixDates(this.cri.DataPrimeiroPmt)
        this.cri.DataVencto = this.fixDates(this.cri.DataVencto)

        if (this.cri.IsDU.toString() == 'false') {
            this.cri.IsDU = 0
        }
        if (this.cri.IsDU.toString() == 'true') {
            this.cri.IsDU = 1
        }
        if (this.cri.PodeTerCorrNeg.toString() == 'false') {
            this.cri.PodeTerCorrNeg = 0
        }
        if (this.cri.PodeTerCorrNeg.toString() == 'true') {
            this.cri.PodeTerCorrNeg = 1
        }
        if (this.cri.AcumulaPrimeiroAno.toString() == 'false') {
            this.cri.AcumulaPrimeiroAno = 0
        }
        if (this.cri.AcumulaPrimeiroAno.toString() == 'true') {
            this.cri.AcumulaPrimeiroAno = 1
        }

        this.onSubmit.emit(this.cri)
    }

    createLiberacaoFormField() {
        let liberacao = { ID: 0, Data: '', Qtde: 0 }
        this.cri.Liberacoes.push(liberacao)
    }

    deleteLiberacaoFormField(index: number) {
        this.cri.Liberacoes.splice(index, 1)
    }

    createCompraFormField() {
        let compra = { ID: 0, Data: '', Qtde: 0, PU: 0, CarteiraID: 2, QtdeVenda: 0, DataVenda: '', PuVenda: -1, TaxaCompra: 0, TaxaVenda: 0 }
        this.cri.ComprasCri.push(compra)
    }

    deleteCompraFormField(index: number) {
        this.cri.ComprasCri.splice(index, 1)
    }

    createIndexadorCriFormField() {
        let indexador = { ID: 0, CRIID: this.cri.CRIID, DataVigencia: '', IndexMensalID: 0, MesCorrecao: 1, IndexOffset: 2 }
        this.cri.IndexadoresCri.push(indexador)
    }

    deleteIndexadorFormField(index: number) {
        this.cri.IndexadoresCri.splice(index, 1)
    }

    createTIRRFormField() {
        let TIRR = { ID: 0, DataVigencia: '', TIRR: 0 }
        this.cri.TIRRs.push(TIRR)
    }

    deleteTIRRFormField(index: number) {
        this.cri.TIRRs.splice(index, 1)
    }

    createDataPagamaneto() {
        this.cri.DatasPagamento.push({
            ID: 0,
            Data: '01/01/2020',
            TaxaAmortOrd: 0,
            TaxaAmortExtra: 0,
            HasAmort: 0,
            HasJuros: 0,
            IsAniversario: 0,
        })
    }

    deleteDataPagamaneto(index: number) {
        this.cri.DatasPagamento.splice(index, 1)
    }

    renderCidadeNome(cidadeID:number):string{
        if (cidadeID < 1 || !cidadeID){
            return ""
        }
        const cidadeObj = this.municipios.filter((obj:any) => obj.ID == cidadeID)[0]
        const cidadeNome = cidadeObj.Nome
        const UFID = cidadeObj.UFID
        const nomeUF = this.unidadesFederativas.filter((obj:any) => obj.ID == UFID)[0].Sigla
        return cidadeNome + " - " + nomeUF
    }

    openCidadeDialog(create:boolean, municipioRateio?:any): void {
        const cidadeDialog = this.dialog.open(CidadeEditDialogComponent, {
            width: '70%',
            height: 'auto',
            autoFocus: false,
            position: {
                top: '100px',
            },
            data: {
                municipioRateio: municipioRateio?{...municipioRateio}:{ID:-1, CRIID:this.cri.CRIID, CidadeID:"", Rateio:0},
                unidadesFederativas: this.unidadesFederativas,
                municipios: this.municipios, 
                create: create?true:false,
            },
        })

        cidadeDialog.afterClosed().subscribe((result:any) => {
            if (result.cancel) {
                return
            }

            if (result.delete){
                const id = result.cidadeRateio.ID
                this.municipiosRateios = this.municipiosRateios.filter(obj => obj.ID !== id)
                return         
            }
            
            const found = this.municipiosRateios.filter((obj:any)=>{
                return (obj.ID == result.cidadeRateio.ID)
            })
            
            if (found.length > 0){
                this.municipiosRateios = this.municipiosRateios.map((obj:MunicipioRateio) => {
                    if (obj.ID == result.cidadeRateio.ID){
                        return result.cidadeRateio
                    } else {
                        return obj
                    }
                })
            } else {
                this.municipiosRateios.push(result.cidadeRateio)
            }
            return
        })
    }
}
