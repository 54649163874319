import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'

@Component({
    selector: 'app-filepicker',
    templateUrl: './filepicker.component.html',
    styleUrls: ['./filepicker.component.css'],
})
export class FilepickerComponent implements OnInit {
    @Input('extensions') extensions: string
    @Output() onFilePick = new EventEmitter()

    constructor() {
        this.extensions = ''
    }

    updateFile(event: any): void {
        this.onFilePick.emit(event.target.files)
    }

    ngOnInit(): void {}
}
