import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { Projeto } from 'src/app/interfaces/projeto'
import { Razoes } from 'src/app/interfaces/razoes'
import { FundoReserva } from 'src/app/interfaces/fundo_reserva'
import { ProjetoCategoria } from 'src/app/interfaces/projeto_categoria'
import { Empreendimento } from '../interfaces/empreendimento'
import { Carteira } from '../interfaces/carteira'
import { Administrador } from '../interfaces/administrador'
import { AgenteFiduciario } from '../interfaces/agende-fiduciario'
import { Ativo } from '../interfaces/ativo'
import { Setor } from '../interfaces/setor'
import { TipoProjeto } from '../interfaces/tipo_projeto'
import { Servicer } from '../interfaces/servicer'

@Injectable({
    providedIn: 'root',
})
export class ProjetosService {
    constructor(private http: RequestService) {}

    private gestaoBlueprintEndpoint: string = this.http.apiEndpoint + 'gestao/'

    public getProjetosServicers(data: any) {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'projetos_servicers', data)
    }

    public getObras(projeto_id: number) {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'projeto_obras', { projeto_id: projeto_id })
    }

    public getProjetosCategoriasInCarteira(data: any) {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'projetos_categorias/carteira', data)
    }

    public getMetodologiasFundoReserva() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'fundo_reserva/metodologias', {})
    }

    public getServicers() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'servicers', {})
    }

    public updateServicer(servicer: Servicer){
        let formData = new FormData()
        formData.append('servicer', JSON.stringify(servicer))
        return this.http.putRequest(this.gestaoBlueprintEndpoint + 'servicers/update', formData)
    }

    public createServicer(servicer: any){
        let formData = new FormData()
        formData.append('servicer', JSON.stringify(servicer))
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'servicers/create', formData)
    }

    public getMetodologiasLTV() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'metodologias_LTV', {})
    }
    public getMetodologiasEstoque() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'metodologias_estoque', {})
    }

    public getMetodologiasRFM() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'metodologias_rfm', {})
    }

    public getMetodologiasInadimplencia() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'metodologias_inadimplencia', {})
    }

    public createProjeto(projeto: Projeto, razoes: Razoes, fundoReserva: FundoReserva) {
        let formData = new FormData()
        formData.append('projeto', JSON.stringify(projeto))
        formData.append('razoes', JSON.stringify(razoes))
        formData.append('fundo_reserva', JSON.stringify(fundoReserva))

        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'projeto/create', formData)
    }

    public updateProjeto(projeto: Projeto) {
        let formData = new FormData()
        formData.append('projeto', JSON.stringify(projeto))
        return this.http.putRequest(this.gestaoBlueprintEndpoint + 'projeto/update', formData)
    }

    public getProjetos() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'projetos', {})
    }
    
    public getProjeto(data: any) {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'projeto', data)
    }
    
    public getCategorias() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'categorias', {})
    }
    
    public getCarteiras() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'carteiras', {})
    }

    public updateCarteira(carteira: Carteira){
        let formData = new FormData()
        formData.append('carteira', JSON.stringify(carteira))
        return this.http.putRequest(this.gestaoBlueprintEndpoint + 'carteiras/update', formData)
    }

    public createCarteira(carteira:any){
        let formData = new FormData()
        formData.append('carteira', JSON.stringify(carteira))
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'carteiras/create', formData)
    }

    public getAdministradores() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'administradores', {})
    }

    public updateAdministrador(administrador: Administrador){
        let formData = new FormData()
        formData.append('administrador', JSON.stringify(administrador))
        return this.http.putRequest(this.gestaoBlueprintEndpoint + 'administradores/update', formData)
    }

    public createAdministrador(administrador:any){
        let formData = new FormData()
        formData.append('administrador', JSON.stringify(administrador))
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'administradores/create', formData)
    }

    public getAgentesFiduciarios() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'agentes_fiduciarios', {})
    }

    public updateAgenteFiduciario(agenteFiduciario: AgenteFiduciario){
        let formData = new FormData()
        formData.append('agente_fiduciario', JSON.stringify(agenteFiduciario))
        return this.http.putRequest(this.gestaoBlueprintEndpoint + 'agentes_fiduciarios/update', formData)
    }

    public createAgenteFiduciario(agenteFiduciario:any){
        let formData = new FormData()
        formData.append('agente_fiduciario', JSON.stringify(agenteFiduciario))
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'agentes_fiduciarios/create', formData)
    }

    public getAtivos() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'ativos', {})
    }

    public updateAtivo(ativo: Ativo){
        let formData = new FormData()
        formData.append('ativo', JSON.stringify(ativo))
        return this.http.putRequest(this.gestaoBlueprintEndpoint + 'ativos/update', formData)
    }

    public createAtivo(ativo:any){
        let formData = new FormData()
        formData.append('ativo', JSON.stringify(ativo))
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'ativos/create', formData)
    }

    public getSetores() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'setores', {})
    }

    public updateSetor(setor: Setor){
        let formData = new FormData()
        formData.append('setor', JSON.stringify(setor))
        return this.http.putRequest(this.gestaoBlueprintEndpoint + 'setores/update', formData)
    }

    public createSetor(setor:any){
        let formData = new FormData()
        formData.append('setor', JSON.stringify(setor))
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'setores/create', formData)
    }
    
    public getTiposProjeto() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'tipos_projetos', {})
    }
    
    public updateTipoProjeto(tipoProjeto: TipoProjeto){
        let tipoProjetoCorrected: any = {ID:tipoProjeto.TipoID, Sigla:tipoProjeto.Sigla}
        let formData = new FormData()
        formData.append('tipo_projeto', JSON.stringify(tipoProjetoCorrected))
        return this.http.putRequest(this.gestaoBlueprintEndpoint + 'tipos_projetos/update', formData)
    }

    public createTipoProjeto(tipoProjeto:any){
        let { Nome, ...tipoProjetoWithoutNome} = tipoProjeto
        let formData = new FormData()
        formData.append('tipo_projeto', JSON.stringify(tipoProjetoWithoutNome))
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'tipos_projetos/create', formData)
    }

    public getIndexadores() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'indexadores', {})
    }

    public createProjetoCategoria(projetoCategoria: ProjetoCategoria) {
        let formData = new FormData()
        formData.append('projeto_categoria', JSON.stringify(projetoCategoria))

        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'projeto_categoria/create', formData)
    }

    public updateProjetoCategoria(projetoCategoria: ProjetoCategoria) {
        let formData = new FormData()
        formData.append('projeto_categoria', JSON.stringify(projetoCategoria))

        return this.http.putRequest(this.gestaoBlueprintEndpoint + 'projeto_categoria/update', formData)
    }

    public getProjetosCategorias() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'projetos_categorias', {})
    }

    public getProjetoCategoria(data: any) {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'projeto_categoria', data)
    }

    public createEmpreendimento(empreendimento: Empreendimento) {
        let formData = new FormData()
        if (typeof empreendimento.ConsiderarParcelaBalao === 'boolean') {
            if (empreendimento.ConsiderarParcelaBalao == true) {
                empreendimento.ConsiderarParcelaBalao = 1
            } else if (empreendimento.ConsiderarParcelaBalao == false) {
                empreendimento.ConsiderarParcelaBalao = 0
            }
        }
        formData.append('obra', JSON.stringify(empreendimento))

        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'obras/create', formData)
    }

    public updateEmpreendimento(empreendimento: Empreendimento) {
        let formData = new FormData()
        if (typeof empreendimento.ConsiderarParcelaBalao === 'boolean') {
            if (empreendimento.ConsiderarParcelaBalao == true) {
                empreendimento.ConsiderarParcelaBalao = 1
            } else if (empreendimento.ConsiderarParcelaBalao == false) {
                empreendimento.ConsiderarParcelaBalao = 0
            }
        }
        formData.append('obra', JSON.stringify(empreendimento))

        return this.http.putRequest(this.gestaoBlueprintEndpoint + 'obras/update', formData)
    }

    public getEmpreendimentos() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'obras', {})
    }

    public getEmpreendimento(obraId: number) {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'obra', { obra_id: obraId })
    }

    public getMesesBaseProjeto(projetoId: number) {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'meses_base_dashboard', { projeto_id: projetoId })
    }

    public getMesesBaseNotas() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'notas/meses_base', {})
    }

    public getNotasResumo(mesBase: string) {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'notas/resumo', { mes_base: mesBase })
    }

    public getNotasDetailed(mesBase: string) {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'notas/detailed', { mes_base: mesBase })
    }

    public getCriteriosNotas() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'notas/criterios', {})
    }

    public updateCriteriosNotas(formData: FormData) {
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'notas/criterios/update', formData)
    }

    public calculateNota(formData: FormData) {
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'notas/calculate_nota_cri', formData)
    }

    public uploadNotasInputs(formData: FormData) {
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'notas/upload_inputs', formData)
    }

    public recalculateAllNotas(formData: FormData) {
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'notas/recalculate_all', formData)
    }

    public getMesesBaseResumo() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'meses_base_resumo', {})
    }

    public getTabelaResumo(mesBase: string) {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'tabela_resumo', { mes_base: mesBase })
    }

    public updateResumo(id: number) {
        let formData = new FormData()
        formData.append('id', JSON.stringify(id))
        return this.http.putRequest(this.gestaoBlueprintEndpoint + 'tabela_resumo/update_linha', formData)
    }

    public updateManualInputs(data: any) {
        return this.http.putRequest(this.gestaoBlueprintEndpoint + 'tabela_resumo/update_manual_inputs', data)
    }

    public calculateTabelaResumo(formData: any) {
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'tabela_resumo/calculate_total', formData)
    }

    public getIsTabelaConsolidada(mesBase: string) {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'tabela_resumo/is_consolidada', { mes_base: mesBase })
    }

    public desbloquearResumo(mesBase: any) {
        let formData = new FormData()
        formData.append('mes_base', mesBase)
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'tabela_resumo/desconsolidar_mes', formData)
    }

    public consolidarResumo(mesBase: any) {
        let formData = new FormData()
        formData.append('mes_base', mesBase)
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'tabela_resumo/consolidar_mes', formData)
    }

    public getEventos() {
        return this.http.getRequest(this.gestaoBlueprintEndpoint + 'eventos', {})
    }

    public createNewEvento(evento: any) {
        let formData = new FormData()
        formData.append('evento', JSON.stringify(evento))
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'dashboard/eventos/create', formData)
    }

    public updateEvento(evento: any) {
        let formData = new FormData()
        formData.append('evento', JSON.stringify(evento))
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'dashboard/eventos/update', formData)
    }

    public updateEventos(eventos: any) {
        let formData = new FormData()
        formData.append('eventos', JSON.stringify(eventos))
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'dashboard/eventos/update_many', formData)
    }

    public deleteEvento(eventoID: any) {
        let formData = new FormData()
        formData.append('evento_id', eventoID)
        return this.http.postRequest(this.gestaoBlueprintEndpoint + 'dashboard/eventos/delete', formData)
    }
}
