<div class="chart-wrapper">
    <div id="ltv-limit-line"></div>
    <ngx-charts-bar-vertical-stacked
        [scheme]="colorScheme"
        [results]="data"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [animations]="animations"
        [showDataLabel]="true"
        [showGridLines]="showGridLines"
        [barPadding]="barPadding"
        [yScaleMax]="yScaleMax"
        [yAxisTicks]="yAxisTicks"
        [dataLabelFormatting]="formatDataLabel"
        [yAxisTickFormatting]="formatYAxis"
    >
        >
    </ngx-charts-bar-vertical-stacked>
</div>
