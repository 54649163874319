import { Injectable } from '@angular/core'
import { RequestService } from './request.service'

@Injectable({
    providedIn: 'root',
})
export class ControladoriaService {
    constructor(private http: RequestService) {}

    private controladoriaBlueprintEndpoint: string = this.http.apiEndpoint + 'controladoria/'

    public getTables() {
        return this.http.getRequest(this.controladoriaBlueprintEndpoint + 'tables', {})
    }

    public uploadFile(data: any) {
        return this.http.postRequest(this.controladoriaBlueprintEndpoint + 'upload_table', data)
    }
}
